import sb from '@sb/util'

export default {
	props: ['text', 'searchText', 'exactly'],

	render() {
		let searchText = sb.unicodeToAscii(this.searchText).toLowerCase()
		let text = sb.unicodeToAscii(this.text).toLowerCase()

		if (this.exactly) {
			searchText = this.searchText
			text = this.text
		}
		let indexes = getAllIndexesSubstring(searchText, text)

		return (
			<div style='white-space: pre-wrap'>
				{this.text.substring(0, indexes.length ? indexes[0] : text.length)}
				{indexes.map((strIndex, i) => {
					let endIndex = i === indexes.length - 1 ? text.length : indexes[i + 1]
					return (
						<Fragment>
							<span style='background: yellow'>{this.text.substring(strIndex, strIndex + searchText.length)}</span>
							{this.text.substring(strIndex + searchText.length, endIndex)}
						</Fragment>
					)
				})}
			</div>
		)
	},
}

function getAllIndexesSubstring(searchStr, str) {
	let searchStrLength = searchStr.length
	if (searchStrLength === 0) return []

	let startIndex = 0
	let outs = []

	let asciiStr = sb.unicodeToAscii(str).toLowerCase()
	let asciiSearchStr = sb.unicodeToAscii(searchStr).toLowerCase()

	let index = asciiStr.indexOf(asciiSearchStr)
	while (index > -1) {
		outs.push(index)
		startIndex = index + asciiSearchStr.length
		index = asciiStr.indexOf(asciiSearchStr, startIndex)
	}

	return outs
}
