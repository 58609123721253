import sb from '@sb/util'
import store from '@sb/store'
import {isGroupIdNormal} from '../common.js'
import {canEditUser} from '../commons/can_do_objects.js'

export default {
	name: 'user-lead-owner',
	props: ['user', 'refresh'],
	data() {
		return {
			isEditLeadOwnersModalOpenned: false,
			inviteGroupLoadings: {},
			inviteAgentLoadings: {},
			keyword: '',
			tab: 'agent', // agent, group
			agents: [],
		}
	},

	mounted() {},

	methods: {
		setAgents() {
			let agentids = lo.get(this.user, 'lead_owners', [])
			this.agents = lo.map(agentids, (id) => store.matchAgent()[id] || {})
			this.agents = lo.filter(this.agents, (agent) => agent.type === 'agent')
		},

		async inviteLeadOwnersAgent(agentid) {
			let agent = store.matchAgent(agentid)
			this.agents = [...this.agents, agent]

			this.inviteAgentLoadings[agentid] = true
			this.$forceUpdate()
			let agentids = lo.get(this.user, 'lead_owners', [])
			agentids.push(agentid)
			let user = lo.cloneDeep(this.user)
			delete user.first_content_view
			delete user.profile_id
			delete user.lastest_content_view
			delete user.start_content_view
			user.lead_owners = agentids
			user.lead_owners = user.lead_owners.filter((owner) => owner !== '')
			await store.updateUser(user)
			this.inviteAgentLoadings[agentid] = false
			this.$forceUpdate()
		},

		async inviteLeadOwnersGroup(groupId) {
			this.inviteGroupLoadings[groupId] = true
			this.$forceUpdate()
			let agentids = lo.get(this.user, 'lead_owners', [])
			let addingAgentIds = lo.get(store.matchAgentGroup(), [groupId, 'agent_ids'])
			lo.each(addingAgentIds, (agid) => {
				let agent = store.matchAgent()[agid]
				if (!agent) return
				if (lo.includes(agentids, agid)) return
				agentids.push(agid)
			})

			let user = lo.cloneDeep(this.user)
			delete user.first_content_view
			delete user.lastest_content_view
			delete user.start_content_view
			user.lead_owners = agentids
			await store.updateUser(user)
			this.inviteGroupLoadings[groupId] = false
			this.$forceUpdate()
		},

		setToMainAssignee(agentid) {
			this.saveAttribute({key: 'owner'}, {key: 'owner', text: agentid})
		},

		renderLeadOwenersEditModal() {
			let mainOwner = sb.getUserTextAttr(this.user, 'owner')
			let $agents = lo.map(this.agents, (agent) => {
				return (
					<div class='assign_agent__member'>
						<Avatar class='assign_agent__member_avatar' agent={agent} size='md' nodot />
						<div class='assign_agent__member_info flex__1'>
							<div class='assign_agent__member_name'>{sb.getAgentDisplayName(agent, store.me())}</div>
							<div class='assign_agent__member_email'>{agent.email}</div>
						</div>
						{mainOwner === agent.id ? (
							<Icon
								name='star'
								stroke-width='2'
								size='16'
								class='ml-2 primary_star_icon active'
								v-tooltip={this.$t('main_assignee')}
							/>
						) : (
							<Icon
								name='star'
								stroke-width='2'
								size='16'
								class='ml-2 primary_star_icon'
								v-tooltip={this.$t('set_as_main_assignee')}
								vOn:click={() => this.setToMainAssignee(agent.id)}
							/>
						)}
					</div>
				)
			})
			if (!lo.size(this.agents)) {
				$agents = (
					<div class='text__muted'>
						<em>{this.$t('no_assign_yet')}</em>
					</div>
				)
			}
			let uninvitedAgents = lo.filter(
				store.matchAgent(),
				(agent) => !lo.get(this.user, 'lead_owners', []).includes(agent.id) && agent.state === 'active',
			)
			uninvitedAgents = lo.filter(uninvitedAgents, (ag) => ag.type === 'agent')
			uninvitedAgents = lo.filter(uninvitedAgents, (ag) => {
				let keyword = sb.unicodeToAscii(this.keyword).toLowerCase()
				let name = sb.getAgentDisplayName(ag)
				name = sb.unicodeToAscii(name).toLowerCase()
				let email = sb.unicodeToAscii(ag.email || '').toLowerCase()

				return name.indexOf(keyword) > -1 || email.indexOf(keyword) > -1
			})
			let me = []
			let remains = []
			lo.each(uninvitedAgents, (agent) => {
				if (agent.id === store.me().id) {
					me.push(agent)
				} else {
					remains.push(agent)
				}
			})
			uninvitedAgents = [...me, ...remains]

			let $tabs = (
				<Fragment>
					<div class='mt-3 tab'>
						<div
							class={{tab__item: true, tab__item__active: this.tab === 'agent'}}
							vOn:click={() => this.changeTab('agent')}
						>
							{this.$t('add_agent')}
						</div>
						<div
							class={{tab__item: true, tab__item__active: this.tab === 'group'}}
							vOn:click={() => this.changeTab('group')}
						>
							{this.$t('add_agents_group')}
						</div>
					</div>
					<div class='input_search mb-3'>
						<Icon name='search' class='input_search__icon_search' size={16} stroke-width='2' />
						<input vModel={this.keyword} class='form-control input_search__input' placeholder={this.$t('search')} />
						{this.keyword && (
							<Icon
								name='x'
								class='input_search__icon_close'
								size='16'
								stroke-width='2'
								vOn:click={() => (this.keyword = '')}
							/>
						)}
					</div>
				</Fragment>
			)
			let $invite = (
				<div>
					{lo.map(uninvitedAgents, (agent) => {
						let $inviteAgentBtn = (
							<button
								class='btn btn__sm btn__primary no-shrink'
								vOn:click={(_) => this.inviteLeadOwnersAgent(agent.id)}
								style='width: 72px; display: flex; align-items: center;'
							>
								<Icon name='plus' stroke-width={2} size='16' />
								{this.$t('add')}
							</button>
						)
						if (this.inviteAgentLoadings[agent.id]) {
							$inviteAgentBtn = (
								<button class='btn btn__sm btn__primary no-shrink' disabled style='width: 72px'>
									<Spinner class='mr-2' size={16} mode='blue' />
								</button>
							)
						}

						return (
							<div class='assign_agent__member'>
								<Avatar class='assign_agent__member_avatar' agent={agent} size='sm' nodot />
								<div class='assign_agent__member_info' style={{width: '184px'}}>
									<div class='assign_agent__member_name'>
										{sb.getAgentDisplayName(agent, store.me())}
										{agent.id === store.me().id && (
											<span class='text__muted' style='font-weight: 400'>
												{' '}
												({this.$t('me')})
											</span>
										)}
									</div>
									<div class='text__sm text__muted text__truncate'>{agent.email}</div>
								</div>
								{$inviteAgentBtn}
							</div>
						)
					})}
				</div>
			)

			let groups = lo.filter(store.matchAgentGroup(), (group) => isGroupIdNormal(group.id))
			groups = lo.filter(groups, (group) => {
				let name = sb.unicodeToAscii(group.name || '').toLowerCase()
				let keyword = sb.unicodeToAscii(this.keyword).toLowerCase()
				return name.indexOf(keyword) > -1
			})
			let $groups = (
				<div>
					{lo.map(groups, (group) => {
						let $inviteAgentBtn = (
							<button
								class='btn btn__sm btn__primary no-shrink'
								vOn:click={(_) => this.inviteLeadOwnersGroup(group.id)}
								style='width: 72px; display: flex; align-items: center;'
							>
								<Icon name='plus' stroke-width={2} size='16' />
								{this.$t('add')}
							</button>
						)
						if (this.inviteGroupLoadings[group.id]) {
							$inviteAgentBtn = (
								<button class='btn btn__sm btn__primary no-shrink' disabled style='width: 72px'>
									<Spinner class='mr-2' size={16} mode='blue' />
								</button>
							)
						}

						return (
							<div class='assign_agent__member'>
								<img2 src={group.logo_url} />
								<div class='assign_agent__member_info'>
									<div class='assign_agent__member_name'>{group.name}</div>
								</div>
								{$inviteAgentBtn}
							</div>
						)
					})}
				</div>
			)

			return (
				<Modal show={this.isEditLeadOwnersModalOpenned} vOn:bg={() => (this.isEditLeadOwnersModalOpenned = false)}>
					<div class='modal__background'>
						<div
							class='modal_content'
							style='width: 350px; height: 600px; overflow: hidden; display: flex; flex-direction: column'
						>
							<div class='modal_content__header'>
								<div class='d-flex align-items-center'>
									<div>{this.$t('assignee')}</div>
									<Icon
										name='x'
										class='btn__close ml-auto'
										vOn:click={() => (this.isEditLeadOwnersModalOpenned = false)}
									/>
								</div>
							</div>
							{canEditUser(this.user) ? (
								<div class='modal_content__main' style=' overflow: auto; flex: 1'>
									{$agents}
									{$tabs}
									{this.tab === 'agent' ? $invite : $groups}
								</div>
							) : (
								<div class='text__center text__muted pl-4 pr-4 pt-5'>
									{this.$t('you_dont_have_permission_edit_user')}
								</div>
							)}
						</div>
					</div>
				</Modal>
			)
		},

		changeTab(tab) {
			this.tab = tab
			this.keyword = ''

			this.loadAgentGroups()
		},

		async loadAgentGroups() {
			if (this.tab !== 'group') return
			if (lo.size(store.matchAgentGroup())) return

			await store.fetchAgentGroups()
			this.$forceUpdate()
		},

		async saveAttribute(attr, newattr) {
			attr.saving = true
			this.$forceUpdate()
			let {error} = await store.updateUser({id: this.user.id, attributes: [newattr]})
			attr.saving = false
			this.$forceUpdate()

			if (error) this.$showError(error)
		},

		openModal() {
			this.isEditLeadOwnersModalOpenned = true
			this.keyword = ''
			this.changeTab('agent')
			this.setAgents()
		},
	},

	render() {
		if (this.refresh) lo.noop()
		if (!this.user) return null

		let agents = lo.get(this.user, 'lead_owners', [])
		agents = lo.map(agents, (id) => store.matchAgent()[id] || {})
		agents = lo.filter(agents, (agent) => agent.type === 'agent')

		let mainOwner = sb.getUserTextAttr(this.user, 'owner')
		let $avatars = <AvatarGroup agents={agents} size={7} xs rounded />
		if (mainOwner) {
			mainOwner = store.matchAgent()[mainOwner] || {id: mainOwner, type: 'agent'}
			$avatars = (
				<div class='d-inline-flex align-items-center'>
					<AvatarGroup agents={[mainOwner]} size={7} xs rounded />
					<div class='ml-2'>{sb.getAgentDisplayName(mainOwner)}</div>
				</div>
			)
		}

		return (
			<div class='user_notes'>
				<div class='user_profile__head' style='justify-content: flex-start'>
					<div class='profile_section_title attribute_label'>{this.$t('assignee')}</div>
					{mainOwner ? (
						<div class='d-flex align-items-center' vOn:click={this.openModal} style='cursor: pointer'>
							{$avatars}
						</div>
					) : (
						<div class='d-flex align-items-center' vOn:click={this.openModal} style='cursor: pointer'>
							<a href='javascript:;'>{this.$t('add_assignee')}</a>
						</div>
					)}
				</div>
				{this.renderLeadOwenersEditModal()}
			</div>
		)
	},
}
