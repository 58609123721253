import store from '@sb/store'
import sb from '@sb/util'
import UserDisplayName from '../commons/user_display_name.js'
import AddLeadModal from '../lead/add_user_modal.js'

export default {
	name: 'ticket-sla-modal',
	props: ['ticket_id', 'open', 'current'],
	data() {
		return {
			keyword: '',
			users: [],
			isAddLeadModalOpened: false,
			anchor: '',
			loadingMore: false,
			searching: false,

			saving: false,
		}
	},

	watch: {
		open() {
			if (this.open) {
				this.resetData()
			}
		},
	},

	methods: {
		closeModal() {
			this.$emit('close')
		},

		async resetData() {
			this.users = []
			this.saving = false
			this.keyword = ''
			this.submitUid = ''
			this.isAddLeadModalOpened = false
			this.newUser = {}
			this.anchor = ''
			this.loadingMore = false
			this.searching = false

			await this.$nextTick()
			if (this.$refs.search_input) this.$refs.search_input.focus()
		},

		//onInput: lo.throttle(() => {
		//this.searchLead()
		//}, 1000),

		//onInput: lo.throttle(this.searchLead, 1000),

		onInput: lo.throttle(function () {
			this.searchLead()
		}, 1000),

		async searchLead() {
			let keyword = this.keyword
			if (!lo.trim(keyword)) return

			this.searching = true
			let fetchId = sb.randomString(8)
			this.fetchId = fetchId
			let query = {
				condition: {
					all: [
						{
							key: 'keyword',
							type: 'text',
							text: {contain: [keyword]},
						},
					],
				},
				limit: 10,
				order_by: '-attr:lead_at',
			}
			let res = await store.fetchLead(query)
			if (this.fetchId !== fetchId) return // avoid rat race
			this.searching = false
			this.users = lo.get(res, 'body.users', [])
			this.anchor = lo.get(res, 'body.anchor')
		},

		async loadMore() {
			let query = {
				condition: {
					all: [
						{
							key: 'keyword',
							type: 'text',
							text: {contain: [lo.trim(this.keyword)]},
						},
					],
				},
				limit: 10,
				order_by: '-attr:lead_at',
				anchor: this.anchor,
			}

			this.loadingMore = true
			let res = await store.fetchLead(query)
			this.loadingMore = false
			let users = lo.get(res, 'body.users', [])
			this.users = [...this.users, ...users]
			this.anchor = lo.get(res, 'body.anchor')
		},

		async submit(uid) {
			if (!this.ticket_id) {
				let users = [...this.current, {id: uid}]
				this.$emit('success', users)
				this.$emit('close')
				return
			}

			if (this.saving) return
			this.saving = true
			this.submitUid = uid

			let ticket = store.matchTicket(this.ticket_id) || {id: this.ticket_id}
			let users = ticket.users || []
			users.push({id: uid})
			await store.updateTicket({
				id: this.ticket_id,
				users,
				_update_fields: ['users'],
			})

			this.saving = false
			this.$emit('close')
			this.$emit('success')
		},

		openAddLeadModal() {
			let newUser = {
				attributes: [
					{key: 'lifecycle_stage', text: 'lead'},
					{key: 'lead_source', text: 'import'},
				],
			}
			if (sb.checkEmail(this.keyword)) {
				newUser.attributes.push({
					key: 'emails',
					text: this.keyword,
				})
			} else if (sb.checkPhoneNumber(this.keyword)) {
				newUser.attributes.push({
					key: 'phones',
					text: this.keyword,
				})
			} else {
				newUser.attributes.push({
					key: 'fullname',
					text: this.keyword,
				})
			}
			this.newUser = newUser
			this.isAddLeadModalOpened = true
		},
	},

	render() {
		let ticket = store.matchTicket(this.ticket_id) || {id: this.ticket_id}
		let ticketUserIds = lo.get(ticket, 'users') || []
		if (!this.ticket_id) {
			ticketUserIds = this.current
		}
		ticketUserIds = lo.map(ticketUserIds, (user) => user.id)
		ticketUserIds = lo.uniq(ticketUserIds)
		ticketUserIds = lo.filter(ticketUserIds, Boolean)

		return (
			<div>
				<Modal show={this.open} vOn:bg={this.closeModal}>
					<div class='modal__background'>
						<div class='modal__content' style='padding: 20px; width: 450px;'>
							<div class='d-flex align-items-center mb-4'>
								<div class='text__lg text__semibold'>{this.$t('add_user_to_ticket')}</div>
								<Icon name='x' class='btn__close ml-auto' vOn:click={this.closeModal} />
							</div>

							<input
								class='form-control'
								ref='search_input'
								vOn:input={this.onInput}
								vModel={this.keyword}
								placeholder={this.$t('find_customer_by_name_email_phone')}
							/>
							<div class='mt-4'>
								<div class='mb-2 d-flex align-items-center'>
									<span class='text__uppercase text__muted text__semibold text__sm'>{this.$t('result')}</span>
									{this.searching && <Spinner size='18' class='ml-2' mode='blue' />}
								</div>
								<div class='sbz_box' style='height: 300px; overflow-x: hidden; overflow-y: auto; position: relative'>
									{lo.size(this.users) ? (
										<Fragment>
											{lo.map(this.users, (user) => {
												let added = lo.includes(ticketUserIds, user.id)

												return (
													<div class='d-flex align-items-center sbz_box_item' style='overflow: hidden'>
														<div
															class='mr-4 flex__1 clickable d-flex align-items-center'
															style='overflow: hidden'
															vOn:click={() => this.$root.$emit('showQuickview', {user_id: user.id})}
														>
															<Avatar user={user} size='40' />
															<div class='flex__1 ml-3' style='overflow: hidd;'>
																<div class='text__semibold text__truncate'>
																	<UserDisplayName user={user} />
																</div>
																<div class='text__xs text__muted text__truncate'>
																	Email: {sb.getUserTextAttr(user, 'emails')}
																</div>
																<div class='text__xs text__muted text__truncate'>
																	{this.$t('phn')}: {sb.getUserTextAttr(user, 'phones')}
																</div>
															</div>
														</div>
														{added ? (
															<button
																type='button'
																class='btn btn__sm btn__light no-shrink'
																disabled
																style='width: 90px'
															>
																{this.$t('added')}
															</button>
														) : (
															<button
																type='button'
																class='btn btn__sm btn__primary no-shrink'
																disabled={this.saving}
																vOn:click={() => this.submit(user.id)}
																style='width: 90px'
															>
																{this.$t('add')}
																{this.submitUid === user.id && <Icon name='loader-2' class='ml-2 rotate' size='16' />}
															</button>
														)}
													</div>
												)
											})}
											{this.anchor && (
												<div class='sbz_box_item text__center'>
													<a href='javascript:;' vOn:click={this.loadMore}>
														{this.$t('load_more')}
													</a>
													{this.loadingMore && <Spinner size='18' mode='blue' class='ml-2' />}
												</div>
											)}
										</Fragment>
									) : (
										<Fragment>
											{this.searching ? (
												<div class='loading-wrapper'>
													<Spinner size='32' mode='blue' />
												</div>
											) : (
												<Fragment>
													<div style='padding-top: 80px' class='text__center pl-4 pr-4'>
														{lo.trim(this.keyword) ? (
															<div class=''>
																<span class='text__muted'>{this.$t('no_search_result')}.</span>{' '}
																<a href='javascript:;' vOn:click={this.openAddLeadModal}>
																	+ {this.$t('add_lead')}
																</a>
															</div>
														) : (
															<div class='text__muted'>{this.$t('start_searching_customer')}</div>
														)}
													</div>
												</Fragment>
											)}
										</Fragment>
									)}
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<AddLeadModal
					user={this.newUser}
					open={this.isAddLeadModalOpened}
					vOn:close={() => (this.isAddLeadModalOpened = false)}
					vOn:success={(user) => {
						this.users = [...this.users, user]
						this.submit(user.id)
					}}
				/>
			</div>
		)
	},
}
