// -----------0+++++++++++10-----15++++++20---------
//arr = [[0, 10], [15, 20]]
let Segment = class {
	constructor(arr) {
		this.arr = arr ? arr.sort(this.compare) : [] //sorted
	}

	add(min, max) {
		if (max < min) return

		if (!this.arr.length) return this.arr.push([min, max])

		let compareArr = [min, max]

		for (let i = 0; i < this.arr.length; i++) {
			let joinArr = this.join(compareArr, this.arr[i])
			if (!joinArr) {
				this.arr.push(compareArr)
				break
			}
			compareArr = joinArr
				this.arr.splice(i, 1, compareArr)
		}

		this.arr.sort(this.compare)
	}

	get(min, max) {
		if (max < min) return true
		if (!this.arr.length) return [min, max]

		for (let i = 0; i < this.arr.length; i++) {
			if (this.arr[i][0] <= min && max <= this.arr[i][1]) {
				return true // segment already covered
			}
			if (this.arr[i][0] <= min && min <= this.arr[i][1]) {
				min = this.arr[i][1]
				break
			}
		}

		for (let i = this.arr.length - 1; i > -1; i--) {
			if (this.arr[i][0] <= max && max <= this.arr[i][1]) {
				max = this.arr[i][0]
				break
			}
		}

		return [min, max] //  the largest segment that is not covered
	}

	join(arr_1, arr_2) {
		if ((arr_1[0] <= arr_2[1] && arr_1[1] >= arr_2[0]) || (arr_2[0] <= arr_1[1] && arr_2[1] >= arr_1[0]))
			return [Math.min(arr_1[0], arr_2[0]), Math.max(arr_1[1], arr_2[1])]
		return null
	}

	compare(a, b) {
		return a[0] < b[0] ? -1 : 1
	}
}

module.exports = Segment
