var config = require('@sb/config')
function parseJSON(str) {
	try {
		return JSON.parse(str)
	} catch (e) {}
}

function getTimezone() {
	let offset = new Date().getTimezoneOffset()
	let hours = Math.floor(Math.abs(offset) / 60)
	let minutes = Math.floor(Math.abs(offset) % 60)

	// The time-zone offset is the difference, in minutes, between UTC and local time
	const sign = offset < 0 ? '+' : '-'

	if (hours < 10) {
		hours = '0' + hours
	}

	if (minutes < 10) {
		minutes = '0' + minutes
	}

	return `${sign}${hours}:${minutes}`
}

function offsetToMin(offset) {
	if (!offset) return 0
	if (offset.length <= 2) {
		offset += ':00'
	}
	let sp = offset.split(':')
	if (sp.length !== 2) return 0
	let hour = parseInt(sp[0])
	if (hour < -23 || hour > 23) return 0
	let min = parseInt(sp[1])
	if (min < 0 || min > 59) return 0
	return hour * 60 + min
}

function unixSec(t) {
	return Math.floor(getMs(t) / 1000)
}

function getMs(t) {
	if (!t) t = 0
	else if (typeof t !== 'number') t = new Date(t).getTime()

	if (t > 1e18) {
		// nanoseconds
		return Math.floor(t / 1e6)
	}
	if (t > 1e15) {
		// microseconds
		return Math.floor(t / 1e3)
	}
	if (t > 1e12) {
		// milliseconds
		return t
	}
	return t * 1e3 // seconds
}

var sleep = (ms) => new Promise((res) => setTimeout(res, ms))

var timeDiff = 0
function loopTime() {
	var xhr = new window.XMLHttpRequest()
	xhr.open('GET', config.ApiURL + 'time', true)
	let responsed
	setTimeout(() => {
		if (responsed) return
		responsed = 'timeout'
		setTimeout(loopTime, 60000)
	}, 5000)

	let startms = Date.now()
	xhr.onreadystatechange = function () {
		if (responsed) return
		// Call a function when the state changes.
		if (this.readyState === window.XMLHttpRequest.DONE) {
			if (responsed) return // already timeout
			responsed = 'ok'
			if (this.status === 200) {
				let t = parseJSON(this.responseText)
				var latences = Math.abs(Date.now() - startms) / 2
				var serverMs = new Date(t).getTime() + latences
				var localMs = Date.now()
				timeDiff = Math.floor(serverMs - localMs)
			}
			setTimeout(loopTime, 30000)
		}
	}
	xhr.send()
}

// true unix millisecond
function now() {
	return Date.now() + timeDiff // millisecond on server
}

loopTime()

module.exports = {
	now,
	getMs,
	sleep,
	unixSec,
	offsetToMin,
	getTimezone,
}
