module.exports = {
	copyToClipboard: (text) => {
		var el = document.createElement('textarea')
		el.value = text
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
	},
}
