import {format, formatDistanceToNow, startOfDay} from 'date-fns'
import {getDateFnsLocale, getCurrentLanguage} from '../languages.js'

const Day = {
	name: 'day',
	props: ['currentDate', 'date', 'startDate', 'endDate'],

	render() {
		let className = []

		if (sameDay(Date.now(), this.date)) className.push('active')

		if (sameDay(this.date, this.startDate)) {
			className.push('start')
			if (sameDay(this.date, this.endDate)) className.push('end')
		} else if (sameDay(this.date, this.endDate)) className.push('end')
		else if (this.startDate < this.date && this.date < this.endDate) className.push('between')
		// if (!sameMonth(this.date, this.currentDate)) className.push('muted')
		// if (isBeforeDay(this.date, new Date())) className.push('muted') // only if we want hide a day in the pass

		return (
			<span vOn:click={() => this.$emit('click', this.date)} class={className.join(' ')}>
				{new Date(this.date).getDate()}
			</span>
		)
	},
}

var Days = {
	name: 'days',
	props: ['date', 'startDate', 'endDate'],

	render() {
		let thisDate = this.date

		const daysInMonth = numDaysInMonth(this.date.getFullYear(), this.date.getMonth() + 1)

		const firstDayDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
		let previousMonth = new Date(this.date)
		previousMonth.setDate(2)
		previousMonth.setMonth(this.date.getMonth() - 1)

		const previousMonthDays = numDaysInMonth(previousMonth.getFullYear(), previousMonth.getMonth() + 1)
		let nextsMonth = new Date(this.date)
		nextsMonth.setDate(2)
		nextsMonth.setMonth(this.date.getMonth() + 1)
		let days = []
		let labels = []

		labels.push(
			<span class='text__muted' style={{fontSize: '13px'}} key='t2'>
				{this.$t('monday_short').toUpperCase()}
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px'}} key='t3'>
				{this.$t('tuesday_short').toUpperCase()}
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px'}} key='t4'>
				{this.$t('wednesday_short').toUpperCase()}
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px'}} key='t5'>
				{this.$t('thursday_short').toUpperCase()}
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px'}} key='t6'>
				{this.$t('friday_shrot').toUpperCase()}
			</span>,
		)
		labels.push(
			<span class='text__danger' style={{fontSize: '13px'}} key='t7'>
				{this.$t('saturday_short').toUpperCase()}
			</span>,
		)
		labels.push(
			<span class='text__danger' style={{fontSize: '13px'}} key='cn'>
				{this.$t('sunday_short').toUpperCase()}
			</span>,
		)

		let firstday = firstDayDate.getDay()
		if (firstday === 0) firstday = 7
		for (let i = firstday; i > 1; i--) {
			previousMonth = new Date(previousMonth)
			previousMonth.setDate(previousMonthDays - i + 2)
			days.push(
				<Day
					key={format(previousMonth, 'dd MM yyyy', {locale: getDateFnsLocale()})}
					vOn:click={(date) => this.$emit('click', date)}
					currentDate={this.date}
					date={previousMonth}
					startDate={this.startDate}
					endDate={this.endDate}
				/>,
			)
		}

		for (let i = 1; i <= daysInMonth; i++) {
			thisDate = new Date(thisDate)
			thisDate.setDate(i)

			days.push(
				<Day
					key={format(thisDate, 'dd MM yyyy', {locale: getDateFnsLocale()})}
					vOn:click={(date) => this.$emit('click', date)}
					currentDate={this.date}
					date={thisDate}
					startDate={this.startDate}
					endDate={this.endDate}
				/>,
			)
		}

		const daysCount = days.length
		for (let i = 1; i <= 42 - daysCount; i++) {
			nextsMonth = new Date(nextsMonth)
			nextsMonth.setDate(i)
			days.push(
				<Day
					key={format(nextsMonth, 'dd MM yyyy', {locale: getDateFnsLocale()})}
					vOn:click={(date) => this.$emit('click', date)}
					currentDate={this.date}
					date={nextsMonth}
					startDate={this.startDate}
					endDate={this.endDate}
				/>,
			)
		}

		return (
			<nav class='calendar--days'>
				{labels.concat()}
				{days.concat()}
			</nav>
		)
	},
}

export default {
	name: 'date-range-picker',
	props: ['startDate', 'endDate', 'style', 'shortcuts', 'sensitive'],
	data() {
		return {
			show: false,
			date: startOfDay(new Date()),

			// use to calculate dropdown position
			rect: {},
		}
	},

	methods: {
		resetDate() {
			this.date = startOfDay(new Date())
		},

		changeMonth(month) {
			this.date.setMonth(month)
			this.$forceUpdate()
		},

		changeDate(date) {
			let shouldclose = true
			let {startDate, endDate} = this

			if (startDate === null || isBeforeDay(date, startDate) || !sameDay(startDate, endDate)) {
				startDate = date
				endDate = null
				shouldclose = false
			} else if (sameDay(date, startDate) && sameDay(date, endDate)) {
				// startDate = null
				endDate = null
				shouldclose = false
			} else if (isBeforeDay(startDate, date)) {
				endDate = date
			}
			this.$emit('change', {
				startDate: new Date(startDate).getTime(),
				endDate: new Date(endDate || startDate).getTime(),
			})
			setTimeout(() => {
				if (shouldclose && this.sensitive) this.Close()
			}, 100)
		},

		Close() {
			this.show = false
		},

		async Open() {
			this.show = true
			this.$emit('openDropdown')
			await this.$nextTick()
			let $input = this.$refs.input
			let rect = $input ? $input.getBoundingClientRect() : {}
			this.rect = rect
		},

		renderCalendar() {
			const {startDate, endDate} = this

			return (
				<div class='calendar' style={this.style}>
					<nav class='calendar--nav'>
						<a vOn:click={() => this.changeMonth(this.date.getMonth() - 1)}>
							<Icon name='chevron-left' stroke='black' />
						</a>
						<div vOn:click={() => this.resetDate()}>
							<div class='form-label mb-0'>
								{format(this.date, 'MMMM', {locale: getDateFnsLocale()})}{' '}
								{format(this.date, 'yyyy', {locale: getDateFnsLocale()})}
							</div>
						</div>
						<a vOn:click={() => this.changeMonth(this.date.getMonth() + 1)}>
							<Icon name='chevron-right' stroke='black' />
						</a>
					</nav>
					<Days
						vOn:click={(date) => this.changeDate(date)}
						date={new Date(this.date)}
						startDate={startDate}
						endDate={endDate}
					/>
				</div>
			)
		},

		clearDatePicker() {
			this.show = false
			this.$emit('change', {startDate: null, endDate: null})
		},

		toggleCalendar() {
			if (!this.show) return this.Open()
			return this.Close()
		},

		renderShortcuts() {
			if (lo.size(this.shortcuts) <= 0) return null
			let $shortcuts = lo.map(this.shortcuts, (shortcut) => {
				return (
					<div
						class='calendar_shortcut__item'
						vOn:click={(_) => {
							let dates = shortcut.onClick()
							this.$emit('change', {startDate: new Date(dates[0]).getTime(), endDate: new Date(dates[1]).getTime()})
							if (this.sensitive) this.Close()
						}}
					>
						{shortcut.text}
					</div>
				)
			})
			return <div class='calendar_shortcut'>{$shortcuts}</div>
		},
		renderInput() {
			let found = lo.find(this.shortcuts, (shortcut) => {
				let dates = shortcut.onClick()
				let startDate = new Date(dates[0]).getTime()
				let endDate = new Date(dates[1]).getTime()

				return sameDay(this.startDate, startDate) && sameDay(endDate, this.endDate)
			})

			if (found) {
				return (
					<div
						ref='input'
						class='form-control date-range-input'
						style='display: flex; align-items: center; width: 100%'
						vOn:click={this.toggleCalendar}
					>
						<div class='text__truncate'>{found.desc || found.text}</div>
						<div style='flex: 1' />
						<Icon name='calendar' size='18' class='link link__secondary ml-3' />
					</div>
				)
			}

			let $text = (
				<Fragment>
					{'⠀'}
					<Icon name='calendar' size='18' class='link link__secondary ml-auto' />
				</Fragment>
			)
			if (this.startDate && this.endDate) {
				$text = (
					<Fragment>
						{format(this.startDate, 'yyyy-MM-dd', {locale: getDateFnsLocale()})}
						<Icon name='arrow-right' size='14' class='text__muted ml-3 mr-3' />
						{format(this.endDate, 'yyyy-MM-dd', {locale: getDateFnsLocale()})}
						<div style='flex: 1' />
						<Icon name='calendar' size='18' class='link link__secondary ml-3' />
					</Fragment>
				)
			}
			return (
				<div
					ref='input'
					class='form-control text__truncate date-range-input'
					style='display: flex; align-items: center; width: 100%'
					vOn:click={this.toggleCalendar}
				>
					{$text}
				</div>
			)
		},

		calculateStyle() {
			let {bottom = 0, left = 0, width = 0, height = 0} = this.rect
			const DROPDOWN_WIDTH = 350
			const DROPDOWN_HEIGHT = 280
			const EDGE = 50 // space to edge of pageview

			let isRight = window.innerWidth - left < EDGE + DROPDOWN_WIDTH
			let isTop = window.innerHeight - bottom < EDGE + DROPDOWN_HEIGHT
			let horizontalStayle = isRight ? `right: ${window.innerWidth - (left + width)}px;` : `left: ${left}px;`
			let verticlaStyle = isTop ? `bottom: ${window.innerHeight - bottom + height}px;` : `top: ${bottom}px;`
			let borderStyle = isTop
				? `border-bottom-left-radius: 0; border-bottom-right-radius: 0; border-top-left-radius: 10px; border-top-right-radius: 10px;`
				: ''

			return `position: fixed; ${horizontalStayle} ${verticlaStyle} ${borderStyle}`
		},
	},

	render() {
		let $body = null
		if (this.show) {
			let dropdownstyle = this.calculateStyle()
			$body = (
				<div class='calendar_container' style={dropdownstyle}>
					{this.renderShortcuts()}
					{this.renderCalendar()}
				</div>
			)
		}
		return (
			<div
				style='position: relative; width: 280px;'
				v-clickaway={(_) => {
					this.show = false
				}}
			>
				{this.renderInput()}
				{$body}
			</div>
		)
	},
}

function sameDay(d1, d2) {
	d1 = new Date(d1)
	d2 = new Date(d2)
	return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
}

function isBeforeDay(d1, d2) {
	d1 = new Date(d1)
	d2 = new Date(d2)
	if (sameDay(d1, d2)) return false
	return d1.getTime() < d2.getTime()
}

function sameMonth(d1, d2) {
	d1 = new Date(d1)
	d2 = new Date(d2)
	return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
}

function numDaysInMonth(year, month) {
	return new Date(year, month, 0).getDate()
}
