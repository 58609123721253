import sb from '@sb/util'
const pubsub = new sb.Pubsub()

export function dispatchClickDynamicField (e, node) {
  pubsub.publish('dynamic-field-click', e, node)
}

export function onClickDynamicField(vm, cb) {
  pubsub.on2(vm, 'dynamic-field-click', cb)
}
