import store from '@sb/store'
const sb = require('@sb/util')
import {format} from 'date-fns'

export default {
	name: 'zns-template-modals',
	props: ['open', 'user', 'zns_template_id'],

	data() {
		return {
			selectedTemplateId: '',
			phone: '',
			znsParams: {},
			suggestedPhones: [],
		}
	},

	mounted() {
		document.addEventListener('keyup', this.closeWhenESC)
	},

	destroyed() {
		document.removeEventListener('keyup', this.closeWhenESC)
	},

	watch: {
		open() {
			if (this.open) {
				this.resetData()
			}
		},
	},

	methods: {
		closeWhenESC(evt) {
			if (evt.keyCode === 27) {
				this.$emit('close')
			}
		},

		resetData() {
			let templates = lo.map(store.matchZnsTemplate(), (tmpl) => tmpl)
			let id = lo.get(templates, '0.templateId')
			if (this.zns_template_id) id = this.zns_template_id
			this.onSelectTemplate(id)

			// select Phone
			let phones = this.getUserPhoneNumbers()
			this.phone = lo.first(phones)
			this.suggestedPhones = phones
		},

		getUserPhoneNumbers() {
			let phones = sb.getUserTextAttr(this.user, 'phones') || ''
			phones = lo.split(phones, ',')
			phones = lo.map(phones, (phone) => phone.trim())
			phones = lo.uniq(phones)

			return phones
		},

		onSelectTemplate(templateId) {
			this.selectedTemplateId = templateId
			let template = store.matchZnsTemplate()[this.selectedTemplateId] || {}
			let znsParams = lo.cloneDeep(template.listParams || {})
			lo.each(znsParams, (param) => {
				if (param.type === 'DATE') {
					param.value = format(new Date(), 'HH:mm dd/MM/yyyy')
				}
			})
			this.znsParams = znsParams
		},

		onChangeParamValue(v, idx) {
			let znsParams = lo.cloneDeep(this.znsParams)
			lo.set(znsParams, `${idx}.value`, v)
			this.znsParams = znsParams
		},

		renderZnsParamField(param, idx) {
			let $input = (
				<input
					value={param.value}
					class='form-control'
					maxlength={param.maxLength}
					vOn:input={(e) => this.onChangeParamValue(e.target.value, idx)}
				/>
			)
			if (param.type === 'DATE') {
				$input = (
					<input
						placeholder={'HH:mm dd/MM/yyyy'}
						value={param.value}
						class='form-control'
						vOn:input={(e) => this.onChangeParamValue(e.target.value, idx)}
					/>
				)
			}
			if (param.type === 'NUMBER') {
				$input = (
					<input
						type='number'
						value={param.value}
						class='form-control'
						type='number'
						vOn:input={(e) => this.onChangeParamValue(+e.target.value, idx)}
					/>
				)
			}
			let $info = null
			if (param.type === 'DATE') {
				$info = (
					<Icon
						name='help'
						tabindex='-1'
						class='text__muted'
						size='16'
						v-tooltip={'Định dạng HH:mm dd/MM/yyyy hoặc dd/MM/yyyy hoặc MM/yyyy'}
					/>
				)
			}
			let $maxLength = null
			if (param.type === 'STRING' && param.maxLength) {
				$maxLength = (
					<div style='font-size: 12px; align-self: flex-end' class='ml-auto'>
						{`${lo.size(param.value)}/${param.maxLength}`}
					</div>
				)
			}
			return (
				<div class={{'mb-4': idx === lo.size(this.znsParams) - 1, 'mb-3': idx < lo.size(this.znsParams) - 1}}>
					<div class='mb-1 d-flex'>
						<div>
							{param.name} {param.require && <span class='text__danger'>*</span>} {$info}
						</div>
						{$maxLength}
					</div>
					{$input}
				</div>
			)
		},

		async submit() {
			if (this.submitting) return
			this.submitting = true
			let selectedTemplate = store.matchZnsTemplate()[this.selectedTemplateId] || {}
			let phone = this.phone
			if (phone.startsWith('0')) {
				phone = phone.replace('0', '84')
			}
			let templateData = {}
			lo.each(this.znsParams, (param) => {
				templateData[param.name] = param.value
			})

			let body = {
				account_id: lo.get(store.me(), 'account_id'),
				phone,
				template_id: this.selectedTemplateId + '',
				template_data: templateData,
				//tracking_id: Date.now(),
				oaid: selectedTemplate.oaId,
			}

			let ev = {
				touchpoint: {channel: 'zns'},
				by: {id: store.me().id, type: 'agent'},
				data: {
					message: {
						zns_phone: phone,
						zns_template_id: this.selectedTemplateId + '',
						zns_template_data: templateData,
					},
				},
				type: 'message_sent',
			}
			let res = await store.sendZns(ev)
			this.submitting = false
			if (res.error) {
				let desc = lo.get(res, 'body.description', '')
				if (desc.indexOf('template data date') > -1) {
					return this.$showError(
						'Vui lòng điền định dạng ngày tháng theo format HH:mm dd/MM/yyyy hoặc dd/MM/yyyy hoặc MM/yyyy',
						5000,
					)
				}

				if (desc.indexOf('Messages cannot be sent at night from 10 PM to 6 AM local') > -1) {
					return this.$showError('Chỉ có thể gửi tin ZNS từ 6h sáng tới 10h tối', 5000)
				}

				this.$showError(res.error)
				return
			}
			this.$emit('close')
			this.$emit('success', this.phone)
		},

		iSBtnDisabled() {
			let disabled = false
			lo.each(this.znsParams, (param) => {
				if (param.require && !param.value) {
					disabled = true
					return false // break loop
				}
			})

			return disabled
		},
	},

	render() {
		let cls = 'modal'
		if (!this.open) return null

		let selectedTemplate = store.matchZnsTemplate()[this.selectedTemplateId] || {}
		let items = lo.map(store.matchZnsTemplate(), (tmpl) => ({
			id: tmpl.templateId,
			label: `${tmpl.templateId} - ${tmpl.templateName}`,
		}))

		let phones = this.getUserPhoneNumbers()
		let phoneItems = lo.map(phones, (phone) => ({
			id: phone,
			label: phone,
		}))

		return (
			<div class={cls} style="z-index: 9999">
				<div class='modal__overlay' vOn:click={(_) => this.$emit('close')} />
				<div class='modal__container'>
					<div class='modal__background' style='overflow: hidden;'>
						<div class='modal_content'>
							<div class='d-flex' style='max-height: 80vh; overflow: hidden;'>
								<div class='flex__1 d-flex flex-column' style='padding: 15px 20px; overflow: auto'>
									<div style='font-size: 18px' class='mb-4'>
										{this.$t('send_zns_to_customer')}
									</div>
									<div class='d-flex mb-1'>
										<div class='text__semibold'>{this.$t('select_template')}</div>
										{/*
										<div class='link ml-auto' style='font-size: 14px'>
											{this.$t('detail')}
										</div>
        */}
									</div>
									<Dropdown2
										items={items}
										mode='input'
										selected={this.selectedTemplateId}
										vOn:select={(item) => this.onSelectTemplate(item.id)}
									/>
									<div class='mt-4 mb-1 text__semibold'>{this.$t('send_to_phone')}</div>
									<input class='form-control' vModel={this.phone} />
									{lo.size(this.suggestedPhones) > 1 && (
										<div class='text__xs text__secondary mt-1'>
											{this.$t('suggestion')}:{' '}
											{lo.map(this.suggestedPhones, (suggestion, sidx) => (
												<Fragment>
													<span class='link link__secondary' vOn:click={() => (this.phone = suggestion)}>
														{suggestion}
													</span>
													{sidx < lo.size(this.suggestedPhones) - 1 ? ', ' : ''}
												</Fragment>
											))}
										</div>
									)}
									<div class='mt-4 text__semibold'>{this.$t('information')}</div>
									{lo.map(this.znsParams, this.renderZnsParamField)}
									<button
										disabled={this.iSBtnDisabled()}
										class='btn btn__primary w_100 mt-auto no-shrink text__semibold'
										vOn:click={this.submit}
									>
										{this.$t('send_zns')}
									</button>
								</div>
								<div
									class='no-shrink d-flex flex-column'
									style='width: 480px; background: whitesmoke; padding: 15px 20px'
								>
									<div class='d-flex align-items-center mb-4'>
										<div class='text__muted'>{this.$t('preview')}</div>
										<Icon
											name='x'
											stroke-width='1.5'
											size='20'
											class='btn__close ml-auto'
											vOn:click={() => this.$emit('close')}
										/>
									</div>
									<div class='flex__1 d-flex align-items-center justify-content-center'>
										<iframe
											src={selectedTemplate.previewUrl}
											style='border: none; width: 400px; height: 500px; align-self: center; justify-self: center'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	},
}
