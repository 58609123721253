import {keymap} from 'prosemirror-keymap'
import {EditorState, Plugin, PluginKey, TextSelection} from 'prosemirror-state'
import GenericTemplateBuilder from './generic_template_builder.js'
import {schema as basicSchema} from 'prosemirror-schema-basic'
import {DOMParser, DOMSerializer, Fragment, Schema, Node} from 'prosemirror-model'
import {EditorView} from 'prosemirror-view'
import {dropCursor} from 'prosemirror-dropcursor'
import {undo, redo, history} from 'prosemirror-history'
import {gapCursor} from 'prosemirror-gapcursor'
const mac = typeof navigator != 'undefined' ? /Mac|iP(hone|[oa]d)/.test(navigator.platform) : false
import {toggleMark, wrapIn, chainCommands, exitCode, selectParentNode, baseKeymap} from 'prosemirror-commands'
import sb from '@sb/util'
import store from '@sb/store'
import {getAttributeName, UNUSED_USER_ATTRIBUTES} from '../common'

const EmojiList =
	'like unlike angry confused crying grinning heart-eyes neutral sleepy sad smiling tongue-out tired surprised wink'.split(
		' ',
	)

const emojiNodeSpec = {
	attrs: {code: {default: 'like'}},
	inline: true,
	group: 'inline',
	draggable: true,

	toDOM: (node) => ['emj', {'emoji-code': node.attrs.code, class: 'emoji emoji__' + node.attrs.code}],
	parseDOM: [
		{
			tag: 'emj[emoji-code]',
			getAttrs: (dom) => {
				let code = dom.getAttribute('emoji-code')
				return EmojiList.indexOf(code) > -1 ? {code} : false
			},
		},
	],
}

const dynamicFieldNodeSpec = {
	attrs: {id: {default: ''}, key: {default: ''}, value: {default: ''}},
	inline: true,
	group: 'inline',
	draggable: true,

	toDOM: (node) => [
		'dfe',
		{
			'dynamic-field-key': node.attrs.key,
			'data-id': node.attrs.id,
			'data-value': node.attrs.value,
			class: 'dynamic-field',
		},
		node.attrs.value || node.attrs.id || 'undefined',
	],
	parseDOM: [
		{
			tag: 'dfe[dynamic-field-key]',
			getAttrs: (dom) => {
				let node = {}
				node.value = dom.getAttribute('data-value')
				node.id = dom.getAttribute('data-id')
				node.key = dom.getAttribute('dynamic-field-key')
				return node
			},
		},
	],
}

let mySchema = new Schema({
	nodes: {
		doc: {
			content: 'paragraph+',
		},
		paragraph: {
			content: 'inline*',
			group: 'block',
			parseDOM: [{tag: 'p'}],
			toDOM() {
				return ['p', 0]
			},
		},
		'dynamic-field': dynamicFieldNodeSpec,
		emoji: emojiNodeSpec,
		text: {
			group: 'inline',
		},
	},
	marks: basicSchema.spec.marks,
})
let plugins = [history(), keymap(buildKeymap(mySchema)), keymap(baseKeymap), dropCursor(), gapCursor()]

import {undoInputRule} from 'prosemirror-inputrules'

function buildKeymap(schema, mapKeys) {
	let keys = {},
		type
	function bind(key, cmd) {
		if (mapKeys) {
			let mapped = mapKeys[key]
			if (mapped === false) return
			if (mapped) key = mapped
		}
		keys[key] = cmd
	}
	bind('Mod-z', undo)
	bind('Shift-Mod-z', redo)
	// bind('Backspace', undoInputRule)
	if (!mac) bind('Mod-y', redo)
	bind('Escape', selectParentNode)
	if ((type = schema.marks.strong)) {
		bind('Mod-b', toggleMark(type))
		bind('Mod-B', toggleMark(type))
	}
	if ((type = schema.marks.em)) {
		bind('Mod-i', toggleMark(type))
		bind('Mod-I', toggleMark(type))
	}
	if ((type = schema.marks.code)) bind('Mod-`', toggleMark(type))
	if ((type = schema.nodes.blockquote)) bind('Ctrl->', wrapIn(type))
	if ((type = schema.nodes.hard_break)) {
		let br = type,
			cmd = chainCommands(exitCode, (state, dispatch) => {
				if (dispatch) dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView())
				return true
			})
		bind('Mod-Enter', cmd)
		bind('Shift-Enter', cmd)
		if (mac) bind('Ctrl-Enter', cmd)
	}
	return keys
}

export default {
	name: 'botTextEditor',
	props: ['message', 'actions', 'placeholder', 'dynamicFieldData', 'actions_always_show', 'locale'],

	data() {
		return {
			openEmoji: false,
			openDynamicField: false,
			// editor: null,

			choosenToken: null,
			isBold: false,
			isItalic: false,
			lang: '',
		}
	},

	watch: {
		locale: function (locale) {
			this.lang = ''
			if (locale) this.lang = locale.replace('-', '_')
		},
		message: function (newVal, oldVal) {
			if(!lo.isEqual(newVal, oldVal))
				this.updateEditor()
		},
	},

	methods: {
		renderGallery() {
			let att = lo.get(this.message, 'attachments.0') || {}
			if (att.type !== 'generic') return null
			return (
				<GenericTemplateBuilder
					noBranching
					locale={lo.get(store.me(), 'account.locale')}
					style='max-width: 100%; padding-right: 10px; padding-left:10px; padding-bottom: 10px'
					message={this.message}
					vOn:change={(msg) => this.$emit('change', msg)}
					vOn:remove={this.removeGallery}
				/>
			)
		},

		removeGallery() {
			let message = lo.cloneDeep(this.message)
			message.attachments = []
			this.$emit('change', message)
			this.focus()
		},

		renderEmojiPopup() {
			if (!this.openEmoji) return null

			return (
				<div
					class='emoji_picker'
					style='top: calc(100% + 5px); right: -20px; bottom: unset;'
					v-clickaway={(_) => (this.openEmoji = false)}
				>
					{lo.map(EmojiList, (emoji) => (
						<div class='emoji_picker__item' v-tooltip={':' + emoji + ':'}>
							<div vOn:click={(_) => this.emojiPick(emoji)} class={'emoji emoji__preview emoji__' + emoji} />
						</div>
					))}
				</div>
			)
		},

		matchUserAttribute() {
			if (this.dynamicFieldData) return this.dynamicFieldData

			return lo
				.filter(store.matchUserAttribute(), (att) => UNUSED_USER_ATTRIBUTES.indexOf(att.key) < 0 && !att.archived)
				.map((att) => Object.assign(att, {name: this.$t(getAttributeName(att))}))
		},

		toggleEmoji() {
			this.openEmoji = !this.openEmoji
			this.openDynamicField = false
		},

		toggleDynamicField() {
			this.choosenToken = null
			this.openEmoji = false
			this.openDynamicField = !this.openDynamicField
		},

		async toggleUpdateDynamicField(e, node) {
			// e.stopPropagation()
			// settimeout to run this function after clickaway
			this.choosenToken = e.target.closest('.dynamic-field')
			await this.$nextTick()
			let $token = this.$refs.custom_token_dropdown
			$token.ToogleDropdown(true)
			setTimeout(() => {
				this.openEmoji = false
				this.openDynamicField = true
			}, 100)
		},

		renderChoosenTokenDropdown() {
			let rect = {}
			if (this.choosenToken) rect = this.choosenToken.getBoundingClientRect() || {}
			let attributes = lo.map(this.matchUserAttribute(), (attr) => ({id: attr.key, label: attr.name}))
			let style = `z-index: 10;background: red;width: 0; position: fixed; top:${rect.top + 20}px;left:${rect.left}px`
			return (
				<Dropdown2
					ref='custom_token_dropdown'
					style={style}
					items={attributes}
					dropdown_width={200}
					vOn:select={this.selectDynamicField}
				></Dropdown2>
			)
		},

		emojiPick(code) {
			insertEmoji(code)(this.editor.state, this.editor.dispatch)
			this.openEmoji = false
		},

		// parrent may call
		InsertDynamicField(attr) {
			const id = sb.randomString(16)
			insertDynamicField(
				id,
				`user.${attr.id}`,
				this.getDynamicFieldName(attr.id),
			)(this.editor.state, this.editor.dispatch)
			this.focus()
			this.openDynamicField = false
		},

		selectDynamicField(attr) {
			if (this.choosenToken) {
				const id = this.choosenToken.getAttribute('data-id')
				// const selectedEmbed = delta.ops.find((op) => op.insert.dynamicField && op.insert.dynamicField.id === id)
				let value = this.getDynamicFieldName(attr.id)
				let key = `user.${attr.id}`
				insertDynamicField(id, key, value)(this.editor.state, this.editor.dispatch)
				this.focus()
				this.openDynamicField = false
				this.choosenToken = null
				return
			}
			return this.InsertDynamicField(attr)
		},

		toggleStyle(style) {
			if (style === 'strong') this.isBold = !this.isBold
			if (style === 'em') this.isItalic = !this.isItalic
		},

		formatTextStyle(style) {
			if (style == 'strong') toggleMark(mySchema.marks.strong)(this.editor.state, this.editor.dispatch)
			if (style == 'em') toggleMark(mySchema.marks.em)(this.editor.state, this.editor.dispatch)
			this.focus()
			return
			if (!selection.length) {
				this.toggleStyle(style)
				let isActive = false
				if (style === 'strong') isActive = this.isBold
				if (style === 'em') isActive = this.isItalic
				this.editor.format(style, isActive, 'user') // must set format type is user to work
			}
			if (selection.length) {
				let format = this.editor.getFormat(selection.index, selection.length)
				let currentStyle = format[style] || false
				this.editor.formatText(selection.index, selection.length, style, !currentStyle)
				if (style === 'strong') this.isBold = !currentStyle
				if (style === 'em') this.isItalic = !currentStyle
			}
		},

		useGallery() {
			let att = lo.get(this.message, 'attachments.0') || {}
			if (att.type === 'generic') return
			let message = lo.cloneDeep(this.message)
			let locale = lo.get(store.me(), 'account.locale')
			locale = locale.replace('-', '_')

			message.attachments = [
				{
					type: 'generic',
					elements: [
						{
							title: 'Sản phẩm 1',
							i18n_title: {[locale]: 'Sản phẩm 1'},
							image_url: 'https://vcdn.subiz-cdn.com/file/fiqxarzhhfhtkixrqlug-5.png',
							subtitle: 'Mô tả về sản phẩm',
							i18n_subtitle: {[locale]: 'Mô tả về sản phẩm'},
							buttons: [
								{
									type: 'url_button',
									title: 'Xem trên website',
									i18n_title: {[locale]: 'Xem trên website'},
									url: '',
								},
								{
									type: 'call_button',
									title: 'Liên hệ ngay',
									i18n_title: {[locale]: 'Liên hệ ngay'},
									phone_number: '',
								},
							],
						},
						{
							title: 'Sản phẩm 2',
							i18n_title: {[locale]: 'Sản phẩm 2'},
							image_url: 'https://vcdn.subiz-cdn.com/file/fiqxarymkkbsyykitvju-2.png',
							subtitle: 'Mô tả về sản phẩm',
							i18n_subtitle: {[locale]: 'Mô tả về sản phẩm'},
							buttons: [
								{
									type: 'url_button',
									title: 'Xem trên website',
									i18n_title: {[locale]: 'Xem trên website'},
									url: '',
								},
								{
									type: 'call_button',
									title: 'Liên hệ ngay',
									i18n_title: {[locale]: 'Liên hệ ngay'},
									phone_number: '',
								},
							],
						},
					],
				},
			]
			this.$emit('change', message)
		},

		renderActions() {
			let actions = ['strong', 'em', 'emoji', 'dynamicField']
			if (lo.size(this.actions) > 0) actions = this.actions

			let $gallery = null
			if (actions.indexOf('gallery') >= 0) {
				let active = lo.get(this.message, 'attachments.0.type') === 'generic'

				$gallery = (
					<div
						class={`bot-textarea__action-item ${active && 'item--active'}`}
						v-tooltip={this.$t('gallery')}
						vOn:click={this.useGallery}
					>
						<Icon name='columns' class='bot-textarea__action-icon' stroke-width='2' size='18' />
					</div>
				)
			}

			let $single_img = null
			if (actions.indexOf('singleImage') >= 0) {
				let url = lo.get(this.message, 'attachments.0.url')
				$single_img = (
					<div class={`bot-textarea__action-item ${url && 'item--active'}`}>
						<Icon
							name='camera'
							class='bot-textarea__action-icon'
							size='16'
							v-tooltip={url ? 'Thay đổi ảnh' : 'Thêm ảnh'}
							vOn:click_stop={(_) => this.$refs.img_upload.click()}
						/>
						<input
							type='file'
							ref='img_upload'
							style='display: none;'
							vOn:change={this.uploadSingleImage}
							accept='image/*'
						/>
					</div>
				)
			}

			let $bold = null
			if (actions.indexOf('strong') >= 0) {
				$bold = (
					<div
						class={`bot-textarea__action-item ${this.isBold && 'item--active'}`}
						v-tooltip={this.$t('bold')}
						vOn:click_stop={(_) => this.formatTextStyle('strong')}
					>
						<Icon name='bold' class='bot-textarea__action-icon' stroke-width='2' size='18' />
					</div>
				)
			}

			let $italic = null
			if (actions.indexOf('em') >= 0) {
				$italic = (
					<div
						class={`bot-textarea__action-item ${this.isItalic && 'item--active'}`}
						v-tooltip={this.$t('italic')}
						vOn:click_stop={(_) => this.formatTextStyle('em')}
					>
						<Icon name='italic' class='bot-textarea__action-icon' stroke-width='2' size='18' />
					</div>
				)
			}

			let $emoji = null
			if (actions.indexOf('emoji') >= 0) {
				$emoji = (
					<div style='position: relative'>
						{this.renderEmojiPopup()}
						<div
							class={`bot-textarea__action-item ${this.openEmoji && 'item--active'}`}
							v-tooltip={this.$t('text_editor_emoji')}
							vOn:click_stop={this.toggleEmoji}
						>
							<Icon name='mood-smile' class='bot-textarea__action-icon' stroke-width='2' size='18' />
						</div>
					</div>
				)
			}

			let $dynamicField = null
			if (actions.indexOf('dynamicField') >= 0) {
				let attributes = lo.map(this.matchUserAttribute(), (attr) => ({id: attr.key, label: attr.name}))
				$dynamicField = (
					<Dropdown2
						ref='dropdown_1'
						mode='custom'
						items={attributes}
						dropdown_width={220}
						vOn:select={this.selectDynamicField}
						right
					>
						<div
							class={`bot-textarea__action-item`}
							vOn:click={this.toggleDynamicField}
							v-tooltip={this.$t('add_contact_token')}
						>
							<Icon name='code-plus' class='bot-textarea__action-icon' stroke-width='2' size='18' />
						</div>
					</Dropdown2>
				)
			}

			// force visible when open dropdowns
			let style = ''
			if (this.openEmoji || this.openDynamicField) style = 'opacity: 1'
			if (this.actions_always_show) style = 'opacity: 1'

			return (
				<div class='bot-textarea__actions' style={style} vOn:click={(_) => this.focus()}>
					{$bold}
					{$italic}
					{$emoji}
					{$dynamicField}
					{$single_img}
					{$gallery}
				</div>
			)
		},

		updateEditor() {
			if (!this.editor) return

			let block = this.message.block
			if (this.lang) block = lo.get(this.message, ['i18n_block', this.lang]) || block
			if (!block) {
				let quilldelta = this.message.quill_delta
				if (this.lang) quilldelta = lo.get(this.message, ['i18n_quill_delta', this.lang])
				block = sb.deltaToBlock(quilldelta)
			}

			const newDoc = sb.blockToProseMirror(block) // get content convert to prosemirror
			const docContent = this.editor.state.doc.content.toJSON()
			let oldblock = sb.proseMirrorToBlock(docContent)
			if (JSON.stringify(block) == JSON.stringify(oldblock)) return
			const content = Node.fromJSON(mySchema, {type: 'doc', content: newDoc}) //parse content to doc prosemirror
			const transaction = this.editor.state.tr
				.replaceWith(0, this.editor.state.doc.content.size, content)
				.scrollIntoView()
			this.editor.dispatch(transaction)
			// this.editor.focus()
		},

		handleTextChange: lo.debounce(
			function () {
				const docContent = this.editor.state.doc.content.toJSON()
				let block = sb.proseMirrorToBlock(docContent)
				let message = lo.cloneDeep(this.message)
				// use simple plaintext format
				// message.text = lo.trim(sb.deltaToPlainText(deltas))
				if (this.lang) {
					lo.set(message, ['i18n_block', this.lang], block)
					// message.i18n_quill_delta = undefined
				}
				message.block = block
				message.quill_delta = undefined
				message.format = 'block'
				message.text = sb.blockToText(message.block, { is_show_dynamic_field_token: true })

				this.$emit('change', message)
				return
			},
			300,
			{leading: false, trailing: true},
		),

		handleSelectionChange(state) {
			let {from, $from, to, empty} = state.selection
			this.isBold = !!mySchema.marks.strong.isInSet(state.storedMarks || $from.marks())
			this.isItalic = !!mySchema.marks.em.isInSet(state.storedMarks || $from.marks())
		},

		onEditorClick(e, node) {
			if (node.type.name == 'dynamic-field') {
				this.toggleUpdateDynamicField(e, node)
			}
		},

		getDynamicFieldName(key) {
			let atts = this.matchUserAttribute()
			let found = lo.find(atts, (att) => att.key === key)
			return lo.get(found, 'name')
		},

		focus() {
			if (!this.editor) return
			// let length = this.editor.getLength()
			// this.editor.setSelection(length, 0)
			this.editor.focus()
		},

		renderSingleImage() {
			let url = lo.get(this.message, 'attachments.0.url')
			if (!url) return null
			return (
				<div style='position: relative;' class='ml-3'>
					<Icon
						name='x'
						v-tooltip={this.$t('remove')}
						size='1.5x'
						stroke-width='2'
						class='message_editor__attachment_x'
						vOn:click={this.removeSingleImage}
					/>
					<img2 src={url} class='message_editor__attachment_image' style='max-width: 200px; max-height: 70px' />
				</div>
			)
		},

		removeSingleImage() {
			let message = lo.cloneDeep(this.message)
			delete message.attachments
			this.$emit('change', message)
			this.focus()
		},

		uploadClick() {
			this.$refs.file_input.click()
		},

		async uploadSingleImage(e) {
			let MAXIMUM_SIZE = 5 * 1024 * 1024
			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (file.size > MAXIMUM_SIZE) {
				return this.$showError(this.$t('error_file_is_too_large'))
			}
			let tempUrl = await sb.getBlobUrlFromFile(file)
			let message = lo.cloneDeep(this.message)
			let id = Date.now()
			message.attachments = [{type: 'file', url: tempUrl, _loading: true, _id: id}]
			this.$emit('change', message)
			let res = await store.uploadLocalFile(file)
			if (lo.get(this.message, 'attachments.0._id') !== id) return // outdated

			message = lo.cloneDeep(this.message)
			message.attachments = [{type: 'file', url: res.url}]
			this.$emit('change', message)
			this.focus()
		},
	},

	async mounted() {
		this.lang = ''
		if (this.locale) this.lang = this.locale.replace('-', '_')
		let block = this.message.block
		if (this.locale) block = lo.get(this.message, ['i18n_block', this.lang]) || block
		if (!block) {
			let quilldelta = this.message.quill_delta
			if (this.locale) quilldelta = lo.get(this.message, ['i18n_quill_delta', this.lang])
			block = sb.deltaToBlock(quilldelta)
		}

		const content = sb.blockToProseMirror(block) // get content convert to prosemirror
		const doc = Node.fromJSON(mySchema, {type: 'doc', content: content}) // parse content to doc prose-mirror
		// const doc = Node.fromJSON(mySchema, {type: 'doc'}) //parse content to doc prosemirror
		const editorState = EditorState.create({doc, plugins})
		var self = this
		this.editor = new EditorView(this.$refs.text_editor, {
			state: editorState,
			handleClickOn: (a, b, node, d, event) => {
				this.onEditorClick(event, node)
			},
			dispatchTransaction(transaction) {
				if (!self.editor.state.selection.eq(transaction.selection.from)) {
					self.handleSelectionChange(transaction)
				}
				let newState = self.editor.state.apply(transaction)
				self.editor.updateState(newState)
				self.handleTextChange()
				return false
			},
		})

		this.editor.focus()

		// update content by props
		this.updateEditor()
		this.attachments = lo.cloneDeep(this.message.attachments)
	},

	render() {
		return (
			<div class='bot-textarea'>
				<div ref='text_editor' class='bot-textarea__input' />
				{this.renderSingleImage()}
				{this.renderGallery()}
				{this.renderActions()}
				{this.$slots.default}
				{this.renderChoosenTokenDropdown()}
			</div>
		)
	},
}

function insertEmoji(code) {
	let emojiType = mySchema.nodes.emoji
	return function (state, dispatch) {
		let {$from} = state.selection,
			index = $from.index()
		if (!$from.parent.canReplaceWith(index, index, emojiType)) return false
		if (dispatch) dispatch(state.tr.replaceSelectionWith(emojiType.create({code})))
		return true
	}
}

function insertDynamicField(id, key, value) {
	let dynamicFieldType = mySchema.nodes['dynamic-field']
	return function (state, dispatch) {
		let {$from} = state.selection,
			index = $from.index()
		if (!$from.parent.canReplaceWith(index, index, dynamicFieldType)) return false
		if (dispatch) dispatch(state.tr.replaceSelectionWith(dynamicFieldType.create({id, key, value})))
		return true
	}
}
