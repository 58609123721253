export default {
	props: ['height', 'checked', 'green', 'isLight', 'disabled', 'yellow', 'dark'],
	render() {
		let height = parseInt(this.height) || 16
		let width = height * 1.75
		let styleTrack = `width: ${width}px;height: ${height}px;`
		let widthThumb = Math.floor(width / 2) - 2
		let styleThumb = `width: ${widthThumb}px;`

		let trackcls = 'switch__track'
		if (this.green) trackcls += ' switch__track__green'
		if (this.isLight) trackcls += ' switch__track--light'
		if (this.yellow) trackcls += ' switch__track__yellow'
		if (this.dark) trackcls += ' switch__track__dark'

		return (
			<label class={'switch ' + (this.disabled && 'switch__disabled')} vOn:click={(e) => this.$emit('click', e)}>
				<input type='checkbox' class='switch__input' vOn:change={this.emitChange} checked={this.checked} />
				<div class={trackcls} style={styleTrack}>
					<div class='switch__thumb' style={styleThumb}></div>
				</div>
			</label>
		)
	},
	methods: {
		emitChange(e) {
			this.$emit('change', e.target.checked)
		},
	},
}
