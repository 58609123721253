const GlobalAudioMethod = {}

GlobalAudioMethod.install = (Vue) => {
	Vue.prototype.$openGlobalAudio = function (src, componentId) {
		this.$root.$emit('openGlobalAudio', src, componentId)
	}
	Vue.prototype.$pauseGlobalAudio = function () {
		this.$root.$emit('pauseGlobalAudio')
	}
	Vue.prototype.$hideGlobalAudio = function () {
		this.$root.$emit('hideGlobalAudio')
	}
	Vue.prototype.$setGlobalAudioTitle = function (title) {
		this.$root.$emit('setGlobalAudioTitle', title)
	}
}

export default GlobalAudioMethod
