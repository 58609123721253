import {getAttributeName, UNUSED_USER_ATTRIBUTES} from '../common.js'
import {format} from 'date-fns'
const sb = require('@sb/util')
import EditUserPrimaryAttributeModal from './edit_user_primary_attribute_modal.js'

export default {
	name: 'user-more-fields-modal',

	props: ['displayFields', 'user', 'x', 'y'],
	data() {
		return {
			editAttributes: [],
			filterInputValue: '',
			saving: false,
			uploading: false,

			// edit primary attr modal
			editPrimaryAttr: {},
			isEditPrimaryAttrModalOpened: false,
		}
	},

	mounted() {
		this.editAttributes = lo.get(this.user, 'attributes', [])
	},

	methods: {
		onChangeAttribute(attr, value) {
			let newattr = lo.cloneDeep(attr)
			let editAttributes = lo.cloneDeep(this.editAttributes)
			newattr[attr.type] = value

			let idx = lo.findIndex(editAttributes, (eattr) => eattr.key === newattr.key)
			if (idx > -1) {
				lo.set(editAttributes, `${idx}.${attr.type}`, value)
			} else {
				editAttributes.push(newattr)
			}

			this.editAttributes = editAttributes
		},

		FocusField(field) {
			let $field = this.$refs[`${field}_field`]
			let $input = this.$refs[`${field}_input`]

			if ($field) {
				$field.scrollIntoViewIfNeeded()
			}
			if ($input) $input.focus()
		},

		async saveEditAttributes() {
			if (this.saving) return
			this.saving = true

			let {error} = await store.updateUser({id: this.user.id, attributes: this.editAttributes})
			this.saving = false
			this.$emit('close')
			if (error) this.$showError(error)
		},

		async toggleDisplayUserAttribute(key) {
			let displayFields = []
			if (lo.includes(this.displayFields, key)) {
				displayFields = lo.filter(this.displayFields, (field) => field !== key && field !== key.toLowerCase())
			} else {
				displayFields = [...this.displayFields, key]
			}
			this.$emit('change', displayFields)

			let out = await store.fetchAgent(store.me().id)
			let me = lo.cloneDeep(out.body || {})
			if (!me || !me.id) return this.$showError(out.error)
			lo.set(me, 'dashboard_setting.user_info_fields', displayFields)
			store.updateAgent({...me, _update_fields: ['dashboard_setting']})
		},

		onClickFileInput(attr) {
			if (this.uploading) return
			this.$refs[`${attr.key}_file_input`].click()
		},

		async onUploadImage(e, attr) {
			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (this.uploading) return
			this.uploading = true
			let res = await store.uploadLocalFile(file)
			this.uploading = false
			if (res.error) {
				this.$showError(res.error)
			}
			this.onChangeAttribute(attr, res.url)
		},

		onToggleCheckbox(attr, value) {
			let userattr = lo.find(this.editAttributes, (uattr) => uattr.key === attr.key) || {}
			let values = userattr.text ? lo.split(userattr.text, ',') : []
			if (values.includes(value)) {
				values = lo.filter(values, (v) => v !== value)
			} else {
				values.push(value)
			}

			this.onChangeAttribute(attr, values.join(','))
		},

		renderTextInput(attr) {
			if (attr.type !== 'text' && attr.type !== 'list') return null
			let $input = null
			let userattr = lo.find(this.editAttributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}

			if (attr.is_image) {
				return (
					<div class='d-flex align-items-center'>
						<img2 src={userattr.text} style='width: 32px; height: 32px' clickable />
						<div class='link ml-3 mr-2' vOn:click={(_) => this.onClickFileInput(attr)}>
							{this.$t('change')}
						</div>
						{this.uploading && <Spinner size='14' mode='blue' />}
						<input
							type='file'
							ref={`${attr.key}_file_input`}
							style='display: none'
							vOn:click={(e) => (e.target.value = '')}
							vOn:change={(e) => this.onUploadImage(e, attr)}
						/>
					</div>
				)
			}

			if (attr.select === 'radio') {
				return lo.map(attr.items, (item) => {
					return (
						<div class='form-check'>
							<input
								type='radio'
								class='form-check-input form-check-input--light'
								name={`${attr.key}_radio`}
								id={`${attr.key}_radio_${item.value}`}
								checked={item.value === userattr.text}
								vOn:change={(e) => this.onChangeAttribute(attr, item.value)}
							/>
							<label class='form-check-label' for={`${attr.key}_radio_${item.value}`}>
								{item.label}
							</label>
						</div>
					)
				})
			}

			if (attr.select === 'checkbox') {
				let values = userattr.text ? lo.split(userattr.text, ',') : []
				return lo.map(attr.items, (item) => {
					return (
						<div class='form-check'>
							<input
								type='checkbox'
								id={`${attr.key}_checkbox_${item.value}`}
								class='form-check-input form-check-input--light'
								name={`${attr.key}_checkbox`}
								checked={lo.includes(values, item.value)}
								vOn:change={(e) => this.onToggleCheckbox(attr, item.value)}
							/>
							<label class='form-check-label' for={`${attr.key}_checkbox_${item.value}`}>
								{item.label}
							</label>
						</div>
					)
				})
			}

			if (attr.select === 'dropdown') {
				let items = lo.map(attr.items, (item) => ({id: item.value, label: item.label}))
				if (userattr.text) items.unshift({id: '', label: this.$t('unselect')})
				return (
					<Dropdown2
						items={items}
						mode='input'
						extra_cls=' form-control form-control__light'
						selected={userattr.text}
						vOn:select={(item) => this.onChangeAttribute(attr, item.id)}
					/>
				)
			}

			if (attr.multiple_line) {
				return (
					<textarea
						ref={`${attr.key}_input`}
						class='form-control form-control__light'
						value={userattr.text}
						vOn:change={(e) => this.onChangeAttribute(attr, e.target.value)}
					/>
				)
			}

			return (
				<input
					class='form-control form-control__light'
					ref={`${attr.key}_input`}
					value={userattr.text}
					vOn:change={(e) => this.onChangeAttribute(attr, e.target.value)}
				/>
			)
		},

		renderNumberInput(attr) {
			if (attr.type !== 'number') return null
			let userattr = lo.find(this.editAttributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}
			return (
				<input
					type='number'
					class='form-control form-control__light'
					ref={`${attr.key}_input`}
					value={userattr.number}
					vOn:change={(e) => this.onChangeAttribute(attr, +e.target.value)}
				/>
			)
		},

		renderBooleanInput(attr) {
			if (attr.type !== 'boolean') return null
			let userattr = lo.find(this.editAttributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}
			return <Sw green checked={userattr.boolean} vOn:change={(checked) => this.onChangeAttribute(attr, checked)} />
		},

		renderDateTimeInput(attr) {
			if (attr.type !== 'datetime') return null
			let userattr = lo.find(this.editAttributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}
			let isDateOnly = attr.is_date_only
			let fm = isDateOnly ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss"

			let date = userattr.datetime ? format(new Date(userattr.datetime), fm) : ''
			return (
				<input
					class='form-control form-control__light'
					style='width: 100%'
					ref={`${attr.key}_input`}
					type={isDateOnly ? 'date' : 'datetime-local'}
					value={date}
					vOn:change={(e) => {
						let date = e.target.value ? new Date(e.target.value) : ''
						this.onChangeAttribute(attr, date ? date.toISOString() : '')
					}}
				/>
			)
		},

		renderPrimaryAttrField(defattr) {
			let attr = lo.find(this.user.attributes, (uattr) => uattr.key.toLowerCase() === defattr.key.toLowerCase())
			if (!attr) attr = {key: defattr.key}

			let values = []
			if (attr.text) {
				values.push(attr.text)
			}
			let otherValues = attr.other_values
			lo.each(otherValues, (value) => {
				values.push(value)
			})
			let $values = (
				<div
					class='user_info__field_primary_input_wrapper form-control form-control__light clickable'
					vOn:click={() => this.openEditPrimaryAttrModal(attr)}
				>
					{lo.map(values, (value, idx) => (
						<Fragment>
							<span title={value}>{value}</span>
							{idx < lo.size(values) - 1 && <span class=''> {'/'} </span>}
						</Fragment>
					))}
				</div>
			)

			return <div class='mt-2'>{$values}</div>
		},

		openEditPrimaryAttrModal(attr) {
			this.editPrimaryAttr = attr
			this.isEditPrimaryAttrModalOpened = true
		},
	},

	render() {
		let attributeM = store.matchUserAttribute()
		//let attrs = lo.filter(attributeM, (attr) => !!lo.get(attributeM, [attr.key, 'name']))
		let attrs = lo.filter(attributeM, (attr) => !UNUSED_USER_ATTRIBUTES.includes(attr.key) && !attr.archived)
		attrs = lo.filter(attrs, (attr) => !lo.startsWith(attr.key, '_') && !lo.startsWith(attr.key, store.me().account_id))
		attrs = lo.orderBy(
			attrs,
			(def) => {
				let typePoint = 5
				if (def.kind === 'system') typePoint = 3
				if (def.kind === 'default') typePoint = 4
				if (def.key === 'fullname') typePoint = 0
				if (def.key === 'emails') typePoint = 2
				if (def.key === 'phones') typePoint = 1
				return typePoint + '.' + def.key
			},
			'asc',
		)
		let userattrs = lo.get(this.user, 'attributes', [])
		let items = lo.filter(attrs, (attr) => {
			let attrname = getAttributeName(attr) || lo.get(store.matchUserAttribute(), [attr.key, 'name'])
			attrname = this.$t(attrname)
			attrname = sb.unicodeToAscii(attrname).toLowerCase()
			let key = sb.unicodeToAscii(attr.key + '').toLowerCase()
			let filter = sb.unicodeToAscii(this.filterInputValue).toLowerCase()
			return attrname.indexOf(filter) > -1 || key.indexOf(filter) > -1
		})

		const DROPDOWN_WIDTH = 500
		const DROPDOWN_HEIGHT = 650
		const INTERNAL_CHAT_WIDTH = 51

		let defaultLeft = window.innerWidth - DROPDOWN_WIDTH - INTERNAL_CHAT_WIDTH
		let defaultTop = window.innerHeight - DROPDOWN_HEIGHT

		let left = this.x || defaultLeft
		if (left > defaultLeft) left = defaultLeft
		let top = this.y || defaultTop
		if (top > defaultTop) top = defaultTop

		let dropdownstyle = `left: ${left}px;top: ${top}px; min-width: ${DROPDOWN_WIDTH}px; width: ${DROPDOWN_WIDTH}px; max-height: unset; position: fixed; z-index: 99;`

		return (
			<div class='dropdown d-flex flex-column' style={dropdownstyle}>
				<div
					class='dropdown_filter_container'
					style='background: white; border-bottom: 1px solid #ececec; padding: 8px 20px 8px 20px'
				>
					<div class='d-flex align-items-center'>
						<div class='text__semibold no-shrink'>{this.$t('information')}</div>
						<div class='dropdown_filter_input_container mr-5 ml-4'>
							<Icon name="search" class='dropdown_filter_input_search_icon' size='13' />
							<input
								style='width: 120px; padding-right: 5px'
								class='form-control dropdown_filter_input'
								placeholder={this.$t('search')}
								vModel={this.filterInputValue}
								ref='filter_input'
							/>
							{this.filterInputValue && (
								<Icon name="x"
									size='13'
									class='dropdown_filter_input_x_icon'
									vOn:click={(_) => (this.filterInputValue = '')}
								/>
							)}
						</div>
						<Icon name="x" size='20' class='x-icon ml-auto' vOn:click={(_) => this.$emit('close')} />
					</div>
				</div>
				<div class='dropdown_item_cotainer' style='height: 550px; padding: 5px 10px; max-height: 550px;'>
					{lo.size(items) > 0 ? (
						lo.map(items, (attr) => {
							let attrname = getAttributeName(attr) || lo.get(store.matchUserAttribute(), `${attr.key}.label`)
							attrname = this.$t(attrname)

							let $cb = (
								<div class='ml-auto form-check'>
									<input
										type='checkbox'
										id={`cb_display_field_${attr.key}`}
										class='form-check-input form-check-input--light'
										style='width: 14px; height: 14px; top: 0'
										checked={this.displayFields.includes(attr.key)}
										vOn:change={(_) => this.toggleDisplayUserAttribute(attr.key)}
									/>
									<label
										class='form-check-label text__muted'
										style='padding-left: 5px; font-size: 14px'
										for={`cb_display_field_${attr.key}`}
									>
										{this.$t('show')}
									</label>
								</div>
							)
							if (['emails', 'phones', 'fullname', 'lifecycle_stage'].includes(attr.key)) {
								$cb = null
							}

							return (
								<div
									class='mt-3 mb-3 pl-2 pr-2'
									style='cursor: auto; border-top: 1px solid whitesmoke;'
									ref={`${attr.key}_field`}
								>
									<div class='d-flex align-items-center mb-1'>
										<div class='text__semibold'>{attrname}</div>
										{$cb}
									</div>
									{['phones', 'emails'].includes(attr.key) ? (
										this.renderPrimaryAttrField(attr)
									) : (
										<Fragment>
											{this.renderTextInput(attr)}
											{this.renderNumberInput(attr)}
											{this.renderBooleanInput(attr)}
											{this.renderDateTimeInput(attr)}
										</Fragment>
									)}
								</div>
							)
						})
					) : (
						<div style='width: 100%; height: 100%' class='text__muted d-flex align-items-center justify-content-center'>
							{this.$t('no_record_found')}
						</div>
					)}
				</div>
				<div
					class='dropdown_filter_container d-flex align-items-center'
					style='padding: 8px 20px; background: white; border-top: 1px solid #ececec'
				>
					<div class='btn btn__sm btn__primary' vOn:click={this.saveEditAttributes} disabled={this.saving}>
						{this.$t('save_changes')}
						{this.saving && <Spinner size='12' class='ml-2' />}
					</div>
					<router-link to={{name: 'settings.user-attributes'}} class='ml-auto' target='_blank'>
						{this.$t('setting_user_attribute')}
					</router-link>
				</div>
				<EditUserPrimaryAttributeModal
					uid={this.user.id}
					attr={this.editPrimaryAttr}
					open={this.isEditPrimaryAttrModalOpened}
					vOn:close={() => (this.isEditPrimaryAttrModalOpened = false)}
				/>
			</div>
		)
	},
}
