import {format} from 'date-fns'
import AppendToBody from './append_to_body.js'

const ALLOW_TIME_FORMATS = ['hh:mm', 'hh:mm:ss']
const DEFAULT_FORMAT = 'hh:mm'

export default {
	name: 'time-picker-input',
	props: ['value', 'now', 'minuteStep', 'hourStep', 'secondStep', 'format', 'placeholder', 'extraClass'], // value should be 00:12, 23:59, vvv
	data() {
		return {
			open: false,
			text: '', // this will display text in input, use for handle input time from keyboard, always has value
			dropdownStyle: {},
		}
	},

	mounted() {
		this.resetText()
	},

	watch: {
		value() {
			this.resetText()
		},
	},

	computed: {
		timeFormat() {
			return ALLOW_TIME_FORMATS.includes(this.format) ? this.format : DEFAULT_FORMAT
		},

		displayMinuteOptions() {
			let minutes = [...Array(60).keys()]
			if (this.minuteStep && this.minuteStep > 1) {
				minutes = lo.filter(minutes, (min) => min % this.minuteStep === 0)
			}
			return lo.map(minutes, (min) => pad(min))
		},

		displayHourOptions() {
			let hours = [...Array(24).keys()]
			if (this.hourStep && this.hourStep > 1) {
				hours = lo.filter(hours, (hour) => hour % this.hourStep === 0)
			}
			return lo.map(hours, (hour) => pad(hour))
		},

		displaySecondOptions() {
			let seconds = [...Array(60).keys()]
			if (this.secondStep && this.secondStep > 1) {
				seconds = lo.filter(seconds, (second) => second % this.secondStep === 0)
			}
			return lo.map(seconds, (second) => pad(second))
		},

		displayHourMinuteOptions() {
			let hours = this.displayHourOptions
			let minutes = this.displayMinuteOptions

			let res = []
			lo.each(hours, (hour) => {
				lo.each(minutes, (min) => {
					res.push(`${hour}:${min}`)
				})
			})

			return res
		},

		simple() {
			return this.timeFormat === 'hh:mm' && this.minuteStep >= 15
		},
	},

	methods: {
		resetText() {
			let text = this.value
			if (!text) {
				text = '--:--'
				if (this.timeFormat === 'hh:mm:ss') {
					text = '--:--:--'
				}
			}
			this.text = text
		},

		async openDropdown() {
			this.computeStyle()

			this.open = true
			await this.$nextTick()
			this.scrollSelectionItems()
		},

		closeDropdown() {
			this.open = false
			this.focusSection = ''
		},

		clearInput() {
			this.open = false
			this.focusSection = ''
			this.$emit('change', '')
		},

		computeStyle() {
			const DROPDOWN_HEIGHT = 280 // constant, we can ehance by calculatate dynamiccally later
			let DROPDOWN_WiDTH = 120
			if (this.timeFormat.includes('ss')) DROPDOWN_WiDTH = 180
			const EDGE = 20

			let $input = this.$refs.input
			let rect = $input ? $input.getBoundingClientRect() : {}
			let {x = 0, y = 0, width = 0, height = 0} = rect
			let isTop = window.innerHeight - (y + height + DROPDOWN_HEIGHT + EDGE) < 0
			let isRight = window.innerWidth - (x + width + DROPDOWN_WiDTH + EDGE) < 0

			this.dropdownStyle = {
				top: isTop ? 'unset' : `${y + height}px`,
				bottom: isTop ? `${window.innerHeight - y}px` : 'unset',
				left: isRight ? 'unset' : `${x}px`,
				right: isRight ? `${window.innerWidth - x - width}px` : 'unset',
			}
		},

		extractHoursString() {
			let value = this.value || ''
			let hours = lo.split(this.text, ':')
			hours = lo.get(hours, 0)
			return hours || '00'
		},

		extractMinutesString() {
			let value = this.value || ''
			let mins = lo.split(this.text, ':')
			mins = lo.get(mins, 1)
			return mins || '00'
		},

		extractSecondsString() {
			let value = this.value || ''
			let secs = lo.split(this.text, ':')
			secs = lo.get(secs, 2)
			return secs || '00'
		},

		async onSelectSeconds(sec) {
			let currentHour = this.extractHoursString()
			let currentMins = this.extractMinutesString()

			let value = ''
			value = `${currentHour}:${currentMins}:${sec}`

			//this.$emit('change', value)
			this.text = value
			await this.$nextTick()
			let $item = this.$refs[`sec_item_${sec}`]
			if ($item) $item.scrollIntoViewIfNeeded()
			this.tryEmitChange()
		},

		async onSelectMinute(min) {
			let currentHour = this.extractHoursString()
			let currentSeconds = this.extractSecondsString()

			let value = ''
			if (this.timeFormat === 'hh:mm:ss') {
				value = `${currentHour}:${min}:${currentSeconds}`
			} else {
				value = `${currentHour}:${min}`
			}

			this.text = value
			await this.$nextTick()
			let $item = this.$refs[`min_item_${min}`]
			if ($item) $item.scrollIntoViewIfNeeded()
			this.tryEmitChange()
		},

		async onSelectHour(hour) {
			let currentMins = this.extractMinutesString()
			let currentSeconds = this.extractSecondsString()

			let value = ''
			if (this.timeFormat === 'hh:mm:ss') {
				value = `${hour}:${currentMins}:${currentSeconds}`
			} else {
				value = `${hour}:${currentMins}`
			}

			this.text = value
			await this.$nextTick()
			let $item = this.$refs[`hour_item_${hour}`]
			if ($item) $item.scrollIntoViewIfNeeded()
			this.tryEmitChange()
		},

		tryEmitChange() {
			let texts = lo.split(this.text, ':')
			let hh = lo.get(texts, 0)
			let mm = lo.get(texts, 1)
			let ss = lo.get(texts, 2)

			let isHHValid = lo.find(this.displayHourOptions, (option) => option === hh)
			let isMMValid = lo.find(this.displayMinuteOptions, (option) => option === mm)
			let isSSValid = lo.find(this.displaySecondOptions, (option) => option === ss)

			if (this.timeFormat === 'hh:mm:ss') {
				if (isHHValid && isMMValid && isSSValid) {
					this.$emit('change', `${hh}:${mm}:${ss}`)
					return
				}
			}
			this.$emit('change', `${hh}:${mm}`)
		},

		scrollSelectionItems(options = {}) {
			const EDGE = 4 // 4px from margin css of timepicker-selection-item

			let currentHour = this.extractHoursString()
			let $hours = this.$refs.hours_selection
			let $hour_item = this.$refs[`hour_item_${currentHour}`]
			if ($hours && $hour_item) {
				let containerRect = $hours.getBoundingClientRect()
				let itemRect = $hour_item.getBoundingClientRect()
				let top = $hour_item.offsetTop - EDGE
				$hours.scrollTo({top: top, behavior: options.behavior || 'instant'})
			}

			let currentMins = this.extractMinutesString()
			let $mins = this.$refs.mins_selection
			let $min_item = this.$refs[`min_item_${currentMins}`]
			if ($mins && $min_item) {
				let containerRect = $mins.getBoundingClientRect()
				let itemRect = $min_item.getBoundingClientRect()
				let top = $min_item.offsetTop - EDGE
				$mins.scrollTo({top: top, behavior: options.behavior || 'instant'})
			}

			let currentSeconds = this.extractSecondsString()
			let $secs = this.$refs.secs_selection
			let $sec_item = this.$refs[`sec_item_${currentSeconds}`]
			if ($secs && $sec_item) {
				let containerRect = $secs.getBoundingClientRect()
				let itemRect = $sec_item.getBoundingClientRect()
				let top = $sec_item.offsetTop - EDGE
				$secs.scrollTo({top: top, behavior: options.behavior || 'instant'})
			}
		},

		renderDropdown() {
			let value = this.text || ''

			let hourValue = lo.split(value, ':')
			hourValue = this.timeFormat.includes('hh') ? lo.get(hourValue, 0) : ''
			let minValue = lo.split(value, ':')
			minValue = this.timeFormat.includes('mm') ? lo.get(minValue, 1) : ''
			let secValue = lo.split(value, ':')
			secValue = this.timeFormat.includes('ss') ? lo.get(secValue, 2) : ''

			let style = this.dropdownStyle
			if (!this.open) style.display = 'none'

			return (
				<AppendToBody>
					<div class='dropdown sbz-time-picker-dropdown' style={style} vOn:click_stop={() => false}>
						<div class='sbz-time-picker-dropdown-content'>
							{this.timeFormat.includes('hh') && (
								<div class='sbz-time-picker-selection hours' ref='hours_selection'>
									{lo.map(this.displayHourOptions, (hour) => {
										let cls = 'sbz-time-picker-selection-item'
										if (hourValue === hour) cls += ' active'
										return (
											<div class={cls} ref={`hour_item_${hour}`} vOn:click={() => this.onSelectHour(hour)}>
												{hour}
											</div>
										)
									})}
								</div>
							)}
							{this.timeFormat.includes('mm') && (
								<div class='sbz-time-picker-selection mins' ref='mins_selection'>
									{lo.map(this.displayMinuteOptions, (min) => {
										let cls = 'sbz-time-picker-selection-item'
										if (minValue === min) cls += ' active'
										return (
											<div class={cls} ref={`min_item_${min}`} vOn:click={() => this.onSelectMinute(min)}>
												{min}
											</div>
										)
									})}
								</div>
							)}
							{this.timeFormat.includes('ss') && (
								<div class='sbz-time-picker-selection secs' ref='secs_selection'>
									{lo.map(this.displaySecondOptions, (sec) => {
										let cls = 'sbz-time-picker-selection-item'
										if (secValue === sec) cls += ' active'
										return (
											<div class={cls} ref={`sec_item_${sec}`} vOn:click={() => this.onSelectSeconds(sec)}>
												{sec}
											</div>
										)
									})}
								</div>
							)}
						</div>
						<div class='sbz-time-picker-dropdown-footer'>
							<a href='javascript:;' class='text__sm' vOn:click={this.clearInput}>
								{this.$t('clear')}
							</a>
							<div
								class='btn btn__xs btn__primary text__xs'
								vOn:click={this.closeDropdown}
								style='padding-left: 10px; padding-right: 10px;'
							>
								{'OK'}
							</div>
						</div>
					</div>
				</AppendToBody>
			)
		},

		renderSimpleInput() {
			let value = this.value || ''
			return (
				<div class='sbz-time-input-wrapper'>
					<select
						class={'form-control sbz-time-input ' + (value ? '' : 'text__muted') + (this.extraClass || '')}
						value={this.value}
						vOn:change={(e) => this.$emit('change', e.target.value)}
					>
						<option value=''>{this.placeholder || this.timeFormat}</option>
						{lo.map(this.displayHourMinuteOptions, (val) => (
							<option value={val}>{val}</option>
						))}
					</select>
					<Icon name='clock' class='sbz-time-input-icon' />
				</div>
			)
		},

		async onInput(e) {
			let text = e.target.value
			let times = lo.split(text, ':')
			let hh = lo.get(times, '0')
			let mm = lo.get(times, '1')

			if (hh.length > 0 && this.focusSection === 'hh') {
				// auto fill if hour press 3,4,5,6,7,8,9
				if (+hh >= 3 && hh.length === 1) {
					this.onSelectHour(pad(hh))
					await this.$nextTick()
					this.focusMinutesInput()
				} else if (+hh > 23 && hh.length === 2) {
					this.onSelectHour('23')
					await this.$nextTick()
					this.focusMinutesInput()
				} else if (hh.length === 2) {
					this.onSelectHour(hh)
					await this.$nextTick()
					this.focusMinutesInput()
				}
			} else if (mm.length > 0 && this.focusSection === 'mm') {
				// auto fill if min press 6,7,8,9
				if (+mm >= 6 && mm.length === 1) {
					this.onSelectMinute(pad(mm))
					if (this.timeFormat === 'hh:mm:ss') {
						this.focusSecondsInput()
					} else {
						e.target.blur()
						this.closeDropdown()
					}
				} else if (+mm > 59 && mm.length === 2) {
					this.onSelectMinute('59')
					if (this.timeFormat === 'hh:mm:ss') {
						this.focusSecondsInput()
					} else {
						e.target.blur()
						this.closeDropdown()
					}
				} else if (mm.length === 2) {
					this.onSelectMinute(mm)
					if (this.timeFormat === 'hh:mm:ss') {
						this.focusSecondsInput()
					} else {
						e.target.blur()
						this.closeDropdown()
					}
				}
			}
		},

		// keypress event will occurs before onInput, so we can validate value of dateime here
		onKeyPress(e) {
			if (!/[0-9]/.test(e.key)) {
				// only allow digit
				e.preventDefault()
				return
			}
		},

		onFocusInput(e) {
			this.focusHoursInput()
			this.openDropdown()
		},

		focusHoursInput() {
			let $input = this.$refs.input
			if (!$input) return
			$input.focus()
			$input.setSelectionRange(0, 2)
			this.focusSection = 'hh'
		},

		focusMinutesInput() {
			let $input = this.$refs.input
			if (!$input) return
			$input.focus()
			$input.setSelectionRange(3, 5)
			this.focusSection = 'mm'
		},

		focusSecondsInput() {
			let $input = this.$refs.input
			if (!$input) return
			$input.focus()
			$input.setSelectionRange(6, 8)
			this.focusSection = 'ss'
		},
	},

	render() {
		if (this.simple) return this.renderSimpleInput()
		return (
			<div class='sbz-time-input-wrapper' v-clickaway={this.closeDropdown}>
				<input
					ref='input'
					class={'form-control sbz-time-input ' + (this.extraClass || '')}
					vOn:click={this.onFocusInput}
					vOn:focus={this.onFocusInput}
					vOn:input={this.onInput}
					vOn:keypress={this.onKeyPress}
					vModel={this.text}
					placeholder={this.placeholder || this.timeFormat}
				/>
				{this.renderDropdown()}
				<Icon name='clock' class='sbz-time-input-icon' vOn:click={this.onFocusInput} />
			</div>
		)
	},
}

function pad(num) {
	if (num < 10) return `0${num}`
	return `${num}`
}
