import store from '@sb/store'

// selected: id of the selected items
// locations: {city_name, country_code}
export default {
	name: 'location-picker',
	props: ['locations', 'add_placeholder'],

	data() {
		return {
			locationFinding: false,
			locationMatches: [],

			isShow: false,
			filter: '',
			softSelected: {},
		}
	},

	mounted() {
		document.body.addEventListener('keyup', this.onKeyup)
		document.body.addEventListener('keydown', this.onKeydown)

		// define onLocationFilterChange here instead of inside method so each
		// instance of this component will have it own onLocationFilterChange.
		// If we dedfine
		// method: {
		//   a: lo.debounce(() =>{})
		// }
		// multiple of the component will point to same function, causing lose
		// events (by debounce)
		this.onLocationFilterChange = lo.debounce(async (e) => {
			let query = e.target.value
			if (this.filter === query && lo.size(this.locationMatches) > 0) return
			this.filter = query

			this.locationFinding = true
			let {body, error} = await store.searchLocations(query || 'vietnam')
			this.locationFinding = false
			if (error) return
			this.locationMatches = lo.get(body, 'locations', [])
		}, 200)

		this.onLocationFilterChange({target: {value: 'vietnam'}})
	},

	destroyed() {
		document.body.removeEventListener('keyup', this.onKeyup)
		document.body.removeEventListener('keydown', this.onKeydown)
	},

	render() {
		let $locations = lo.map(this.locations, (location, idx) => {
			let $name = null
			let country_code = lo.lowerCase(location.country_code)
			if (location.city_name) {
				$name = (
					<span v-tooltip={location.city_name} class='text__truncate'>
						{location.city_name}
					</span>
				)
			}
			if (location.country_code) {
				$name = (
					<span v-tooltip={location.city_name} class='text__truncate'>
						<i class={`flag-icon flag-icon-${country_code} mr-2`}></i>
						{this.$t('all_cities_of')} {location.country_code}
					</span>
				)
			}

			if (location.city_name && location.country_code) {
				$name = (
					<span>
						<i class={`flag-icon flag-icon-${country_code} mr-2`}></i>
						{location.city_name}
					</span>
				)
			}

			if (!$name) return null
			return (
				<div class='location_picker__location'>
					{$name}
					<Icon
						name='x'
						class='location_picker__remove_location_btn'
						size='18'
						v-tooltip={this.$t('delete')}
						vOn:click_stop={(_) => this.removeLocationFilter(idx)}
					/>
				</div>
			)
		})

		let matches = lo.filter(this.locationMatches, (loc) => {
			let found = lo.find(this.locations, (cond) => {
				if (cond.city_name) return loc.city_name === cond.city_name
				if (cond.country_code && !loc.city_name) return loc.country_code === cond.country_code
			})
			return !found
		})
		let $items = lo.map(matches, (loc) => {
			let country_code = loc.country_code || ''
			let cls = 'dropdown__item dropdown__item__no_hover '
			if (this.softSelected.city_name == loc.city_name && this.softSelected.country_code == loc.country_code) {
				cls += 'dropdown__item__selected'
			}

			country_code = country_code.toLowerCase()
			let $flag = <i class={`flag-icon flag-icon-${country_code} mr-2`}></i>

			let name = loc.city_name
			if (!name) name = loc.country_name + ' (' + this.$t('setting_form_country') + ')'
			return (
				<div
					class={cls}
					vOn:click={(_) => this.addLocationFilter(loc.country_code, loc.city_name)}
					vOn:mousemove={(_) => this.onMouseOver(loc)}
					vOn:mouseover={(_) => this.onMouseOver(loc)}
				>
					{$flag} {name}
				</div>
			)
		})
		let $loading = null
		if (this.locationFinding) {
			$loading = <Spinner mode='dark' class='ml-2' style='position: absolute; right: 10px; top: calc(50% - 7px)' />
		}

		if (lo.size($items) === 0) {
			$items = this.renderEmpty()
		}

		let $dropdown = (
			<div class='dropdown dropdown__right' style='width: 400px; min-height: 250px'>
				<div class='mt-3 ml-3 mr-3 mb-3' style='position: relative'>
					<input
						ref='input'
						class='form-control'
						placeholder={this.$t('type_city_name')}
						vOn:change={this.onLocationFilterChange}
						vOn:keyup={this.onLocationFilterChange}
					/>
					{$loading}
				</div>

				{$items}
			</div>
		)

		if (!this.isShow) $dropdown = null

		return (
			<div class='location_picker'>
				<div class='location_picker__locations'>{$locations}</div>
				<div style='position: relative' vOn:v-clickaway={(_) => this.toogleDropdown(false)}>
					<a href='#' class='link' vOn:click={(_) => this.toogleDropdown(true)}>
						+ {this.add_placeholder || this.$t('rule_add_location')}
					</a>
					{$dropdown}
				</div>
			</div>
		)
	},

	methods: {
		addLocationFilter(countryCode, cityName) {
			if (!countryCode && !cityName) return
			let locations = lo.clone(this.locations) || []

			var idx = lo.findIndex(
				this.locationMatches,
				(loc) => loc.city_name == this.softSelected.city_name && loc.country_code == this.softSelected.country_code,
			)

			this.locationMatches = lo.filter(
				this.locationMatches,
				(loc) => loc.country_code != countryCode || loc.city_name != cityName,
			)

			if (idx >= lo.size(this.locationMatches)) idx = 0
			this.softSelected = this.locationMatches[idx] || {}

			locations.push({city_name: cityName || '', country_code: countryCode || ''})
			this.$emit('change', locations)
		},

		removeLocationFilter(idx) {
			let locations = lo.clone(this.locations)
			locations.splice(idx, 1)
			this.$emit('change', locations)
		},

		onKeydown(e) {
			if (!this.isShow) return
			switch (e.keyCode) {
				case 38: // UP
					var idx = lo.findIndex(
						this.locationMatches,
						(loc) => loc.city_name == this.softSelected.city_name && loc.country_code == this.softSelected.country_code,
					)
					idx--
					if (idx < 0) idx = lo.size(this.locationMatches) - 1
					this.softSelected = this.locationMatches[idx] || {}
					// this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				case 40: // DOWN
					var idx = lo.findIndex(
						this.locationMatches,
						(loc) => loc.city_name == this.softSelected.city_name && loc.country_code == this.softSelected.country_code,
					)
					idx++
					if (idx >= lo.size(this.locationMatches)) idx = 0
					this.softSelected = this.locationMatches[idx] || {}
					e.preventDefault()
					break
			}
		},

		onKeyup(e) {
			if (!this.isShow) return
			switch (e.keyCode) {
				case 27: // ESC
					this.toogleDropdown(false)
					break
				case 13: // ENTER
					var idx = lo.findIndex(
						this.locationMatches,
						(loc) => loc.city_name == this.softSelected.city_name && loc.country_code == this.softSelected.country_code,
					)
					var loc = this.locationMatches[idx]
					this.addLocationFilter(loc.country_code, loc.city_name)
					e.preventDefault()
					break
			}
		},

		onMouseOver(loc) {
			if (this.softSelected !== loc) this.softSelected = loc
		},

		toogleDropdown(v) {
			if (v === false || v === true) this.isShow = v
			else this.isShow = !this.isShow
			if (this.isShow) this.focusInput()
		},

		focusInput() {
			if (!this.isShow) return

			this.$refs.input && this.$refs.input.focus()
			setTimeout(() => {
				this.$refs.input && this.$refs.input.focus()
			}, 200)
		},

		renderEmpty() {
			return (
				<div class='combobox_empty'>
					<img class='location_picker__empty_img' src={require('../assets/img/not-found.png')} alt='' />
					<p class='text__muted mt-4'>{this.$t('no_location_found')}</p>
				</div>
			)
		},
	},
}
