import LexicalEditor from '../commons/lexical-editor.js'
import {VueTagsInput, createTags} from '@johmun/vue-tags-input'
const emailAddresses = require('email-addresses')
const sb = require('@sb/util')

const parseEmail = (email) => lo.get(emailAddresses.parseOneAddress(email), 'address', email)
const validation = [
	{
		classes: 'not-email',
		rule: (em) => {
			let email = lo.get(em, 'text')
			if (!email || !email.trim) return false // allow empty
			return !sb.checkEmail(lo.trim(lo.get(em, 'text')))
		},
	},
]

export default {
	name: 'send-email-window',
	data() {
		return {
			mini: false,
			open: false,
			initMessage: {},
			isCcShow: false,
			sending: false,

			cc: '',
			subject: '',
			from: '',
			to: '',
			tos: null,
			froms: [],
			ccs: [],

			// try to detect user id when fill to email
			uid: '',
			uidLoading: false,
		}
	},

	mounted() {
		//this.fillDefault({from: 'trandduck@gmail.shop'})
		this.$root.$on('show_global_email', this.onShowGlobalEmail)
	},

	destroyed() {
		this.$root.$off('show_global_email', this.onShowGlobalEmail)
	},

	watch: {
		tos() {
			this.clookupUserFromToEmail()
		},
	},

	methods: {
		async clookupUserFromToEmail() {
			let toEmail = lo.get(this.tos, '0.text')
			console.log('clookupUserFromToEmail', this.tos, toEmail)
			if (!toEmail) return
			let res = await store.lookupUserByEmail(toEmail)
			if (res.error) {
				this.uid = ''
				return
			}
			let user = lo.get(res, 'body', {})
			let uid = user.primary_id || user.id
			this.uid = uid
			console.log('clookupUserFromToEmaildddd', uid, res)
			if (!store.matchUser(uid)) {
				await store.fetchUser(uid, true)
				this.$forceUpdate()
			}
		},

		async onShowGlobalEmail(params) {
			this.open = true
			this.mini = false
			this.uid = ''
			this.ticket_id = params.ticket_id
			this.fillDefault(params)

			this.initMessage = {text: params.text, attachments: params.attachments}
			let $editor = this.$refs.editor
			await this.$nextTick()
			// clear editor content
			if ($editor) $editor.setInitialContent()
			await this.$nextTick()
			this.setFocus()
			let currentMsg = this.getComputedEmailMessage()
			this._comapreMessage = lo.cloneDeep(currentMsg)
		},

		getComputedEmailMessage() {
			let fields = []
			if (this.subject) {
				fields.push({
					key: 'subject',
					value: JSON.stringify(this.subject),
				})
			}
			if (lo.size(this.froms)) {
				let froms = lo.map(this.froms, (tag) => tag.text)
				fields.push({
					key: 'from',
					value: JSON.stringify(froms[0]),
				})
			}
			if (lo.size(this.tos)) {
				let tos = lo.map(this.tos, (tag) => tag.text)
				fields.push({
					key: 'to',
					value: JSON.stringify(tos),
				})
			}
			if (lo.size(this.ccs)) {
				let ccs = lo.map(this.ccs, (tag) => tag.text)
				fields.push({
					key: 'cc',
					value: JSON.stringify(ccs),
				})
			}
			return {
				text: this.initMessage.text,
				attachments: this.initMessage.attachments,
				fields,
				format: 'html',
			}
		},

		onChange(msg) {
			this.initMessage = msg
			//if (lo.size(msg.fields)) {
			//let ev = {data: {message: msg}}
			//let subject = sb.getMsgField(ev, 'subject')
			//let from = sb.getMsgField(ev, 'from')
			//if (subject) this.subject = subject
			//if (!lo.size(this.froms) && from) this.froms = [from]
			//}
		},

		onSelectMessageTemplate(msg) {
			if (lo.size(msg.fields)) {
				let ev = {data: {message: msg}}
				let subject = sb.getMsgField(ev, 'subject')
				let from = sb.getMsgField(ev, 'from')
				if (subject) this.subject = subject
				if (!lo.size(this.froms) && from) this.froms = createTags([from])
			}
		},

		fillDefault(params = {}) {
			this.subject = params.subject || ''

			if (params.from) {
				if (Array.isArray(params.from)) {
					this.froms = createTags(lo.take(params.from, 1))
				} else {
					this.froms = createTags([params.from])
				}
			} else {
				let cachedFromEmail = store.getFromEmailCache()
				if (cachedFromEmail) {
					this.froms = createTags([cachedFromEmail])
				} else {
					this.froms = []
				}
			}

			if (params.to) {
				if (Array.isArray(params.to)) {
					this.tos = createTags(lo.take(params.to, 1))
				} else {
					this.tos = createTags([params.to])
				}
			} else {
				this.tos = []
			}

			if (params.cc) {
				this.isCcShow = true
				if (Array.isArray(params.cc)) {
					this.ccs = createTags(lo.take(params.cc, 1))
				} else {
					this.ccs = createTags([params.cc])
				}
			} else {
				this.ccs = []
			}
		},

		setFocus() {
			if (!lo.size(this.froms)) {
				this.focusFrom()
				return
			}
			if (!lo.size(this.tos)) {
				this.focusTo()
				return
			}
			if (this.isCcShow && !lo.size(this.ccs)) {
				this.focusCc()
				return
			}
			if (!this.subject) {
				this.focusSubject()
				return
			}
			this.$refs.editor.focus()
		},

		focusTo() {
			if (!this.$refs.to_tag) return
			this.$refs.to_tag.$el.querySelector('input').focus()
		},

		focusFrom() {
			if (!this.$refs.from_tag) return
			this.$refs.from_tag.$el.querySelector('input').focus()
		},

		focusSubject() {
			if (!this.$refs.subject_input) return
			this.$refs.subject_input.focus()
		},

		focusCc() {
			if (!this.$refs.cc_tag) return
			this.$refs.cc_tag.$el.querySelector('input').focus()
		},

		setTos(tgs) {
			this.tos = tgs
		},

		setFroms(tgs) {
			this.froms = tgs
		},

		setCCs(tgs) {
			this.ccs = tgs
		},

		async showCc() {
			this.isCcShow = true
			await this.$nextTick()
			this.focusCc()
		},

		renderFrom() {
			let autoCompleteFrom = []
			let incommingEmails = lo.filter(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
			)
			incommingEmails = lo.orderBy(incommingEmails, ['address'], ['asc'])
			incommingEmails = lo.map(incommingEmails, (em) => ({text: em.address}))
			autoCompleteFrom = lo.filter(incommingEmails, (i) => lo.toLower(i.text).indexOf(lo.toLower(this.from)) !== -1)

			return (
				<div class='global_email_window_content_info_wrapper'>
					<div class='text__muted no-shrink'>{this.$t('from')}:</div>
					<div class='flex__1 ml-2'>
						<VueTagsInput
							vModel_trim={this.from}
							ref='from_tag'
							vOn:focus={this.onFromFocus}
							max-tags={1}
							validation={validation}
							autocomplete-items={autoCompleteFrom}
							autocomplete-min-length={lo.size(this.froms) ? 1 : 0}
							tags={createTags(this.froms)}
							vOn:tags-changed={this.setFroms}
							allowEditTags
							placeholder=''
						/>
					</div>
				</div>
			)
		},

		renderTo() {
			let autoCompleteTo = []
			return (
				<div class='global_email_window_content_info_wrapper'>
					<div class='text__muted no-shrink'>{this.$t('to')}:</div>
					<div class='flex__1 ml-2'>
						<VueTagsInput
							vModel_trim={this.to}
							ref='to_tag'
							vOn:focus={this.onToFocus}
							validation={validation}
							autocomplete-items={autoCompleteTo}
							tags={createTags(this.tos || [])}
							vOn:tags-changed={this.setTos}
							placeholder=''
							allow-edit-tags
							separators={[';', ',']}
						/>
					</div>
					{!this.isCcShow && (
						<div class='link link__secondary ml-2 text__underline' vOn:click={this.showCc}>
							Cc
						</div>
					)}
				</div>
			)
		},

		renderSubject() {
			return (
				<div class='global_email_window_content_info_wrapper'>
					<div class='text__muted no-shrink'>{this.$t('subject')}:</div>
					<div class='flex__1 ml-2'>
						<input ref='subject_input' class='w_100 email_header__subject_input mt-1 mb-1' vModel={this.subject} />
					</div>
				</div>
			)
		},

		async setFullWindow() {
			this.mini = false
			await this.$nextTick()
			this.setFocus()
		},

		validateForm() {
			let fromEmail = lo.get(this.froms, '0.text', '')
			let toEmail = lo.get(this.tos, '0.text', '')

			if (!sb.checkEmail(fromEmail)) {
				this.$showError(this.$t('from_email_invalid'))
				return false
			}

			let checkOutboundEmail = lo.find(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS' && email.address === fromEmail,
			)

			if (!checkOutboundEmail) {
				this.$showError(this.$t('unverified_outbound_email'))
				return false
			}

			if (!sb.checkEmail(toEmail)) {
				this.$showError(this.$t('to_email_invalid'))
				return false
			}

			return true
		},

		async submit() {
			if (this.sending) return
			if (!this.validateForm()) return

			let msg = this.getComputedEmailMessage()
			let fromEmail = sb.getMsgField({data: {message: msg}}, 'from')
			store.updateFromEmailCache(fromEmail)
			this.sending = true
			let {body, error} = await store.sendMessage2({
				touchpoint: {
					channel: 'email',
					source: fromEmail,
					id: '',
				},
				msg,
				userids: this.uid ? [this.uid] : undefined,
			})
			this.sending = false
			if (error) return this.$showError(error)

			let convoid = sb.convoOfEv(body)
			if (this.ticket_id) this.tryLinkConvoIdToTicket(convoid)

			// auto select convo when in activities or ticket detail
			let isQuickview = lo.get(this.$route, 'query.quickview')
			if (isQuickview) {
				this.$root.$emit('showQuickview', {user_id: this.uid || '-', convo_id: convoid})
			} else if (lo.get(this.$route, 'name') === 'activities') {
				this.$root.$emit('changeUcid', this.uid || '-', convoid)
			} else if (this.ticket_id && lo.get(this.$route, 'name') === 'ticket-detail') {
				console.log('email successs ticket detaillllll', convoid)
				this.$root.$emit('ticketEmailSuccess', this.ticket_id, convoid)
			} else {
				this.$root.$emit('showQuickview', {user_id: this.uid || '-', convo_id: convoid})
			}
			this.open = false
		},

		async tryLinkConvoIdToTicket(cid) {
			console.log('tryLinkConvoIdToTicket', cid, this.ticket_id)
			if (!cid) return
			let ticket = store.matchTicket(this.ticket_id)
			if (!ticket) {
				await store.fetchTicket(this.ticket_id)
				ticket = store.matchTicket(this.ticket_id)
			}
			if (!ticket) return

			let ticketconvos = lo.get(ticket, 'associated_conversations') || []
			if (ticketconvos.includes(cid)) return
			ticketconvos.push(cid)
			store.updateTicket({
				id: this.ticket_id,
				associated_conversations: ticketconvos,
				_update_fields: ['associated_conversations'],
			})
		},

		renderCc() {
			if (!this.isCcShow) return null

			return (
				<div class='global_email_window_content_info_wrapper'>
					<div class='text__muted no-shrink'>{this.$t('Cc')}:</div>
					<div class='flex__1 ml-2'>
						<VueTagsInput
							vModel_trim={this.cc}
							ref='cc_tag'
							validation={validation}
							autocomplete-items={[]}
							tags={createTags(this.ccs)}
							vOn:tags-changed={this.setCCs}
							allow-edit-tags
							separators={[';', ',']}
							placeholder=''
						/>
					</div>
				</div>
			)
		},

		async closeModal() {
			let currentMsg = this.getComputedEmailMessage()
			if (lo.isEqual(currentMsg, this._comapreMessage)) {
				this.open = false
				return
			}
			let cf = await this.$confirm({
				title: this.$t('unsave_email'),
				ok: this.$t('exit'),
				cancel: this.$t('stay'),
				description: this.$t('unsave_email_desc'),
				style: 'danger',
			})
			if (!cf) return

			this.open = false
		},
	},

	render() {
		if (!this.open) return
		let cls = {global_email_window: true, mini: this.mini, hidden: !this.open}
		//return null

		let $user = null
		if (this.uid) {
			let user = store.matchUser(this.uid) || {}
			$user = (
				<div class='ml-3 flex__1 mr-4' style='overflow: hidden; line-height: 1;'>
					<div
						class='d-inline-flex align-items-center'
						style='max-width: 100%; overflow: hidden; border-radius: 9999px; padding: 0 4px; border: 1px solid #dedede; padding-right: 10px; height: 24px;'
					>
						<Avatar size='18' class='no-shrink mr-2' user={user} />
						<div class='text__truncate text__sm' title={sb.getUserDisplayName(user)}>
							{sb.getUserDisplayName(user)}
						</div>
					</div>
				</div>
			)
		}
		let $header = (
			<div class='global_email_window_header'>
				<div class='text__semibold'>{this.$t('send_email')}</div>
				{$user}
				<Icon size='18' name='minus' class='link link__secondary ml-auto' vOn:click={() => (this.mini = true)} />
				<Icon size='18' name='x' class='link link__secondary ml-2 text__underline' vOn:click={this.closeModal} />
			</div>
		)
		if (this.mini) {
			$header = (
				<div class='global_email_window_header clickable' vOn:click={this.setFullWindow}>
					<div class='text__semibold'>{this.$t('send_email')}</div>
					<Icon size='18' name='maximize' class='link link__secondary ml-auto' />
					<Icon size='18' name='x' class='link link__secondary ml-2 text__underline' vOn:click_stop={this.closeModal} />
				</div>
			)
		}
		return (
			<div class={cls}>
				{$header}
				<div class='global_email_window_content'>
					<div class='email_header'>
						{this.renderFrom()}
						{this.renderTo()}
						{this.renderCc()}
						{this.renderSubject()}
					</div>
					<LexicalEditor
						html
						ref='editor'
						initMessage={this.initMessage}
						vOn:selectTemplate={this.onSelectMessageTemplate}
						no_clear_on_submit
						useTemplate
						uid={this.uid}
						cid={''}
						template_connector_type='email'
						transform_dynamic_field
						vOn:change={this.onChange}
						no_preview_mode
						vOn:submit={this.submit}
						has_submit_btn
						submit_btn_loading={this.sending}
					></LexicalEditor>
				</div>
			</div>
		)
	},
}
