import store from '@sb/store'
var sb = require('@sb/util')
import {Time} from '@sb/com'

export default {
	name: 'user-note',
	props: ['note', 'userId'],
	data() {
		return {
			editNote: {},
			isExpanded: false,
			isEditting: false,
			isSaving: false,
		}
	},

	methods: {
		showEditNote() {
			if (lo.get(store.me(), 'id') !== this.note.creator_id) return
			this.isEditting = true
			this.resetState()

			this.$nextTick(() => {
				let $input = this.$refs.input
				let $container = this.$refs.textarea_container
				if ($container) $container.scrollIntoViewIfNeeded()
				if ($input) $input.focus()
			})
		},

		resetState() {
			this.editNote = lo.cloneDeep(this.note)
		},

		cancelUpdateNote() {
			if(!this.note.id) this.$emit('delete', this.note)
			this.isEditting = false
			this.isSaving = false
		},

		renderUpdateNote() {
			if (!this.isEditting) return null

			return (
				<div class='' ref={`textarea_container`}>
					<textarea
						vOn:keydown_enter_exact_prevent={(_) => _}
						vOn:keyup_enter_exact={(_) => this.onClickUpdateNote()}
						vOn:keyup_esc={(_) => this.cancelUpdateNote()}
						value={this.editNote.text}
						vOn:input={(e) => (this.editNote = {...this.editNote, text: e.target.value})}
						class='user_notes__input form-control mt-2 mb-2'
						cols='10'
						rows='2'
						placeholder={this.$t('add_note_here')}
						ref={`input`}
					></textarea>
					<div class='text-right'>
						<button class='btn btn__primary btn__sm mr-2' vOn:click_prevent={(_) => this.onClickUpdateNote()}>
							{this.$t('setting_segment_edit_button_update')}
						</button>
						<button class='btn btn__light btn__sm' vOn:click_prevent={(_) => this.cancelUpdateNote()}>
							{this.$t('Cancel')}
						</button>
					</div>
				</div>
			)
		},
		async onClickRemoveNote() {
			this.isSaving = true

			let {error} = await store.removeNote(this.userId, this.note.id)
			this.isSaving = false
			if (error) return this.$showError(error)
			this.$emit('delete', this.note)
		},

		async onClickUpdateNote() {
			if (!lo.trim(this.editNote.text)) return this.$showError(this.$t('cannot_be_empty'))

			var payload = this.editNote
			this.isSaving = true
			let {note, error} = await store.upsertNote(this.userId, payload)
			this.isSaving = false
			if (error) {
				return this.$showError(error)
			}
			if (!this.note.id) {
				this.$emit('create', note)
			} else {
				this.$emit('update', note)
			}
			this.isEditting = false
		},

		expandNote() {
			this.isExpanded = true
		},

		collapseNote() {
			this.isExpanded = false
		},
	},

	render() {
		let note = this.note
		let cls = 'user_notes__note'

		if (this.isSaving) cls += ' user_notes__note__lock'
		if (this.isEditting) {
			return <div class={cls}>{this.renderUpdateNote()}</div>
		}

		const TEXT_COLLAPSE_LENGTH = 170
		var $content = null

		if (this.isExpanded) {
			let $collapse = null
			if (lo.size(this.note.text) > TEXT_COLLAPSE_LENGTH) {
				$collapse = (
					<div class='user_note_text_action' vOn:click={() => this.collapseNote()}>
						{this.$t('collapse')}
					</div>
				)
			}
			$content = (
				<div class='user_notes__note_content' vOn:dblclick={(_) => this.showEditNote()}>
					{note.text} {$collapse}
				</div>
			)
		} else {
			let $more = null
			if (lo.size(this.note.text) > TEXT_COLLAPSE_LENGTH) {
				$more = (
					<span class='user_note_text_action' vOn:click={() => this.expandNote()}>
						{this.$t('more')}
					</span>
				)
			}

			$content = (
				<div class='user_notes__note_content' vOn:dblclick={(_) => this.showEditNote()}>
					{lo.truncate(note.text, {length: TEXT_COLLAPSE_LENGTH})} {$more}
				</div>
			)
		}

		let agent = this.note.creator_id
		agent = lo.get(store.matchAgent(), agent, {})

		return (
			<div class={cls}>
				<div class='d-flex mb-2'>
					<Avatar agent={agent} rounded nodot size='sm' class='no-shrink' />
					<div class='ml-3' style='flex: 1'>
						<div class='d-flex align-items-center'>
							<div class='text__semibold text__truncate mr-2'>{sb.getAgentDisplayName(agent)}</div>
							<Time time={note.created} class='user_notes__note_time no-shrink' />

							{lo.get(store.me(), 'id') === note.creator_id && (
								<div class='user_notes__note_actions__actions ml-2'>
									<div>{'   ·   '}</div>
									<div vOn:click={() => this.showEditNote()} class='user_notes__note_action link'>
										{this.$t('edit')}
									</div>
									<div>{'   ·   '}</div>
									<div
										class='user_notes__note_action link link__danger'
										style='padding-right: 0'
										vOn:click={() => this.onClickRemoveNote()}
									>
										{this.$t('delete')}
									</div>
								</div>
							)}
						</div>
						{$content}
					</div>
				</div>
			</div>
		)
	},
}
