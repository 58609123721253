var sb = require('@sb/util')
const config = require('@sb/config')
const flow = require('@subiz/flow')

export default {
	name: 'image-attachments',
	props: ['ev'],

	methods: {
		downloadImages(images) {
			flow.map(images, async (image) => {
				let url = sb.replaceFileUrl(image.url + `?cd=attachment`)
				if (image.name) url += '%3Bfilename=%22' + image.name + '%22'
				sb.downloadURI(url, image.name || 'untitled')
				await sb.sleep(100)
			})
		},
	},

	render() {
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var images = []
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'

		lo.each(attachments, (att, i) => {
			if (!att) return
			if (!lo.includes(att.mimetype, 'image')) return
			if (!att.url) return

			var uri = att.thumbnail_url || att.url
			if (
				!uri.startsWith('https://') &&
				!uri.startsWith('http://') &&
				!uri.startsWith('content://') &&
				!uri.startsWith('data:')
			) {
				att.url = config.FileUrl + uri
			}
			att._true_idx = i
			images.push(att)
		})

		if (images.length === 0) return null

		var $images = lo.map(images, (image, index) => (
			<div class={`message_image message_image__${byType}`}>
				<img2
					object_fit='cover'
					maxHeight='450px'
					class='message_image__img clickable'
					src={sb.replaceFileUrl(image.thumbnail_url || image.url)}
					true_src={sb.replaceFileUrl(image.url)}
					vOn:click={() => this.$emit('gallery', {evid: this.ev.id, attachment_idx: image._true_idx})}
				/>
			</div>
		))

		let $downloadall = null
		if (byType == 'user' && images.length > 1) {
			$downloadall = (
				<a
					class='link link__secondary message_container message_container__user message_container__image mt-2'
					href='#'
					vOn:click={(_) => this.downloadImages(images)}
				>
					<small>
						<Icon size='16' name='download' />
						&nbsp;{this.$t('download_all_images')}
					</small>
				</a>
			)
		}
		let gridColumns = calculateGridColumn(images)
		return (
			<div
				class={{
					message_container: byType !== 'user',
					message_container__agent: byType !== 'user',
					w_100: true,
				}}
			>
				<div
					class={{
						message_container: true,
						message_container__image: true,
						[`message_container__${byType}`]: true,
						has_grid: gridColumns > 1,
						[`grid_columns_${gridColumns}`]: gridColumns > 1,
					}}
				>
					{$images}
				</div>

				{$downloadall}
			</div>
		)
	},
}

function calculateGridColumn(images) {
	if (lo.size(images) === 1) return 1
	if (lo.size(images) > 4 || lo.size(images) === 3) return 3
	if (lo.size(images) % 2 === 0) return 2
}
