import store from '@sb/store'
import sb from '@sb/util'
import AppendToBody from '../../commons/append_to_body.js'
import {computeErrorMessage} from '../../commons/compute_error_message.js'

const DROPDOWN_WIDTH = 480
const DROPDOWN_HEIGHT = 300
const EDGE = 50

export default {
	props: ['convo'],
	data() {
		return {
			loading: false,
			open: false,
			ticketKeyword: '',
			ticketSearching: false,
			fetchError: '',
			// if we have other association type in the future, copy state searchTickets and displayTicketIds
			selectedTicketIds: [],
			searchedTickets: [],
			currentTickets: [],
			tab: 'ticket',
			ticketLoadings: {}, // loading state when toggle check a ticket
		}
	},

	mounted() {
		this.loadRelatedTicket()
	},

	watch: {
		convo(newconvo, oldconvo) {
			let newid = lo.get(newconvo, 'id')
			let oldid = lo.get(oldconvo, 'id')
			if (newid && newid !== oldid) this.loadRelatedTicket()
		},
	},

	methods: {
		closeDropdown() {
			this.open = false
		},

		async loadRelatedTicket() {
			if (!this.convo) return
			this.fetchError = ''

			this.loading = true
			let query = {
				condition: {
					all: [
						{
							key: 'associated_conversations',
							type: 'text',
							text: {
								op: 'eq',
								eq: [lo.get(this.convo, 'id')],
							},
						},
					],
				},
				limit: 500,
				order_by: 'created',
			}
			let res = await store.listTickets(query)
			this.loading = false
			if (res.error) {
				this.fetchError = computeErrorMessage(res.error)
				return
			}
			let tickets = lo.get(res, 'body.tickets', [])
			this.selectedTicketIds = lo.map(tickets, (ticket) => ticket.id)
			this.resetCurrentTickets()
		},

		toggleDropdown() {
			this.open = !this.open
			if (this.open) {
				this.calculateDropdownStyle()
				this.tab = 'ticket'
				this.ticketKeyword = ''
				this.searchedTickets = []
			}
		},

		calculateDropdownStyle() {
			let $btn = this.$refs.btn
			let rect = $btn ? $btn.getBoundingClientRect() : {}
			let {top = 0, left = 0, width = 0, height = 0} = rect

			this.style = {
				top: top + height + 2 + 'px',
				right: 'unset',
				left: left - 2 + 'px',
				position: 'absolute',
				minWidth: 'unset',
				maxWidth: 'unset',
				width: DROPDOWN_WIDTH + 'px',
				minHeight: 'unset',
				maxHeight: 'unset',
				height: DROPDOWN_HEIGHT + 'px',
			}
		},

		renderTicketRow(ticket) {
			ticket = store.matchTicket(ticket.id) || {id: ticket.id}
			return (
				<div
					class='convo_associations_dropdown_table_row'
					key={ticket.id}
					vOn:click={() => this.toggleTicketSelect(ticket.id)}
				>
					<input
						checked={lo.includes(this.selectedTicketIds, ticket.id)}
						type='checkbox'
						vOn:click_stop={() => false}
						vOn:change={() => this.toggleTicketSelect(ticket.id)}
						style='margin: 0'
						class='form-check-input form-check-input--bold no-shrink'
					/>
					<div class='ml-3 flex__1 text__truncate' title={'(' + ticket.number + ') ' + ticket.title}>
						({ticket.number}) {ticket.title}
					</div>
					{this.ticketLoadings[ticket.id] && <Icon name='loader-2' class='ml-2 text__muted rotate' size='18' />}
				</div>
			)
		},

		toggleTicketSelect(ticketid) {
			this._currentTicketIds = lo.cloneDeep(this.selectedTicketIds)
			if (this.selectedTicketIds.includes(ticketid)) {
				this.selectedTicketIds = lo.filter(this.selectedTicketIds, (id) => id !== ticketid)
			} else {
				this.selectedTicketIds.push(ticketid)
			}
			this.updateTicketAssociatedConvos(ticketid)
		},

		async updateTicketAssociatedConvos(ticketid) {
			if (this.ticketLoadings[ticketid]) return

			this.ticketLoadings[ticketid] = true
			this.$forceUpdate()
			let res = await store.fetchTicket(ticketid)
			if (res.error) {
				this.ticketLoadings[ticketid] = false
				this.$forceUpdate()
				this.$showError(res.error)
				this.selectedTicketIds = this._currentTicketIds
				return
			}

			let ticket = res
			let convoids = lo.get(ticket, 'associated_conversations') || []
			if (lo.includes(this.selectedTicketIds, ticketid)) {
				if (!lo.includes(convoids, this.convo.id)) convoids.push(this.convo.id)
			} else {
				convoids = lo.filter(convoids, (cid) => cid !== this.convo.id)
			}
			res = await store.updateTicket({
				id: ticketid,
				associated_conversations: convoids,
				_update_fields: ['associated_conversations'],
			})
			this.ticketLoadings[ticketid] = false
			this.$forceUpdate()
			if (res.error) {
				this.$showError(res.error)
				this.selectedTicketIds = this._currentTicketIds
				return
			}

			this.$showSuccess(this.$t('success'))
		},

		clearTicketKeyword() {
			this.ticketKeyword = ''
			this.resetCurrentTickets()
		},

		resetCurrentTickets() {
			this.currentTickets = lo.map(this.selectedTicketIds, (id) => store.matchTicket(id) || {id})
		},

		onTicketKeywordChange(e) {
			let v = e.target.value
			if (!v) {
				this.clearTicketKeyword()
				return
			}
			this.ticketKeyword = v
			this.throttleSearchTicket()
		},

		throttleSearchTicket: lo.throttle(function () {
			this.searchTickets()
		}, 800),

		async searchTickets() {
			this.ticketSearching = true
			let query = {
				condition: {
					all: [
						{
							key: 'deleted',
							type: 'number',
							number: {
								op: 'eq',
								eq: [0],
							},
						},
					],
				},
				limit: 10,
				query: this.ticketKeyword,
				order_by: 'created',
			}
			let flag = sb.randomString(8) + Date.now()
			this._flag = flag
			let res = await store.listTickets(query, true)
			if (this._flag !== flag) return
			this.ticketSearching = false
			this.searchedTickets = lo.get(res, 'body.tickets', [])
		},

		renderDropdown() {
			if (!this.open) return
			return (
				<AppendToBody>
					<div class='dropdown' style={this.style || {}} vOn:click_stop={() => false}>
						<div class='d-flex w_100 h_100' style='overflow: hidden'>
							<div class='convo_associations_dropdown_type'>
								<div class='convo_associations_dropdown_type_item active'>
									{this.$t('ticket')}
									<div class='ml-auto'>{lo.size(this.selectedTicketIds)}</div>
								</div>
							</div>
							<div class='convo_associations_dropdown_content'>
								<div style='padding: 10px; padding-bottom: 5px;' class='no-shrink'>
									<div style='position: relative'>
										{this.ticketSearching ? (
											<Spinner size='18' mode='dark' style='position: absolute; top: 9px; left: 8px' />
										) : (
											<Icon
												name='search'
												size='18'
												class='text__muted'
												style='position: absolute; top: 9px; left: 8px'
											/>
										)}
										<input
											style='padding-left: 30px; padding-right: 30px;'
											class='form-control'
											placeholder={this.$t('search_ticket')}
											vModel={this.ticketKeyword}
											vOn:input={this.onTicketKeywordChange}
										/>
										{this.ticketKeyword && (
											<Icon
												name='x'
												size='18'
												style='position: absolute; right: 8px; top: 9px'
												class='x-icon'
												vOn:click={this.clearTicketKeyword}
											/>
										)}
									</div>
								</div>
								<div class='flex__1' style='overflow-y: scroll'>
									{this.ticketKeyword ? (
										<div>
											{lo.size(this.searchedTickets) ? (
												lo.map(this.searchedTickets, this.renderTicketRow)
											) : (
												<div class='text__center text__muted mt-5'>{this.$t('no_search_result')}</div>
											)}
										</div>
									) : (
										<div>
											{lo.size(this.currentTickets) ? (
												lo.map(this.currentTickets, this.renderTicketRow)
											) : (
												<div class='text__center text__muted mt-5'>
													{this.$t('convo_doesnt_have_associated_tickets')}
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</AppendToBody>
			)
		},
	},

	render() {
		if (!this.convo) return null
		let $text = this.$t('set_associations')
		let $icon = <Icon name='chevron-down' size='14' stroke-width='2' class='ml-2 text__muted' />
		if (lo.size(this.selectedTicketIds))
			$text = lo.size(this.selectedTicketIds) + ' ' + this.$t('associations').toLowerCase()
		if (this.fetchError) {
			$text = '0 ' + this.$t('associations').toLowerCase()
			$icon = (
				<Icon
					name='alert-circle-filled'
					class='ml-2'
					size='14'
					title={this.fetchError + '. ' + this.$t('click_to_reload')}
				/>
			)
		}
		if (this.loading) $text = <em class='text__muted'>{this.$t('loading')}...</em>

		let style = {}
		if (this.loading) {
			style.pointerEvents = 'none'
			style.opacity = '0.7'
		}
		return (
			<div class='mr-3' v-clickaway={this.closeDropdown} style={style}>
				<button
					ref='btn'
					type='button'
					class={{
						btn: true,
						btn__sm: true,
						'd-flex': true,
						'align-items-center': true,
						btn__white: !this.fetchError,
						btn__outline_danger: this.fetchError,
					}}
					style='display: flex; height: 32px'
					vOn:click={() => (this.fetchError ? this.loadRelatedTicket() : this.toggleDropdown())}
				>
					{$text}
					{$icon}
				</button>
				{this.renderDropdown()}
			</div>
		)
	},
}
