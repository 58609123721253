import store from '@sb/store'

export default {
	name: 'sidebarFooter',
	props: ['agent', 'facebook_setting', 'webcheck', 'validateFunction'],

	data() {
		return {
			compared: false,
			successfulUpdate: 0,
			saving: false,
		}
	},

	watch: {
		agent(newAgent, oldAgent) {
			if (!oldAgent && newAgent) this.compared = lo.cloneDeep(this.agent)
			// refresh when select edit another agent
			if (newAgent && oldAgent && newAgent.id !== oldAgent.id) {
				this.compared = lo.cloneDeep(this.agent)
			}
		},

		facebook_setting(newObj, oldObj) {
			if (!oldObj && newObj) this.compared = lo.cloneDeep(this.facebook_setting)
			// refresh when select edit another facebook_setting
			if (newObj && oldObj && newObj.id !== oldObj.id) {
				this.compared = lo.cloneDeep(this.facebook_setting)
			}
		},

		webcheck(newObj, oldObj) {
			if (!oldObj && newObj) this.compared = lo.cloneDeep(this.webcheck)
			// refresh when select edit another webcheck
			if (newObj && oldObj && newObj.id !== oldObj.id) {
				this.compared = lo.cloneDeep(this.webcheck)
			}
		},
	},

	created() {
		let obj = this.getObjectData()
		this.compared = lo.cloneDeep(obj)
	},

	methods: {
		isDirty() {
			let obj = this.getObjectData()
			return !lo.isEqual(obj, this.compared)
		},

		getObjectData() {
			if (this.agent) return this.agent
			if (this.facebook_setting) return this.facebook_setting
			if (this.webcheck) return this.webcheck
		},

		isCloseAfterSubmit() {
			if (this.agent) return lo.get(this.agent, 'id') === 'new'
		},

		async handleFetch() {
			if (this.agent) {
				if (lo.get(this.agent, 'id') === 'new') {
					let inviteData = await store.inviteAgent(this.agent)
					return inviteData
				}

				let out = await store.fetchAgent(this.agent.id)
				let agent = lo.cloneDeep(out.body)
				if (!agent || !agent.id) return this.$showError(out.error)
				agent = {
					...agent,
					...this.agent,
				}
				let data = await store.updateAgent(agent)
				return data
			}
			if (this.facebook_setting) {
				let data = await store.updateFacebookSetting(this.facebook_setting)
				return data
			}
			if (this.webcheck) {
				if (lo.get(this.webcheck, 'id') === 'new') {
					let webcheck = lo.cloneDeep(this.webcheck)
					delete webcheck.id
					let inviteData = await store.createWebcheck(webcheck)
					return inviteData
				}

				let data = await store.updateWebcheck(this.webcheck)
				return data
			}
		},

		renderCancelButton() {
			// allow custom cancel button
			if (this.$slots.cancel) {
				return <div vOn:click={this.cancelEdit}>{this.$slots.cancel}</div>
			}

			return (
				<button type='button' class='btn btn__light ml-3' vOn:click={this.cancelEdit}>
					{this.$t('cancel')}
				</button>
			)
		},

		cancelEdit() {
			console.log('cancelEdit')
			let obj = lo.cloneDeep(this.compared)
			this.$emit('change', obj)
		},

		renderSaveButton() {
			let style = 'min-width: 120px'
			if (this.$slots.save) {
				let $btn = this.$refs.save_btn
				if ($btn) {
					let width = lo.get($btn.getBoundingClientRect(), 'width')
					style = `min-width: ${width}px`
				}
			}

			if (this.saving) {
				return (
					<button ref='save_btn' type='button' class='btn btn__primary' style={style}>
						<Spinner />
					</button>
				)
			}

			if (Date.now() - this.successfulUpdate > 1000) {
				// allow custom save button
				if (this.$slots.save) {
					return (
						<div ref='save_btn' vOn:click={this.submit}>
							{this.$slots.save}
						</div>
					)
				}

				return (
					<button ref='save_btn' type='button' class='btn btn__primary' style={style} vOn:click={this.submit}>
						{this.$t('confirm_save_settings')}
					</button>
				)
			}

			return (
				<button type='button' class='btn btn__primary' style={style}>
					<Icon name='check' size='1x' />
				</button>
			)
		},

		async submit() {
			if (this.saving) return
			// handle logic validation at frontend
			if (this.validateFunction && !this.validateFunction()) return

			this.saving = true
			let {error} = await this.handleFetch()
			this.saving = false

			if (error) return this.$showError(error)
			this.successfulUpdate = Date.now()
			this.compared = lo.cloneDeep(this.getObjectData())

			// reset save button state
			setTimeout(() => {
				this.$forceUpdate()
				if (this.isCloseAfterSubmit()) this.$emit('close')
			}, 1500)
		},
	},

	render() {
		let cls = 'fanpage-settings-sidebar__foot'
		if (this.isDirty() || Date.now() - this.successfulUpdate <= 1000) cls += ' active'

		return (
			<div class={cls}>
				{this.renderSaveButton()}
				{this.renderCancelButton()}
			</div>
		)
	},
}
