import store from '@sb/store'
const sb = require('@sb/util')

export default {
	name: 'edit-convo-subject-modal',
	props: ['cid', 'open'],
	data() {
		return {
			subject: '',
			submitting: false,
		}
	},

	watch: {
		async open() {
			if (this.open) {
				this.resetData()
				await this.$nextTick()
				let $input = this.$refs.input
				if ($input) $input.focus()
			}
		},
	},

	methods: {
		resetData() {
			this.submitting = false
			let convo = store.matchConvo(this.cid) || {}
			this.subject = convo.subject || ''
		},

		async submit() {
			if (this.submitting) return
			this.submitting = true
			let res = await store.updateConvo({id: this.cid, subject: this.subject})
			this.submitting = false
			if (res.error) {
				this.$showError(res.error)
				return
			}
			this.$showSuccess(this.$t('success'))
			this.closeModal()
		},

		closeModal() {
			this.$emit('close')
		},
	},

	render() {
		let cls = 'modal'
		if (!this.open) cls += ' modal__hide'

		return (
			<Modal show={this.open} vOn:bg={this.closeModal}>
				<div class='modal__background'>
					<div class='modal_content' style='width: 480px; padding: 20px'>
						<div class='d-flex'>
							<div style='font-size: 18px' class='text__semibold'>
								{this.$t('edit_convo_subject')}
							</div>
							<Icon name='x' class='btn__close ml-auto' size='16' vOn:click={this.closeModal} />
						</div>
						<input ref='input' class='mt-3 form-control' vModel={this.subject} />
						<div class='d-flex justify-content-end mt-4'>
							<button class='btn btn__sm btn__light' vOn:click={this.closeModal}>
								{this.$t('cancel')}
							</button>
							<button
								type='button'
								disabled={this.submitting}
								class='ml-3 btn btn__primary btn__sm'
								vOn:click={this.submit}
							>
								{this.$t('save')}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	},
}
