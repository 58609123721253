import store from '@sb/store'

export default {
	name: 'too_old_browser',

	methods: {
		renderState(state) {
			if (state) return <img src={require('./assets/img/done.svg')} style='width: 16px; margin-right: 10px;' />
			return <img src={require('./assets/img/x.svg')} style='width: 16px; margin-right: 10px;' />
		},

		saywho() {
			var ua = navigator.userAgent
			var tem
			var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
			if (/trident/i.test(M[1])) {
				tem = /\brv[ :]+(\d+)/g.exec(ua) || []
				return 'IE ' + (tem[1] || '')
			}
			if (M[1] === 'Chrome') {
				tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
				if (tem != null)
					return tem
						.slice(1)
						.join(' ')
						.replace('OPR', 'Opera')
			}
			M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
			if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
			return M.join(' ')
		},
	},
	async created() {
		var elem = document.createElement('canvas')
		this.canCanvas = !!(elem.getContext && elem.getContext('2d'))

		!(function(s, u, b, i, z) {
			var o, t, r, y
			s[i] ||
				((s._sbzaccid = z),
				(s[i] = function() {
					s[i].q.push(arguments)
				}),
				(s[i].q = []),
				s[i]('setAccount', z),
				(r = [
					'widget.subiz.net',
					'storage.googleapis' + (t = '.com'),
					'app.sbz.workers.dev',
					i +
						'a' +
						(o = function(k, t) {
							var n = t <= 6 ? 5 : o(k, t - 1) + o(k, t - 3)
							return k !== t ? n : n.toString(32)
						})(20, 20) +
						t,
					i + 'b' + o(30, 30) + t,
					i + 'c' + o(40, 40) + t,
				]),
				(y = function(k) {
					var t, n
					s._subiz_init_2094850928430 ||
						(r[k] &&
							((t = u.createElement(b)),
							(n = u.getElementsByTagName(b)[0]),
							(t.async = 1),
							(t.src = 'https://' + r[k] + '/sbz/app.js?accid=' + z),
							n.parentNode.insertBefore(t, n),
							setTimeout(y, 2e3, k + 1)))
				})(0))
		})(window, document, 'script', 'subiz', 'acpxkgumifuoofoosble')
		let me = store.me()
		if (me) {
			subiz('updateUserAttributes', [
				{key: 'fullname', text: me.fullname},
				{key: 'account_id', text: me.account_id},
				{key: 'emails', text: me.email},
				{key: 'phones', text: me.phone},
			])
		}
		subiz('upsertSetting', {desktop_button: {margin_x: 25, margin_y: 30, position: 'left'}})
	},

	render() {
		let $step1 = <span>1. Đóng tab này</span>

		return (
			<div style='display: flex; align-items: center; justify-content: center;  margin-top: 140px'>
				<img src={require('./assets/img/404.jpg')} style='width: 400px;' />
				<div style='margin-left: 40px;'>
					<div>
						<span class='badge badge__dark'>426</span> <span class='text__muted ml-2 mr-2'>|</span>{' '}
						<b class='text__dark'>Trình duyệt không tương thích</b>
					</div>
					<div class='subtitle mt-4'>
						<b>Trình duyệt quá cũ hoặc không hỗ trợ các tính năng thiết yếu để subiz hoạt động được.</b>
						<br />
						Hãy nâng cấp trình duyệt để trải nghiệm subiz một cách tốt nhất nhé
						<br />
						<br />
						Danh sách trình duyệt tối thiểu
						<br />
						<div>
							<div>
								Cốc cốc <b>45</b>&nbsp;·&nbsp;Chrome <b>55</b>&nbsp;·&nbsp;Edge <b>17</b>&nbsp;·&nbsp;Firefox <b>52</b>
								&nbsp;·&nbsp;Safari <b>11.1</b>&nbsp;·&nbsp;Opera <b>42</b>&nbsp;·&nbsp;QQ Browser <b>10.4</b>
							</div>
						</div>
						<br />
						<div>
							Trình duyệt của bạn: <b>{this.saywho()}</b>
						</div>
						<br />
						<div>
							<div>
								<b>Yêu cầu tính năng</b>
							</div>
							<div class='d-flex align-items: center'>{this.renderState(navigator.serviceWorker)} Service worker</div>
							<div class='d-flex align-items: center'>
								{this.renderState(CSS.supports('flex-wrap', 'wrap'))} Flexbox
							</div>
							<div class='d-flex align-items: center'>{this.renderState(this.canCanvas)} HTML5 Canvas</div>
							<div class='d-flex align-items: center'>{this.renderState(window.Notification)} Push notification</div>
						</div>
						<div class='d-flex align-items: center'>{this.renderState(window.indexedDB)} Indexed DB</div>
					</div>
				</div>
			</div>
		)
	},
}
