export default {
	mounted() {
		document.querySelector('body').appendChild(this.$el)
	},

	beforeDestroy() {
    // use try because somehow dropdown DOM of dropdown.js component was removed after this hook fire
		try {
			document.querySelector('body').removeChild(this.$el)
		} catch (err) {
			console.log('beforeDestroy append_to_body', err)
		}
	},

	render() {
		return this.$slots.default
	},
}
