
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icon-tabler-tag",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","stroke":"none"}}),_c('circle',{attrs:{"cx":"8.5","cy":"8.5","r":"1","fill":"currentColor"}}),_c('path',{attrs:{"d":"M4 7v3.859c0 .537.213 1.052.593 1.432l8.116 8.116a2.025 2.025 0 002.864 0l4.834-4.834a2.025 2.025 0 000-2.864L12.29 4.593A2.025 2.025 0 0010.859 4H7a3 3 0 00-3 3z"}})])
          )
        }
      }
    