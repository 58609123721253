import {$createParagraphNode, $getRoot, $getSelection, CLEAR_EDITOR_COMMAND, COMMAND_PRIORITY_EDITOR} from 'lexical'
import {mergeRegister} from '@lexical/utils'

export function useClearEditorPlugin(editor) {
	return mergeRegister(
		editor.registerCommand(
			CLEAR_EDITOR_COMMAND,
			(payload) => {
				editor.update(() => {
					const root = $getRoot()
					const selection = $getSelection()
					const paragraph = $createParagraphNode()
					root.clear()
					root.append(paragraph)

					if (selection !== null) {
						paragraph.select()
					}
				})
				return true
			},
			COMMAND_PRIORITY_EDITOR,
		),
	)
}
