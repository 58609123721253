function isCalling(entry) {
	return entry.last_call_status === 'calling'
}

function isCalled(entry) {
	return entry.call_id || entry.called > 0
}

function getEntryId(entry) {
	return `${entry.campaign_id}%${entry.user_id}`
}

function isScheduled(entry) {
	return entry.scheduled_in
}

// logic to display call entry is need to care about
function isCallCompleted(entry) {
	if (!entry) return false
	if (entry.do_not_call) return true
	return entry.state === 'goal' || entry.state === 'called'
}

export default {
	isCalling,
	isCalled,
	getEntryId,
	isScheduled,
	isCallCompleted,
}
