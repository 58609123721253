var Color = require('color')

import Spinner from './spinner.js'

export default {
	name: 'tag',
	props: ['tag', 'loading', 'deletable', 'selected'],

	render() {
		var cls = 'convo_tag '
		let tagColor = this.tag.color || 'black'

		var color = new Color('black')
		try {
			color = new Color(tagColor)
		} catch (e) {}

		let bgcolor = whiten(color, 0.85)
		let bordercolor = whiten(color, 0.7)

		let $loading = null
		if (this.loading) {
			$loading = <Spinner static class='ml-2' size='12' mode='dark' />
			bgcolor = whiten(color, 0.98)
			bordercolor = whiten(color, 0.8)
			color = whiten(color, 0.5)
		}

		let $delete = null
		if (this.deletable && !this.loading) {
			$delete = (
				<span vOn:click={(_) => this.$emit('delete', this.tag)} class='convo_tag__delete pl-1'>
					<Icon
						name='x'
				stroke-width="2"
						v-tooltip={this.$t('remove')}
						style='width: 14px; height: 14px'
						size='14'
						class='cursor__pointer'
					/>
				</span>
			)
		}

		let style = {color: color, background: bgcolor, borderColor: bordercolor}
		if (this.selected) {
			style.fontWeight = 700
			style.boxShadow = `0 0 8px 2px ${color}`
		}

		return (
			<div class={cls} style={style} vOn:click={(_) => this.$emit('click', this.tag)}>
				<span class='convo_tag__title'>{this.tag.title || ' '}</span>
				{$delete}
				{$loading}
			</div>
		)
	},
}

// return the combination of color with alpha on the white background
function whiten(color, alpha) {
	let newred = 255 - (255 - color.red()) * (1 - alpha)
	let newblue = 255 - (255 - color.blue()) * (1 - alpha)
	let newgreen = 255 - (255 - color.green()) * (1 - alpha)
	return Color.rgb(newred, newgreen, newblue)
}
