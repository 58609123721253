import store from '@sb/store'
import sb from '@sb/util'
import {computeErrorMessage} from '../commons/compute_error_message.js'
import {extractLexicalHtml, getTicketStateInfo} from './com.js'
import LexicalEditor from '../commons/lexical-editor.js'
import TicketMessageSentEvent from './ticket_message_sent_event.js'
import UserDisplayName from '../commons/user_display_name.js'

export default {
	name: 'ticket-detail-activities',
	props: ['ticket_id'],
	data() {
		return {
			initMessage: {
				type: 'plaintext',
				is_template: true,
			},
		}
	},

	watch: {
		ticket_id() {
			this.loadData()
		},
	},

	mounted() {
		this.loadData()
		// try reload 1 times because somehow ticket_creaed event doesnt appear immidiately after create ticket
		setTimeout(this.loadData, 800)
		store.onTicketHistory(this, (ev) => {
			if (lo.get(ev, 'ticket_id') !== this.ticket_id) return
			//store.readTickets([this.ticket_id])
			this.$forceUpdate()
		})
	},

	methods: {
		fetchUser(uid) {
			if (!this._fetched) this._fetched = {}
			if (this._fetched[uid]) return
			this._fetched[uid] = true // prevent forever loop
			setTimeout(async () => {
				await store.fetchUser(uid)
				this.$forceUpdate()
			})
		},

		async loadData() {
			await store.fetchTicketEvents(this.ticket_id)
			this.$forceUpdate()
		},

		renderSingleEvent(ev) {
			let $content = null
			if (ev.type === 'ticket_created') $content = this.renderTicketCreatedEvent(ev)
			if (ev.type === 'ticket_comment_added') $content = this.renderMessageSentEvent(ev)
			if (ev.type === 'ticket_reassigned') $content = this.renderReassignedEvent(ev)
			if (ev.type === 'ticket_assigned') $content = this.renderAssignedEvent(ev)
			if (ev.type === 'ticket_state_updated') $content = this.renderTicketStateEvent(ev)
			if (ev.type === 'ticket_rated') $content = this.renderTicketRatedEvent(ev)
			if (ev.type === 'ticket_sla_breached') $content = this.renderSLABreachedEvent(ev)
			return (
				<div key={ev.id} class='ticket_detail_event_wrapper'>
					{$content}
				</div>
			)
		},

		renderTicketRatedEvent(ev) {
			let [$avatar, $name] = this.renderAvatarAndName(ev)
			let rating = lo.get(ev, 'data.rating.rating')
			let text = <em title={rating}>this.t('undefined')</em>
			if (rating === 1 || rating === 2) text = this.$t('poor')
			if (rating === 3) text = this.$t('average')
			if (rating === 4 || rating === 5) text = this.$t('great')

			return (
				<div class='d-flex ticket_detail_event_inner'>
					<div class='ticket_detail_event_inner_avatar_wrapper'>{$avatar}</div>
					<div class='ml-3 text__muted'>
						<span class='text__semibold'>{$name}</span> {this.$t('rated').toLowerCase()}{' '}
						<span class='text__semibold'>{text}</span>
						<span class='dot ml-3 mr-3 dot--sm' style='display: inline-block; top: -4px; position: relative'></span>
						<Time time={ev.created} ago suffix />
					</div>
				</div>
			)
		},

		renderTicketStateEvent(ev) {
			let [_, $name] = this.renderAvatarAndName(ev)
			let ticket = lo.get(ev, 'data.ticket') || {}
			let {text: statustext, type} = getTicketStateInfo(ticket)
			return (
				<div class='d-flex ticket_detail_event_inner'>
					<div class='ticket_detail_event_inner_avatar_wrapper'>
						<Icon name='circle-filled' size='16' class={`text__${type}`} />
					</div>
					<div class='ml-3 text__muted'>
						<span class='text__semibold'>{$name}</span> {this.$t('change_status_to').toLowerCase()}{' '}
						<span class='text__semibold'>{statustext}</span>
						<span class='dot ml-3 mr-3 dot--sm' style='display: inline-block; top: -4px; position: relative'></span>
						<Time time={ev.created} ago suffix />
					</div>
				</div>
			)
		},

		renderSLABreachedEvent(ev) {
			let slaTypeText = this.$t('SLA')
			let target = lo.get(ev, 'data.violation.target')
			if (target === 'first_response_sec') slaTypeText = `${this.$t('SLA')} ${this.$t('first_response')}`
			else if (target === 'every_response_sec') slaTypeText = `${this.$t('SLA')} ${this.$t('next_response')}`
			else if (target === 'peoredic_update_sec') slaTypeText = `${this.$t('SLA')} ${this.$t('periodic_response')}`
			else if (target === 'resolution_sec') slaTypeText = `${this.$t('SLA')} ${this.$t('close_ticket')}`

			return (
				<div class='d-flex ticket_detail_event_inner'>
					<div class='ticket_detail_event_inner_avatar_wrapper'>
						<Icon name='circle-x' size='18' class='text__danger' />
					</div>
					<div class='ml-3 text__muted'>
						<span class='text__semibold'>{slaTypeText}</span> {this.$t('was_overdue').toLowerCase()}{' '}
						<span class='dot ml-3 mr-3 dot--sm' style='display: inline-block; top: -4px; position: relative'></span>
						<Time time={ev.created} ago suffix />
					</div>
				</div>
			)
		},

		renderAssignedEvent(ev) {
			let [$avatar, $name] = this.renderAvatarAndName(ev)

			let assigneeid = lo.get(ev, 'data.ticket.assignee')
			let assignee = store.matchAgent()[assigneeid] || {id: assigneeid}
			return (
				<div class='d-flex ticket_detail_event_inner'>
					<div class='ticket_detail_event_inner_avatar_wrapper'>{$avatar}</div>
					<div class='ml-3 text__muted'>
						<span class='text__semibold'>{$name}</span>
						{lo.get(ev, 'by.id') === assigneeid ? (
							<span> {this.$t('self_assigns').toLowerCase()}</span>
						) : (
							<Fragment>
								{' '}
								{this.$t('assigns').toLowerCase()}{' '}
								<span class='text__semibold'>{sb.getAgentDisplayName(assignee)}</span>
							</Fragment>
						)}
						<span class='dot ml-3 mr-3 dot--sm' style='display: inline-block; top: -4px; position: relative'></span>
						<Time time={ev.created} ago suffix />
					</div>
				</div>
			)
		},

		renderReassignedEvent(ev) {
			let [$avatar, $name] = this.renderAvatarAndName(ev)
			let assigneeid = lo.get(ev, 'data.ticket.assignee')
			let assignee = store.matchAgent()[assigneeid] || {id: assigneeid}

			let text = sb.getAgentDisplayName(assignee)
			if (assigneeid === lo.get(ev, 'by.id')) text = this.$t('myself').toLowerCase()
			return (
				<div class='d-flex ticket_detail_event_inner'>
					<div class='ticket_detail_event_inner_avatar_wrapper'>{$avatar}</div>
					<div class='ml-3 text__muted'>
						<span class='text__semibold'>{$name}</span> {this.$t('reassigns').toLowerCase()}{' '}
						<span class='text__semibold'>{text}</span>
						<span class='dot ml-3 mr-3 dot--sm' style='display: inline-block; top: -4px; position: relative'></span>
						<Time time={ev.created} ago suffix />
					</div>
				</div>
			)
		},

		renderAvatarAndName(ev) {
			let $name = null
			let $avatar = null
			let byid = lo.get(ev, 'by.id')

			let bytype = lo.get(ev, 'by.type')
			if (bytype == 'user') {
				let user = store.matchUser(byid, true) || {id: byid}
				$name = (
					<fragment>
						<UserDisplayName user={user} />
						<span class='text__muted'> ({this.$t('guest')})</span>
					</fragment>
				)
				$avatar = <Avatar user={user} size='18' />
			}

			if (bytype == 'agent') {
				let agent = store.matchAgent()[byid] || {id: byid}
				$name = sb.getAgentDisplayName(agent)
				$avatar = <Avatar agent={agent} size='18' />
			}

			if (bytype == 'subiz') {
				$name = this.$t('system')
				$avatar = <Avatar by={ev.by} size='18' />
			}
			return [$avatar, $name]
		},

		renderTicketCreatedEvent(ev) {
			let [$avatar, $name] = this.renderAvatarAndName(ev)
			return (
				<div class='d-flex ticket_detail_event_inner'>
					<div class='ticket_detail_event_inner_avatar_wrapper'>{$avatar}</div>
					<div class='ml-3 text__muted'>
						<span class='text__semibold'>{$name}</span> {this.$t('create_ticket').toLowerCase()}
						<span class='dot ml-3 mr-3 dot--sm' style='display: inline-block; top: -4px; position: relative'></span>
						<Time time={ev.created} ago suffix />
					</div>
				</div>
			)
		},

		renderMessageSentEvent(ev) {
			return <TicketMessageSentEvent ev={ev} ticket_id={this.ticket_id} />
		},

		async onSubmitComment() {
			let message = lo.cloneDeep(this.initMessage)
			let ev = {
				type: 'ticket_comment_added',
				by: {
					type: 'agent',
					id: store.me().id,
				},

				data: {
					message: this.initMessage,
				},
			}
			this.initMessage = {
				type: 'plaintext',
				is_template: true,
			}
			store.commentTicket(this.ticket_id, message)
		},

		renderInput() {
			return (
				<div key={'ev_input'} class='ticket_detail_event_wrapper'>
					<LexicalEditor
						class=''
						placeholder={this.$t('comment')}
						plaintext
						useMention
						useAutoLink
						has_submit_btn
						vOn:submit={this.onSubmitComment}
						initMessage={this.initMessage}
						ref='note_input'
						vOn:change={(msg) => (this.initMessage = msg)}
					/>
				</div>
			)
		},
	},

	render() {
		let events = lo.map(store.matchTicketEvents(this.ticket_id), (ev) => ev)
		events = lo.orderBy(events, ['created'], ['asc'])
		return (
			<div class='ticket_detail_activities'>
				{lo.map(events, this.renderSingleEvent)}
				{this.renderInput()}
			</div>
		)
	},
}
