import sb from '@sb/util'
import store from '@sb/store'
import Avatar from '@sb/com/avatar'
import Fragment from '@sb/com/fragment'

let audioRingingFile = ''
let audioSupport = document.createElement('audio')
if (audioSupport.canPlayType('audio/mpeg')) {
	audioRingingFile = require('../src/assets/media/ringing.mp3')
} else {
	audioRingingFile = require('../src/assets/media/ringing.ogg')
}

const ringingAudio = new Audio(audioRingingFile)
ringingAudio.addEventListener(
	'ended',
	function () {
		this.currentTime = 0
		this.play()
	},
	false,
)

export default {
	name: 'calling',
	props: ['mode'],

	data() {
		return {
			showTransferDevices: false,
			showingEnd: false,
			last_call_id: '',

			mic: null,
			isWaitMicPermission: false,
		}
	},

	async mounted() {
		let rerender = setInterval(() => {
			if (this.mode.startsWith('calling_')) this.$forceUpdate()
		}, 800)
		this.$once('hook:beforeDestroy', () => clearInterval(rerender))

		let nextCallInternval = setInterval(this.nextCall, 2000)
		this.$once('hook:beforeDestroy', () => clearInterval(nextCallInternval))

		store.onNumberInfo(this, (e) => this.$forceUpdate())
		store.onWebCallStatus(this, (e) => {
			if (this.mic === null) this.checkMic()
			if (this.showingEnd) return
			if (!this.last_call_id) return this.nextCall()
			let evCallId = lo.get(e, 'data.call_info.call_id')
			if (this.last_call_id != evCallId) return
			let last_call = store.matchWebcallCall(this.last_call_id)
			if (this.isCallEnded(last_call)) {
				this.showingEnd = true
				setTimeout(() => {
					this.showingEnd = false
					this.nextCall()
				}, 1000)
			}
			this.nextCall()
			this.$forceUpdate()
		})
	},

	methods: {
		async nextCall() {
			if (this.showingEnd) return
			let call = store.getCurrentCall() || {}
			if (this.mode.startsWith('calling_')) {
				if (!call || !call.status || call.status == 'ended') {
					if (this.mode == 'calling_expanded') this.$emit('changeDisplayMode', 'phone')
					else if (this.mode == 'calling_minimized') this.$emit('changeDisplayMode', '')
				}
			} else {
				if (call && (call.status == 'active' || call.status == 'dialing'))
					this.$emit('changeDisplayMode', 'calling_expanded')
			}

			if (call && call.status == 'dialing' && call.direction == 'inbound') {
				let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
				link.type = 'image/x-icon'
				link.rel = 'shortcut icon'
				link.href = '/favicon_unread.ico'
				document.getElementsByTagName('head')[0].appendChild(link)
				document.title = this.$t('you_have_new_call')
			}
			this.last_call_id = lo.get(call, 'call_id')

			if (call.status === 'dialing' && call.direction === 'inbound') ringingAudio.play()
			if (call.status !== 'dialing') ringingAudio.pause()
		},

		miniMizeModal() {
			this.$emit('changeDisplayMode', 'calling_minimized')
		},

		fullModal() {
			this.$emit('changeDisplayMode', 'calling_expanded')
		},

		toogleTransferDevices() {
			this.showTransferDevices = !this.showTransferDevices
			if (this.showTransferDevices) {
				setTimeout(() => {
					this.$refs.transfer.focus()
				}, 0)
			}
		},

		transferToDevice(device) {
			if (device.type !== 'agent') store.transferWebCall('' + device.id)
			else store.transferWebCall('' + device.extension)
			this.toogleTransferDevices()
		},

		renderCallCenterNumberSelected(call) {
			let $call_center_number = <div>Chưa có số tổng đài</div>
			let call_center_number =
				lo.get(call, 'direction') === 'outbound' ? lo.get(call, 'from_number') : lo.get(call, 'to_number')
			if (call_center_number) {
				if (call_center_number.startsWith('0')) call_center_number = '84' + call_center_number.substr(1)
				let call_center_number_inte = lo.find(
					store.matchIntegration(),
					(phone) =>
						phone.connector_type === 'call' &&
						phone.state !== 'deleted' &&
						lo.get(lo.split(lo.get(phone, 'id'), '.'), '1') == call_center_number,
				)

				$call_center_number = sb.getNumberDisplayName(call_center_number_inte)
			}

			return $call_center_number
		},

		renderAvatar(number) {
			let call_info = lo.get(store.matchNumberInfo(number), 'info')
			if (!call_info) return

			let $avatar = <Avatar style='display: block;' nodot user={store.matchUser(call_info.id)} size={64} />
			if (lo.get(call_info, 'type') === 'agent') {
				$avatar = <Avatar style='display: block;' nodot agent={store.matchAgent(call_info.id)} size={64} />
			}

			if (!call_info || !call_info.id) return

			return <div style='border: 3px solid white; flex-shrink: 0; border-radius: 35px;'>{$avatar}</div>
		},

		renderCallInfo(current_call) {
			let direction = lo.get(current_call, 'direction')
			let number = lo.get(current_call, 'from_number', '')
			if (direction === 'outbound') number = lo.get(current_call, 'to_number')

			let $callInfo = (
				<Fragment>
					{this.renderAvatar(number)}
					<div class='calling_user_name mt-4'>{lo.get(store.matchNumberInfo(number), 'info.fullname')}</div>
					<div class='calling_user_number mt-2 mb-2'>{lo.get(store.matchNumberInfo(number), 'info.number')}</div>
				</Fragment>
			)

			if (
				lo.get(store.matchNumberInfo(number), 'state') === 'loading' ||
				lo.get(store.matchNumberInfo(number), 'info.fullname') === '' ||
				lo.get(store.matchNumberInfo(number), 'info.number') === ''
			)
				$callInfo = <Spinner size='80' mode='light' />

			return (
				<div class='d-flex  align-items-center' style='flex-direction: column; flex: 1; padding: 5px 20px; '>
					<div class='d-flex justify-content-center align-items-center' style='flex: 1; flex-direction: column;'>
						{$callInfo}
					</div>
					{this.renderCallStatus(current_call)}
				</div>
			)
		},

		renderCallStatus(current_call) {
			let $status = (
				<div class='blinking' style='font-size: 16px;'>
					{this.$t('calling')} ...
				</div>
			)

			let duration = Date.now() - lo.get(current_call, 'answered')
			if (duration < 0) duration = 0
			duration = duration / 1000
			if (lo.get(current_call, 'status') === 'active')
				$status = (
					<div class='call_duration' style='font-size: 16px;'>
						{sb.displayClockTimer(duration)}
					</div>
				)

			if (lo.get(current_call, 'direction') === 'inbound' && lo.get(current_call, 'status') === 'dialing')
				$status = <div class='blinking'>{this.$t('incoming_call')} ...</div>

			if (lo.get(current_call, 'answer_requested') && lo.get(current_call, 'status') === 'dialing')
				$status = (
					<div class='blinking'>
						<div style='opacity: 0.5;'>{this.$t('connecting')} ...</div>
					</div>
				)

			if (this.isCallEnded(current_call)) $status = <div style='font-size: 16px;'>{this.$t('ended')}</div>

			return $status
		},

		getAllPhoneDevice() {
			let me = store.me()
			let myPhoneDevices = lo.filter(
				store.matchPhoneDevice(),
				(device) =>
					device.type != 'webphone' &&
					device.type != 'appphone' &&
					device.id &&
					lo.includes(device.bind_to_agents, me.id) &&
					!device.disabled &&
					device.status !== 'unavailable',
			)
			let itemMyPhoneDevices = lo.map(myPhoneDevices, (phoneDevice) => ({
				id: phoneDevice.id,
				label: `${this.$t('You')}: ${phoneDevice.name}`,
				status: true,
				type: phoneDevice.type,
			}))
			lo.forEach(itemMyPhoneDevices, (item) => {
				item.img = me.avatar_url || sb.getAgentDefaultAvatarUrl(me)
				item.extension = me.extension
			})

			let agents = lo.filter(
				store.matchAgent(),
				(agent) => agent.type === 'agent' && agent.state === 'active' && agent.id !== me.id,
			)
			let itemAgentPhoneDevices = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: sb.getAgentDisplayName(agent),
				status: this.isAgentReadyToCall(agent),
				extension: agent.extension,
				type: agent.type,
			}))

			return [...itemMyPhoneDevices, ...itemAgentPhoneDevices]
		},

		renderGroupBtn(current_call) {
			let group_btn_style = ' display: block; width: 100%; margin: 4.5px 0'
			let $group_btn = (
				<button
					class='btn btn__danger call_icon_option'
					style={group_btn_style}
					vOn:click={() => store.hangUpWebCall(lo.get(current_call, 'call_id'))}
					v-tooltip={this.$t('end_call')}
				>
					{this.$t('hangup')}
				</button>
			)

			if (lo.get(current_call, 'status') === 'active')
				$group_btn = (
					<div class='d-flex mt-8' style='flex-direction: row; '>
						<Dropdown2
							ref='transfer'
							mode='custom'
							dropdown_width={240}
							style='display: inline-block'
							items={this.getAllPhoneDevice()}
							vOn:select={(item) => this.transferToDevice(item)}
							right
						>
							<button
								class='btn btn__dark btn_transfer'
								vOn:click={this.toogleTransferDevices}
								v-tooltip={this.$t('transfer_call')}
							>
								{this.$t('transfer')}
							</button>
						</Dropdown2>
						<div style='flex: 1;'></div>
						<button
							class='btn btn__danger call_icon_option'
							vOn:click={() => store.hangUpWebCall(lo.get(current_call, 'call_id'))}
							v-tooltip={this.$t('end_call')}
						>
							{this.$t('hangup')}
						</button>
					</div>
				)

			if (lo.get(current_call, 'direction') === 'inbound' && lo.get(current_call, 'status') === 'dialing')
				$group_btn = (
					<Fragment>
						<button
							class='btn btn__success'
							style={group_btn_style}
							vOn:click={async () => store.answerWebCall(lo.get(current_call, 'call_id'))}
						>
							{this.$t('answer')}
						</button>
						<button
							class='btn btn__danger'
							style={group_btn_style}
							vOn:click={() => store.hangUpWebCall(lo.get(current_call, 'call_id'))}
						>
							{this.$t('hangup')}
						</button>
						{/*<button class='btn btn_call_later' style={group_btn_style}>
							{this.$t('call_later')}
							</button>*/}
					</Fragment>
				)

			// already answered
			if (
				lo.get(current_call, 'direction') === 'inbound' &&
				lo.get(current_call, 'status') === 'dialing' &&
				lo.get(current_call, 'answer_requested')
			) {
				$group_btn = (
					<button
						class='btn btn__danger'
						style={group_btn_style}
						vOn:click={() => store.hangUpWebCall(lo.get(current_call, 'call_id'))}
					>
						{this.$t('hangup')}
					</button>
				)
			}

			if (this.isCallEnded(current_call)) $group_btn = null

			let background_modal_style = '#00000000'
			// Date.now() - current_call.answered  > 1000 to waiting for background animation to ended
			if (current_call && current_call.status == 'active' && Date.now() - current_call.answered > 300)
				background_modal_style = '#0265FF'

			let $audio_waveform = null
			let stream = store.getMicroStream()
			if (lo.get(current_call, 'status') === 'active' && stream)
				$audio_waveform = (
					<AudioWaveform
						stream={stream}
						bgColor={background_modal_style}
						style='width: 100%; height: 50px;'
						graphColor={'#fff'}
					/>
				)

			return (
				<div class='d-flex' style='flex-direction: column; padding: 0 20px 15px 20px; height: 110px;'>
					<div style='flex: 1;'>{$audio_waveform}</div>
					{$group_btn}
				</div>
			)
		},

		renderCallingModalContent(current_call) {
			if (this.mic === null && !this.isWaitMicPermission) return
			let $mic
			if (this.mic) $mic = <div style='height: 52px;'></div>
			else
				$mic = (
					<div v-tooltip={this.$t('microphone_is_off')} class='calling_modal_box_warning'>
						<a
							class='calling_modal_warning'
							target='blank'
							href='https://subiz.com.vn/docs/883847443-cau-hoi-ve-tong-dai#th%C3%B4ng-b%C3%A1o-micro-c%E1%BB%A7a-b%E1%BA%A1n-kh%C3%B4ng-ho%E1%BA%A1t-%C4%91%E1%BB%99ng-th%C3%AC-x%E1%BB%AD-l%C3%BD-nh%C6%B0-th%E1%BA%BF-n%C3%A0o'
						>
							{this.$t('microphone_is_off')}
						</a>
					</div>
				)

			let background_modal_style = 'background: #041228;'
			if (lo.get(current_call, 'status') === 'active') background_modal_style = ' background: #0265FF;'
			if (this.isCallEnded(current_call)) background_modal_style = 'background: #041228;'

			return (
				<div class='call_modal_content' style={background_modal_style}>
					<div class='d-flex align-items-center' style='height: 46px; padding: 5px 20px 0 20px;'>
						{this.renderCallCenterNumberSelected(current_call)}
						<div style='flex: 1;'></div>
						<div class='minimize_icon' vOn:click={this.miniMizeModal}>
							<Icon stroke-width={2} name='minus' class='' size='18' color='white' />{' '}
						</div>
					</div>
					{$mic}
					{this.renderCallInfo(current_call)}
					{this.renderGroupBtn(current_call)}
				</div>
			)
		},

		renderCallingModalMinimized(call) {
			let direction = call.direction
			let number = lo.get(call, 'from_number', '')
			if (direction === 'outbound') number = lo.get(call, 'to_number')

			let background = '#E34545;'

			let duration = Date.now() - lo.get(call, 'answered', Date.now())
			if (duration < 0) duration = 0
			duration = duration / 1000
			if (!this.isCallEnded(call)) background = '#0265FF;'

			let $status = lo.get(store.matchNumberInfo(number), 'info.fullname')
			if (call.status === 'dialing' && (call.direction === 'inbound' || call.direction === 'inbound')) {
				$status = lo.get(store.matchNumberInfo(number), 'info.number', number) + ' ' + this.$t('_calling')
			}

			if (call.status === 'dialing' && (call.direction === 'outbound' || call.direction === 'outbound')) {
				$status = this.$t('_calling') + ' ' + (lo.get(store.matchNumberInfo(number), 'info.number') || number)
			}

			if (lo.get(store.matchNumberInfo(number), 'state') === 'loading') $status = <Spinner mode='light' size='20' />

			let minimized_icon_style = 'border: none; background-color: rgba(0, 0, 0, 0)'
			if (this.isCallEnded(call)) $status = <span>{this.$t('ended')}</span>

			let $duration = null
			if (duration && (call.status === 'active' || call.status === 'ended'))
				$duration = <div>{sb.displayClockTimer(duration)}&nbsp;</div>
			return (
				<div
					class='d-flex calling_modal_minimize'
					style={`background-color: ${background}; `}
					vOn:click_stop={this.fullModal}
				>
					{$duration}
					<div class='text__truncate' style='flex: 1;'>
						{$status}&nbsp;
					</div>
					{call.status != 'ended' && (
						<button
							style={minimized_icon_style}
							vOn:click={(e) => {
								e.stopPropagation()
								store.hangUpWebCall(lo.get(call, 'call_id'))
							}}
						>
							<Icon size='16' color='white' stroke-width={2} name='phone-off' />
						</button>
					)}
					{lo.get(call, 'status') === 'dialing' && lo.get(call, 'direction') === 'inbound' && (
						<button
							style={minimized_icon_style}
							vOn:click={async (e) => {
								e.stopPropagation()
								store.answerWebCall(lo.get(call, 'call_id'))
							}}
							v-tooltip={this.$t('answer')}
						>
							<Icon size='16' color='white' stroke-width={2} name='phone' />
						</button>
					)}
				</div>
			)
		},

		isAgentReadyToCall(ag) {
			if (Date.now() < ag.dashboard_setting.mute_call_until) return false
			return lo.find(store.matchPhoneDevice(), (device) => {
				// ignore other agent's devices
				if (!lo.includes(device.bind_to_agents, ag.id)) return false

				if (device.type === 'webphone') {
					if (ag.dashboard_setting.webphone_disabled) return false
					return !device.disabled
				}
				return !device.disabled && device.status !== 'unavailable'
			})
		},

		isCallEnded(call) {
			if (!call) return true
			if (!call.call_id) return true
			let storecall = store.matchWebcallCall(call.call_id)
			return !storecall || storecall.status === 'ended'
		},

		async checkMic() {
			let mic = store.checkMic()
			let micTimeout = await mic.timeout
			if (micTimeout === 'Not_authorized') this.isWaitMicPermission = true
			let micResult
			if (this.isWaitMicPermission) micResult = await mic.result
			else micResult = micTimeout
			this.mic = micResult
			this.isWaitMicPermission = false
		},
	},

	render() {
		if (!this.mode.startsWith('calling_')) return null
		if (!this.last_call_id) return null
		let call = store.matchWebcallCall(this.last_call_id)
		if (!call) return null

		let style
		let $body
		if (this.mode == 'calling_minimized') {
			style = 'width: 230px; bottom: 20px;'
			$body = this.renderCallingModalMinimized(call)
		} else {
			$body = this.renderCallingModalContent(call)
		}

		return (
			<div class='calling' style={style}>
				{$body}
				{this.isWaitMicPermission && (
					<div class='modal' style='z-index: 9999;'>
						<div class='modal__overlay'></div>
					</div>
				)}
			</div>
		)
	},
}
