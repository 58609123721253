// window.localStorage.setItem = undefined
// console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD', window.localStorage)
try {
	window.localStorage.setItem('%', '%')
	window.localStorage.removeItem('%')
	window.myLocalStorage = window.localStorage
} catch (e) {
	let localStorageMemory = require('./inmem_localstorage.js')
	console.log('STORELOCALSTORAGEERR', e)
	window.myLocalStorage = localStorageMemory
}
