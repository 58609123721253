// see https://making.close.com/posts/rendering-untrusted-html-email-safely
import * as DOMPurify from 'dompurify'

export default {
	name: 'shadow-dom-root',
	props: ['html', 'throttle_update', 'scale', 'attachments'],
	data() {
		return {
			containerHeight: 150,
		}
	},

	mounted() {
		// this.shadowRoot = this.$refs.container.attachShadow({ mode: 'open' })
		this.update(this.html)
	},

	destroyed() {
		if (this.intervalHeight) clearInterval(this.intervalHeight)
	},

	watch: {
		html(html) {
			if (this.throttle_update) {
				return this.throttleUpdate(html)
			}
			this.update(html)
		},
	},

	methods: {
		getScale() {
			if (!this.scale) return 1
			let scale = Number(this.scale)
			if (Number.isNaN(scale)) scale = 1
			return scale
		},

		resizeIframe() {
			let iframe = this.$refs.container
			if (!iframe) return
			let scale = this.getScale()
			// console.log("SETTING", iframe.contentWindow.document.documentElement.scrollHeight)
			// iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px'

			iframe.style.height = iframe.contentDocument.body.offsetHeight + 10 + 'px'
			this.containerHeight = (iframe.contentDocument.body.offsetHeight + 10) * scale
		},

		throttleUpdate: lo.debounce(function (html) {
			return this.update(html)
		}, 1000),

		update(html) {
			if (lo.size(this.attachments) > 0) {
				html = DOMPurify.sanitize(html, {ALLOW_UNKNOWN_PROTOCOLS: true})
				const parser = new DOMParser()
				const htmlDoc = parser.parseFromString(html, 'text/html')
				let out = htmlDoc.querySelectorAll('img').forEach((img) => {
					let src = img.getAttribute('src') || ''
					if (src.startsWith('cid:')) {
						lo.map(this.attachments, (att) => {
							if (att.type == 'file' && ('cid:' + att.content_id == src || src.startsWith('cid:' + att.name))) {
								img.setAttribute('src', att.thumbnail_url || att.url)
							}
						})
					}
				})
				html = htmlDoc.documentElement.innerHTML
			}

			// Don't show HTML at all if they are using a browser that doesn't support <iframe sandbox>
			// since our security model relies on it to prevent XSS.
			// (And the basic functionality relies on <iframe srcdoc>)
			let iframe = this.$refs.container
			const isHtmlEmailSupported = 'sandbox' in iframe && 'srcdoc' in iframe
			if (!isHtmlEmailSupported) {
				let doc = `<html>
<head>
  <meta http-equiv="Content-Security-Policy" content="script-src 'none'" csp="script-src 'none'">
  <base target="_blank">
  </style>
</head>
<body>We cannot display the content in your browser</body>
</html>`
				this.$refs.container.setAttribute('srcdoc', doc)

				// change setTimeout to setInterval because sometine image doesnt load after 1sec so the height is incorrect
				this.intervalHeight = setInterval(this.resizeIframe, 2000)
				return
			}

			let doc = `<html>
<head>
  <meta http-equiv="Content-Security-Policy" content="script-src 'none'" csp="script-src 'none'">
  <base target="_blank">
  <style type="text/css">
    body { font-family: 'Roboto', sans-serif!important; font-size: 14px!important; line-height: 20px; overflow-y: hidden; margin:0 }
    h{ font-size: 14/18px!important; }
    a { text-decoration: none;}
    a[href] { color: #3781B8; text-decoration:none; }
    a[href]:hover { text-decoration:underline; }
    p { margin-top: 0px; margin-bottom: 0px; }
    p:first-child { margin-top: 0;}
    p:last-child { margin-bottom: 0;}
    html:not(.x),body:not(.x){height:auto!important}
    blockquote[type=cite] {margin:0 0 0 .8ex;border-left: 1px #ccc solid;padding-left: 1ex;}
    img { max-width: 100%; }
    ul, ol { padding: 0; margin: 10px 0 10px 25px; padding-left: 10px }
    ul { list-style-type: disc; }
  </style>
</head>
<body>${html}</body>
</html>`
			this.$refs.container.setAttribute('srcdoc', doc)
			this.intervalHeight = setInterval(this.resizeIframe, 2000)
		},
	},

	render() {
		let scale = this.getScale()
		let style = 'width: 100%; overflow-y: hidden; overflow-x: auto; border: none;'
		if (scale !== 1) {
			style += `transform-origin: 0 0; transform: scale(${scale}); width: 800px;`
			return (
				<div style={`overflow: hidden; height: ${this.containerHeight}px`}>
					<iframe
						ref='container'
						style={style}
						frameborder='0'
						csp="script-src 'none'"
						sandbox='allow-popups allow-popups-to-escape-sandbox allow-same-origin'
						onload={this.resizeIframe}
					/>
				</div>
			)
		}

		return (
			<iframe
				ref='container'
				style={style}
				frameborder='0'
				csp="script-src 'none'"
				sandbox='allow-popups allow-popups-to-escape-sandbox allow-same-origin'
				onload={this.resizeIframe}
			/>
		)
	},
}
