
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icon-tabler-bell-ringing-2",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","stroke":"none"}}),_c('path',{attrs:{"d":"M19.364 4.636a2 2 0 010 2.828 7 7 0 01-1.414 7.072l-2.122 2.12a4 4 0 00-.707 3.536L3.808 8.88a4 4 0 003.535-.707L9.464 6.05a7 7 0 017.072-1.414 2 2 0 012.828 0z"}}),_c('path',{attrs:{"d":"M7.343 12.414l-.707.707a3 3 0 004.243 4.243l.707-.707"}})])
          )
        }
      }
    