import sb from '@sb/util'

export default {
	name: 'address-iput',
	props: ['address', 'size', 'is_invalid'], // size: 'md' | 'sm' | 'xs' | 'lg'

	data() {
		return {
			inputValue: '',
			addresses: [],

			currentKeyword: '',
			isFocused: false,
		}
	},

	watch: {
		address() {
			this.resetInputValue()
		},
	},

	mounted() {
		this.resetInputValue()
	},

	methods: {
		resetInputValue() {
			this.inputValue = lo.get(this.address, 'address', '')
		},

		async onSelect(item) {
			let address = lo.cloneDeep(this.address)

			let suggestedAddress = this.addresses[item.id]
			if (!suggestedAddress) return

			address.province = suggestedAddress.province
			address.province_id = suggestedAddress.province_id
			address.district = suggestedAddress.district
			address.district_id = suggestedAddress.district_id
			address.ward = suggestedAddress.ward
			address.ward_id = suggestedAddress.ward_id
			address.street = suggestedAddress.street
			address.address = (suggestedAddress.address || '') + (suggestedAddress.street || '')
			this.$emit('change', address)
		},

		onInput(e) {
			this.inputValue = e.target.value
			let address = lo.cloneDeep(this.address)
			address.address = this.inputValue
			this.$emit('change', address)
			this.searchAddress(e.target.value)
		},

		onFocus() {
			if (this.isFocused) return
			this.isFocused = true
			this.searchAddress(this.inputValue)
		},

		searchAddress: lo.throttle(async function () {
			// flag to detect rat race
			let requestId = Date.now()
			this.requestId = requestId

			let res = await store.getSuggestedAddresses({keyword: this.inputValue})

			// avoid previouse request render after
			if (this.requestId !== requestId) return
			this.addresses = lo.get(res, 'body.addresses', [])
		}, 100),
	},

	render() {
		let items = lo.map(this.addresses, (address, i) => ({
			id: i,
			label: [((address.address || '') + ' ' + normalize(address.street)).trim()].join(', '),
			desc: [normalize(address.ward), normalize(address.district), normalize(address.province)].join(', '),
		}))

		let extra_cls = `form-control-${this.size || 'md'}`
		if (this.is_invalid) extra_cls += ' is-invalid'

		return (
			<Dropdown2
				mode='suggestion'
				items={items}
				no_filter
				vOn:select={this.onSelect}
				extra_item_cls={'text__sm'}
				extra_cls={extra_cls}
				input_value={this.inputValue}
				vOn:input={this.onInput}
				vOn:focus={this.onFocus}
			/>
		)
	},
}

function normalize(s) {
	let ls = (s + '').toLowerCase()
	if (ls.startsWith('tp ')) return s.substr(3)

	if (ls.startsWith('thanh pho ')) return s.substr('thanh pho '.length)
	if (ls.startsWith('thành phố ')) return s.substr('thành phố '.length)
	if (ls.startsWith('tỉnh ')) return s.substr('tỉnh '.length)
	if (ls.startsWith('huyện ')) return s.substr('huyện '.length)
	if (ls.startsWith('tỉnh ')) return s.substr('tỉnh '.length)
	if (ls.startsWith('xã ')) return s.substr('xã '.length)
	if (ls.startsWith('thị trấn ')) return s.substr('thị trấn '.length)
	if (ls.startsWith('thị xã ')) return s.substr('thị xã '.length)
	// phuong khuong dinh => khuong dinh; phuong 1 => phuong 1
	if (ls.startsWith('đường ') && (s[7] < '0' || s[7] > '9')) return s.substr('đường '.length)
	if (ls.startsWith('phường ') && (s[7] < '0' || s[7] > '9')) return s.substr('phường '.length)
	if (ls.startsWith('quận ') && (s[7] < '0' || s[7] > '9')) return s.substr('quận '.length)
	return s
}
