export default {
	props: ['open', 'position', 'noOverlay', 'simple'], // open: true, false; position: 'right', 'left'
	data () {
		return {
			extraCls: '',
		}
	},

	methods: {
		changeOpen (open) {
			this.extraCls = this.extraCls.trim()

			if (open) {
				this.extraCls = this.extraCls.split('drawer--fadeout').join('') // remove drawer--fadeout
				this.extraCls += ' drawer--active'
				return
			}

			// handle close drawer
			this.extraCls += ' drawer--fadeout'
			setTimeout(() => {
				if (this.open !== open) return // outdated
				this.extraCls = this.extraCls.split('drawer--active').join('') // remove drawer--active
				this.extraCls = this.extraCls.split('drawer--fadeout').join('') // remove drawer--fadeout
			}, 310)
		},
	},

	mounted () {
		this.changeOpen(this.open)
	},

	watch: {
		open (open) {
			this.changeOpen(open)
		},
	},

	render () {
		let position = this.position || 'right'
		let $overlay = <div class="drawer__overlay" vOn:click_stop={_ => this.$emit('close')} />
		if (this.noOverlay) $overlay = null

		let bgcls = 'drawer__background drawer__background--border-radius-left'
		if (this.simple) bgcls = 'drawer__background drawer__background__simple'

		return (
			<div class={`drawer drawer--${position} ${this.extraCls}`}>
				{$overlay}
				<div class={bgcls}>{this.$slots.default}</div>
			</div>
		)
	},
}
