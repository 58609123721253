import sb from '@sb/util'

const AGENTS_LIMIT = 10

export default {
	name: 'agents-picker',
	props: {
		agents: Array,
		picked_agents: Array,
		position: {
			type: String,
			default: 'top',
		},
		sm: Boolean,
	},

	data() {
		return {
			filter: '',
			showDropdown: false,
			dropdownStyle: '',
		}
	},

	render() {
		const $agents = lo.map(this.picked_agents, (ag) => {
			let style = ''
			let tooltip = sb.getAgentDisplayName(ag)
			if (ag.state === 'inactive') {
				style = 'filter: grayscale(1); opacity: 0.75'
				tooltip += ' (Inactive)'
			}
			return (
				<div class='agents_picker__agent' v-tooltip={tooltip} style={style}>
					<Avatar agent={ag} size={this.sm ? 'sm' : 'md'} nodot notooltip />
					<Icon
						name='x'
						stroke-width='3'
						class='agents_picker__agent__remove_btn'
						vOn:click={(_) => this.removeAgent(ag)}
					/>
				</div>
			)
		})

		let unChosenAgents = lo.filter(this.agents, (a) => !lo.find(this.picked_agents, (pa) => pa.id === a.id))
		unChosenAgents = lo.filter(unChosenAgents, (ag) => {
			let fullname = sb.unicodeToAscii(ag.fullname).toLowerCase()
			let email = sb.unicodeToAscii(ag.email).toLowerCase()
			let filterText = sb.unicodeToAscii(this.filter).toLowerCase()
			return fullname.indexOf(filterText) > -1 || email.indexOf(filterText) > -1
		})
		let activeAgents = []
		let inactiveAgents = []
		lo.each(unChosenAgents, (agent) => {
			if (agent.state === 'inactive') {
				inactiveAgents.push(agent)
			} else {
				activeAgents.push(agent)
			}
		})
		unChosenAgents = [...activeAgents, ...inactiveAgents]

		let $dropdownItems = lo.map(unChosenAgents, (agent) => {
			let style = ''
			if (agent.state === 'inactive') style = 'opacity: 0.6'
			return (
				<div class='dropdown__item dropdown__item__thin' style={style} vOn:click={(_) => this.addAgent(agent)}>
					<Avatar agent={agent} size='30' class='mr-3' />
					<div class='text__truncate' style='line-height: 1.2'>
						<div class='d-flex align-items-center'>
							<div class='text__truncate'>{agent.fullname}</div>
							{agent.state === 'inactive' && <div class='ml-2 text__muted text__sm'>(Inactive)</div>}
						</div>
						<div class='text__truncate text__muted'>
							<small>{agent.email}</small>
						</div>
					</div>
				</div>
			)
		})

		if ($dropdownItems.length === 0) {
			$dropdownItems = (
				<div class='dropdown__item dropdown__item__thin dropdown__item__disabled'>
					<div class='text__muted'>
						{this.$t('no_agents_left')} <Icon name='mood-empty' stroke-width='2' size='18' />{' '}
					</div>
				</div>
			)
		}

		let customStyle = ''
		if (this.position === 'bottom') customStyle = 'top: 0; bottom: auto;'
		let $dropdown = (
			<div class='d-flex align-items-center' style='position: relative'>
				<div style={this.dropdownStyle} class='dropdown agents_picker__dropdown' vOn:click_stop={this.focusOnFilter}>
					{this.renderFilter()}
					<div class='agents_picker__dropdown_agents'>{$dropdownItems}</div>
				</div>
			</div>
		)
		if (!this.showDropdown) $dropdown = null

		return (
			<div class='d-flex flex-wrap' vOn:click={(_) => this.toogleDropdown(false)}>
				<div
					ref='add_btn_container'
					class={'agents_picker__add_btn ' + (this.sm ? 'agents_picker__add_btn__sm' : '')}
					vOn:click_stop={this.toogleDropdown}
					v-clickaway={(_) => this.toogleDropdown(false)}
					v-tooltip={this.$t('assign_agent_to_conversation')}
				>
					<Icon name='plus' class='agents_picker__add_btn_icon' size='1x' />
					{$dropdown}
				</div>

				{$agents}
			</div>
		)
	},

	methods: {
		focusOnFilter() {
			setTimeout(() => this.$refs.filter && this.$refs.filter.focus(), 10)
		},

		onFilterChange(e) {
			this.filter = this.$refs.filter && this.$refs.filter.value
		},

		renderFilter() {
			if (lo.size(this.agents) <= AGENTS_LIMIT) return null
			return (
				<div class='agents_picker__filter'>
					<input
						ref='filter'
						class='form-control agents_picker__filter_input'
						type='text'
						vOn:change={this.onFilterChange}
						vOn:keyup={this.onFilterChange}
					/>
				</div>
			)
		},

		setDropdownStyle() {
			let rect = this.$refs.add_btn_container ? this.$refs.add_btn_container.getBoundingClientRect() : {}
			let {width = 0, height = 0, top = 0, left = 0} = rect

			const DROPDOWN_HEIGHT = 300
			const EDGE_TO_TOP = 20
			let isBottom = top + height / 2 <= DROPDOWN_HEIGHT + EDGE_TO_TOP // 320 is dropd
			let style = 'position: fixed;'
			if (this.bottom || isBottom) {
				style += `left: ${left + width / 2}px; top: ${top + height / 2}px; bottom: unset;`
			} else {
				style += `left: ${left + width / 2}px; bottom: ${window.innerHeight - height / 2 - top}px`
			}

			this.dropdownStyle = style
		},

		toogleDropdown(v) {
			if (v === false || v === true) this.showDropdown = v
			else this.showDropdown = !this.showDropdown

			if (this.showDropdown) {
				this.setDropdownStyle()
				this.focusOnFilter()
				this.filter = ''
			}
		},

		addAgent(agent) {
			let picked_agents = lo.filter(this.picked_agents, (a) => a.id !== agent.id)
			picked_agents.push(agent)
			this.$emit('change', picked_agents)
			this.focusOnFilter()
		},

		removeAgent(agent) {
			let picked_agents = lo.filter(this.picked_agents, (a) => a.id !== agent.id)
			this.$emit('change', picked_agents)
		},
	},
}
