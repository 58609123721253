import {STANDARD_UNLIMITED_VND_PRICE} from './trial-subscription/common'

const millisecondsInADay = 24 * 3600 * 1000
const plans_time_limit = {
	trial: {
		time_expired_limit: 7 * millisecondsInADay,
		skip_time_limit: millisecondsInADay,
	},
	standard: {
		time_expired_limit: 14 * millisecondsInADay,
		skip_time_limit: 2 * millisecondsInADay,
	},
}

export default {
	name: 'WarningExpiredBar',
	props: ['plan'],
	data() {
		return {
			day_expired: 0,
			show: false,
		}
	},
	mounted() {
		this.handleDisplayWarningExpired()
	},
	methods: {
		skipExpiredWarning() {
			const skip_time = Date.now()
			store.setSkipTimeExpiredWarning(skip_time)
			this.show = false
		},

		handleDisplayWarningExpired() {
			const end_trial_time = lo.get(store.matchSubscription(), 'ended', 0)
			let remaining_time = sb.getMs(end_trial_time) - Date.now()
			this.day_expired = Math.ceil(remaining_time / millisecondsInADay)
			const skip_time = store.getSkipTimeExpiredWarning()
			let plan_limit = lo.get(plans_time_limit, this.plan)

			if (plan_limit && remaining_time > 0 && remaining_time < plan_limit.time_expired_limit) {
				if (Date.now() - skip_time > plan_limit.skip_time_limit) this.show = true
			}
		},

		renderTrialExpiredBar() {
			let me = store.me()
			let scopes = lo.get(me, 'scopes', [])
			let $text_warning = (
				<div
					class='trial_expired_title text__truncate'
					domPropsInnerHTML={this.$t('trial_expired_agent_desc', [this.day_expired])}
				></div>
			)
			let $button = null

			if (me.is_owner || scopes.includes('account_manage') || scopes.includes('owner')) {
				$text_warning = (
					<div
						class='trial_expired_title text__truncate'
						domPropsInnerHTML={this.$t('trial_expired_desc', [this.day_expired])}
					></div>
				)

				$button = (
					<button
						class='btn btn__sm btn__dark'
						style='font-size: 14px; padding: 0 8px;'
						vOn:click={() => {
							this.$router.push({name: 'trial.subscriptions'})
						}}
					>
						{this.$t('compare_package')}
					</button>
				)
			}
			return (
				<div class='expired_bar'>
					<div class='flex__1 d-flex align-items-center justify-content-center' style='overflow: hidden;'>
						{$text_warning}
						{$button}
					</div>
					<div
						class='ml-3 link text__semibold text__underline'
						style='color: #000000B2; font-size: 14px;'
						vOn:click={() => this.skipExpiredWarning()}
					>
						{this.$t('skip')}
					</div>
				</div>
			)
		},

		renderStandardExpiredBar() {
			let plan = this.$t('plan_standard')
			let price = sb.displayMoney(STANDARD_UNLIMITED_VND_PRICE)
			return (
				<div class='expired_bar'>
					<div class='flex__1 d-flex align-items-center justify-content-center' style='overflow: hidden;'>
						<div
							class='trial_expired_title text__truncate'
							domPropsInnerHTML={this.$t('expired_bar_manager_content', [plan, this.day_expired, price])}
						></div>
						<div
							class='link link__primary text__semibold'
							style='font-size: 14px; padding: 0 8px;'
							vOn:click={() => {
								this.$router.push({name: 'trial.subscriptions'})
							}}
						>
							{this.$t('view_detail')}
						</div>
					</div>
					<div
						class='ml-3 link text__semibold text__underline'
						style='color: #000000B2; font-size: 14px;'
						vOn:click={() => this.skipExpiredWarning()}
					>
						{this.$t('skip')}
					</div>
				</div>
			)
		},
	},

	render() {
		if (!this.show) return null
		let $content = null
		if (this.plan === 'trial') $content = this.renderTrialExpiredBar()
		if (this.plan === 'standard') $content = this.renderStandardExpiredBar()
		return <div>{$content}</div>
	},
}
