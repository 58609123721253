const sb = require('@sb/util')
import LexicalEditor from '../commons/lexical-editor.js'
import ConvoEmailEditor from './conversation/convo_email_editor.js'

export default {
	name: 'facebook-private-reply',
	props: ['show', 'user_id', 'comment_id', 'touchpoint', 'convo_id', 'ticket_id'],

	data() {
		return {
			creating: false,
			creatingIndex: 0,

			initMsg: {},
			editorkey: 0,

			errorText: '',
		}
	},

	mounted() {
		store.onUser(this, (userM) => this.$forceUpdate())
	},

	watch: {
		async show(show) {
			this.editorkey++
			this.creating = false
			this.initMsg = {}
			this.errorText = ''

			if (show) {
				let connectorType = this.getConnectorType()
				if (connectorType == 'email') {
					this.initMsg = {
						format: 'html',
						text: '<p></p>',
						fields: [{value: JSON.stringify([this.touchpoint.id]), key: 'to'}],
					}
				}

				if (connectorType == 'subiz') {
					let messageTemplates = store.matchMessageTemplate()
					let template = lo.find(messageTemplates, (template) => lo.includes(template.keys, 'hello')) || {
						message: {text: ''},
					}
					this.initMsg = template.message
					await this.$nextTick()
					this.$refs.editor && this.$refs.editor.setInitialContent()
					this.$refs.editor && this.$refs.editor.onSelectMessageTemplate(template.message)
					this.$refs.editor && this.$refs.editor.focus()
				}
			}
		},
	},

	methods: {
		renderPageIcon() {
			let connectorType = this.getConnectorType()
			if (connectorType == 'subiz' || connectorType == 'email') return null

			let inte = store.matchIntegration()[this.getIntegrationId()] || {}
			let img_src = inte.logo_url
			if (connectorType == 'google_review' || connectorType == 'google_message' || connectorType == 'google_question')
				img_src = require('../assets/img/google_message_channel.svg')
			return (
				<div>
					<div class='d-flex align-items-center'>
						<img class='convo_header__source_img' src={img_src} />
						<div class='ml-2 text__muted'>{inte.name}</div>
					</div>
				</div>
			)
		},

		getConnectorType() {
			let connectorType = lo.get(this.touchpoint, 'channel')
			if (!connectorType) {
				let inte = store.matchIntegration()[this.getIntegrationId()] || {}
				connectorType = inte.connector_type || 'subiz'
			}

			return connectorType
		},

		renderChannelIcon() {
			let connectorType = this.getConnectorType()
			if (connectorType === 'facebook' || connectorType === 'facebook_comment') {
				return (
					<img
						width='20'
						height='20'
						class='mr-2'
						src={require('../assets/img/messenger_channel.svg')}
						v-tooltip={this.$t('messenger')}
					/>
				)
			}

			if (connectorType === 'instagram' || connectorType === 'instagram_comment') {
				return <img width='20' height='20' class='mr-2' src={require('../assets/img/instagram.svg')} />
			}

			if (connectorType === 'zalo') {
				return <img width='20' height='20' class='mr-2' src={require('../assets/img/zalo_channel.svg')} />
			}

			if (connectorType === 'email') {
				return <img width='20' height='20' class='mr-2' src={require('../assets/img/mail_icon.svg')} />
			}

			return <img class='mr-2' width='20' height='20' src={require('../assets/img/subiz_channel.svg')} />
		},

		renderBody() {
			if (this.loading)
				return (
					<div style='flex: 1;  padding: 20px; background: whitesmoke'>
						<Spinner size='20' mode='blue' />
					</div>
				)

			let connectorType = this.getConnectorType()
			let user_id = this.user_id
			let acceptAttachment = connectorType === 'zalo' ? 'image/png, image/jpeg, image/gif' : undefined
			let isemail = connectorType === 'email'
			let html = isemail

			let user = store.matchUser(user_id, true)
			let lastMessageSent = sb.getUserDateAttr(user, 'last_message_sent')
			lastMessageSent = new Date(lastMessageSent).getTime()

			let $editor
			let availabelEmail = lo.filter(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
			)
			if (!lo.size(availabelEmail) && isemail) {
				$editor = (
					<div style='border-radius: 7px; background-color: #e8e8e8; width: 100%; padding: 10px; text-align: center;'>
						<span style='opacity: 0.7; pointer-events: none'>{this.$t('error_no_business_email_address')}</span>&nbsp;
						<span
							class='link'
							vOn:click={(_) => {
								this.$router.push({name: 'settings.email'})
							}}
						>
							{this.$t('set_up_now')}
						</span>
					</div>
				)
			} else if (isemail) {
				$editor = (
					<ConvoEmailEditor
						class='facebook_private_reply_convo_email_editor'
						uid={this.user_id}
						cid={this.convo_id}
						key={this.editorkey}
						ref='editor'
						vOn:change={(msg) => (this.initMsg = msg)}
						initMessage={this.initMsg}
					/>
				)
			} else {
				$editor = (
					<LexicalEditor
						plaintext
						ref='editor'
						initMessage={this.initMsg}
						vOn:change={(msg) => (this.initMsg = msg)}
						submit_on_enter
						vOn:submit={this.onSubmit2}
						useTemplate
						cid={this.convo_id}
						uid={this.user_id}
						transform_dynamic_field
						template_connector_type='subiz'
						no_preview_mode
					/>
				)
			}

			return (
				<div
					style='flex: 1;  padding: 20px; background: whitesmoke; overflow: hidden;'
					class='private_message_modal_body d-flex flex-column'
				>
					<div class='no-shrink'>
						{this.comment_id && (
							<Fragment>
								<div>{this.$t('send_private_message_desc')}</div>
								<div class='mt-3 text__semibold'>{this.$t('send_private_message_note')}</div>
							</Fragment>
						)}
						<div class='d-flex align-items-center mt-3 mb-4'>
							<Avatar class='mr-3' user={user} size='24' />
							<div>
								<b>{sb.getUserDisplayName(user)}</b>
							</div>
						</div>
					</div>
					{$editor}
					<div class='text__sm mt-2 text__muted no-shrink'>
						<span>{this.$t('last_message')}: </span>
						<Time time={lastMessageSent} ago suffix />
					</div>
					{this.errorText !== '' ? (
						<div class='mt-1'>
							<small class='text__danger'>{this.errorText}</small>
						</div>
					) : (
						<div class='mt-1' style='height: 24px;'></div>
					)}
				</div>
			)
		},

		async close(e) {
			let availabelEmail = lo.filter(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
			)
			if (!lo.size(availabelEmail) && this.getConnectorType() === 'email') return this.$emit('close')
			let text = lo.get(this.$refs.editor.GetMessage(), 'text', '')
			if (text !== '<p><br></p>' && text !== '') {
				let cf = await this.$confirm({
					ok: this.$t('exit'),
					cancel: this.$t('keep'),
					title: this.getConnectorType() === 'email' ? this.$t('typing_email') : this.$t('typing_message'),
					description:
						this.getConnectorType() === 'email' ? this.$t('typing_email_desc') : this.$t('typing_message_desc'),
					style: 'danger',
				})
				if (!cf) return
				this.$emit('close')
				return
			}
			this.$emit('close')
		},

		async convertImgToPublishUrl(msg) {
			let deltas = sb.parseJSON(msg.quill_delta) || {ops: []}
			let new_deltas = {ops: []}
			for (let op of deltas.ops) {
				let ins = op
				let img = lo.get(op, 'insert.image')
				if (img && img.startsWith('data:image/png;base64')) {
					let url = lo.get(op, 'insert.image')
					let file = dataURLtoFile(url, `image_copy.png`)
					let res = await store.uploadLocalFile(file)
					if (res.error) return this.$showError(res.error)
					ins = {
						insert: {
							image: res.url,
						},
					}
				}
				new_deltas.ops.push(ins)
			}
			return new_deltas
		},

		validateEmailHeader() {
			if (this.getConnectorType() !== 'email') return true
			let result = true
			let msg = this.$refs.editor.GetMessage()
			let fields = lo.get(msg, 'fields', [])
			let to = lo.find(fields, (field) => field.key === 'to')
			let from = lo.find(fields, (field) => field.key === 'from')
			if (!to || !lo.size(sb.parseJSON(lo.get(to, 'value')))) {
				result = false
				this.errorText = this.$t('to_email_invalid')
			}
			if (!from || !lo.size(lo.get(from, 'value'))) {
				result = false
				this.errorText = this.$t('from_email_invalid')
			}
			if ((!to || !lo.size(sb.parseJSON(lo.get(to, 'value')))) && (!from || !lo.size(lo.get(from, 'value')))) {
				result = false
				this.errorText = this.$t('to_and_from_email_invalid')
			}
			return result
		},

		async onSubmit2() {
			let msg = this.$refs.editor.GetMessage()

			this.errorText = ''
			if (!this.validateEmailHeader()) return
			if (lo.trim(msg.text) == '' && lo.size(msg.attachments) == 0) return this.$showError(this.$t('input_the_message'))

			let attachments = lo.get(this.initMsg, 'attachments', [])
			const ZALO_ACCEPTTED_MIMETYPES = [
				'application/msword',
				'application/pdf',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			]
			let hasInvalidZaloFile =
				lo.get(this.touchpoint, 'channel') === 'zalo' &&
				lo.find(
					attachments,
					(file) =>
						!ZALO_ACCEPTTED_MIMETYPES.includes(file.mimetype) &&
						!(file.mimetype || '').startsWith('image') &&
						file.type === 'file',
				)
			if (hasInvalidZaloFile) return this.$showError(this.$t('zalo_only_support_format_files'))

			let creating = this.creatingIndex + 1
			this.creatingIndex = creating
			this.creating = true

			if (msg.format === 'html') {
				//let delta = await this.convertImgToPublishUrl(msg)
				//let quill = JSON.stringify(delta)
				//msg.quill_delta = quill
				//msg.text = sb.deltaToHtml(delta.ops)
			} else if (msg.format === 'plaintext' || !msg.format) {
				msg = lo.cloneDeep(msg)
				msg.text = sb.lexicalToPlainText(msg.text, {use_old_emoji: true})
				msg.format = 'plaintext'
			}

			let {body, error} = await store.sendMessage2({
				touchpoint: this.touchpoint,
				msg,
				userids: [this.user_id],
				comment_id: this.comment_id,
			})
			this.creating = false
			if (creating != this.creatingIndex) return
			if (error) return this.$showError(error)
			let ev = body
			var convoid = sb.convoOfEv(ev)
			if (this.ticket_id) this.tryLinkConvoIdToTicket(convoid)
			this.$emit('changeUcid', this.user_id, convoid)
			this.$emit('close')
		},

		async tryLinkConvoIdToTicket(cid) {
			console.log('tryLinkConvoIdToTicket', cid, this.ticket_id)
			if (!cid) return
			let ticket = store.matchTicket(this.ticket_id)
			if (!ticket) {
				await store.fetchTicket(this.ticket_id)
				ticket = store.matchTicket(this.ticket_id)
			}
			if (!ticket) return

			let ticketconvos = lo.get(ticket, 'associated_conversations') || []
			if (ticketconvos.includes(cid)) return
			ticketconvos.push(cid)
			store.updateTicket({
				id: this.ticket_id,
				associated_conversations: ticketconvos,
				_update_fields: ['associated_conversations'],
			})
		},

		getIntegrationId() {
			if (this.touchpoint.channel === 'facebook') {
				return `${lo.get(store.me(), 'account_id')}.${this.touchpoint.source}.fabikon`
			}
			if (this.touchpoint.channel === 'instagram') {
				return `${lo.get(store.me(), 'account_id')}.instagram_${this.touchpoint.source}.fabikon`
			}
			if (this.touchpoint.channel === 'zalo') {
				return `${lo.get(store.me(), 'account_id')}.${this.touchpoint.source}.zalokon`
			}
			if (this.touchpoint.channel === 'google_message') {
				return `${lo.get(store.me(), 'account_id')}.${this.touchpoint.source}.googlekon`
			}
			return `${lo.get(store.me(), 'account_id')}.subizv4.subikon`
		},
	},

	render() {
		let connectorType = this.getConnectorType()

		let title = this.$t('send_private_message')
		if (connectorType == 'subiz' || connectorType == 'zalo') title = this.$t('create_conversation')
		if (connectorType == 'email') title = this.$t('send_email')

		return (
			<Modal zindex={9999} show={this.show} style='align-items: flex-end;' vOn:bg={this.close}>
				<div
					class='modal__background'
					style='width:600px; position: realtive; min-height: 300px; padding: 0; display: flex; flex-direction: column; overflow: hidden;'
				>
					<div class='assign_agent__header' style='padding: 10px 20px'>
						<div class='d-flex align-items-center'>
							{this.renderChannelIcon()}
							{title}
						</div>
						<Icon name='x' v-tooltip={this.$t('close')} class='btn__close' vOn:click_stop={this.close} />
					</div>
					{this.renderBody()}
					<div class='order_modal__footer'>
						<div>{this.renderPageIcon()}</div>
						<div style='flex: 1' />
						<button
							disabled={this.creating}
							class='btn btn__primary btn__sm'
							style='width: 120px'
							vOn:click={this.onSubmit2}
						>
							{this.creating ? <Spinner size='12' /> : this.$t('send_message')}
						</button>
					</div>
				</div>
			</Modal>
		)
	},
}

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = window.atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new window.File([u8arr], filename, {type: mime})
}
