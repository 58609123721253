import store from '@sb/store'
import sb from '@sb/util'
import {i18n} from '../../languages.js'

export function isAdmin() {
	return store.checkPerm('call_setting:update')
}

export function isDeskPhone(device) {
	return device.type !== 'webphone' && device.type !== 'appphone'
}

export function getPhoneDeviceModelName(device) {
	let {brand, model} = sb.extractPhoneUA(device.user_agent)
	if (brand === 'unknown') return i18n.t('undefined')
	return `${sb.capitalizeFirstLetter(brand)} ${model}`
}

export function getPhoneDeviceModelImage(device) {
	let {brand} = sb.extractPhoneUA(device.user_agent)
	let img = ''
	try {
		img = require('../../assets/img/device/' + brand + '.jpg')
	} catch (err) {
		console.log('CANNOT LOAD LOCAL PHONE MODEL IMAGE', err, brand)
		img = require('../../assets/img/device/unknown.jpg')
	}

	return img
}
