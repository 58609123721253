import {computeErrorMessage} from './compute_error_message.js'

function normalizeParamsPopupNotify(type, params) {
	let noty = {type, created: Date.now()}
	if (typeof params[0] === 'string' && typeof params[1] === 'string') {
		noty.title = params[0]
		noty.description = params[1]
		noty.timeout = params[2]
		noty.duration = params[2]
	} else if (typeof params[0] === 'string' && typeof params[1] === 'number') {
		noty.title = params[0]
		noty.timeout = params[1]
		noty.duration = params[1]
	} else if (typeof params[0] === 'string') {
		noty.title = params[0]
	}

	// new error code standard
	if (typeof params[0] === 'object' && type === 'danger') {
		let errorMsg = computeErrorMessage(params[0])
		noty.title = errorMsg
		if (typeof params[1] === 'string') {
			noty.description = params[1]
			noty.duration = params[2]
		} else if (typeof params[1] === 'number') {
			noty.duration = params[1]
		}
	}

	// error with new standard should show in longer duration
	if (noty.type === 'danger' && !noty.duration) {
		noty.duration = 5_000
	}
	return noty
}

var Toast = {}

Toast.install = (Vue) => {
	Vue.prototype.$showSuccess = function (...params) {
		// params = [title, description, timeout] or [description, timeout] or [timeout]
		let filterParams = normalizeParamsPopupNotify('success', params)
		this.$root.$emit('push_noti_message', filterParams)
		//this.$root.$emit('_popup_notify', filterParams)
	}

	Vue.prototype.$showWarning = function (...params) {
		// params = [title, description, timeout] or [description, timeout] or [timeout]
		let filterParams = normalizeParamsPopupNotify('warning', params)
		this.$root.$emit('push_noti_message', filterParams)
		//this.$root.$emit('_popup_notify', filterParams)
	}

	Vue.prototype.$showError = function (...params) {
		// params = [title, description, timeout] or [description, timeout] or [timeout]
		let filterParams = normalizeParamsPopupNotify('danger', params)
		this.$root.$emit('push_noti_message', filterParams)
		//this.$root.$emit('_popup_notify', filterParams)
	}

	Vue.prototype.$confirm = function () {
		this.$root.$emit('_confirm_request')

		let rs
		let responseFn = function (param) {
			// {ok, id}
			if (param.id !== id) return
			this.$root.$off('_confirm_response', responseFn)
			rs(param.ok)
		}
		this.$root.$on('_confirm_response', responseFn)
		return new Promise((resolve) => {
			rs = resolve
		})
	}
}

export default Toast
