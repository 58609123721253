import {Tag} from '@sb/com'
import AppendToBody from '../commons/append_to_body.js'

export default {
	name: 'convo-tags',
	props: [
		'tags',
		'editable',
		'allTags',
		'loadingTags',
		'dropdownFull',
		'position',
		'placeholder',
		'extra_cls',
		'expand',
	],

	data() {
		return {
			dropdownTag: false,
			searchTag: '',
		}
	},

	mounted() {
		if (this.$el) new ResizeObserver(this.outputsize).observe(this.$el)
	},

	render() {
		let cls = 'convo_tags'
		if (this.extra_cls) cls += ` ${this.extra_cls}`
		if (this.expand) return <div class={cls}>{this.renderExpandContent()}</div>
		return <div class={cls}>{this.renderContent()}</div>
	},

	methods: {
		renderContent() {
			// get current offset width
			if (!this.$el) return
			var maxwidth = this.$el.offsetWidth

			// find out number tags to display
			// tags width should not excess max width

			var lastwidth = 0
			var tags = []
			var taglefts = []

			var N = lo.size(this.tags)
			for (var i = 0; i < N; i++) {
				var tag = this.tags[i]
				var tagswidth = lastwidth + getTagWidth(lo.get(tag, 'title'))
				if (this.editable) tagswidth += 21 // X button width
				var tagsleft = N - i - 1

				var morewidth = 0
				if (tagsleft >= 0) morewidth = getTagWidth('+' + tagsleft) // more btn
				if (tagsleft >= 0 && this.editable) morewidth = morewidth + getTagWidth('+') // add btn

				if (tagswidth + morewidth > maxwidth - 10) {
					for (var j = i; j < N; j++) taglefts.push(this.tags[j])
					break
				}

				tags.push(tag)
				lastwidth = tagswidth
			}

			let $tags = lo.map(tags, (tag) => {
				if (!tag) return null
				let loading = !!lo.get(this, ['loadingTags', tag.id])
				var tagswidth = getTagWidth(lo.get(tag, 'title'))
				return (
					<Tag
						data-mw={maxwidth}
						data-tw={tagswidth}
						tag={tag}
						hidden={tag._hidden}
						vOn:click={this.onTagClick}
						deletable={this.editable}
						vOn:delete={(_) => this.$emit('delete', tag)}
						loading={loading}
					/>
				)
			})

			let $addTag = null
			if (this.editable) {
				let $dropdownTags = null
				let taggeds = lo.map(this.tags)

				let untaggeds = lo.filter(this.allTags, (t) => !lo.find(taggeds, (ted) => ted.id === t.id))
				if (lo.size(untaggeds) > 0) {
					if (this.dropdownTag) {
						let $untags = lo.map(untaggeds, (tag) => {
							if (!tag) return null
							let loading = !!lo.get(this, ['loadingTags', tag.id])

							return (
								<div class='dropdown__item dropdown__item__thin' vOn:click={(_) => this.$emit('add', tag)}>
									<Tag tag={tag} loading={loading} />
								</div>
							)
						})

						//if (this.dropdownFull) {
						//$dropdownTags = <div class='dropdown convo_header__tag_dropdown__full'>{$untags}</div>
						//} else
						//$dropdownTags = (
						//<div class='dropdown' style={this.position === 'left' ? 'left: 0' : ''}>
						//{$untags}
						//</div>
						//)

						$dropdownTags = this.renderAddTagsDropdown(untaggeds)
					}

					let $addTagBtn = (
						<div class='convo_tag convo_tag__add_btn' ref='add_btn' vOn:click={this.onAddTagClicked}>
							<Icon name='plus' size='14' stroke-width='1.5' />
						</div>
					)
					if (lo.size(tags) === 0) {
						$addTagBtn = (
							<div class='convo_tag convo_tag__add_btn' ref='add_btn' vOn:click={this.onAddTagClicked}>
								{this.placeholder || (
									<Fragment>
										<Icon name="tag" size='1x' /> {this.$t('tag_conversation')}
									</Fragment>
								)}
							</div>
						)
					}

					if (this.dropdownFull) {
						$addTag = (
							<div v-clickaway={this.hideDropdown}>
								{$addTagBtn}
								{$dropdownTags}
							</div>
						)
					} else {
						$addTag = (
							<div style='position: relative;' v-clickaway={this.hideDropdown}>
								{$addTagBtn}
								{$dropdownTags}
							</div>
						)
					}
				}
			}
			// this.renderAddTags()
			// show more
			return (
				<Fragment>
					{$tags}
					{this.renderMore(taglefts)}
					{$addTag}
				</Fragment>
			)
		},

		calculateStyle() {
			const DROPDOWN_WIDTH = 200
			const DROPDOWN_HEIGHT = 350
			const EDGE = 20

			let $add_btn = this.$refs.add_btn
			let rect = $add_btn ? $add_btn.getBoundingClientRect() : {}
			let {x = 0, y = 0, width = 0, height = 0} = rect

			let isTop = window.innerHeight - (y + height + DROPDOWN_HEIGHT + EDGE) < 0
			let isRight = window.innerWidth - (x + width + DROPDOWN_WIDTH + EDGE) < 0

			this.dropdownStyle = {
				top: isTop ? 'unset' : `${y + height}px`,
				bottom: isTop ? `${window.innerHeight - y}px` : 'unset',
				left: isRight ? 'unset' : `${x}px`,
				right: isRight ? `${window.innerWidth - x - width}px` : 'unset',
			}
		},

		renderAddTagsDropdown(untaggeds) {
			let style = this.dropdownStyle || {}
			style['z-index'] = 9999
			style['maxWidth'] = '220px'
			style['overflow'] = 'hidden'
			if (!this.dropdownTag) style.display = 'none'
			let untags = untaggeds
			if (this.searchTag !== '') {
				untags = lo.filter(untags, (tag) => {
					let title = lo.get(tag, 'title', '')
					let label = sb.unicodeToAscii(title).toLowerCase()
					let filter = sb.unicodeToAscii(this.searchTag).toLowerCase()
					return label.indexOf(filter) > -1
				})
			}
			let $search_tag = null
			if (lo.size(untaggeds) > 10) {
				$search_tag = (
					<div class='search_dropdown_input'>
						<Icon name='search' class='dropdown_filter_input_search_icon' size='13' style='left: 18px;' />
						<input class='form-control dropdown_filter_input' vModel={this.searchTag} ref='tag_input' />
						{this.searchTag && (
							<Icon
								stroke-width='2'
								name='x'
								size='13'
								class='dropdown_filter_input_x_icon'
								style='right: 18px;'
								vOn:click={() => (this.searchTag = '')}
							/>
						)}
					</div>
				)
			}
			let $untaggeds = lo.map(untags, (tag) => {
				if (!tag) return null
				let loading = !!lo.get(this, ['loadingTags', tag.id])

				return (
					<div class='dropdown__item dropdown__item__thin' vOn:click={(_) => this.$emit('add', tag)}>
						<Tag tag={tag} loading={loading} />
					</div>
				)
			})

			return (
				<AppendToBody>
					<div class='dropdown' style={style} vOn:click_stop={() => false}>
						{$search_tag}
						<div style='overflow: auto; max-height: 300px;'>{$untaggeds}</div>
					</div>
				</AppendToBody>
			)
		},

		renderExpandContent() {
			// get current offset width
			if (!this.$el) return

			// find out number tags to display
			// tags width should not excess max width

			let $tags = lo.map(this.tags, (tag) => {
				if (!tag) return null
				let loading = !!lo.get(this, ['loadingTags', tag.id])
				return (
					<Tag
						tag={tag}
						hidden={tag._hidden}
						vOn:click={this.onTagClick}
						deletable={this.editable}
						vOn:delete={(_) => this.$emit('delete', tag)}
						loading={loading}
					/>
				)
			})

			let $addTag = null
			if (this.editable) {
				let $dropdownTags = null
				let taggeds = lo.map(this.tags)

				let untaggeds = lo.filter(this.allTags, (t) => !lo.find(taggeds, (ted) => ted.id === t.id))
				if (lo.size(untaggeds) > 0) {
					if (this.dropdownTag) {
						let $untags = lo.map(untaggeds, (tag) => {
							if (!tag) return null
							let loading = !!lo.get(this, ['loadingTags', tag.id])

							return (
								<div class='dropdown__item dropdown__item__thin' vOn:click={(_) => this.$emit('add', tag)}>
									<Tag tag={tag} loading={loading} />
								</div>
							)
						})

						//if (this.dropdownFull) {
						//$dropdownTags = <div class='dropdown convo_header__tag_dropdown__full'>{$untags}</div>
						//} else
						//$dropdownTags = (
						//<div class='dropdown' style={this.position === 'left' ? 'left: 0' : ''}>
						//{$untags}
						//</div>
						//)
						$dropdownTags = this.renderAddTagsDropdown(untaggeds)
					}

					let $addTagBtn = (
						<div class='convo_tag convo_tag__add_btn' ref='add_btn' vOn:click={this.onAddTagClicked}>
							+
						</div>
					)
					if (lo.size(this.tags) === 0) {
						$addTagBtn = (
							<div class='convo_tag convo_tag__add_btn' ref='add_btn' vOn:click={this.onAddTagClicked}>
								{this.placeholder || (
									<Fragment>
										<Icon name="tag" size='1x' /> {this.$t('tag_conversation')}
									</Fragment>
								)}
							</div>
						)
					}

					if (this.dropdownFull) {
						$addTag = (
							<div v-clickaway={this.hideDropdown}>
								{$addTagBtn}
								{$dropdownTags}
							</div>
						)
					} else {
						$addTag = (
							<div style='position: relative;' v-clickaway={this.hideDropdown}>
								{$addTagBtn}
								{$dropdownTags}
							</div>
						)
					}
				}
			}
			// this.renderAddTags()
			// show more
			return (
				<Fragment>
					{$tags}
					{$addTag}
				</Fragment>
			)
		},

		outputsize() {
			this.$forceUpdate()
		},

		hideDropdown() {
			this.dropdownTag = false
			this.searchTag = ''
		},

		onAddTagClicked() {
			this.calculateStyle()
			this.dropdownTag = !this.dropdownTag
			setTimeout(() => {
				this.$refs.tag_input && this.$refs.tag_input.focus()
			}, 100)
		},

		renderAddTags() {},

		onTagClick(tag) {
			this.$emit('tagClick', tag)
		},

		onClickMore() {
			this.$emit('moreClick')
		},

		renderMore(tags) {
			if (lo.size(tags) === 0) return null

			let $items = lo.map(tags, (tag) => {
				if (!tag) return
				let loading = !!lo.get(this, ['loadingTags', tag.id])
				return (
					<div class='convo_tags__dropdown_tag'>
						<Tag
							tag={tag}
							hidden={tag._hidden}
							vOn:click={this.onTagClick}
							deletable={this.editable}
							vOn:delete={(_) => this.$emit('delete', tag)}
							loading={loading}
						/>
					</div>
				)
			})
			let $dropdown_content = <div style='padding: 8px 10px'>{$items}</div>

			return (
				<div class='convo_tags__more'>
					<HoverDropdown dropdown_content={$dropdown_content} style='line-height: 1; flex-shrink: 0' delay={100}>
						<div class='convo_tag' vOn:click={this.onClickMore}>
							{'+' + lo.size(tags)}
						</div>
					</HoverDropdown>
				</div>
			)
		},
	},
}

let getTagWidth = lo.memoize((text) => {
	var div = document.createElement('div')
	div.position = 'absolute'
	div.style.opacity = '0'
	div.classList.add('convo_tag')
	div.innerText = text
	document.body.append(div)

	var width = div.offsetWidth
	//var padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)
	//var border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth)
	div.remove()

	return width + 1 //  padding + border
})

window.getTagWidth = getTagWidth
