var sb = require('@sb/util')
import store from '@sb/store'

export default {
	name: 'task-message',
	props: ['ev', 'cid', 'uid'],

	mounted() {
		store.onMessage(this, (convoid) => {
			if (convoid === this.cid) this.$forceUpdate()
		})

		store.onTask(this, (task) => {
			if (!task) return
			if (lo.includes(task.associated_users, this.uid)) {
				this.$forceUpdate()
			}
		})
	},

	render() {
		let ev = this.ev
		let by = lo.get(ev, 'by', {})
		if (by.type === 'user') return null

		let $avatar = null
		let agent = store.matchAgent()[by.id] || {}
		let agentName = sb.getAgentDisplayName(agent)
		if (by.type === 'subiz') agent = {fullname: 'System', avatar_url: require('../../assets/img/subiz2.svg')}
		$avatar = <Avatar agent={agent} size='xs' />

		let task = lo.get(ev, 'data.task', {})
		let taskNow = store.matchTask(task.id)

		if (!taskNow) return null

		let created = new Date(sb.unixSec(task.created) * 1000)
		let updated = new Date(sb.unixSec(task.updated) * 1000)

		let text_style = 'display: flex; flex: 1; cursor: pointer;'
		if (taskNow.status === 'done') text_style += ' opacity: 0.3;'

		if (ev.type === 'task_created')
			return (
				<div class='d-flex mt-3 mb-2 contain_task_message'>
					<div
						class='text__truncate text__muted task_message d-flex'
						vOn:click={() => this.$root.$emit('open_tasks_modal', {task_id: task.id})}
					>
						<div class='icon_status_task_message'>
							<Icon name='point' size='14' stroke-width='3' class='done_task_icon' />
						</div>
						<div class='text__truncate' style={text_style}>
							{task.number && (
								<div class='text__truncate' style='flex: 1;'>{`#${Number(task.number)}: ${taskNow.title} `}</div>
							)}
							&nbsp;
							<div title={created.toLocaleDateString() + ' ' + created.toLocaleTimeString()}>
								{this.$t('is_created_by').toLowerCase()}
							</div>
							&nbsp;
							<div>{agentName}</div>
						</div>
					</div>
				</div>
			)

		if (ev.type === 'task_updated') {
			let action = this.$t('is_finishedi_by')
			let $icon = (
				<div class='icon_status_task_message reopen'>
					<Icon name='check' size='13' stroke-width='3.5' class='reopen_task_icon' style='' />
				</div>
			)
			if (task.status !== 'done') {
				action = this.$t('is_reopen_by')
				$icon = (
					<div class='icon_status_task_message'>
						<Icon name='point' size='14' stroke-width='3' class='done_task_icon' />
					</div>
				)
			}

			return (
				<div class='d-flex mt-3 mb-2 contain_task_message'>
					<div
						class='text__truncate text__muted task_message d-flex'
						vOn:click={() => this.$root.$emit('open_tasks_modal', {task_id: task.id})}
					>
						{$icon}
						<div class='text__truncate' style={text_style}>
							{task.number && (
								<div class='text__truncate' style='flex: 1;'>{`#${Number(task.number)}: ${taskNow.title}`}</div>
							)}
							&nbsp;
							<div title={updated.toLocaleDateString() + ' ' + updated.toLocaleTimeString()}>
								{action.toLowerCase()}
							</div>
							&nbsp;
							<div>{agentName}</div>
						</div>
					</div>
				</div>
			)
		}
		return null
	},
}
