import NotiList from './noti_list.js'
import Search from './search.js'
const sb = require('@sb/util')
import store from '@sb/store'
import {isTicketImportantUnread} from './ticket/com.js'
import {canViewReport} from './commons/can_do_objects.js'

export default {
	props: ['routerName'],
	data() {
		return {
			isHelpAvailable: false,

			previewEv: null,
			closedPreview: false,

			isMoreMenuOpened: false,

			showNoti: false,
			showSettingError: true,
			isTaskModalOpened: false,

			isOrderMenuOpened: false,
		}
	},

	mounted() {
		store.onNoti(this, (cat) => this.$forceUpdate())
		store.onAccount(this, (payload) => {
			if (lo.get(payload, 'object_type') === 'integration') {
				this.checkFBAndZaloError()
				this.$forceUpdate()
			}
			if (lo.get(payload, 'object_type') === 'site') {
				this.$forceUpdate()
			}
		})
		this.checkFBAndZaloError()

		store.onTicketViewCount(this, (ticketview) => {
			if (lo.get(ticketview, 'id') !== `tvdefault_${store.me().id}_inbox`) return
			this.$forceUpdate()
		})
		this.$root.$on('tasks_modal_open_changed', (val) => {
			this.isTaskModalOpened = val
		})
	},

	methods: {
		onClickNoti(noti) {
			if (!noti.data) return null
			let data = JSON.parse(noti.data) || {}

			let uid = lo.get(data, 'user.id')
			if (noti.type === 'campaign_user_converted' || noti.type === 'user_opened_email') uid = lo.get(data, 'user_id')
			// this.removePopupNoti(noti.id)

			store.readNotification('user', noti.checkpoint, noti.topic)
			if (noti.category === 'order') {
				return this.$root.$emit('showOrder', {id: data.order_id})
			}
			if (!uid) return
			this.$root.$emit('showQuickview', {user_id: uid})
		},

		settingClick() {
			setTimeout(() => this.$router.push({name: 'profile.setting-notification'}), 100)
		},

		renderNotiList() {
			if (!this.showNoti) return null
			return (
				<div vOn:click_prevent_stop={(_) => false}>
					<div class='instant_noti__menu' style='left: unset;  height: 500px ;right: -11px; top: 45px;'>
						<div class='instant_noti__menu_head'>
							<div class='instant_noti__menu_head__title'>{this.$t('notification')}</div>
							<div class='link link__primary' vOn:click={this.settingClick}>
								{this.$t('setting')}
							</div>
						</div>
						<NotiList vOn:notiClick={this.onClickNoti} show={this.showNoti} />
					</div>
				</div>
			)
		},

		closeMoreMenu() {
			this.isMoreMenuOpened = false
		},

		toggleMoreMenu() {
			this.isMoreMenuOpened = !this.isMoreMenuOpened
		},

		checkFBAndZaloError() {
			let zaloIntes = lo.filter(
				store.matchIntegration(),
				(inte) => inte.connector_type === 'zalo' && inte.state !== 'deleted',
			)
			zaloIntes = lo.filter(zaloIntes, (inte) => inte.sending_status !== 'unstable' && inte.sending_status !== 'ok')

			let fbIntes = lo.filter(
				store.matchIntegration(),
				(inte) => inte.connector_type === 'facebook' && inte.state !== 'deleted',
			)
			fbIntes = lo.filter(fbIntes, (inte) => inte.token_status === 'failed')

			if (lo.size(fbIntes) || lo.size(zaloIntes)) {
				// use setTimeout because header component is mounted before noti_message_manager
				setTimeout(() => {
					this.$root.$emit('push_noti_message', {
						id: 'integration_error',
						type: 'integration_error',
						data: [...zaloIntes, ...fbIntes],
						priority: 'high',
					})
				})
			} else {
				this.$root.$emit('remove_noti_message', 'integration_error')
			}
		},

		isSettingError() {
			if (!this.showSettingError) return false
			return this.isZaloError() || this.isFbError()
		},

		isZaloError() {
			let fbIntes = lo.filter(
				store.matchIntegration(),
				(inte) => inte.connector_type === 'zalo' && inte.state !== 'deleted',
			)
			fbIntes = lo.filter(fbIntes, (inte) => inte.sending_status !== 'unstable' && inte.sending_status !== 'ok')

			return lo.size(fbIntes)
		},

		isFbError() {
			let fbIntes = lo.filter(
				store.matchIntegration(),
				(inte) => inte.connector_type === 'facebook' && inte.state !== 'deleted',
			)
			fbIntes = lo.filter(fbIntes, (inte) => inte.token_status === 'failed')

			return lo.size(fbIntes)
		},

		renderSettingsError() {
			return null
			//return null
			if (!this.isSettingError()) return null

			let text = 'Fanpage'
			let routerName = 'settings.messenger'
			if (this.isFbError() && this.isZaloError()) {
				text = 'Fanpage và Zalo'
			} else if (this.isZaloError()) {
				text = 'Zalo'
				routerName = 'settings.zalo'
			}

			return (
				<div class='error_settings_dropdown'>
					<div class='d-flex align-items-center'>
						<div class='text__semibold '>
							<Icon name='alert-triangle' width='18' stroke-width='2' class='mr-3' />
							Lỗi gián đoạn {text}.{' '}
							{routerName === 'settings.messenger' ? (
								<a
									href='https://www.youtube.com/watch?v=sYfkWY1mJuI'
									target='_blank'
									class='link link__dark text__underline'
								>
									Xem ngay
								</a>
							) : (
								<router-link to={{name: routerName}} class='link link__dark text__underline'>
									Xem ngay
								</router-link>
							)}
						</div>
						<Icon
							name='x'
							stroke-width='2'
							v-tooltip={this.$t('close')}
							class='btn__close ml-auto btn__close_light'
							vOn:click={() => (this.showSettingError = false)}
						/>
					</div>
				</div>
			)
		},
	},

	render() {
		// get parent router name
		let routerName = this.routerName
		let scopes = store.me().scopes || []
		let isSupervisor = lo.get(store.me(), 'is_supervisor')

		const subscription = store.matchSubscription() || {}

		let ordercls = 'header_bar_menu_link'
		if (routerName === 'order-list' || routerName === 'product-list') ordercls += ' active'
		let ticketcls = 'header_bar_menu_link'
		if (routerName === 'ticket-list' || routerName === 'ticket-detail') ticketcls += ' active'
		let onlinecls = 'header_bar_menu_link'
		if (routerName === 'liveoverview' || routerName === 'liveview') onlinecls += ' active'
		let leadcls = 'header_bar_menu_link'
		if (routerName === 'lead') leadcls += ' active'
		let submenucls = 'header_bar_menu_link'
		if (this.isMoreMenuOpened) submenucls += ' active'
		let accmgrcls = 'header_bar_menu_link'
		if (routerName === 'accmgr' || routerName === 'refmgr' || routerName === 'promomgr') accmgrcls += ' active'
		let settingcls = 'header_menu_icon'
		if ((routerName || '').startsWith('settings')) settingcls += ' active'
		let knowbasecls = 'header_bar_menu_link'
		if ((routerName || '').startsWith('knowledgebase')) knowbasecls += ' active'

		let $orderupdate = null
		let notibody = store.matchNotis('order') || {}
		if (lo.get(notibody, 'unread') > 0) $orderupdate = <div class='header_bar_noti_count_badge'>{notibody.unread}</div>

		let $notiupdate = null
		let usernotibody = store.matchNotis('user') || {}
		if (lo.get(usernotibody, 'unread') > 0)
			$notiupdate = <div class='header_bar_noti_count_badge'>{usernotibody.unread}</div>

		let $setting = null
		if (!scopes.includes('account_manage') && !scopes.includes('owner') && !scopes.includes('account_setting')) {
			$setting = null
		} else {
			$setting = (
				<router-link to={{name: 'settings.general'}} class={settingcls}>
					<div style='position: relative' v-tooltip={this.$t('setting')}>
						<Icon size='24' name='settings' class='header_bar_menu_link_icon' />
					</div>
					{store.shouldShowOnboarding() && (
						<div
							class='header_bar_noti_count_badge'
							style={{
								width: '16px',
								height: '16px',
								minWidth: '16px',
								top: '-2px',
								right: '4px',
								borderRadius: '50%',
								padding: 0,
							}}
						/>
					)}
				</router-link>
			)
		}

		let $ticketnoti = null
		let inboxticketview = store.matchTicketView()[`tvdefault_${store.me().id}_inbox`] || {}
		let count = lo.find(inboxticketview.members, (mem) => mem.id === store.me().id)
		count = lo.get(count, 'count') || 0
		if (count > 0) {
			$ticketnoti = (
				<div class='header_bar_noti_count_badge'>
					<Numeric number={count} short />
				</div>
			)
		}

		let headercls = 'header_bar'
		if (subscription.churned) headercls += ' header_bar__churned'

		let notiCls = 'header_menu_icon'
		if (this.showNoti) notiCls += ' active'

		return (
			<div>
				<div class={headercls}>
					<div class='header_bar_left'>
						<img
							src={require('./assets/img/sbz-white.svg')}
							class='header_bar_logo'
							vOn:click={(_) => this.$router.push({name: 'activities'})}
							style='width: 29px; height: 22px;'
						/>
						<Search />
					</div>
					<div class='header_bar_center'>
						<MessageHeaderItem routerName={this.routerName} />
						{store.hasTicketFeature() && (
							<router-link to={{name: 'ticket-list'}} class={ticketcls}>
								<div class='text-truncate'>{this.$t('header_ticket')}</div>
								<div style='position: relative; height: 100%'>{$ticketnoti}</div>
								<span
									class='badge badge__primary'
									style='position: absolute; bottom: -4px; right: -10px; font-size: 10px; padding: 2px 4px; border-radius: 2px'
								>
									Beta
								</span>
							</router-link>
						)}
						{store.hasKnowLedgeBaseFeature() ? (
							<router-link to={{name: 'knowledgebase.self'}} class={knowbasecls}>
								<div class='text-truncate'>{this.$t('knowledge_base')}</div>
							</router-link>
						) : null}

						<div
							class={ordercls}
							v-clickaway={() => (this.isOrderMenuOpened = false)}
							vOn:click={() => (this.isOrderMenuOpened = !this.isOrderMenuOpened)}
						>
							<div class='d-flex align-items-center'>
								<div class='text-truncate'>{this.$t('order_2')}</div>
								<Icon name='chevron-down' size='18' class='ml-2' />
							</div>
							<div class='more_menu_order_dropdown' style={!this.isOrderMenuOpened ? 'display: none' : ''}>
								<div class='more_menu_items_container'>
									<router-link to={{name: 'order-list'}} class='more_menu_item'>
										<Icon size='22' name='receipt-2' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>{this.$t('order')}</div>
											<div class='more_menu_item_sub'>{this.$t('order_menu_desc')}</div>
										</div>
									</router-link>
									<router-link to={{name: 'product-list'}} class='more_menu_item'>
										<Icon name='package' size='22' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>{this.$t('product')}</div>
											<div class='more_menu_item_sub'>{this.$t('product_menu_desc')}</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>

						{store.checkPerm('live:read') ? (
							<router-link to={{name: 'liveoverview'}} class={onlinecls}>
								<div style='position: relative'></div>
								<div class='text-truncate'>{this.$t('Live')}</div>
							</router-link>
						) : null}

						<router-link to={{name: 'lead'}} class={leadcls}>
							<div class='text-truncate'>{this.$t('lead-2')}</div>
						</router-link>
						{canViewReport() && (
							<router-link
								to={{
									name: 'newreports.convo',
								}}
								class={`header_bar_menu_link ${(routerName || '').startsWith('newreport') ? 'active' : ''}`}
							>
								<div class='text-truncate'>{this.$t('report')}</div>
							</router-link>
						)}
						<div
							class={submenucls}
							v-clickaway={this.closeMoreMenu}
							vOn:click={this.toggleMoreMenu}
							style='display: flex; align-items: center; justify-content: center; user-select: none'
						>
							<div class='d-flex align-items-center'>
								<div class='text-truncate'>{this.$t('tools')}</div>
								<Icon name='chevron-down' size='18' class='ml-2' />
							</div>
							<div class='more_menu_dropdown' style={!this.isMoreMenuOpened ? 'display: none' : ''}>
								<div class='more_menu_items_container'>
									<router-link to={{name: 'web_plugin'}} class='more_menu_item'>
										<Icon name='browser' stroke-width='2' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>{this.$t('web_plugin')}</div>
											<div class='more_menu_item_sub'>{this.$t('web_plugin_menu_desc')}</div>
										</div>
									</router-link>
									<router-link to={{name: 'bot-list'}} class='more_menu_item'>
										<Icon name='cpu' stroke-width='2' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>{this.$t('bot')}</div>
											<div class='more_menu_item_sub'>{this.$t('bot_menu_desc')}</div>
										</div>
									</router-link>

									{[
										'acpxkgumifuoofoosble',
										'acrgwpijnddohugcufdm',
										'acqsulrowbxiugvginhw',
										'acqtwtasitcxoydziwrn',
										'acpzooihzhalzeskamky',
										'acriviayfmabzskstrpq',
										'acrpgzqsezkafoiarcgs',
										'acrjfbhsfcqrctxgkomj',
										'acrxlzmdxndfebdlwnuz',
									].includes(store.me().account_id) && (
										<Fragment>
											<router-link to={{name: 'campaign-list'}} class='more_menu_item'>
												<Icon name='ad' stroke-width='2' class='more_menu_item_icon' />
												<div>
													<div class='more_menu_item_header'>
														{this.$t('broadcast')}{' '}
														<span
															class='badge badge__primary'
															style='position: relative; top: -2px; left: 5px; font-size: 12px; padding: 4px 8px; border-radius: 4px'
														>
															Beta
														</span>
													</div>
													<div class='more_menu_item_sub'>{this.$t('campaign_menu_desc')}</div>
												</div>
											</router-link>
										</Fragment>
									)}
									<router-link to={{name: 'telesale-list'}} class='more_menu_item'>
										<Icon name='phone' stroke-width='2' class='more_menu_item_icon' />
										<div>
											<div class='more_menu_item_header'>
												{this.$t('telesale')}{' '}
												<span
													class='badge badge__primary'
													style='position: relative; top: -2px; left: 5px; font-size: 12px; padding: 4px 8px; border-radius: 4px'
												>
													Beta
												</span>
											</div>
											<div class='more_menu_item_sub'>{this.$t('telesale_menu_desc')}</div>
										</div>
									</router-link>
								</div>
							</div>
						</div>

						{lo.get(store.me(), 'account.id') === 'acpxkgumifuoofoosble' && (
							<div>
								<router-link to={{name: 'accmgr'}} class={accmgrcls}>
									Accmgr
								</router-link>
							</div>
						)}
					</div>
					<div class='header_bar_right'>
						{$setting}
						<div
							class={{header_menu_icon: true, active: this.isTaskModalOpened}}
							vOn:click_stop={() => {
								this.$root.$emit('toggle_tasks_modal', {user_id: ''})
								this.showNoti = false
							}}
						>
							<div style='position: relative' v-tooltip={this.$t('tasks_remind')}>
								<Icon name='circle-check' size='24' class='header_bar_menu_link_icon' />
								<TaskNotiCounter extraClass='header_bar_noti_count_badge' />
							</div>
						</div>
						<div
							class={notiCls}
							vOn:click={(_) => {
								this.showNoti = !this.showNoti
							}}
							v-clickaway={(_) => {
								this.showNoti = false
							}}
						>
							{this.renderNotiList()}
							<div style='position: relative' v-tooltip={this.$t('notification')}>
								<Icon size='24' name='bell' class='header_bar_menu_link_icon' />
								{$notiupdate}
							</div>
						</div>
						<AgentMenu />
					</div>
				</div>
				<div style='position: relative'>{this.renderSettingsError()}</div>
			</div>
		)
	},
}

// so we dont broadcast rerender when convo changed
var MessageHeaderItem = {
	props: ['routerName'],
	created() {
		store.onConvo(this, (_) => this.$forceUpdate())
		store.onAccount(this, (_) => this.$forceUpdate())
	},

	methods: {
		countConvoUnread() {
			let seen = lo.get(store.me(), 'dashboard_setting.conversation_seen', 0)
			let topics = store.listTopics(5_000)
			return lo.filter(topics, (topic) => {
				if (topic.actived <= seen) return false
				if (sb.isTopicIdConvo(topic.id)) return !topic.read
				return isTicketImportantUnread(store.matchTicket(topic.id) || {id: topic.id})
			}).length
		},
	},

	render() {
		let unreadcount = this.countConvoUnread()
		let $unreadTopic = null
		let routerName = this.routerName
		if (unreadcount > 0)
			$unreadTopic = (
				<div class='header_bar_noti_count_badge'>
					<Numeric number={unreadcount} short />
				</div>
			)
		if (routerName === 'activities') $unreadTopic = null
		let convocls = 'header_bar_menu_link'
		if (routerName === 'activities') convocls += ' active'

		return (
			<router-link to={{name: 'activities'}} class={convocls}>
				<div class='text-truncate'>{this.$t('messages')}</div>
				<div style='position: relative; height: 100%;'>{$unreadTopic}</div>
			</router-link>
		)
	},
}

var AgentMenu = {
	data() {
		return {
			profileHover: false,
			accounts: [],
			showAccountList: false,
		}
	},
	async mounted() {
		this.fetchAccount()
	},

	methods: {
		async fetchAccount() {
			const res = await store.listAgentAccounts()
			if (res.error) return
			let accounts = lo.get(res, 'accounts', [])

			if (lo.size(accounts) > 1) {
				accounts.sort((a, b) => {
					if (a.state === 'activated' && b.state !== 'activated') return -1
					if (a.state !== 'activated' && b.state === 'activated') return 1
					if (a.state === b.state) return a.created - b.created
					return 0
				})
			}

			this.accounts = accounts
		},

		onProfileMouseOver() {
			clearTimeout(this.profileleavinghandler)
			this.profileHover = true
		},

		onProfileMouseLeave() {
			clearTimeout(this.profileleavinghandler)
			this.profileleavinghandler = setTimeout(() => {
				this.profileHover = false
			}, 150)
		},

		redirectNewWorkSpace(accid) {
			let link = `/convo?x-account-id=${accid}`
			window.open(link)
		},

		renderAgentMenu() {
			let fullname = lo.get(store.me(), 'fullname', '')
			let email = lo.get(store.me(), 'email', '')
			const account_name = lo.get(store.me(), 'account.name', '')

			const subscription = store.matchSubscription() || {}
			let {plan, account_id} = subscription

			let style = 'display: none'
			let $paymentTag = null

			if (!plan) plan = 'locked'

			if (plan.startsWith('trial') || plan === 'free')
				$paymentTag = <div class='plan_tag plan_tag__danger'>{this.$t('upgrade_plan')}</div>

			if (plan.startsWith('standard'))
				$paymentTag = <div class='plan_tag plan_tag__primary'>{this.$t('plan_standard')}</div>

			if (plan.startsWith('advanced'))
				$paymentTag = <div class='plan_tag plan_tag__success'>{this.$t('plan_advanced')}</div>

			if (this.profileHover) style = 'display:block'

			let scopes = store.me().scopes || []
			let $setting = null
			let $payment = null
			if (!scopes.includes('account_manage') && !scopes.includes('owner') && !scopes.includes('account_setting')) {
				$setting = null
			} else {
				$setting = (
					<div
						class='agent_profile_item'
						vOn:click={() => {
							this.$router.push({name: 'settings.general'})
							this.profileHover = false
						}}
					>
						{this.$t('account_setting')}
					</div>
				)
				$payment = (
					<div
						class='agent_profile_item'
						vOn:click={(_) => {
							this.$router.push({name: 'settings.subscription-info'})
							this.profileHover = false
						}}
					>
						<div>{this.$t('invoice_list_column_payment')}</div>
					</div>
				)
			}
			let $switch_account_icon = null
			if (lo.size(this.accounts) > 1)
				$switch_account_icon = (
					<div style='cursor: pointer' vOn:click={() => (this.showAccountList = true)}>
						<Icon name='switch-horizontal' size='24' />
					</div>
				)
			let $switch_account = (
				<div class='switch_account_item'>
					<div class='d-flex align-items-center justify-content-between mb-2' style='font-weight: 400; color: #828282;'>
						<div style='text-transform: uppercase; font-size: 14px;'>{this.$t('account')}</div>
						{$switch_account_icon}
					</div>
					<div class='d-flex align-items-center mb-2'>
						<div class='text__semibold' style='margin-right: 15px;'>
							{account_name}
						</div>
						{$paymentTag}
					</div>
					<div style='font-weight: 400; color:#909090;'>{account_id}</div>
				</div>
			)
			let $body_menu = null
			if (this.showAccountList) {
				$body_menu = (
					<div>
						<div class='agent_profile_item' vOn:click={() => (this.showAccountList = false)}>
							<Icon class='mr-2' name='chevron-left' size='18' />
							{this.$t('back')}
						</div>
						{lo.map(this.accounts, (account) => {
							return (
								<div
									class='agent_profile_item d-flex align-items-center'
									vOn:click={() => this.redirectNewWorkSpace(account.id)}
								>
									<img src={account.logo_url} width={20} height={20} />
									<div class='ml-2'>{account.name}</div>
								</div>
							)
						})}
					</div>
				)
			} else
				$body_menu = (
					<div>
						{$switch_account}
						<div class='agent_profile_body'>
							{$setting}
							{$payment}
							<div class='agent_profile_item' vOn:click={(_) => window.open('/agent')}>
								{this.$t('document_and_custom')}
							</div>

							<div
								class='agent_profile_item'
								vOn:click={(_) => {
									this.$root.$emit('agent_modal_open', '')
									this.profileHover = false
								}}
							>
								Agent
							</div>
							<div
								class='agent_profile_item'
								vOn:click={(_) => {
									this.profileHover = false
									this.$root.$emit('message_template_modal_open')
								}}
							>
								{this.$t('message_template')}
							</div>
							<div class='agent_profile_item' vOn:click={(_) => window.open('/agent/referral')}>
								{this.$t('referral_label', ['10%'])}
							</div>
							<a
								class='agent_profile_item d-flex align-items-center'
								href='https://subiz.com.vn/docs/'
								target='_blank'
								style='color: black; width: 100%;'
							>
								{this.$t('agent_profile_help')}
							</a>
						</div>
						<div
							class='agent_profile_item text__danger'
							style='padding: 10px 20px;'
							vOn:click_prevent={(_) => store.logout()}
						>
							{this.$t('agent_profile_logout')}
						</div>
					</div>
				)

			return (
				<div class='nav_my_profile' style={style}>
					<div class='nav_my_profile__info' vOn:click={(_) => window.open('/agent')}>
						<div class='' style='overflow: hidden'>
							<div class='h5 ' style='cursor: pointer'>
								{fullname}
							</div>
							<span class='text__muted' style='word-wrap: break-word' v-tooltip={email}>
								{email}
							</span>
						</div>
						<div style='cursor: pointer;'>
							<Avatar agent={store.me()} size='md' nodot notooltip />
						</div>
					</div>
					{$body_menu}
				</div>
			)
		},

		onClickAwayMenu() {
			this.profileHover = false
			this.showAccountList = false
		},

		onClickAgentInfo() {
			this.profileHover = !this.profileHover
			if (this.showAccountList) this.showAccountList = false
		},
	},

	created() {
		store.onAccount(this, (_) => this.$forceUpdate())
	},

	render() {
		let agent = store.me() || {}
		const account_name = lo.get(agent, 'account.name', '')
		let email = lo.get(store.me(), 'email', '')
		let headerAgentCls = 'header_bar_agent_avatar_container'
		if (this.profileHover) headerAgentCls += ' active'
		return (
			<div class='' style='position: relative;' v-clickaway={() => this.onClickAwayMenu()}>
				<div class={headerAgentCls} style='cursor: pointer' vOn:click={() => this.onClickAgentInfo()} v-tooltip={email}>
					<Avatar agent={agent} size='22' notooltip nodot />
					<div class='text__light ml-3' style='max-width: 100px;'>
						<div class='account_info_name text__semibold'>{account_name}</div>
					</div>
				</div>
				{this.renderAgentMenu()}
			</div>
		)
	},
}
