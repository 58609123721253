const sb = require('@sb/util')
import store from '@sb/store'
import AppendToBody from './append_to_body.js'

export default {
	name: 'message-templates',
	props: ['query', 'connectorType'],
	data() {
		return {
			currentSelectedIndex: 0,
			isShowing: false,
			forceShow: false, // when agent click on message template icon
			filteredTemplates: [],
			start: '/',

			// trigger position to calculate message template fixed position
			customStyle: {},
		}
	},

	async mounted() {
		let temps = lo.map(store.matchMessageTemplate())
		temps = temps.concat(
			lo.map(store.matchConversationModal(), (m) => {
				return Object.assign({}, m, {message: {text: m.description}, keys: [m.key], type: 'modal'})
			}),
		)
		temps.sort((i, j) => {
			if (!i.keys || !i.keys[0]) return -1
			if (!j.keys || !j.keys[0]) return 1
			return i.keys[0] > i.keys[0] ? 1 : -1
		})

		this.onQueryChange(this.query)
		store.onAccount(this, () => {
			this.$forceUpdate()
		})

		document.body.addEventListener('keyup', this.onKeyup)
		document.body.addEventListener('keydown', this.onKeydown)
	},

	destroyed() {
		document.body.removeEventListener('keyup', this.onKeyup)
		document.body.removeEventListener('keydown', this.onKeydown)
	},

	watch: {
		query(query) {
			this.onQueryChange(query)
		},

		isShowing() {
			this.calculateStyle()
		},

		filteredTemplates() {
			this.calculateStyle()
		},

		forceShow() {
			this.calculateStyle()
		},
	},

	methods: {
		calculateStyle() {
			if (!this.IsShow()) return
			const MAX_HEIGHT = 500
			const VERTICAL_EDGE = 100
			const MIN_HEIGHT = 200

			let customStyle = {}
			let rect = this.$refs.trigger ? this.$refs.trigger.getBoundingClientRect() : {}
			let {top = 0, left = 0, width = 0} = rect
			customStyle.bottom = window.innerHeight - top + 'px'
			customStyle.left = left + 'px'
			customStyle.width = width + 'px'
			customStyle.zIndex = 99999

			let isOverHeight = top < MAX_HEIGHT
			if (isOverHeight) {
				let maxHeight = top - VERTICAL_EDGE
				if (maxHeight < MIN_HEIGHT) {
					customStyle.bottom = window.innerHeight - top - (MIN_HEIGHT - maxHeight) + 'px'
					maxHeight = MIN_HEIGHT
				}
				customStyle.maxHeight = maxHeight + 'px'
			}
			this.customStyle = customStyle
		},

		onQueryChange(query) {
			query = lo.trim(query)
			if (query.length < this.start.length) this.start = '/'

			if (!query.startsWith(this.start)) {
				this.isShowing = false
				this.filteredTemplates = []
				return
			}

			// clean this.start if user has deleted it
			if (this.forceShow) this.isShowing = true
			this.forceShow = false

			query = query.substr(this.start.length, query.length - this.start.length)

			// smart trigger
			if (query === '') this.isShowing = true
			if (!this.isShowing) return

			let temps = lo.map(store.matchMessageTemplate())
			temps = temps.concat(
				lo.map(store.matchConversationModal(), (m) => {
					return Object.assign({}, m, {message: {text: m.description}, keys: [m.key], type: 'modal'})
				}),
			)
			temps.sort((i, j) => {
				if (!i.keys || !i.keys[0]) return -1
				if (!j.keys || !j.keys[0]) return 1
				return i.keys[0] > i.keys[0] ? 1 : -1
			})

			let search = sb.unicodeToAscii(query).toLowerCase()

			// only by key
			this.filteredTemplates = lo.filter(temps, (tmpl) => {
				return lo.find(tmpl.keys, (key) => {
					let asciiKey = sb.unicodeToAscii(key).toLowerCase()
					return asciiKey.indexOf(search) > -1
				})
			})

			let connectorType = this.connectorType || 'subiz'
			if (connectorType != 'email') connectorType = 'subiz'
			this.filteredTemplates = lo.filter(
				this.filteredTemplates,
				(templ) => templ.type == 'modal' || templ.channel_type === connectorType,
			)

			this.filteredTemplates = lo.take(this.filteredTemplates, 50)
		},

		IsShow() {
			return this.isShowing && lo.size(this.filteredTemplates) > 0
		},

		Show(show, start) {
			if (!show) return this.hideWindow()
			start = lo.trim(start)
			if (!start) start = '/'
			this.start = start
			this.onQueryChange(start)

			this.forceShow = true
		},

		onKeydown(e) {
			if (!this.isShowing) return
			switch (e.keyCode) {
				case 38: // UP
					this.currentSelectedIndex--
					if (this.currentSelectedIndex < 0) this.currentSelectedIndex = lo.size(this.filteredTemplates) - 1
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				case 40: // DOWN
					this.currentSelectedIndex++
					if (this.currentSelectedIndex >= lo.size(this.filteredTemplates)) this.currentSelectedIndex = 0
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
			}
		},

		onKeyup(e) {
			if (!this.isShowing) return
			switch (e.keyCode) {
				case 27: // ESC
					this.hideWindow()
					break
				case 13: // ENTER
					if (!this.isShowing) return
					var tmpl = this.filteredTemplates[this.currentSelectedIndex]
					this.chooseTemplate(tmpl)
			}
		},

		hideWindow() {
			if (!this.isShowing) return
			this.start = '/'
			this.isShowing = false
			this.currentSelectedIndex = 0
		},

		chooseTemplate(templ) {
			if (!templ) templ = {message: {text: '', format: 'plaintext'}}
			if (templ.type == 'modal') this.$emit('modal', {id: templ.id, query: this.query})
			else this.$emit('choose', Object.assign({format: 'plaintext'}, templ.message))
			this.hideWindow()
		},

		openManageModal() {
			this.isShowing = false
			let channelType = this.connectorType
			if (channelType !== 'email') channelType = 'subiz'
			this.$root.$emit('message_template_modal_open', channelType)
		},
	},

	render() {
		let $templs = lo.map(this.filteredTemplates, (templ, i) => {
			let $img = null
			let attachments = lo.get(templ, 'message.attachments', [])
			let images = lo.filter(attachments, (att) => (att.mimetype || '').startsWith('image'))
			let files = lo.filter(attachments, (att) => !(att.mimetype || '').startsWith('image') && att.type === 'file')
			let generics = lo.filter(attachments, (att) => att.type === 'generic')
			let eles = []
			lo.each(generics, (gen) => {
				lo.each(gen.elements, (el) => eles.push(el))
			})

			if (lo.size(files) && !lo.size(images)) {
				$img = (
					<div class='text__muted'>
						<Icon name="paperclip" size='15' />{' '}
						<em>
							{lo.size(files)} {this.$t('title_attachment').toLowerCase()}
						</em>
					</div>
				)
			}
			if (lo.size(images) && !lo.size(files)) {
				$img = (
					<div class='d-flex align-items-center'>
						<div class='message_template_modal_box_item_img'>
							<img2 src={lo.get(images, '0.url')} style='width: 40px; height: 40px' clickable />
						</div>
						{lo.size(images) > 1 && <span class='text__muted ml-2'>+ {lo.size(images) - 1}</span>}
					</div>
				)
			}
			if (lo.size(images) && lo.size(files)) {
				$img = (
					<div class='d-flex align-items-center'>
						<div class='message_template_modal_box_item_img'>
							<img2 src={lo.get(images, '0.url')} style='width: 40px; height: 40px' clickable />
						</div>
						<div class='text__muted ml-2'>
							{lo.size(images) > 1 && (
								<span class='text__muted ml-2'>
									+ {lo.size(images) - 1} {this.$t('image').toLowerCase()}
								</span>
							)}{' '}
							{this.$t('and')} {lo.size(files)} {this.$t('title_attachment').toLowerCase()}
						</div>
					</div>
				)
			}
			if (lo.size(generics)) {
				$img = (
					<div class='text__muted'>
						<Icon style='position: relative; top: -1px' name='layout-columns' size='15' stroke-width='2' />{' '}
						<em>
							{lo.size(eles)} {this.$t('attach_products').toLowerCase()}
						</em>
					</div>
				)
			}

			let active = i === this.currentSelectedIndex

			let summary = lo.get(templ, 'message.text')
			if (lo.get(templ, 'message.format') === 'markdown') {
				summary = lo.get(templ, 'message.quill_delta')
				summary = sb.parseJSON(summary) || {}
				summary = sb.deltaToPlainText(summary)
			}
			if (lo.get(templ, 'message.is_template')) {
				let text = lo.get(templ, 'message.text' || '')
				summary = sb.lexicalToPlainText(text, {is_show_dynamic_field_token: true})
			}
			if (templ.channel_type === 'email') {
				summary = sb.getMsgField({data: {message: templ.message}}, 'subject')
			}

			let sumcls = 'template_picker__template_summary'
			if (active) sumcls += ' template_picker__template_summary__active'

			let lblcls = 'template_picker__template_label'
			if (active) lblcls += ' template_picker__template_label__active'

			let cls = 'template_picker__template'
			if (active) cls += ' template_picker__template__active'

			return (
				<div key={templ.id} ref={`item${i}`} vOn:click={(_) => this.chooseTemplate(templ)} class={cls}>
					<div class='template_picker__template_label_wrapper'>
						{lo.map(templ.keys, (key) => (
							<div class={lblcls}>/{key}</div>
						))}
					</div>
					<div class={sumcls}>{summary}</div>
					<div class='ml-4'>{$img}</div>
				</div>
			)
		})
		let cls = 'template_picker'

		if (!this.isShowing || this.filteredTemplates.length === 0) cls += ' template_picker__hide'
		return (
			<div style='position: absolute; inset: 0; width: 100%;height: 100%; z-index: -9999'>
				<div
					ref='trigger'
					style='position: absolute; inset: 0; width: 100%; height: 100%; opacity: 0; z-index: -9999'
				/>
				<AppendToBody>
					<div class={cls} v-dblclickaway={this.hideWindow} v-clickaway={this.hideWindow} style={this.customStyle}>
						<div class='template_picker__header'>
							<div class='text__semibold'>{this.$t('message_template')}</div>
							<div class='ml-auto link' vOn:click={(_) => this.openManageModal()}>
								{this.$t('manage')}
							</div>
						</div>
						<div class='template_picker__templates'>{$templs}</div>
					</div>
				</AppendToBody>
			</div>
		)
	},
}
