import LexicalEditor from '../../commons/lexical-editor.js'
import EmailHeader from '../../commons/emailHeader.js'
import sb from '@sb/util'

export default {
	name: 'email-convo-editor',
	props: ['uid', 'cid', 'noTo', 'alwayFull', 'has_submit_btn', 'initMessage', 'placeholder'],
	data() {
		return {
			expandEmailHeader: false,
		}
	},

	mounted() {
		this.$refs.editor && this.$refs.editor.focus()
	},

	methods: {
		async onChange(msg) {
			//// handle subject and from field
			let $emheader = this.$refs.email_header
			let subject = sb.getMsgField({data: {message: msg}}, 'subject')
			let from = sb.getMsgField({data: {message: msg}}, 'from')
			let fields = $emheader ? $emheader.GetFields() : []
			msg = lo.cloneDeep(msg)
			msg.fields = fields
			if (subject) {
				let ev = {data: {message: msg}}
				sb.setMsgField(ev, 'subject', subject)
				msg = ev.data.message
			}
			if (from) {
				let ev = {data: {message: msg}}
				sb.setMsgField(ev, 'from', from)
				msg = ev.data.message
			}
			console.log('convo_email_editor changeeeeee', msg)
			this.$emit('change', msg)
			if (subject || from) {
				await this.$nextTick()
				this.$refs.email_header && this.$refs.email_header.fillDefault()
			}
		},

		GetMessage() {
			return this.getMessage()
		},

		onSubmitMsg(msg) {
			this.$emit('submit', this.getMessage())
			this.clearContent()
		},

		async clearContent() {
			this.initMessage = {text: '', format: 'html'}
			// use nextTick so lexicalEditor receive setInitialContent with exactly initMessage
			//await this.$nextTick()
			//this.$refs.editor && this.$refs.editor.setInitialContent()
		},

		getMessage() {
			let message = lo.cloneDeep(this.initMessage)
			let $emheader = this.$refs.email_header
			if ($emheader) {
				let fields = $emheader.GetFields()
				message.fields = fields
			} else {
				message.fields = lo.get(this.initMessage, 'fields', [])
			}

			return message
		},

		onExpandEmailHeader(mini) {
			this.expandEmailHeader = !mini
		},
	},

	render() {
		let $emheader = (
			<EmailHeader
				ref='email_header'
				style={this.no_email_header ? 'display: none' : ''}
				initEvent={this.initMessage}
				noTo={this.noTo}
				alwayFull={this.alwayFull}
				mini={!this.expandEmailHeader}
				vOn:miniChange={this.onExpandEmailHeader}
				vOn:changed={() => this.$emit('change', this.getMessage())}
				uid={this.uid}
				cid={this.cid}
			/>
		)
		return (
			<div class=''>
				<LexicalEditor
					html
					ref='editor'
					initMessage={this.initMessage}
					useTemplate
					uid={this.uid}
					cid={this.cid}
					template_connector_type='email'
					transform_dynamic_field
					vOn:change={this.onChange}
					placeholder={this.placeholder || this.$t('placeholder_editor')}
					has_submit_btn={this.has_submit_btn}
					vOn:submit={this.onSubmitMsg}
					no_preview_mode
				>
					{$emheader}
				</LexicalEditor>
			</div>
		)
	},
}
