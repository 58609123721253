import FormAttachment from './formAttachment.js'

export default {
	name: 'form-submit-attachment',
	props: ['ev'],

	render() {
		let submits = []
		let attachments = lo.get(this.ev, 'data.message.attachments') || []
		lo.each(attachments, (att) => {
			if (!att) return
			if (att.type !== 'form_submit') return
			if (!att.form_submit) return
			submits.push(att.form_submit)
		})

		if (submits.length === 0) return null

		let $forms = lo.map(submits, (form, index) => {
			return <FormAttachment key={index} form={form} ev={this.ev} />
		})

		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		return <div class={`message_container message_container__${byType}`}>{$forms}</div>
	},
}
