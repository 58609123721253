import sb from '@sb/util'

import AppendToBody from './append_to_body.js'

export default {
	name: 'suggestion-input',
	props: ['defaultValue', 'suggestions', 'clearable', 'placeholder', 'extra_cls'],

	data() {
		return {
			isDropdownOpened: false,
			softSelected: '',
			value: '',
		}
	},

	mounted() {
		this.value = this.defaultValue || ''
	},

	methods: {
		// PUBLIC METHOD
		focus() {
			this.$refs.input.focus()
		},

		blur() {
			this.$refs.input.blur()
		},
		// PUBLIC METHOD ENDDD

		onKeydown(e) {
			if (!this.isDropdownOpened) return
			switch (e.keyCode) {
				case 38: {
					// UP
					let items = this.getFilteredItems()
					let idx = lo.findIndex(items, (item) => item === this.softSelected)
					idx--
					if (idx < 0) idx = lo.size(items) - 1
					this.softSelected = items[idx]

					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-id="${this.softSelected}"]`)
					if ($element) $element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
				case 40: {
					// DOWN
					let items = this.getFilteredItems()
					let idx = lo.findIndex(items, (item) => item === this.softSelected)
					idx++
					if (idx >= lo.size(items)) idx = 0
					this.softSelected = items[idx]

					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-id="${this.softSelected}"]`)
					if ($element) $element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
			}
		},

		onKeyup(e) {
			if (!this.isDropdownOpened) return
			switch (e.keyCode) {
				case 27: // ESC
					this.closeDropdown()
					e.preventDefault()
					e.stopPropagation()
					break
				case 13: // ENTER
					var item = lo.find(this.suggestions, (item) => item === this.softSelected)
					this.onSelectItem(item)
					break
			}
		},

		renderDropdown() {
			let $wrapper = this.$refs.container
			let rect = $wrapper ? $wrapper.getBoundingClientRect() : {}
			let {top = 0, left = 0, width = 0, height = 0, right = 0} = rect

			let style = `position: fixed; z-index: 9999; top: ${
				top + height
			}px; left: ${left}px; width: ${width}px; min-width: ${width}px; max-width: ${width}px;`
			if (!this.isDropdownOpened) style += 'display: none;'

			return (
				<AppendToBody>
					<div class='dropdown d-flex flex-column' style={style} ref='dropdown'>
						<div class='dropdown_item_cotainer'>{this.renderItems()}</div>
					</div>
				</AppendToBody>
			)
		},

		renderItems() {
			let items = this.getFilteredItems()

			return lo.map(items, (item) => {
				let cls = 'dropdown_item'
				if (this.softSelected === item) cls += ' active'
				if (this.extra_item_cls) cls += ` ${this.extra_item_cls}`
				return (
					<div data-id={item} class={cls} vOn:click={() => this.onSelectItem(item)}>
						{item}
					</div>
				)
			})
		},

		onSelectItem(item) {
			this.value = item
			this.$emit('input', this.value)
			this.$emit('select', item)
			this.closeDropdown()
		},

		getFilteredItems() {
			let suggestions = lo.uniq(this.suggestions)
			suggestions = lo.filter(suggestions, (sug) => {
				sug = sb.unicodeToAscii(sug || '').toLowerCase()
				let keyword = sb.unicodeToAscii(this.value).toLowerCase()
				return sug.indexOf(keyword) > -1
			})

			return suggestions
		},

		onInput(e) {
			this.value = e.target.value
			this.$emit('input', this.value)
			this.openDropdown()
		},

		onBlur() {
			this.closeDropdown()
		},

		onFocus() {
			this.openDropdown()
		},

		openDropdown() {
			let items = this.getFilteredItems()
			if (this.value && lo.size(items)) {
				this.softSelected = lo.get(items, '0', '')
				this.isDropdownOpened = true
			}
		},

		closeDropdown() {
			this.isDropdownOpened = false
		},
	},

	render() {
		let cls = 'form-control'
		if (this.extra_cls) cls += ` ${this.extra_cls}`
		return (
			<div style='position: relative' ref='container'>
				<input
					placeholder={this.placeholder}
					class={cls}
					value={this.value}
					vOn:input={this.onInput}
					vOn:blur={this.onBlur}
					vOn:focus={this.onFocus}
					vOn:keyup={this.onKeyup}
					vOn:keydown={this.onKeydown}
					ref='input'
				/>
				{this.renderDropdown()}
			</div>
		)
	},
}
