import {TextNode} from 'lexical'
import {dispatchClickDynamicField} from './lexical-dynamic-field-click-handler.js'

function convertDynamicFieldElement(domNode) {
	const textContent = domNode.textContent
	let attrKey = domNode.getAttribute('data-dynamic-field')

	if (textContent !== null) {
		const node = $createDynamicFieldNode(textContent, attrKey)
		return {
			node,
		}
	}

	return null
}

export class DynamicFieldNode extends TextNode {
	__attrKey

	static getType() {
		return 'dynamicField'
	}

	static clone(node) {
		return new DynamicFieldNode(node.__attrKey, node.__text, node.__key)
	}

	constructor(attrKey, text, key) {
		super(text, key)
		this.__attrKey = attrKey
	}

	setAttrKey(attrKey) {
		// getWritable() creates a clone of the node
		// if needed, to ensure we don't try and mutate
		// a stale version of this node.
		const self = this.getWritable()
		self.__attrKey = attrKey
	}

	getAttrKey(attrKey) {
		// getLatest() ensures we are getting the most
		// up-to-date value from the EditorState.
		const self = this.getLatest()
		return self.__attrKey
	}

	createDOM(config) {
		const dom = super.createDOM(config)
		dom.className = 'sbz-dynamic-field'
		dom.setAttribute('data-dynamic-field', this.__attrKey)
		return dom
	}

	updateDOM(prevNode, dom, config) {
		const isUpdated = super.updateDOM(prevNode, dom, config)
		if (prevNode.__attrKey !== this.__attrKey) {
			dom.setAttribute('data-dynamic-field', this.__attrKey)
		}
		return isUpdated
	}

	static importDOM() {
		return {
			span: (domNode) => {
				if (!domNode.hasAttribute('data-dynamic-field')) {
					return null
				}
				return {
					conversion: convertDynamicFieldElement,
					priority: 1,
				}
			},
		}
	}

	// note: custom node with getType() is not 'text' must implement custom exortJSON so Copy texteditor must work
	exportJSON() {
		return {
			...super.exportJSON(),
			type: 'dynamicField',
			//attrKey: this.getAttrKey(),
			//text: this.getTextContent(),
		}
	}

	importJSON(serializedNode) {
		const node = $createDynamicFieldNode(serializedNode.text, serializedNode.attrKey)
		return node
	}
}

export function $isDynamicFieldNode(node) {
	return node instanceof DynamicFieldNode
}

export function $createDynamicFieldNode(text, attrKey) {
	return new DynamicFieldNode(attrKey, text).setMode('token')
}
