import Buttons from './buttons.js'
const sb = require('@sb/util')
var Drag = require('./dragscroll.js')

export default {
	name: 'generic-template-attachments',

	props: ['ev'],

	watch: {
		ev(ev) {
			this.$nextTick(() => this.bindDrag())
		},
	},
	mounted() {
		this.bindDrag()
	},

	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'

		// filter out all generic
		let attachments = lo.get(this.ev, 'data.message.attachments', [])
		let generics = lo.filter(attachments, (att) => {
			return lo.get(att, 'type') === 'generic' || lo.get(att, 'type') === 'fallback'
		})

		if (generics.length === 0) return null
		var $generics = lo.map(generics, (gen, index) => {
			var $eles = lo.map(gen.elements, (ele) => {
				let title = this.i18n(ele.i18n_title) || ele.title
				let subtitle = this.i18n(ele.i18n_subtitle) || ele.subtitle
				let $title = title ? <div class='generic-attachment--element-title'>{title}</div> : null
				let isDefaultActionUrl =
					ele.default_action && lo.get(ele, 'default_action.type') === 'web_url' && lo.get(ele, 'default_action.url')
				if (title && isDefaultActionUrl) {
					$title = (
						<a
							class='generic-attachment--element-title'
							href={lo.get(ele, 'default_action.url')}
							target='_blank'
							style='display: block'
						>
							{title}
						</a>
					)
				}
				let $subtitle = subtitle ? <div class='generic-attachment--element-subtitle'>{subtitle}</div> : null

				return (
					<div class='generic-attachment--element'>
						<div class='no-shrink'>
							<img class='generic-attachment--element-img' src={sb.replaceFileUrl(ele.image_url)} />
						</div>
						<div class='flex__1'>
							{$title}
							{$subtitle}
						</div>
						<Buttons buttons={ele.buttons} />
					</div>
				)
			})

			return (
				<div class={'generic-attachment-container generic-attachment-container__' + byType}>
					<div ref={'template_' + index} class='generic-attachment'>
						{$eles}
					</div>
				</div>
			)
		})

		return $generics
	},

	methods: {
		i18n(t) {
			if (!t) return ''
			let fallbackLocale = lo.get(store.me(), 'account.locale', 'vi-VN')
			fallbackLocale = fallbackLocale.replace('-', '_')
			return t[fallbackLocale]
		},

		bindDrag() {
			lo.map(this.$refs, (ref, k) => {
				if (!k.startsWith('template_')) return
				Drag(ref, () => {})
			})
		},
	},
}
