const sb = require('@sb/util')
var api = require('./api.js')
const flow = require('@subiz/flow')
import InMemKV from './inmem_kv.js'
import NewObjectStore from './object_store.js'
const config = require('@sb/config')
import common from './common.js'

// parent {api, realtime, insync, pubsub}
function TicketStore(realtime, pubsub) {
	let kv = new InMemKV()
	kv.init()

	let currentFilter = {}

	let me = {}
	realtime.onEvent(async (ev) => {})

	realtime.subscribe([
		'ticket_history_updated',
		'ticket_created',
		'ticket_updated',
		'ticket_rated',
		'ticket_deleted',
		'ticket_recovered',
	])

	realtime.onEvent(async (ev) => {
		if (!ev || !ev.type) return
		let ticket = {}
		switch (ev.type) {
			case 'ticket_history_updated':
				let ticketid = lo.get(ev, 'data.ticket_history_entry.ticket_id', '')
				if (!ticketid) return
				let hist = kv.match('ticket_history_' + ticketid)
				if (!hist) return
				let entry = lo.get(ev, 'data.ticket_history_entry')
				if (!lo.get(entry, 'event')) return
				let newEv = lo.get(entry, 'event') || {}
				if (!newEv.type) return // somehow BE response with empty event
				let idempotency = lo.get(newEv, 'data.message.idempotency_key')

				let offlineEvIdx = -1
				if (idempotency) {
					offlineEvIdx = lo.findIndex(hist, (ev) => lo.get(ev, 'data.message.idempotency_key') === idempotency)
				}
				if (offlineEvIdx > -1) {
					hist[offlineEvIdx] = newEv
				} else {
					hist.push(newEv)
				}
				kv.put('ticket_history_' + ticketid, hist)
				// todo becareful for updating comment (which is not supported yet)
				pubsub.publish('ticket_history', entry)
				break
			case 'ticket_created':
			case 'ticket_recovered':
			case 'ticket_deleted':
			case 'ticket_updated':
				ticket = lo.get(ev, 'data.ticket')
				if (!ticket) return
				db.put(ticket.id, ticket)
				// publish is unnecessary, NewObjectStore already do this logic
				//pubsub.publish('ticket', {[ticket.id]: ticket})
				return
			case 'ticket_rated':
				ticket = lo.get(ev, 'data.ticket') || {}
				pubsub.publish('ticket_rated', ticket)
				return
		}
	})

	me.onTicketHistory = (o, cb) => pubsub.on2(o, 'ticket_history', cb)
	me.onTicket = (o, cb) => pubsub.on2(o, 'ticket', cb)
	me.onTicketCreated = (o, cb) => pubsub.on2(o, 'ticket_created', cb)

	me.onViewChanged = (o, cb) => pubsub.on2(o, 'view_changed', cb)
	me.pushViewChanged = (p) => pubsub.publish('view_changed', p)

	let account_id = api.getAccountId()
	let db = NewObjectStore(
		account_id,
		realtime,
		pubsub,
		'ticket',
		async (tickets) => {
			let ids = lo.map(tickets, 'id')
			let last_modifieds = lo.map(tickets, (ticket) => ticket.updated || 0)
			let {body, code, error} = await api.matchTickets(ids, last_modifieds)
			if (error || code !== 200) return {error}
			let out = lo.get(body, 'tickets', [])

			let os = []
			lo.map(out, (ticket) => {
				let i = lo.findIndex(ids, (id) => ticket.id == id)
				if (i == -1) return
				if (lo.size(ticket) < 2) delete ticket.id // no updates, must delete id to tell object store
				os[i] = ticket
			})

			return {data: os}
		},
		[],
		true,
	)

	db.onchange = (ticketM) => {
		lo.map(ticketM, (ticket) => {
			me.number2idM[ticket.number] = ticket.id
			me.id2numberM[ticket.id] = ticket.number
		})
	}

	me.saveCurrentFilter = (view) => {
		currentFilter = lo.cloneDeep(view)
		currentFilter._last_updated = Date.now()
	}
	me.matchCurrentFilter = () => currentFilter

	me.updateTicket = async (ticket, fields) => {
		let description = ticket.description
		if (description) {
			let {message: newMessage, error} = await common.replaceMessageBase64Images(ticket.description)
			if (error) return {error: 'invalid_attachment_url'}
			ticket.description = newMessage
		}
		var {error, body: ticket} = await api.update_ticket(ticket, fields)
		if (error) return {error}
		db.put(ticket.id, ticket)
		return ticket
	}

	me.updateTicketStore = (ticket) => {
		db.put(ticket.id, ticket)
	}

	me.updateLastSelectedTicket = (ticketid) => kv.put('last_selected_ticket_id', ticketid)
	me.getLastSelectedTicket = () => kv.match('last_selected_ticket_id')

	me.setLastTicketViewId = (view_id) => window.myLocalStorage.setItem('last_ticket_view_id', view_id)
	me.getLastTicketViewId = () => window.myLocalStorage.getItem('last_ticket_view_id')
	
	me.createSubizTicket = async (ticket) => {
		var {error, body: ticket} = await api.create_subiz_ticket(ticket)
		if (error) return {error}
		return ticket
	}

	me.createTicket = async (ticket) => {
		let description = ticket.description
		if (description) {
			let {message: newMessage, error} = await common.replaceMessageBase64Images(ticket.description)
			if (error) return {error: 'invalid_attachment_url'}
			ticket.description = newMessage
		}
		var {error, body: ticket} = await api.create_ticket(ticket)
		if (error) return {error}
		db.put(ticket.id, ticket)

		pubsub.publish('ticket_created', ticket)
		return ticket
	}

	me.deleteTicket = async (id) => {
		var {error, body: ticket} = await api.delete_ticket(id)
		if (error) return {error}
		//db.del(ticket.id)
		return ticket
	}

	me.restoreTicket = async (id) => {
		var {error, body: ticket} = await api.restore_ticket(id)
		if (error) return {error}
		return ticket
	}

	me.matchTicket = (id) => {
		if (!id || id === '-') return undefined
		return db.match(id)
	}

	me.id2numberM = {}
	me.number2idM = {}

	me.matchTicketFromIdOrNumber = (idorNumber) => {
		let ticket = me.matchTicket(idorNumber)
		if (ticket) return ticket

		let id = me.number2idM[idorNumber]
		return me.matchTicket(id)
	}

	me.fetchTicket = async (num) => {
		let id = me.number2idM[num]
		if (id) {
			await me.fetchTickets([id])
			return db.match(id)
		}
		let res = await api.get_ticket(num)
		if (res.error) return res
		let ticket = lo.get(res, 'body') || {}
		if (ticket.id) db.put(ticket.id, ticket)
		return ticket
	}

	me.fetchTickets = (ids, force) => {
		let correctids = lo.map(ids, (id) => {
			return me.number2idM[id] || id
		})
		return db.fetch(correctids, force)
	}

	me.matchListTicket = (query) => {
		let querystr = JSON.stringify(
			Object.assign(
				{},
				{id: query.id, next_cursor: query.next_cursor, condition: query.condition, order_by: query.order_by},
			),
		)
		return me.ticket_cache[querystr] || {body: undefined}
	}

	me._isTicketStoreDestroy = false
	me.destroy = () => {
		me._isTicketStoreDestroy = true
		kv.destroy()
		clearTimeout(me._cleanTicketCacheTimeout)
	}

	me.isListingTicket = {}
	me.lastListingTicket = {}
	me.ticket_cache = {}
	me._cleanTicketCacheTimeout = setTimeout(async () => {
		while (!me._isTicketStoreDestroy) {
			await sb.sleep(10000)
			let newCache = {}
			lo.map(me.lastListingTicket, (ms, k) => {
				if (Date.now() - ms < 120000) {
					// keep
					newCache[k] = me.ticket_cache[k]
				}
			})
			me.ticket_cache = newCache
		}
	}, 1000)

	me.listTickets = async (query, force) => {
		let querystr = JSON.stringify(
			Object.assign(
				{},
				{id: query.id, next_cursor: query.next_cursor, condition: query.condition, order_by: query.order_by},
			),
		)
		if (!force) {
			// use cache the result for 30 sec
			if (Date.now() - (me.lastListingTicket[querystr] || 0) < 2000) return me.ticket_cache[querystr]
			if (me.isListingTicket[querystr]) {
				while (me.isListingTicket[querystr]) await sb.sleep(100)
				let useCache = me.ticket_cache[querystr]
				return useCache
			}
		}

		me.isListingTicket[querystr] = true
		let out = await api.list_tickets(query)
		lo.map(lo.get(out, 'body.tickets', []), (ticket) => {
			db.put(ticket.id, ticket)
		})
		// should we publish onTicket event to all ?
		//pubsub.publish('ticket')
		me.isListingTicket[querystr] = false
		if (!query.query) {
			me.lastListingTicket[querystr] = Date.now()
			// only save without query
			me.ticket_cache[querystr] = out
		}
		return out
	}

	me.listTicketIds = async (query = {}) => {
		return await api.list_tickets({...query, shorten: true})
	}

	me.createTicketEvent = api.create_ticket_event
	me.rateTicket = api.rate_ticket
	me.matchTicketEvents = (ticketid) => {
		return kv.match('ticket_history_' + ticketid) || []
	}
	me.commentTicket = async (ticketid, message) => {
		let {agent_id} = api.getCred() || {}
		let idempotency = sb.randomString(10)
		message.idempotency_key = idempotency
		let ev = {
			type: 'ticket_comment_added',
			by: {
				type: 'agent',
				id: agent_id,
			},
			data: {
				message,
			},
			created: Date.now(),
			_sending: true,
			ticket_id: ticketid,
		}
		let hist = kv.match('ticket_history_' + ticketid) || []
		hist.push(ev)
		kv.put('ticket_history_' + ticketid, hist)
		pubsub.publish('ticket_history', ev)
		let res = await store.createTicketEvent(ticketid, ev)
		if (res.error) {
			ev._sending = false
			ev._error = true
			pubsub.publish('ticket_history', ev)
		}
	}
	me.editTicketComment = async (ticketid, evid, message) => {
		let {agent_id} = api.getCred() || {}
		let ev = {
			id: evid,
			type: 'ticket_comment_updated',
			data: {
				message,
			},
		}

		let res = await store.createTicketEvent(ticketid, ev)
		if (res.error) return res

		let newev = lo.get(res, 'body') || {}
		let hist = kv.match('ticket_history_' + ticketid) || []
		let currentEvIdx = lo.findIndex(hist, (ev) => ev.id === newev.id)
		if (currentEvIdx > -1) {
			lo.set(hist, [currentEvIdx], newev)
			kv.put('ticket_history_' + ticketid, hist)
			pubsub.publish('ticket_history', ev)
		}
		return res
	}
	me.updateTicketMember = api.update_ticket_member
	me.removeTicketMember = api.delete_ticket_member
	me.subscribeTicket = api.subscribe_ticket
	me.unsubscribeTicket = api.unsubscribe_ticket
	me.listTicketSLAViolations = api.list_ticket_sla_violations

	me.fetchTicketEvents = async (ticketid) => {
		let out = await api.list_ticket_events(ticketid)
		if (out.error) return {error: out.error}
		let hist = lo.get(out, 'body.events') || []
		kv.put('ticket_history_' + ticketid, hist)
		return hist
	}

	me.readTickets = (ids) => {
		let {agent_id} = api.getCred() || {}
		// try to read ticket in store ticket
		lo.each(ids, (id) => {
			let storeticket = db.match(id)
			if (!storeticket) return // continue

			let members = lo.get(storeticket, 'read_receipts') || []
			let member = lo.find(members, (mem) => mem.id === agent_id)
			if (!member) return // continue loop
			member.read_at = Date.now()
			storeticket.read_receipts = members
			db.put(id, storeticket)
		})
		api.read_tickets(ids)
	}
	me.reportTicket = api.report_ticket

	// try to cache report result about 5 seconds to avoid unnecessary request
	let reportRatingCache = {}
	let reportRatingLock = {}
	const REPORT_RATING_CACHE_DURATION = 5_000

	const reportTicketRating = async (p) => {
		let queryJSON = JSON.stringify(p)
		if (reportRatingLock[queryJSON]) {
			await sb.sleep(10)
			return await reportTicketRating(p)
		}

		reportRatingLock[queryJSON] = true
		if (
			reportRatingCache[queryJSON] &&
			Date.now() - reportRatingCache[queryJSON].timestamp < REPORT_RATING_CACHE_DURATION
		) {
			reportRatingLock[queryJSON] = false
			return reportRatingCache[queryJSON].res
		}
		let res = await api.report_ticket_rating(p)
		reportRatingLock[queryJSON] = false
		if (res.error) return res
		reportRatingCache[queryJSON] = {res: res, timestamp: Date.now()}
		return res
	}
	me.reportTicketRating = reportTicketRating
	me.reportSLAViolation = api.report_sla_violations
	me.reportTicketRatingList = api.report_ticket_rating_list
	me.reportSLAViolationList = api.report_sla_violation_list

	return me
}

export default TicketStore
