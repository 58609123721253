const loadEmojis = () => import(/*webpackChunkName: 'twemojis'*/ '../commons/emojis.json')

let emojis = [
	'like',
	'unlike',
	'wink',
	'tongue-out',
	'tired',
	'surprised',
	'smiling',
	'sleepy',
	'sad',
	'neutral',
	'heart-eyes',
	'grinning',
	'crying',
	'confused',
	'angry',
	'cry',
	'heart_eyes',
	'neutral_face',
	'emoji--disappointed',
	'smile',
	'open_mouth',
	'tired_face',
	'stuck_out_tongue',
	'moyai',
	'thumbsdown',
	'dislike',
	'thumbsup',
	'ok_hand',
	'octopus',
	'pig2',
	'wave',
	'mouse',
	'mouse2',
	'rat',
]
// please dont change value of emojis variable, it was exported and use for some logic to detect custom emoji
emojis = emojis.map((e) => ':' + e + ':')
var twemojisCheck = {}

const EMOJIS_MAP = {}
lo.each(emojis, (code) => {
	EMOJIS_MAP[code] = code
})

async function addTwenmoji() {
	let twemojis = await loadEmojis()
	lo.each(twemojis, (emoji) => {
		if (!emoji || !emoji.code) return // could be default key in react native
		twemojisCheck[emoji.code.substring(0, 1)] = true
		twemojisCheck[emoji.code.substring(0, 2)] = true
		twemojisCheck[emoji.code.substring(0, 3)] = true
		twemojisCheck[emoji.code.substring(0, 4)] = true
		twemojisCheck[emoji.code.substring(0, 5)] = true

		EMOJIS_MAP[emoji.code] = emoji.code
	})
}

setTimeout(addTwenmoji, 0)

function extractEmojis(text) {
	const MIN_EMOJI_LENGTH = 5
	const MAX_EMOJI_LENGTH = 22
	var out = []
	for (let i = 0; i < text.length; i++) {
		// old emoji format startsWith : and have at least 3 charaters and max is 22
		if (text[i] === ':') {
			for (let j = MIN_EMOJI_LENGTH; j < MAX_EMOJI_LENGTH; j++) {
				let chr = text.substring(i, i + j)
				let emojiCode = EMOJIS_MAP[chr]
				if (EMOJIS_MAP[chr]) {
					out.push({index: i, length: emojiCode.length})
					i += emojiCode.length - 1
					break
				}
			}
		} else {
			const UNICODE_EMOJI_MAX_LENGTH = 6
			// because this emoji 👩‍💻 has length is 5, the longest
			let chr = ''
			for (let j = 1; j < 6; j++) {
				chr += text[i + j - 1]
				if (!twemojisCheck[chr]) break

				let emojiCode = EMOJIS_MAP[chr]
				if (EMOJIS_MAP[chr]) {
					out.push({index: i, length: emojiCode.length})
					i += emojiCode.length - 1
					break
				}
			}
		}
	}

	return out
}

function extractEmails(text) {
	if (!text) return []
	if (text.indexOf('@') == -1) return []
	var reg =
		/(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gim

	var out = []
	var match
	while ((match = reg.exec(text))) {
		out.push({index: match.index, length: reg.lastIndex - match.index})
	}
	return out
}

function extractLinks(text) {
	if (!text) return []
	if (text.indexOf('.') == -1) return []

	var reg =
		/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gim
	var out = []
	var match
	while ((match = reg.exec(text))) {
		out.push({index: match.index, length: reg.lastIndex - match.index})
	}

	return out
}

function extractPhones(msg) {
	if (!msg) return []
	// must have atleast 5 number
	let nNumber = 0
	for (var i = 0; i < msg.length; i++) {
		var ch = msg.charAt(i)
		if (ch >= '0' && ch <= '9') nNumber++
	}
	if (nNumber < 5) return []

	var fgmsg = ''
	// fine-grained msg
	// remove '-' in number-number
	// remove ' ' in number number
	// remove '.' in number.number

	var realchmap = {}
	for (var i = 0; i < msg.length; i++) {
		var ch = msg.charAt(i)

		if (ch !== '.' && ch !== '-' && ch !== ' ') {
			fgmsg += ch
			realchmap[fgmsg.length - 1] = i
			continue
		}

		var prevCh = i > 0 ? msg.charAt(i - 1) : '-'
		var nextCh = i < msg.length - 1 ? msg.charAt(i + 1) : '-'

		if ('0123456789'.indexOf(prevCh) !== -1 && '0123456789'.indexOf(nextCh) !== -1) continue
		fgmsg += ch
		realchmap[fgmsg.length - 1] = i
	}

	var reg = /([0-9]{8,13})/gim

	var out = []
	while ((match = reg.exec(fgmsg))) {
		var prevChar = match.index > 0 ? fgmsg.charAt(match.index - 1) : ''
		const validChars = ['', ' ', '+']
		// work around for nbsp; charcode (nbsp; charcode is 160)
		if (validChars.includes(prevChar) || prevChar.charCodeAt(0) === 160) {
			out.push({index: realchmap[match.index], length: realchmap[reg.lastIndex - 1] - realchmap[match.index] + 1})
		}
	}

	return out
}

function extractHostname(text) {
	var reg = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim
	return reg.exec(text)
}

function extract(text, extractor, type) {
	if (!text) return []
	var out = []
	var lastindex = 0
	var tags = extractor(text)
	if (lo.size(tags) === 0) return [{type: 'text', text}]
	for (let i = 0; i < tags.length; i++) {
		let tag = tags[i]
		let t = text.substr(lastindex, tag.index - lastindex)
		if (t) out.push({type: 'text', text: t})
		t = text.substr(tag.index, tag.length)
		if (t) out.push({type, text: t})
		lastindex = tag.index + tag.length
	}
	var lastTag = lo.last(tags)
	let t = text.substr(lastTag.index + lastTag.length, text.length - lastTag.index + lastTag.length)
	if (t) out.push({type: 'text', text: t})

	return out
}

function pipeExtracts(text, extractors, i) {
	var out = []
	var ext = extractors[i]
	if (lo.size(extractors) === 0 || !ext) return [{type: 'text', text}]
	var tags = extract(text, ext, ext.type)
	for (let j = 0; j < tags.length; j++) {
		let t = tags[j]
		if (t.type !== 'text') {
			out.push(t)
			continue
		}
		out = out.concat(pipeExtracts(t.text, extractors, i + 1))
	}
	return out
}

extractLinks.type = 'link'
extractEmails.type = 'email'
extractPhones.type = 'phone'
extractEmojis.type = 'emoji'
let extractArr = [extractEmails, extractLinks, extractPhones, extractEmojis]
function extractText(text) {
	return pipeExtracts(text, extractArr, 0)
}

function extractPhoneUA(ua) {
	ua = (ua || '').trim()
	if (ua.toLowerCase().startsWith('yealink')) {
		// Yealink SIP - T28P 2.73.208.87
		// VP59,T58A with Camera,SIP-T58A,SIP-T57W,SIP-T54W,SIP-T53W,SIP-T53,SIP-T48S,SIP-T46S,SIP-T42S,SIP-T41S,SIP-T40G,SIP-T40P,SIP-T29G,SIP-T27G,SIP-T23G,SIP-T23P,SIP-T21(P) E2,SIP-T19(P) E2,SIP-T33P(G),SIP-T31(P)(G),SIP-T30(P),SIP-T28P,CP860,SIP-T26P,SIP-T22P,SIP-T21P,SIP-T20P,SIP-T19P,SIP VP-T49G,SIP-T48G,SIP-T46G,SIP-T42G,SIP-T27P ,SIP-T41P
		let model = (ua.split('-')[1] || '').trim()
		return {brand: 'yealink', model: model.split(' ')[0].trim()}
	}

	if (ua.toLowerCase().startsWith('grandstream')) {
		// Grandstream GXP1610 1.0.7.13 000b8283aebd
		let model = (ua.split(' ')[1] || '').trim()
		return {
			brand: 'grandstream',
			model: model,
		}
	}
	if (ua.toLowerCase().startsWith('aastra')) {
		// Aastra 480i/1.3.0.1080 Brcm Callctrl/1.5.1 MxSF/v3.2.6.2
		let model = (ua.split(' ')[1] || '').trim()
		return {
			brand: 'aastra',
			model: model.split('/')[0].trim(),
		}
	}

	if (ua.toLowerCase().startsWith('linphone')) {
		// linphone-android 3.2.4 CC3F6056809F; Samsung Galaxy S3
		let model = (ua.split('-')[1] || '').trim()
		return {
			brand: 'linphone',
			model: model.split(' ')[0].trim(),
		}
	}

	// microsip
	if (ua.toLowerCase().startsWith('microsip')) {
		// MicroSIP/3.6.3
		let model = (ua.split('/')[1] || '').trim()
		return {
			brand: 'microsip',
			model: model,
		}
	}

	// Telepathy
	if (ua.toLowerCase().startsWith('telepathy-rakia/')) {
		// Telepathy-Rakia/0.8.0 sofia-sip/1.12.11devel
		let model = (ua.split('/')[1] || '').trim()
		return {brand: 'telepathy', model: model}
	}

	// Jami
	if (ua.toLowerCase().startsWith('jami')) {
		// Jami Daemon 13.4.0 (linux)
		let model = (ua.split(' ')[2] || '').trim()
		return {brand: 'jami', model: model}
	}

	// zoiper
	if (ua.toLowerCase().startsWith('z ') || ua.toLowerCase().startsWith('zoiper ')) {
		// Z 5.5.12 v2.10.18.2
		// Zoiper rd82a609
		let model = (ua.split(' ')[1] || '').trim()
		return {
			brand: 'zoiper',
			model: model,
		}
	}

	// Panasonic_{MODEL}/{fwver} ({mac})
	if (ua.toLowerCase().startsWith('panasonic')) {
		// Panasonic-MPR12-V004.22016/VSIPGW-V3.0000
		let model = (ua.split('_')[1] || '').trim()
		return {
			brand: 'panasonic',
			model: model.split('/')[0].trim(),
		}
	}

	return {brand: 'unknown'}
}

const CUSTOM_EMOJI_CODES = emojis
module.exports = {
	extract,
	extractText,
	extractEmails,
	extractPhones,
	extractHostname,
	CUSTOM_EMOJI_CODES,
	extractPhoneUA,
}
