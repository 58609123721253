import Buttons from './buttons.js'

export default {
	props: ['ev'],

	render() {
		// dont support user sent button yet
		let byType = lo.get(this.ev, 'by.type')
		if (byType === 'user') return null
		byType = 'agent'

		// filter out all button
		let buttons = []
		let attachments = lo.get(this.ev, 'data.message.attachments', [])
		lo.each(attachments, (att, i) => {
			if (!att || lo.get(att, 'type') !== 'button') return
			lo.each(lo.get(att, 'buttons') || [], (btn) => buttons.push(btn))
		})

		return (
			<div class={`message_container message_container__${byType}`}>
				<Buttons byType={byType} buttons={buttons} />
			</div>
		)
	},
}
