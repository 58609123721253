import accounting from 'accounting'

// This component use to display number in multi format
// Example use case:
//
// 1. Default (mostly used)
// 1960000 => 1,960,000
// 8.234311 => 8.23
//
// 2. Short
// 1720000000 => 1.7b | 1.7ty
// 1960000 => 1.9m | 1.9tr
// 2290 => 2.2k
export default {
	props: ['number', 'short'],
	methods: {
		displayShortFormat(num) {
			let abs = Math.abs(num)
			let operator = num > 0 ? '' : '-'

			if (abs >= 1e9) {
				let result = (abs / 1e9).toFixed(1)
				if (parseFloat(result) === Math.floor(result)) {
					result = `${operator}${Math.floor(result)}${this.$t('billion_short_format')}`
				} else {
					result = `${operator}${result}${this.$t('billion_short_format')}`
				}
				return result
			}

			if (abs >= 1e6) {
				let result = (abs / 1e6).toFixed(1)
				if (parseFloat(result) === Math.floor(result)) {
					result = `${operator}${Math.floor(result)}${this.$t('milion_short_format')}`
				} else {
					result = `${operator}${result}${this.$t('milion_short_format')}`
				}
				return result
			}

			if (abs >= 1e3) {
				let result = (abs / 1e3).toFixed(1)
				if (parseFloat(result) === Math.floor(result)) {
					result = `${operator}${Math.floor(result)}${this.$t('thounsand_short_format')}`
				} else {
					result = `${operator}${result}${this.$t('thounsand_short_format')}`
				}
				return result
			}

			return num
		},
	},

	render() {
		let num = Number(this.number)
		if (this.short) return <div>{this.displayShortFormat(num)}</div>

		return <div>{accounting.formatNumber(num)}</div>
	},
}
