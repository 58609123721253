import store from '@sb/store'

export default {
	mounted() {
		store.onAccount(this, () => this.$forceUpdate())
	},

	methods: {
		renderTab() {
			let routerName = lo.get(this.$route, 'name', '')
			let mgrcls = 'tab__item'
			if (routerName === 'accmgr') mgrcls += ' tab__item__active'

			let refcls = 'tab__item'
			if (routerName === 'refmgr') refcls += ' tab__item__active'
			let promocls = 'tab__item'
			if (routerName === 'promomgr' || routerName === 'promoetail') promocls += ' tab__item__active'

			return (
				<div class='tab'>
					<div vOn:click={(_) => this.$router.push({name: 'accmgr'})} class={mgrcls}>
						Account
					</div>
					<div vOn:click={(_) => this.$router.push({name: 'refmgr'})} class={refcls}>
						Referral
					</div>
					<div vOn:click={(_) => this.$router.push({name: 'promomgr'})} class={promocls}>
						Promotion
					</div>
				</div>
			)
		},
	},

	render() {
		return (
			<div class='accmgr'>
				<div class='accmgr_header'>{this.renderTab()}</div>
				<div style='flex: 1; overflow: auto'>
					<router-view />
				</div>
			</div>
		)
	},
}
