import store from '@sb/store'
var sb = require('@sb/util')
import {Time} from '@sb/com'
import Avatar from '@sb/com/avatar'
import Fragment from '@sb/com/fragment'

const LIMIT = 5

export default {
	name: 'user-tasks',
	props: ['user', 'refresh'],
	data() {
		return {
			loading: false,

			limit: LIMIT,
			showFull: false,
			markingDone: {},
		}
	},

	mounted() {
		if (this.user) this.loadUserTasks(this.user.id)
		store.onTask(this, (task) => {
			if (!task) return
			if (lo.get(this.user, 'id')) {
				if (lo.includes(task.associated_users, this.user.id)) {
					this.calculateLimit(this.user.id)
					this.$forceUpdate()
				}
			}
		})
	},

	watch: {
		user(user) {
			if (user) this.loadUserTasks(user.id)
		},
	},

	methods: {
		calculateLimit(uid) {
			let openTasks = lo.filter(store.matchUserTasks(uid), (task) => task.status !== 'done')
			if (lo.size(openTasks)) {
				this.limit = lo.size(openTasks) > LIMIT ? LIMIT : lo.size(openTasks)
			} else {
				this.limit = 1
			}
		},

		async loadUserTasks(uid) {
			if (!uid) return
			this.loading = true
			await store.fetchUserTasks(uid)
			this.loading = false
			this.calculateLimit(uid)
			this.$forceUpdate()
		},

		renderUserTasks(uid) {
			let tasks = lo.filter(store.matchUserTasks(uid), (task) => task.id)
			// trim the list
			if (!this.showFull && tasks.length > this.limit) tasks = lo.take(tasks, this.limit)
			//if (tasks) tasks = tasks.slice(0, LIMIT)
			return lo.map(tasks, (task) => this.renderTask(task))
		},

		renderDueAt(task) {
			if (!task.due_at) return null
			if (task.status === 'done') return null

			let now = new Date()
			let due = new Date(task.due_at)
			let isSameDate =
				now.getDate() === due.getDate() && now.getMonth() === due.getMonth() && now.getFullYear() === due.getFullYear()

			if (now < task.due_at && isSameDate) {
				return (
					<div class='task_due_status_badge due_today mt-1'>
						<Icon name='calendar' size='14' class='mr-2' stroke-width='2' />
						{this.$t('due_today')}
					</div>
				)
			}

			if (now > task.due_at) {
				return (
					<div class='task_due_status_badge overdue mt-1'>
						<Icon name='calendar' size='14' class='mr-2' stroke-width='2' />
						{this.$t('overdue')}
						<Time duration time={(now - task.due_at) / 1000} style='margin-left: 3px' />
					</div>
				)
			}
		},

		renderHandle(tasks) {
			let more = tasks.length - this.limit
			if (more <= 0) return null

			return (
				<div class='profile_section__more' vOn:click={this.toogleShowFull}>
					{this.showFull ? this.$t('collapse') : this.$t('show_more_tasks', [more])}
				</div>
			)
		},

		toogleShowFull() {
			this.showFull = !this.showFull
		},

		renderTask(task) {
			task = store.matchTask(task.id) || task
			let $status = <div class='task_checkbox' vOn:click_stop={() => this.markDone(task.id)}></div>
			if (task.status == 'done')
				$status = (
					<div class='task_checkbox task_checkbox__done' vOn:click_stop={() => this.markUndone(task.id)}>
						<Icon name='check' size='14' stroke-width='3.5' />
					</div>
				)

			let title_cls = ''
			if (task.status == 'done') title_cls += ' text__muted'

			return (
				<div class='user_task_row'>
					<div class='d-flex flex__1'>
						<div class='no-shrink' style='margin-right: 12px'>
							{$status}
						</div>
						<div class='clickable' vOn:click={() => this.$root.$emit('open_user_task_modal', {id: task.id})}>
							<div class={title_cls}>
								{lo.trim(task.title) ? lo.trim(task.title) : <i class='text__muted'>{this.$t('untitled')}</i>}
							</div>
							<div class='text__muted text__truncate mr-2' style='width: 100%; font-size: 13px '>
								{task.number && <Fragment>{`#${Number(task.number)} `}</Fragment>}
								{this.$t('created_at').toLowerCase()} <Time ago time={task.created} suffix /> {this.$t('by')}{' '}
								{sb.getAgentDisplayName(store.matchAgent(task.created_by))}
							</div>
							{this.renderDueAt(task)}
						</div>
					</div>
				</div>
			)
		},

		async markDone(tId) {
			this.markingDone[tId] = 'marking'
			this.$forceUpdate()
			let done = await store.markTaskDone(tId)
			delete this.markingDone[tId]
			this.$forceUpdate()
		},

		async markUndone(tId) {
			this.markingDone[tId] = 'marking'
			this.$forceUpdate()
			let done = await store.markTaskDone(tId, 'open')
			delete this.markingDone[tId]
			this.$forceUpdate()
		},
	},

	render() {
		if (this.refresh) lo.noop()
		if (!this.user) return null
		let uid = this.user.id
		let tasks = lo.filter(store.matchUserTasks(uid), (task) => task.id)

		let $loading = null
		if (lo.size(tasks) > 0)
			$loading = (
				<div class='text__muted ml-3' style='color: #adb5bd;'>
					{lo.size(tasks)}
				</div>
			)
		if (this.loading) $loading = <Spinner static class='ml-2 mb-1' mode='dark' />

		return (
			<div class='user_tasks'>
				<div class='user_profile__head'>
					<div class='profile_section_title' style='display: flex; flex-direction: row;'>
						{this.$t('tasks_remind')} {$loading}
					</div>
					<div style='flex: 1'></div>
					<div
						class='link link__primary d-flex align-items-center'
						vOn:click={(_) =>
							this.$root.$emit('open_user_task_modal', {
								id: '',
								assignee: store.me().id,
								status: 'open',
								due_at: Date.now() + 24 * 3600_000,
								type: 'todo',
								associated_users: [this.user.id],
							})
						}
					>
						{this.$t('create')}&nbsp;
						<Icon name='file-plus' size='18' stroke-width='1.5' />
					</div>
				</div>
				{lo.size(tasks) > 0 && (
					<div class='user_tasks__body mt-3'>
						{this.renderUserTasks(this.user.id)}
						{this.renderHandle(tasks)}
					</div>
				)}
			</div>
		)
	},
}
