const sb = require('@sb/util')
const lang = require('../languages')
let i18n = lang.i18n
var langmap = require('@subiz/langmap')

let sampleAvatars = [
	// bot avatar
	require('../assets/img/bot/bot-1.svg'),
	require('../assets/img/bot/bot-2.svg'),
	require('../assets/img/bot/bot-3.svg'),
	require('../assets/img/bot/bot-4.svg'),
	require('../assets/img/bot/bot-5.svg'),
	require('../assets/img/bot/bot-6.svg'),
	require('../assets/img/bot/bot-7.svg'),
	require('../assets/img/bot/bot-8.svg'),
	require('../assets/img/bot/bot-9.svg'),
	require('../assets/img/bot/bot-10.svg'),
	require('../assets/img/bot/bot-11.svg'),
	require('../assets/img/bot/bot-12.svg'),
	require('../assets/img/bot/bot-13.svg'),
	require('../assets/img/bot/bot-14.svg'),
	require('../assets/img/bot/bot-15.svg'),
	require('../assets/img/bot/bot-16.svg'),
	require('../assets/img/bot/bot-17.svg'),
	// human avatar
	require('../assets/img/bot/human-1.svg'),
	require('../assets/img/bot/human-2.svg'),
	require('../assets/img/bot/human-3.svg'),
	require('../assets/img/bot/human-4.svg'),
	require('../assets/img/bot/human-5.svg'),
	require('../assets/img/bot/human-6.svg'),
]

function isBasicBot(bot) {
	return bot.bot_type === 'hello' || bot.bot_type === 'offline'
}

function findParentNode(node, actionId) {
	if (!node) return
	let found
	lo.find(node.action.nexts, (next) => {
		if (next.action.id === actionId) {
			found = node
			return true
		}
		return false
	})

	if (found) return found

	lo.find(node.action.nexts, (next) => {
		let ret = findParentNode(next, actionId)
		if (ret) {
			found = ret
			return true
		}
		return false
	})

	return found
}

// {
//   '1' -> node 1
//   '2' -> node 2
//   'parent-2' -> node 2
// }
//
function indexBot(node, out) {
	if (!node || !node.action) return undefined
	if (node.action.id) out[node.action.id] = node
	lo.map(node.action.nexts, (next) => {
		if (next.action.id) out['parent-' + next.action.id] = node
		indexBot(next, out)
	})
}

function getAllParentNodes(targetid, node, indexMap) {
	if (!indexMap) return []
	let parents = []
	for (;;) {
		let parent = indexMap['parent-' + targetid]
		targetid = lo.get(parent, 'action.id')
		if (!targetid) break
		parents.push(parent)
	}
	return parents
}

function findAllParentNodes(targetid, node, parents) {
	if (!node) return undefined
	if (!node.action) return undefined
	if (node.action.id === targetid) return parents || []
	if (!node.action.nexts || node.action.nexts.length === 0) return undefined

	let childparents = (parents || []).concat([node])
	let found
	node.action.nexts.map((next) => {
		let out = findAllParentNodes(targetid, next, childparents)
		if (!out) return
		found = out
	})
	return found
}

function findNode(node, actionId) {
	if (!node || !node.action) return undefined
	if (node.action.id === actionId) return node

	let found
	lo.find(node.action.nexts, (next) => {
		let out = findNode(next, actionId)
		if (out) found = out
		return out
	})

	return found
}

// bot is the first node
function filterNode(node, predicate) {
	if (!node) return
	lo.each(node.action.nexts, (next, i) => {
		if (!next) return
		if (predicate(next)) return

		// empty branch_on_no_reply
		if (lo.get(node, 'action.type') === 'assign') {
			if (lo.get(node, 'action.assign.branch_on_no_reply') === next.action.id) {
				let nexts = lo.get(next, 'action.nexts', [])
				let defbranch = nexts[nexts.length - 1]
				// parentnode.action.nexts = nexts
				node.action.nexts[i] = undefined
				node.action.nexts.unshift(defbranch)
				node.action.nexts = lo.filter(node.action.nexts, (next) => next)
				lo.set(node, 'action.assign.branch_on_no_reply', lo.get(defbranch, 'action.id'))
				return
			}
		}

		if (lo.get(node, 'action.type') === 'confirm_order') {
			if (lo.get(node, 'action.confirm_order.branch_on_cancellation') === next.action.id) {
				let nexts = lo.get(next, 'action.nexts', [])
				let defbranch = nexts[nexts.length - 1]
				// parentnode.action.nexts = nexts
				node.action.nexts[i] = undefined
				node.action.nexts.unshift(defbranch)
				node.action.nexts = lo.filter(node.action.nexts, (next) => next)
				lo.set(node, 'action.confirm_order.branch_on_cancellation', lo.get(defbranch, 'action.id'))
				return
			}
		}

		let nextofnext = lo.get(next, 'action.nexts.0')
		if (lo.get(node, 'action.type') === 'ask_question') {
			if (lo.get(node, 'action.ask_question.branch_on_failed') === next.action.id) {
				node.action.ask_question.branch_on_failed = lo.get(nextofnext, 'action.id')
			}

			let messages = lo.get(node, 'action.ask_question.messages', [])
			let attachments = lo.get(lo.last(messages), 'attachments', [])
			let replyAtt = lo.find(attachments, (att) => att.type === 'quick_replies')
			let branches = lo.map(lo.get(replyAtt, 'quick_replies'), (reply) => {
				let payload = sb.parseJSON(reply.payload) || {}
				if (payload.action_id == next.action.id) {
					payload.action_id = lo.get(nextofnext, 'action.id')
				}
				reply.payload = JSON.stringify(payload)
			})

			let genericMsg = lo.find(messages, (msg) => lo.find(msg.attachments, (att) => att.type === 'generic'))
			let genericAtt = lo.find(lo.get(genericMsg, 'attachments'), (att) => att.type === 'generic')

			let i = 0
			lo.each(lo.get(genericAtt, 'elements'), (ele, eleindex) => {
				lo.each(ele.buttons, (btn, btnindex) => {
					let payload = sb.parseJSON(btn.payload) || {}
					if (!payload) return

					if (payload.action_id == next.action.id) {
						payload.action_id = lo.get(nextofnext, 'action.id')
					}
					btn.payload = JSON.stringify(payload)
				})
			})
		}

		node.action.nexts[i] = nextofnext

		// remove the whole tree
		// if (lo.size(next.action.nexts) > 1) {
		//node.action.nexts[i] = undefined
		//return
		//}
	})

	node.action.nexts = lo.filter(node.action.nexts, (next) => !!next)
	lo.map(node.action.nexts, (next) => filterNode(next, predicate))
}

function newActionId(bot) {
	let nodes = [bot]
	let ids = []
	while (true) {
		let nexts = []
		lo.each(nodes, (node) => {
			if (!node) return
			if (lo.get(node, 'action.type')) {
				let actionId = lo.get(node, 'action.id')
				actionId = parseInt(actionId) || 0
				ids.push(actionId)
			}
			let action = node.action
			if (action.nexts && action.nexts.length) nexts = [...nexts, ...action.nexts]
		})
		if (nexts.length === 0) break
		nodes = nexts
	}
	return lo.max(ids) + 1 + ''
}

function getAllNodes(bot) {
	let output = []
	let nodes = [bot]
	while (true) {
		let nexts = []
		lo.each(nodes, (node) => {
			output.push(node)
			let action = node.action || {}
			if (action.nexts && action.nexts.length > 0) nexts = [...nexts, ...action.nexts]
		})
		if (nexts.length === 0) break
		nodes = nexts
	}
	return output
}

function getActionTitle(actionId, bot, noNumber) {
	let node = findNode(bot, actionId)
	if (!node) return ''
	let action = node.action || {}

	let actionName = ''
	if (!action.type) actionName = i18n.t('untitled')
	if (action.type === 'switch_language') actionName = i18n.t('switch_language')
	if (action.type === 'end_bot') actionName = i18n.t('end_bot')
	if (action.type === 'confirm_order') {
		if (lo.get(action, 'confirm_order.wait_for_user_response')) actionName = i18n.t('confirm_order')
		else actionName = i18n.t('send_order_detail')
	}
	if (action.type === 'ask_question') {
		actionName = i18n.t('bot_ask_custom_question')
		let saveto = lo.get(action, 'ask_question.save_to_attribute')
		let waitResponse = lo.get(action, 'ask_question.wait_for_user_response')
		let quickReplyMsg = lo.find(lo.get(action, 'ask_question.messages'), (msg) => {
			return lo.find(lo.get(msg, 'attachments'), (att) => att.type === 'quick_replies')
		})
		let genericMsg = lo.find(lo.get(action, 'ask_question.messages'), (msg) => {
			return lo.find(lo.get(msg, 'attachments'), (att) => att.type === 'generic')
		})
		if (!waitResponse) actionName = i18n.t('bot_send_messages')
		if (saveto === 'phones' || saveto === 'phone') actionName = i18n.t('bot_ask_phone')
		if (saveto === 'emails' || saveto === 'email') actionName = i18n.t('bot_ask_email')
		if (saveto === 'address') actionName = i18n.t('bot_ask_address')
		if (saveto === 'fullname') actionName = i18n.t('bot_ask_name')
		if (waitResponse && quickReplyMsg) actionName = i18n.t('bot_ask_custom_question')
		if (waitResponse && genericMsg) actionName = i18n.t('product_card_list')
		if (quickReplyMsg && waitResponse && saveto && saveto !== 'none') actionName = i18n.t('bot_ask_custom_question')
	}
	if (action.type === 'jump') {
		if (lo.get(action, 'jump.action_id')) {
			actionName = i18n.t('jump_to') + ' #' + lo.get(action, 'jump.action_id')
		} else {
			actionName = i18n.t('jump_to')
		}
	}

	if (action.type === 'send_http') actionName = i18n.t('send_http')
	if (action.type === 'update_conversation') {
		if (action.update_conversation.end_conversation) {
			actionName = i18n.t('complete_convo')
		} else actionName = i18n.t('tag_conversation')
	}
	if (action.type === 'assign') actionName = i18n.t('send_to_agents')
	if (action.type === 'update_user') actionName = i18n.t('update_user_attributes')
	if (action.type === 'update_user_labels') actionName = i18n.t('update_user_labels')
	if (action.type === 'confirm_order') actionName = i18n.t('create_order')
	if (action.type === 'create_task') actionName = i18n.t('create_task')

	// root action
	// if (actionId === bot.action.id) actionName = i18n.t('start')

	if (!node.action.id || noNumber) return actionName
	return `${node.action.id}. ${actionName}`
}

function getActionTitle2(actionId, noNumber, indexMap) {
	let node = indexMap[actionId]
	if (!node) return ''
	let action = node.action || {}

	let actionName = ''
	if (!action.type) actionName = i18n.t('untitled')
	if (action.type === 'switch_language') actionName = i18n.t('switch_language')
	if (action.type === 'end_bot') actionName = i18n.t('end_bot')
	if (action.type === 'confirm_order') {
		if (lo.get(action, 'confirm_order.wait_for_user_response')) actionName = i18n.t('confirm_order')
		else actionName = i18n.t('send_order_detail')
	}
	if (action.type === 'ask_question') {
		actionName = i18n.t('bot_ask_custom_question')
		let saveto = lo.get(action, 'ask_question.save_to_attribute')
		let waitResponse = lo.get(action, 'ask_question.wait_for_user_response')
		let quickReplyMsg = lo.find(lo.get(action, 'ask_question.messages'), (msg) => {
			return lo.find(lo.get(msg, 'attachments'), (att) => att.type === 'quick_replies')
		})
		let genericMsg = lo.find(lo.get(action, 'ask_question.messages'), (msg) => {
			return lo.find(lo.get(msg, 'attachments'), (att) => att.type === 'generic')
		})
		if (!waitResponse) actionName = i18n.t('bot_send_messages')
		if (saveto === 'phones' || saveto === 'phone') actionName = i18n.t('bot_ask_phone')
		if (saveto === 'emails' || saveto === 'email') actionName = i18n.t('bot_ask_email')
		if (saveto === 'address') actionName = i18n.t('bot_ask_address')
		if (saveto === 'fullname') actionName = i18n.t('bot_ask_name')
		if (waitResponse && quickReplyMsg) actionName = i18n.t('bot_ask_custom_question')
		if (waitResponse && genericMsg) actionName = i18n.t('product_card_list')
		if (quickReplyMsg && waitResponse && saveto && saveto !== 'none') actionName = i18n.t('bot_ask_custom_question')
	}
	if (action.type === 'jump') {
		if (lo.get(action, 'jump.action_id')) {
			actionName = i18n.t('jump_to') + ' #' + lo.get(action, 'jump.action_id')
		} else {
			actionName = i18n.t('jump_to')
		}
	}

	if (action.type === 'send_http') actionName = i18n.t('send_http')
	if (action.type === 'update_conversation') {
		if (action.update_conversation.end_conversation) {
			actionName = i18n.t('complete_convo')
		} else actionName = i18n.t('tag_conversation')
	}
	if (action.type === 'assign') actionName = i18n.t('send_to_agents')
	if (action.type === 'update_user') actionName = i18n.t('update_user_attributes')
	if (action.type === 'update_user_labels') actionName = i18n.t('update_user_labels')
	if (action.type === 'confirm_order') actionName = i18n.t('create_order')
	if (action.type === 'create_task') actionName = i18n.t('create_task')

	// root action
	// if (actionId === bot.action.id) actionName = i18n.t('start')

	if (!node.action.id || noNumber) return actionName
	return `${node.action.id}. ${actionName}`
}

let askInfoAction = (bot) => {
	let locales = lo.get(bot, 'locales')
	let i18n_block = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN') i18n_block.vi_VN = {type: 'paragraph'}
		else i18n_block[locale.replace('-', '_')] = {type: 'paragraph'}
	})
	return {
		name: '',
		type: 'ask_question',
		ask_question: {
			wait_for_user_response: true,
			messages: [
				{
					format: 'plaintext',
					text: '',
					i18n_block: i18n_block,
				},
			],
			allow_open_response: true,
			save_to_attribute: '',
			validation: 'none',
			retry_on_invalid: 3,
			skip_if_attribute_already_existed: true,
			typing_duration: 2,
			validation_error_message: {},
		},
	}
}

let askAddressAction = (bot) => {
	let locales = lo.get(bot, 'locales')
	let i18n_block = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN') i18n_block.vi_VN = {type: 'paragraph', content: [{type: 'text', text: 'Bạn đang ở đâu?'}]}
		else
			i18n_block[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Where are you living now?'}],
			}
	})

	return {
		name: '',
		type: 'ask_question',
		ask_question: {
			wait_for_user_response: true,
			messages: [
				{
					format: 'plaintext',
					text: i18n.t('bot.sample_ask_address_action'),
					i18n_block: i18n_block,
				},
			],
			allow_open_response: true,
			save_to_attribute: 'address',
			validation: 'none',
			retry_on_invalid: 3,
			skip_if_attribute_already_existed: true,
			typing_duration: 2,
			validation_error_message: {},
		},
	}
}

let askEmailAction = (bot) => {
	let locales = lo.get(bot, 'locales')
	let i18n_block = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN') {
			i18n_block.vi_VN = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Địa chỉ email chúng tôi có thể liên hệ với bạn là gì'}],
			}
		} else
			i18n_block[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'What is your email address?'}],
			}
	})

	return {
		name: '',
		type: 'ask_question',
		ask_question: {
			wait_for_user_response: true,
			messages: [
				{
					format: 'plaintext',
					text: i18n.t('bot.sample_ask_email_action'),
					i18n_block: i18n_block,
				},
			],
			allow_open_response: true,
			save_to_attribute: 'emails',
			validation: 'email',
			retry_on_invalid: 3,
			skip_if_attribute_already_existed: true,
			typing_duration: 2,
			validation_error_message: {
				vi: 'Có vẻ bạn đã nhập nhầm email. Vui lòng nhập lại email chính xác lần nữa',
				en: 'Oops! You may input wrong email. Please input your email again',
			},
		},
	}
}

let askPhoneAction = (bot) => {
	let locales = lo.get(bot, 'locales')
	let i18n_block = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN')
			i18n_block.vi_VN = {type: 'paragraph', content: [{type: 'text', text: 'Số điện thoại của bạn là gì?'}]}
		else
			i18n_block[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'What is your phone number?'}],
			}
	})

	return {
		name: '',
		type: 'ask_question',
		ask_question: {
			wait_for_user_response: true,
			messages: [
				{
					format: 'plaintext',
					text: i18n.t('bot.sample_ask_phone_action'),
					i18n_block: i18n_block,
				},
			],
			allow_open_response: true,
			save_to_attribute: 'phones',
			validation: 'phone',
			retry_on_invalid: 3,
			skip_if_attribute_already_existed: true,
			typing_duration: 2,
		},
	}
}

let askNameAction = (bot) => {
	let locales = lo.get(bot, 'locales')
	let i18n_block = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN')
			i18n_block.vi_VN = {type: 'paragraph', content: [{type: 'text', text: 'Tên đầy đủ của bạn là gì?'}]}
		else
			i18n_block[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'What is your fullname?'}],
			}
	})

	return {
		name: '',
		type: 'ask_question',
		ask_question: {
			wait_for_user_response: true,
			messages: [
				{
					format: 'plaintext',
					text: i18n.t('bot.sample_ask_name_action'),
					i18n_block: i18n_block,
				},
			],
			allow_open_response: true,
			save_to_attribute: 'fullname',
			validation: 'none',
			retry_on_invalid: 3,
			skip_if_attribute_already_existed: true,
			typing_duration: 2,
			validation_error_message: {},
		},
	}
}

let selectionAction = (bot) => {
	let locales = lo.get(bot, 'locales')
	let i18n_block = {}
	let firstReplyI18nTitle = {}
	let secondReplyI18nTitle = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN') {
			i18n_block.vi_VN = {type: 'paragraph', content: [{type: 'text', text: 'Bạn cần hỗ trợ gì vậy ạ'}]}
			firstReplyI18nTitle.vi_VN = 'Bảo hành'
			secondReplyI18nTitle.vi_VN = 'Báo giá'
		} else {
			i18n_block[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'What can I do for you'}],
			}
			firstReplyI18nTitle[locale.replace('-', '_')] = 'Maintain'
			secondReplyI18nTitle[locale.replace('-', '_')] = 'Pricing'
		}
	})

	return {
		name: '',
		type: 'ask_question',
		ask_question: {
			wait_for_user_response: true,
			messages: [
				{
					format: 'plaintext',
					text: i18n.t('bot.sample_ask_selection_action'),
					i18n_block: i18n_block,
					attachments: [
						{
							type: 'quick_replies',
							quick_replies: [
								{
									i18n_title: firstReplyI18nTitle,
								},
								{
									i18n_title: secondReplyI18nTitle,
								},
							],
						},
					],
				},
			],
			allow_open_response: false,
			validation: 'none',
			retry_on_invalid: 3,
			typing_duration: 2,
		},
	}
}

function switchLanguageAction(loc, locales) {
	let i18n_block = {}
	if (!loc) loc = 'en-US'
	i18n_block.vi_VN = {type: 'paragraph', content: [{type: 'text', text: 'Bạn muốn tư vấn bằng ngôn ngữ nào?'}]}
	i18n_block.en_US = {type: 'paragraph', content: [{type: 'text', text: '"What is your preferred language"'}]}
	if (!loc) i18n_block[loc.replace('-', '_')] = i18n_block.en_US

	if (lo.size(locales) === 0) locales = [loc]
	let qreplies = []
	lo.map(locales, (locale) => {
		let engname = lo.get(langmap, [locale, 'nativeName'], '')
		let i18n_title = {}
		lo.map(locales, (locale) => {
			i18n_title[locale.replace('-', '_')] = engname
		})

		qreplies.push({i18n_title: i18n_title, payload: JSON.stringify({type: 'bot_switch_language', title: locale})})
	})

	let attachments = [{type: 'quick_replies', quick_replies: qreplies}]
	return {
		name: '',
		type: 'switch_language',
		ask_question: {
			wait_for_user_response: true,
			messages: [
				{
					format: 'plaintext',
					i18n_block: i18n_block,
					attachments: attachments,
				},
			],
			allow_open_response: false,
			retry_on_invalid: 3,
			typing_duration: 1,
			validation_error_message: {},
		},
	}
}

let assignAgentAction = {
	name: '',
	type: 'assign',
	assign: {use_rule: true, strategy: '', assign_tos: []},
}

let sendOrderAction = (bot) => {
	let locales = lo.get(bot, 'locales', [])
	let i18n_block = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN') {
			i18n_block.vi_VN = {type: 'paragraph', content: [{type: 'text', text: 'Bấm vào link dưới để xem đơn hàng'}]}
		} else {
			i18n_block[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Click on the link bellow to view your order'}],
			}
		}
	})

	return {
		name: '',
		type: 'confirm_order',
		confirm_order: {
			messages: [
				{
					format: 'plaintext',
					text: 'Bấm vào link dưới để xem đơn hàng',
					i18n_block: i18n_block,
				},
			],
			branch_on_cancellation: '', // no
			wait_for_user_response: false, // no
		},
	}
}

let createTaskAction = (bot) => {
	return {
		name: i18n.t('create_task'),
		type: 'create_task',
		create_task: {
			assignees: [],
			watchers: [],
		},
	}
}

let confirmOrderAction = (bot) => {
	let locales = lo.get(bot, 'locales', [])
	let i18n_block = {}
	let i18n_block_invalid_1 = {}
	let i18n_block_invalid_2 = {}
	let i18n_block_invalid_3 = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN') {
			i18n_block.vi_VN = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Bạn vui lòng bấm vào link dưới để xác nhận đơn'}],
			}
			i18n_block_invalid_1.vi_VN = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Đơn hàng đã rất gần rồi. Bạn hãy click để xác nhận đơn nhé'}],
			}
			i18n_block_invalid_2.vi_VN = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Chỉ còn 1 bước nữa thôi. Hãy xác nhận đơn ngay nhé'}],
			}
			i18n_block_invalid_3.vi_VN = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Mời bạn click vào link bên dưới để xác nhận đơn'}],
			}
		} else {
			i18n_block[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Please click link below to confirm your order'}],
			}
			i18n_block_invalid_1[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Please click link below to confirm your order'}],
			}
			i18n_block_invalid_2[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Please click link below to confirm your order'}],
			}
			i18n_block_invalid_3[locale.replace('-', '_')] = {
				type: 'paragraph',
				content: [{type: 'text', text: 'Please click link below to confirm your order'}],
			}
		}
	})

	return {
		name: '',
		type: 'confirm_order',
		confirm_order: {
			messages: [
				{
					format: 'plaintext',
					text: '',
					i18n_block: i18n_block,
				},
			],
			invalid_messages: [
				{
					format: 'plaintext',
					text: '',
					i18n_block: i18n_block_invalid_1,
				},
				{
					format: 'plaintext',
					text: '',
					i18n_block: i18n_block_invalid_2,
				},
				{
					format: 'plaintext',
					text: '',
					i18n_block: i18n_block_invalid_3,
				},
			],
			branch_on_cancellation: '',
			wait_for_user_response: true,
		},
	}
}

let sendMessageAction = (bot) => {
	let locales = lo.get(bot, 'locales')
	let i18n_block = {}
	lo.each(locales, (locale) => {
		if (locale === 'vi-VN') i18n_block.vi_VN = {type: 'paragraph'}
		else i18n_block[locale.replace('-', '_')] = {type: 'paragraph'}
	})

	return {
		name: '',
		type: 'ask_question',
		ask_question: {
			messages: [
				{
					format: 'plaintext',
					text: '',
					i18n_block: i18n_block,
				},
			],
			typing_duration: 2,
		},
	}
}

let sendHttpAction = {
	name: '',
	type: 'send_http',
	send_http: {url: '', method: 'POST', header: [{key: 'Content-Type', value: 'application/json'}]},
}

let replyFacebookComment = {
	name: '',
	type: 'reply_facebook_comment',
	reply_facebook_comment: {
		message: {
			format: 'block',
			text: 'Cảm ơn bạn đã bình luận',
			block: {type: 'paragraph', content: [{type: 'text', text: 'Cảm ơn bạn đã bình luận'}]},
		},
	},
}

let replyFacebookCommentAction = {
	type: 'reply_facebook_comment',
	reply_facebook_comment: {
		message: {
			format: 'plaintext',
			text: 'Cảm ơn bạn đã bình luận',
			block: {type: 'paragraph', content: [{type: 'text', text: 'Cảm ơn bạn đã bình luận'}]},
		},
	},
}

let sendFacebookPrivateReplyAction = {
	type: 'send_faceook_private_reply',
	send_faceook_private_reply: {
		message: {
			format: 'plaintext',
			text: 'Cảm ơn bạn đã bình luận',
			block: {type: 'paragraph', content: [{type: 'text', text: 'Cảm ơn bạn đã bình luận'}]},
		},
	},
}

let likeFacebookCommentAction = {
	type: 'like_facebook_comment',
}

let hideFacebookCommentAction = {
	type: 'hide_facebook_comment',
}

let jumpAction = {
	type: 'jump',
	jump: {
		action_id: '',
		to_first_action: false,
	},
}

let restartAction = {
	type: 'jump',
	jump: {
		action_id: '',
		to_first_action: true,
	},
}

let endChatAction = {
	type: 'update_conversation',
	update_conversation: {
		end_conversation: true,
		tag_ids: [],
	},
}

let tagAction = {
	name: '',
	type: 'update_conversation',
	update_conversation: {
		end_conversation: false,
		tag_ids: [],
	},
}

let updateUserAction = {
	type: 'update_user',
	update_user: {
		attribute: '',
		value: '',
	},
}

let addUserLabel = {
	type: 'update_user_labels',
	update_user_labels: {
		labels: [],
		remove_labels: [],
	},
}

function getSubText(node) {
	if (!node || !node.action) return ''
	if (node.action.type === 'ask_question') {
		return lo.get(node, 'action.ask_question.messages.0.text')
	}
	return ''
}

function getNextActions(com, inbot, node) {
	let bot = lo.cloneDeep(inbot)
	let actions = [{label: com.$t('empty'), id: 'empty', group: com.$t('existed_branch')}]
	lo.map(node.action.nexts, (next) => {
		let actionId = lo.get(next, 'action.id')
		let actionName = getActionTitle(actionId, bot)

		if (lo.get(next, 'action.type') === 'end_bot') {
			actions.push({
				label: com.$t('end_bot'),
				id: next.action.id,
				group: com.$t('existed_branch'),
			})
		} else {
			actions.push({
				label: com.$t('turn_to_branch') + ' ' + actionName,
				id: next.action.id,
				group: com.$t('existed_branch'),
			})
		}
	})

	return actions.concat([])
}

function isAskEmail(node) {
	let saveto = lo.get(node, 'action.ask_question.save_to_attribute')
	return saveto === 'emails' || saveto === 'email'
}

function isAskPhone(node) {
	let saveto = lo.get(node, 'action.ask_question.save_to_attribute')
	return saveto === 'phones' || saveto === 'phone'
}

function isAskName(node) {
	let saveto = lo.get(node, 'action.ask_question.save_to_attribute')
	return saveto === 'fullname'
}

function getMessageTextAndFormat(message, locale) {
	let block
	if (locale) {
		locale = locale.replace('-', '_')
		block = lo.get(message, `i18n_block.${locale}`)
	} else {
		block = lo.get(message, `block`)
	}

	text = lo.trim(sb.blockToText(block, {is_show_dynamic_field_token: true}))
	format = 'block'
	return {text, format}
}

function fixI18nTextAskQuestion(action, locale, fallbackLocale) {
	if (!action) return
	locale = locale.replace('-', '_')
	fallbackLocale = fallbackLocale.replace('-', '_')
	action = lo.cloneDeep(action)
	if (action.type !== 'ask_question' && action.type !== 'confirm_order' && action.type !== 'switch_language')
		return action

	let messages = lo.get(action, `${action.type}.messages`, [])
	lo.each(messages, (message) => {
		let {text, format} = getMessageTextAndFormat(message, locale)
		if (!text) {
			text = getMessageTextAndFormat(message, fallbackLocale).text
			lo.set(message, `i18n_block.${locale}`, lo.get(message, `i18n_block.${fallbackLocale}`))
		}
		if (!text) {
			text = getMessageTextAndFormat(message).text
			lo.set(message, `i18n_block.${locale}`, lo.get(message, `block`))
		}
		message.text = text
		message.format = format
		message.block = lo.get(message, `i18n_block.${locale}`)
		lo.each(message.attachments, (attachment) => {
			if (attachment.type === 'quick_replies') {
				lo.each(attachment.quick_replies, (reply) => {
					let title = lo.get(reply, `i18n_title.${locale}`)
					if (!title) {
						title = lo.get(reply, `i18n_title.${fallbackLocale}`)
						lo.set(reply, `i18n_title.${locale}`, title)
					}
					reply.title = title
				})
			}
			if (attachment.type === 'generic') {
				lo.each(attachment.elements, (ele) => {
					let title = lo.get(ele, `i18n_title.${locale}`)
					let subtitle = lo.get(ele, `i18n_subtitle.${locale}`)
					if (!title) {
						title = lo.get(ele, `i18n_title.${fallbackLocale}`)
						lo.set(ele, `i18n_title.${locale}`, title)
					}
					if (!subtitle) {
						subtitle = lo.get(ele, `i18n_subtitle.${fallbackLocale}`)
						lo.set(ele, `i18n_subtitle.${locale}`, subtitle)
					}
					ele.title = title
					ele.subtitle = subtitle

					lo.each(ele.buttons, (button) => {
						let title = lo.get(button, `i18n_title.${locale}`)
						if (!title) {
							title = lo.get(button, `i18n_title.${fallbackLocale}`)
							lo.set(button, `i18n_title.${locale}`, title)
						}
						button.title = title
					})
				})
			}
			if (attachment.type === 'button') {
				lo.each(attachment.buttons, (button) => {
					let title = lo.get(button, `i18n_title.${locale}`)
					if (!title) {
						title = lo.get(button, `i18n_title.${fallbackLocale}`)
						lo.set(button, `i18n_title.${locale}`, title)
					}
					button.title = title
				})
			}
		})
	})
	let resumeMessage = lo.get(action, 'ask_question.resume_message')
	if (resumeMessage) {
		let {text, format} = getMessageTextAndFormat(resumeMessage, locale)
		if (!text) {
			text = getMessageTextAndFormat(resumeMessage, fallbackLocale).text
			lo.set(resumeMessage, `i18n_block.${locale}`, lo.get(resumeMessage, `i18n_block.${fallbackLocale}`))
		}
		resumeMessage.text = text
		//resumeMessage.format = format
		resumeMessage.block = lo.get(resumeMessage, `i18n_block.${locale}`)
	}
	return action
}

function fixI18nTextBot(node, locale, fallbackLocale) {
	node.action = fixI18nTextAskQuestion(node.action, locale, fallbackLocale)
	lo.each(lo.get(node, 'action.nexts'), (node) => fixI18nTextBot(node, locale, fallbackLocale))
}

function listBranches(node) {
	let messages = lo.get(node, 'action.ask_question.messages', [])
	let attachments = lo.get(lo.last(messages), 'attachments', [])
	let replyAtt = lo.find(attachments, (att) => att.type === 'quick_replies')
	let branches = lo.map(lo.get(replyAtt, 'quick_replies'), (reply, i) => {
		let payload = sb.parseJSON(reply.payload) || {}
		return {
			index: i,
			label: reply.title,
			action_id: lo.get(payload, 'action_id'),
			type: 'reply',
			reply: reply,
		}
	})

	let genericMsg = lo.find(messages, (msg) => lo.find(msg.attachments, (att) => att.type === 'generic'))
	let genericAtt = lo.find(lo.get(genericMsg, 'attachments'), (att) => att.type === 'generic')

	let i = 0
	lo.each(lo.get(genericAtt, 'elements'), (ele, eleindex) => {
		lo.each(ele.buttons, (btn, btnindex) => {
			if (btn.type !== 'postback_button') return
			let payload = sb.parseJSON(btn.payload)
			if (!payload) return

			branches.push({
				index: 'ele_' + eleindex + '_' + btnindex,
				label: btn.title + '+' + ele.title,
				action_id: lo.get(payload, 'action_id'),
				type: 'button',
				button: btn,
			})
		})
	})
	return branches
}

module.exports = {
	newActionId,
	findNode,
	filterNode,
	findParentNode,
	sendMessageAction,
	assignAgentAction,
	askNameAction,
	askEmailAction,
	askAddressAction,
	askPhoneAction,
	sendHttpAction,
	selectionAction,
	jumpAction,
	endChatAction,
	tagAction,
	restartAction,
	askInfoAction,
	findAllParentNodes,
	getAllNodes,
	getActionTitle,
	getNextActions,
	isBasicBot,
	sampleAvatars,
	replyFacebookCommentAction,
	sendFacebookPrivateReplyAction,
	likeFacebookCommentAction,
	hideFacebookCommentAction,
	isAskPhone,
	isAskName,
	isAskEmail,
	fixI18nTextBot,
	switchLanguageAction,
	updateUserAction,
	addUserLabel,
	listBranches,
	getSubText,
	confirmOrderAction,
	sendOrderAction,
	indexBot,
	getAllParentNodes,
	getActionTitle2,
	createTaskAction,
}
