
export default {
	props: ['buttons', 'byType'],

	methods: {
		i18n(t) {
			if (!t) return ''
			let fallbackLocale = lo.get(store.me(), 'account.locale', 'vi-VN')
			fallbackLocale = fallbackLocale.replace('-', '_')
			return t[fallbackLocale]
		},
	},

	render() {
		if (lo.size(this.buttons) === 0) return null
		var $buttons = lo.map(this.buttons, (button, index) => {
			let title = this.i18n(button.i18n_title) || button.title
			if (button.type === 'call_button') {
				return (
					<a class='url-button-attachment' href={'tel:' + button.phone_number} key={index} title={title}>
						<Icon name='phone' size='1x' style='margin-top: -4px' class='mr-2' /> {title}
					</a>
				)
			}

			if (button.type === 'postback_button') {
				return (
					<a class='url-button-attachment' href='#' key={index} title={title}>
						{title}
					</a>
				)
			}

			if (button.type === 'message_button') {
				return (
					<a class='url-button-attachment' href='#' key={index} title={title}>
						{title}
					</a>
				)
			}

			if (button.type === 'url_button' || button.type === 'web_url') {
				let payload = sb.parseJSON(button.payload)
				let isFbRefLink = lo.get(payload, 'action') === 'fb_link_ref'

				return (
					<a
						class='url-button-attachment'
						href={isFbRefLink ? 'javascript:;' : button.url}
						target={isFbRefLink ? '' : '_blank'}
						key={index}
						title={title}
					>
						{title || button.url}
					</a>
				)
			}

			if (button.type === 'widget_ask_desktop_notify_button') {
				return (
					<a class='url-button-attachment' key={index} title={title}>
						{this.$t('allow_notification') || title}
					</a>
				)
			}

			if (button.type === 'widget_ask_contact_info_button') {
				return (
					<a class='url-button-attachment' key={index} vOn:click_stop={this.openSetting} title={title}>
						{this.$t('btn_ask_save_info') || title}
					</a>
				)
			}
			return null
		})

		return <div class={`message_button message_button__${this.byType}`}>{$buttons}</div>
	},
}
