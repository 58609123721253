const loadingimg = require('../assets/img/loading_static.svg')

export default {
	props: ['size', 'mode', 'static', 'center', 'slow', 'colorful'],
	render() {
		let mode = (this.mode + '').toLowerCase()
		let mainColor = mode === 'dark' ? '#000000dd' : mode === 'blue' ? 'rgba(52, 152, 220, .8)' : '#ffffffdd'

		let borderColor = mode === 'dark' ? '#00000055' : mode === 'blue' ? 'rgba(52, 152, 220, .15)' : '#ffffff55'

		let size = this.size || 14
		let borderWidth = Math.ceil(size / 14)

		let style = `width: ${size}px;height: ${size}px;color: ${borderColor};border-width: ${borderWidth}px;
    border-left-color: ${borderColor};border-top-color: ${borderColor};
    border-bottom-color: ${borderColor};border-right-color: ${mainColor}`


		if (this.static) {
			let style = `width: ${size}px;height: ${size}px;`
			if (this.center) {
				return (
					<div class='loading'>
						<img style={style} src={loadingimg} />
					</div>
				)
			}
			return <img style={style} src={loadingimg} />
		}

		if (this.colorful) {
			return <SunIcon class='rotate_twinkle' stroke-width='1.5' size={size + ''} />
		}

		// if (size < 40) return <SunIcon class='rotate' stroke-width='1.5' size={size + ''} style={`stroke:${mainColor}`} />

		return <div class='spinner1' style={style}></div>
	},
}
