var sb = require('@sb/util')
const config = require('@sb/config')

export default {
	name: 'link-attachments',
	props: ['ev'],

	render() {
		var attachments = lo.get(this.ev, 'data.message.attachments', [])
		var links = []
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'

		lo.each(attachments, (att, i) => {
			if (!att) return
			if (att.type !== 'link' && att.type !== 'fallback') return
			if (!att.url) return

			var uri = att.url
			if (
				!uri.startsWith('https://') &&
				!uri.startsWith('http://') &&
				!uri.startsWith('content://') &&
				!uri.startsWith('data:') &&
				!uri.startsWith('//:')
			) {
				att.url = '//' + uri
			}
			links.push(att)
		})

		if (links.length === 0) return null

		var $links = lo.map(links, (link, index) => {
			if (link.type === 'fallback') {
				return (
					<div class={`message_link message_link__${byType}`}>
						<a href={link.url} target='_blank'>
							<div class='message_link__description'>{link.title}</div>
						</a>
					</div>
				)
			}
			return (
				<div class={`message_link message_link__${byType}`}>
					<a href={link.url} target='_blank'>
						<div class='message_link__description'>{link.description}</div>
						<img class='message_link__img' src={sb.replaceFileUrl(link.thumbnail_url)} />
					</a>
				</div>
			)
		})
		return <div class={`message_container message_container__${byType}`} style="flex-direction: column">
			{$links}
		</div>
	},
}
