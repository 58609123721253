import store from '@sb/store'
import accounting from 'accounting'
var sb = require('@sb/util')
import pc from '../product/common.js'

export default {
	name: 'user-orders',
	props: ['user', 'refresh', 'cid'],
	data() {
		return {
			loading: false,

			modal_right: 0,
			order: {},

			orders: [],
		}
	},

	created() {
		if (this.user) {
			this.loadOrders(this.user.id)
		}

		setTimeout(() => {
			this.$forceUpdate() // load product done
		}, 5000)
		store.onOrder(this, (order) => {
			if (!this.user) return
			if (!order) return
			if (!order.user) return
			if (order.user.id != this.user.id) return
			this.$forceUpdate()

			if (!lo.find(this.orders, (o) => o.id == order.id)) {
				this.loadOrders(this.user.id)
			}
		})
		this.$root.$on('order_modal_closed', () => this.user && this.loadOrders(this.user.id))
		this.$root.$once('hook:beforeDestroy', () => this.user && this.loadOrders(this.user.id))
	},

	watch: {
		user(user) {
			this.orders = []
			if (!user) return
			setTimeout(() => this.loadOrders(user.id))
		},
	},

	render() {
		if (this.refresh) lo.noop()
		if (!this.user) return null

		let $loading = <span class='user_profile_count_badge'>{lo.size(this.orders)}</span>
		if (this.loading) $loading = <Spinner static class='ml-2 mb-1' mode='dark' />

		let acc = store.me().account

		let orders = lo.map(this.orders).sort(function (a, b) {
			let issuea = a.issue_date || a.created
			let issueb = b.issue_date || b.created
			return issueb - issuea
		})
		let $orders = lo.map(orders, (order) => {
			let items = lo.get(order, 'items', [])

			let firstimg = lo.get(items, '0.product.image')
			if (!firstimg && lo.get(items[0], 'product.id')) {
				firstimg = lo.get(store.matchProduct(items[0].product.id), 'images.0')
			}

			let $firstItem = (
				<div class='user_info_order_item'>
					<div class='text__truncate mr-4'>{pc.getProductNameWithProp(lo.get(items, '0.product', {}))}</div>
					<div class='text__muted ml-auto no-shrink'>&times; {lo.get(items, '0.quantity')}</div>
				</div>
			)

			let $secondItem = (
				<div class='user_info_order_item'>
					<div class='text__truncate mr-4'>{pc.getProductNameWithProp(lo.get(items, '1.product', {}))}</div>
					<div class='text__muted ml-auto no-shrink'>&times; {lo.get(items, '1.quantity')}</div>
				</div>
			)
			if (lo.size(items) === 1) $secondItem = null
			if (lo.size(items) >= 3) {
				$secondItem = (
					<div class='user_info_order_item_more'>
						+{lo.size(items) - 1} {this.$t('other_products').toLowerCase()}
					</div>
				)
			}
			let $dot = null

			if (order.is_draft)
				$dot = (
					<span
						v-tooltip={this.$t('draft_order')}
						class='dot dot__warning dot__lg mr-2'
						style='display: inline-block; border: 2px solid rgb(255, 241, 160); margin-top: 1px;'
					></span>
				)

			let $verify = this.renderVerifiedOrder(order)
			if ($verify)
				$verify = (
					<div class='ml-2' style='position: relative; top: -2px'>
						{$verify}
					</div>
				)

			let $total = <strong class='ml-auto'>{sb.displayMoney(order.total, order.currency)}</strong>

			if (order.payment_status == 'paid' && order.status != 'canceled') {
				$total = <strong class='ml-auto text__success'>{sb.displayMoney(order.total, order.currency)}</strong>
			}

			let infocls = 'user_info_order_info'
			if (order.status == 'canceled') infocls += ' canceled'

			let $detail = null
			if (order.status != 'canceled') {
				$detail = (
					<div class='user_info_order_items'>
						{$firstItem}
						{$secondItem}
					</div>
				)
			}

			if (!pc.canViewOrder(order)) {
				$dot = <Icon name='lock' stroke-width='1.5' class='text__muted mr-2' size='18' />
				return (
					<div class='user_info_order' vOn:click={(_) => this.$showOrder(order)}>
						<div class={infocls}>
							{$dot}
							<b>
								{this.$t('order_short')} {pc.getOrderDisplayName(order)}
							</b>
						</div>
						<div class='user_info_order_items'>
							<em class='text__muted'>{this.$t('other_agent_order')}</em>
						</div>
					</div>
				)
			}
			return (
				<div class='user_info_order' vOn:click={(_) => this.$showOrder(order)}>
					<div class={infocls}>
						{$dot}
						<b>
							{this.$t('order_short')} {pc.getOrderDisplayName(order)}
						</b>
						{$verify}
						<Time short class='text__muted ml-3' time={order.issue_date || order.created} style='font-size: 13px' />
						{$total}
					</div>
					{$detail}
				</div>
			)
		})

		if (lo.size($orders) === 0) {
			$orders = (
				<div class='ml-4 mr-4'>
					<div class='text__muted mt-2'>{this.$t('order_label_empty')}</div>
				</div>
			)
		} else {
			$orders = <div class='ml-3 mr-3'>{$orders}</div>
		}

		if (this.error) {
			return (
				<div class='user_orders' ref='order_container'>
					<div class='user_profile__head'>
						<div class='profile_section_title'>{this.$t('orders')}</div>
						<div style='position: relative;'>
							<a href='#' vOn:click={this.showNewOrder}>
								{this.$t('add_order_label')} <Icon name='file-plus' size='18' stroke-width='1.5' />
							</a>
						</div>
					</div>
					<div class='ml-4 mr-4'>
						<Icon name="alert-triangle" style='margin-top:-3px' class='text__danger' size='1x' /> {this.$t('load_order_error')}{' '}
						<a href='#' vOn:click={(_) => this.loadOrders(this.user.id)}>
							{this.$t('retry')}
						</a>
					</div>
				</div>
			)
		}

		return (
			<div class='user_orders' ref='order_container'>
				<div class='user_profile__head'>
					<div class='profile_section_title'>
						{this.$t('orders')}
						{$loading}
					</div>
					<div style='position: relative;'>
						<a href='#' vOn:click={this.showNewOrder}>
							{this.$t('add_order_label')} <Icon name='file-plus' size='18' stroke-width='1.5' />
						</a>
					</div>
				</div>
				{$orders}
			</div>
		)
	},

	methods: {
		renderVerifiedOrder(order) {
			// cancelted
			// both confirmed
			if (order.status === 'closed') {
				if (order.user_confirmation == 'confirmed') {
					return (
						<img
							height='16'
							width='16'
							src={require('../assets/img/verified_3.svg')}
							v-tooltip={this.$t('confirmed')}
						/>
					)
				}

				// only agent confirmed
				return (
					<img height='16' width='16' src={require('../assets/img/verified_2.svg')} v-tooltip={this.$t('confirmed')} />
				)
			}

			// only customer confirmed
			// TODO: remove new
		},

		async loadOrders(uid) {
			this.loading = true
			this.error = false
			let orders = await store.fetchUserOrders(uid)
			this.loading = false
			if (orders.error) {
				this.error = orders.error
				return
			}
			this.orders = orders
		},

		showUpdateOrder(e, order) {
			let rect = this.$refs.order_container.getBoundingClientRect()
			this.$showOrder({id: order.id}, {no_change_user: true})
		},

		showNewOrder(e) {
			let rect = this.$refs.order_container.getBoundingClientRect()
			let user = lo.cloneDeep(store.matchUser(this.user.id))
			delete user.session
			delete user.account_id

			let defpos = lo.get(store.me(), 'dashboard_setting.default_pos')
			if (!defpos) defpos = lo.get(pc.getDefaultPOS(), 'id')

			let convo = store.matchConvo(this.cid)
			let channel = lo.get(convo, 'touchpoint.channel', 'subiz')
			let channel_touchpoint = lo.get(convo, 'touchpoint.source', '')
			if (channel == 'subiz') {
				channel_touchpoint = lo.get(convo, 'source', '')
				try {
					const {hostname} = new URL(channel_touchpoint)
					channel_touchpoint = hostname
				} catch (e) {}
			}

			let defpipeline = pc.getDefaultPipeline()
			let stageid = lo.get(defpipeline, 'stages.0.id')

			this.$showOrder(
				{
					id: 'new',
					payment_made: 0,
					payment_status: 'unpaid',
					related_conversations: [this.cid],
					is_draft: true,
					items: [],
					created: Date.now(),
					issue_date: Date.now(),
					created_by: store.me().id,
					updated: Date.now(),
					updated_by: store.me().id,
					pos_id: defpos,
					status: 'open',
					payment_method: {method: 'cod'},
					user: user,
					channel: channel,
					channel_touchpoint: channel_touchpoint,
					salesperson: store.me().id,
					pipeline: defpipeline.id,
					pipeline_stage: stageid,
					assigned: Date.now(),
					no_auto_create_bill_on_delivered: true,
				},
				{no_change_user: true},
			)
		},
	},
}

// src='https://jobseekers.vn/wp-content/uploads/2017/10/Logo.jpg'

/*
 TEST ORDER
	name  price  qty   total  tax    discount               subto.  disc.    tax
	Ao1   100k   x1    100k   10%    20k (sau thue)          100k     20k    10k
	Ao1   100k   x1    100k   10%    20% (sau thue)          100k     22k    10k
	Ao1   100k   x1    100k   10%    100% (sau thue)         100k    110k    10k
	Ao1   100k   x1    100k   10%    400k (sau thue)         100k    110k    10k
	Ao2   100k   x2    200k   10%    10k (truoc thue)        190k       0    19k
	Ao3   100k   x3    300k   10%    20% (truoc thue)        240k       0    24k
	Ao4   100k   x3    300k   10%    100% (truoc thue)       0k         0      0
	Ao4   100k   x3    300k   10%    500k (truoc thue)       0k         0      0
	Bo    200k   x5    1M     10%    0                       1M         0    100
	--------------------------------------------------------------------
	subtotal                                                 1830k
	discount                                                       262k
	tax                                                                    183k

  DISCOUNT AFTER TAX   20% // just add to discount = (1830k+tax)*0.20                                                X = K / subtotal + tax
  DISCOUNT AFTER TAX   200k // just add to discount                                                            K

  DISCOUNT BEFORE TAX   20%  // effect tax // decrease every tax by 20% , add 20%*subtotal to discount         X = Y / subtotal
  DISCOUNT BEFORE TAX   200k //  effeect tax = decrease 200/1830 percentate                                    Y

	-------------------
	total         310k


	Bo 200k 10%    200k
	-------------------
	subtotal       280k

	discount      0
	tax           28
	-------------------
	total         308k



A 100k  10%  100k   100k   10
B 200k  5%   200k   200k   10
---------------------------------------
subtotal            300k
tax                       20
total 320

// D 150 before tax
A 100k  10%  100k   50k    50    5
B 200k  5%   200k   100k   100   5
-------------------------------
subtotal                   150k
tax                              10k
total 160


// D 150 after tax
A 100k  10%  100k    10    5
B 200k  5%   200k    10
-------------------------------
subtotal     300k
tax                  20k
decrease 150
total = 320 - 150 = 170



150k before tax
=> 50k to A, 100k to B


*/
