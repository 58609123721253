
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icon-tabler-alert-triangle-filled",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","stroke":"none"}}),_c('path',{attrs:{"d":"M11.94 2a2.99 2.99 0 012.45 1.279l.108.164 8.431 14.074a2.989 2.989 0 01-2.366 4.474l-.2.009H3.507a2.99 2.99 0 01-2.648-4.308l.101-.189L9.385 3.438A2.989 2.989 0 0111.94 2zm.07 14l-.127.007a1 1 0 000 1.986L12 18l.127-.007a1 1 0 000-1.986L12.01 16zM12 8a1 1 0 00-.993.883L11 9v4l.007.117a1 1 0 001.986 0L13 13V9l-.007-.117A1 1 0 0012 8z","fill":"currentColor","stroke":"none"}})])
          )
        }
      }
    