import ChainMgr from './chain.js'
const flow = require('@subiz/flow')
const config = require('@sb/config')

function NewRealtimeChain(db, realtime, pubsub, topics, inMem) {
	let syncStatus = {} // 'offline' // 'online'
	realtime.onInterrupted(() => {
		syncStatus = {} // 'offline'
	})

	let chaindb = new ChainMgr(config.db_prefix + db, inMem)
	chaindb.init()
	let me = {}

	realtime.subscribe(topics)

	me.matchSync = (chain) => syncStatus[chain]

	me.list = (chain, api, name) => {
		if (syncStatus[chain] !== 'online') syncq.push({chain, api, name})
		return chaindb.listM(chain)
	}

	me.resetAnchor = (chain) => chaindb.resetAnchor(chain)

	me.fetchMore = async (chain, limit, api) => {
		let out = (await chaindb.fetchMore(chain, limit, api)) || {}
		if (out.error) return out
		if (!out.old_anchor) syncStatus[chain] = 'online'
		return out
	}

	me.put = chaindb.put.bind(chaindb)
	me.del = chaindb.del.bind(chaindb)

	let syncq = new flow.batch(50, 1000, async (reqs) => {
		reqs = reqs.filter((req) => {
			return syncStatus[req.chain] !== 'online'
		})
		let reqM = {}
		reqs.map((req) => {
			reqM[req.chain] = req
		})

		if (reqs.length == 0) return
		let {error: suberr} = await realtime.subscribe(topics)

		await flow.map(reqM, 4, async (req, chain) => {
			await chaindb.reload(chain, req.api)
			syncStatus[chain] = suberr ? 'offline' : 'online'
			pubsub.publish(req.name, chain)
		})
	})

	me.match = (chain, id, api, name) => {
		if (syncStatus[chain] !== 'online') syncq.push({chain, api, name})
		return chaindb.match(chain, id)
	}
	return me
}

export default NewRealtimeChain
