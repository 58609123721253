
function pagination(total, current) {
	if (total < 12) {
		let pages = []
		for (var i = 1; i <= total; i++) pages.push(i)
		return pages
	}

	if (current < 7) return [1, 2, 3, 4, 5, 6, 7, 8, '...', total - 1, total]

	// current >= 7
	if (current > total - 6) {
		return [1, 2, '...', total - 7, total - 6, total - 5, total - 4, total - 3, total - 2, total - 1, total]
	}

	// if (current >= 7 && current <= n - 6) {
	return [1, 2, '...', current - 2, current - 3, current, current + 1, current + 2, '...', total - 1, total]
	//}

	if (!current) current = 1
	if (current < 1) current = 1
	if (current > total) current = total

	let cc = current - 2
	let c = current - 1
	let C = current + 1
	let CC = current + 2

	// missing both
	if (cc < 1 && CC > total) {
		if (total == 1) return [1]
		if (total == 2) return [1, 2]
		// total == 3, total cannot > 3 since it would make either prefix or surfix enought
		return [1, 2, 3]
	}

	let prefix = []
	let suffix = []
	// missing after
	if (cc >= 1 && CC > total) {
		if (current == total) suffix = []
		//  current == total-1
		else suffix = [total]
	}

	// missing before
	if (cc < 1 && CC <= total) {
		if (current == 1) prefix = [1]
		if (current == 2) prefix = [1, 2]
	}

	if (cc >= 1) {
		// do prefix
		if (cc == 1) prefix = [1, 2, 3]
		if (cc == 2) prefix = [1, 2, 3, 4]
		if (cc == 3) prefix = [1, 2, 3, 4, 5]
		if (cc > 3) prefix = [1, '...', cc, c, current]
	}

	if (CC <= total) {
		// do suffix

		suffix = [C, CC]
		if (CC == total) {
			// do nothing
		}
		if (CC == total - 1) suffix.push(total)
		if (CC == total - 2) {
			suffix.push(total - 1)
			suffix.push(total)
		}
		if (CC < total - 2) {
			suffix.push('...')
			suffix.push(total)
		}
	}

	return prefix.concat(suffix)
}

export default {
	name: 'pagination',
	props: ['page', 'limit', 'disabled', 'total'],
	methods: {
		getTotalPages() {
			return Math.ceil(this.total / this.limit)
		},
	},

	render() {
		if (!this.total) return null
		const pages = pagination(this.getTotalPages(), this.page)
		let prevcls = 'pagination_btn pagination_navigator'
		let nextcls = 'pagination_btn pagination_navigator'
		if (this.page === 1) prevcls += ' pagination_btn__disabled'
		if (this.page === this.getTotalPages()) nextcls += ' pagination_btn__disabled'

		return (
			<div class='pagination' style={this.disabled ? 'pointer-events: none; opacity: 0.85' : ''}>
				<div class={prevcls} vOn:click={(_) => this.$emit('change', this.page - 1)}>
					<Icon name='chevron-left' size='18' />
				</div>
				{lo.map(pages, (page) => {
					if (page === '...') return <div class='pagination_btn pagination_btn__disabled'>...</div>

					let cls = 'pagination_btn'
					if (this.page === page) cls += ' pagination_btn__active'
					return (
						<div class={cls} vOn:click={(_) => this.$emit('change', page)}>
							{page}
						</div>
					)
				})}
				<div class={nextcls} vOn:click={(_) => this.$emit('change', this.page + 1)}>
					<Icon name='chevron-right' size='18' />
				</div>
			</div>
		)
	},
}
