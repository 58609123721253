import store from '@sb/store'

export const canEditUser = (user) => {
	let scopes = lo.get(store.me(), 'scopes') || []
	let maps = store.getPrimitiveScope(scopes)
	if (maps['user:update'] || maps['user:update:all']) return true
	if (maps['user:update:none']) return false

	let owners = lo.get(user, 'lead_owners') || []
	return lo.includes(owners, store.me().id)
}

export const canViewUser = (user) => {
	let scopes = lo.get(store.me(), 'scopes') || []
	let maps = store.getPrimitiveScope(scopes)
	if (maps['user:read'] || maps['user:read:all']) return true
	if (maps['user:read:none']) return false

	let owners = lo.get(user, 'lead_owners') || []
	return lo.includes(owners, store.me().id)
}

export const canViewConvo = (convo) => {
	let scopes = lo.get(store.me(), 'scopes') || []
	let maps = store.getPrimitiveScope(scopes)
	if (maps['conversation:read'] || maps['conversation:read:all']) return true
	if (maps['conversation:read:none']) return false

	let members = lo.get(convo, 'members')
	return lo.find(members, (mem) => mem.id === store.me().id && mem.membership !== 'left')
}

export const canEditConvo = (convo) => {
	let scopes = lo.get(store.me(), 'scopes') || []
	let maps = store.getPrimitiveScope(scopes)
	if (maps['conversation:update'] || maps['conversation:update:all']) return true
	if (maps['conversation:update:none']) return false

	let members = lo.get(convo, 'members')
	return lo.find(members, (mem) => mem.id === store.me().id && mem.membership !== 'left')
}

export const canViewReport = () => {
	let scopes = lo.get(store.me(), 'scopes') || []
	let maps = store.getPrimitiveScope(scopes)

	return maps['report:read'] || maps['report:read:all']
}
