import accounting from 'accounting'

export default {
	name: 'money-input',
	props: ['value', 'allow_empty', 'disabled', 'lg'],

	data() {
		return {
			num: '',
		}
	},

	mounted() {
		this.convertToMask(this.value + '')
	},

	watch: {
		value() {
			this.convertToMask(this.value + '')
		},
	},

	methods: {
		convertToMask(num) {
			let value = num.replace(/[^\d.-]/g, '')

			// special case when endding character is .
			let special = value.endsWith('.')
			let special2 = value === '-'
			let precision = 0

			if (value.indexOf('.') > -1) {
				precision = lo.split(value, '.')
				precision = lo.last(precision)
				precision = precision.length
			}

			if (special2) {
			} else {
				this.num = accounting.formatNumber(value, precision, ',')
			}
			if (special) this.num = this.num + '.'
			if (this.allow_empty && this.num === '0') this.num = ''
		},

		onChangeNum(e) {
			let value = e.target.value
			this.num = value
			this.convertToMask(value)
			if (this.num === '-') return
			this.$emit('change', accounting.unformat(this.num))
		},

		focus() {
			this.$refs.input.focus()
		},

		blur() {
			this.$refs.input.blur()
		},
		Focus() {
			this.$refs.input.focus()
			setTimeout(() => this.$refs.input.focus(), 20)
			setTimeout(() => this.$refs.input.focus(), 200)
		},
	},

	render() {
		return (
			<input
				disabled={this.disabled}
				ref='input'
				{...{attrs: this.$attrs}}
				value={this.num}
				vOn:input={this.onChangeNum}
				vOn:blur={(e) => this.$emit('blur', e)}
				vOn:keydown={(e) => this.$emit('keydown', e)}
				vOn:keyup={(e) => this.$emit('keyup', e)}
			/>
		)
	},
}
