export default {
	name: 'arrow_right',

	render() {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24px'
				height='24px'
				viewBox='0 0 24 24'
				fill='none'
				stroke='currentColor'
				stroke-width='2'
				stroke-linecap='round'
				stroke-linejoin='round'>
				<line x1='0' y1='12' x2='20' y2='12'></line>
				<polyline points='15 6 22 12 15 18'></polyline>
			</svg>
		)
	},
}
