import store from '@sb/store'
var sb = require('@sb/util')

export default {
	name: 'text-call-message',
	props: ['ev'],
	data() {
		return {
			loading: false,
			isError: false,
		}
	},

	watch: {
		ev(newEv, oldEv) {
			if (newEv.id !== oldEv.id) {
				if (this.shouldRefetchData()) this.fetchCallData()
			}
		},
	},

	mounted() {
		if (this.shouldRefetchData()) this.fetchCallData()
	},

	methods: {
		shouldRefetchData() {
			let cid = lo.get(this.ev, 'data.message.conversation_id')
			let convo = store.matchConvo(cid)
			return !convo
		},

		async fetchCallData() {
			this.loading = true
			this.isError = false
			let cid = lo.get(this.ev, 'data.conversation.id')
			let res = await store.fetchConvos([cid])
			this.loading = false
			if (res.error) this.isError = true
			this.$forceUpdate()
		},

		onClickCall() {
			let cid = lo.get(this.ev, 'data.conversation.id')
			let uid = lo.get(this.ev, 'user_id')
			this.$root.$emit('showQuickview', {user_id: uid, convo_id: cid})
		},
	},

	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		let cId = lo.get(this.ev, 'data.conversation.id')
		let convo = store.matchConvo(cId) || {}
		let cls = `message_call_wrapper message_call_wrapper__${byType}`

		if (this.isError) {
			return (
				<div class={cls}>
					<Icon name='alert-triangle' size='16' class='text__warning mr-2' />
					<div class='text__secondary mr-2'>{this.$t('error_load_data')}.</div>
					<a href='javascript:;' vOn:click={this.fetchCallData}>
						{this.$t('reload')}
					</a>
				</div>
			)
		}
		if (this.loading) {
			return (
				<div class={cls}>
					<div class='message_call text__secondary'>{this.$t('loading')}...</div>
				</div>
			)
		}

		let $icon = null
		let direction = lo.get(convo, 'call.direction')
		let isMissed = lo.get(convo, 'call.is_missed')
		let duration_sec = lo.get(convo, 'call.recorded_audio.duration', 0)
		if (!duration_sec) {
			let answered = lo.get(convo, 'call.answered', 0)
			let started = lo.get(convo, 'call.started', 0)
			let sec = lo.get(convo, 'call.duration_sec', 0)
			duration_sec = (sec * 1000 + answered - started) / 1000
		}

		let text = ''
		let $recall = null

		if (isMissed && direction === 'outbound') {
			$icon = (
				<svg width='16' height='16' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3'
						fill='#929292'
					/>
					<path
						d='M11.25 6.75L15 3'
						stroke='#929292'
						stroke-width='1.2'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path d='M12 3H15V6' stroke='#929292' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round' />
				</svg>
			)
			text = this.$t('outbound_call_failed')
		}

		if (isMissed && direction === 'inbound') {
			$icon = (
				<svg width='14' height='12' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M1.52941 1H4.58824L6.11765 4.82353L4.20588 5.97059C5.02485 7.63117 6.36883 8.97515 8.02941 9.79412L9.17647 7.88235L13 9.41177V12.4706C13 12.8762 12.8389 13.2652 12.552 13.552C12.2652 13.8389 11.8762 14 11.4706 14C8.48767 13.8187 5.67424 12.552 3.5611 10.4389C1.44797 8.32577 0.181273 5.51233 0 2.52941C0 2.12379 0.161134 1.73477 0.447954 1.44795C0.734775 1.16113 1.12379 1 1.52941 1Z'
						fill='#FF3647'
					/>
					<path
						d='M11 1L15 5M15 1L11 5'
						stroke='#FF3647'
						stroke-width='1.2'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</svg>
			)
			text = this.$t('missed_call')
			$recall = (
				<a class='ml-3 mr-3' href='javascript:;'>
					{this.$t('recall')}
				</a>
			)
		}

		if (!isMissed && direction === 'outbound') {
			$icon = (
				<svg width='16' height='16' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3Z'
						fill='#19B600'
					/>
					<path
						d='M11.25 6.75L15 3'
						stroke='#19B600'
						stroke-width='1.2'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path d='M12 3H15V6' stroke='#19B600' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round' />
				</svg>
			)
			text = sb.displayClockTimer(duration_sec)
		}

		if (!isMissed && direction === 'inbound') {
			$icon = (
				<svg width='16' height='16' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M3.75 3H6.75L8.25 6.75L6.375 7.875C7.17822 9.50365 8.49635 10.8218 10.125 11.625L11.25 9.75L15 11.25V14.25C15 14.6478 14.842 15.0294 14.5607 15.3107C14.2794 15.592 13.8978 15.75 13.5 15.75C10.5744 15.5722 7.81512 14.3299 5.74262 12.2574C3.67013 10.1849 2.42779 7.42555 2.25 4.5C2.25 4.10218 2.40804 3.72064 2.68934 3.43934C2.97064 3.15804 3.35218 3 3.75 3'
						fill='#19B600'
					/>
					<path
						d='M11.25 6.75L15 3'
						stroke='#19B600'
						stroke-width='1.2'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M11.25 3.75V6.75H14.25'
						stroke='#19B600'
						stroke-width='1.2'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</svg>
			)
			text = sb.displayClockTimer(duration_sec)
		}

		return (
			<div class={cls} vOn:click={() => this.onClickCall()}>
				<div class='message_call'>
					{$icon} <div class='ml-2'>{text}</div>
				</div>
				{$recall}
			</div>
		)
	},
}
