let sb = require('@sb/util')

export default {
	props: ['items', 'selected'],

	data () {
		return {
			search: '',
			isShow: false,
		}
	},

	render () {
		let fields = sb.filter(this.items, item => {
			let search = this.search.toLowerCase()
			let name = item || ''
			return name.toLowerCase().indexOf(search) > -1
		})
		let $fields = sb.map(fields, field => {
			return (
				<li class="custom-select--item-options" vOn:click={() => this.add(field)}>
					{field}
				</li>
			)
		})
		let $selected = sb.map(this.selected, (item, i) => {
			return (
				<div class="custom-select--selected-item" vOn:click_stop_prevent={() => this.remove(i)}>
					{item}
					<i class="icn-delete delete-tag"></i>
				</div>
			)
		})
		let $dropdown = (
			<div class="custom-select--wrap-options">
				<input
					vOn:click_stop_prevent={() => {
						this.isShow = true
					}}
					type="text"
					vModel={this.search}
					placeholder="Tìm kiếm"
					class="custom-select--input-search"
				/>
				<ul class="custom-select--items-options">{$fields}</ul>
			</div>
		)
		if (!this.isShow) {
			$dropdown = null
		}
		return (
			<div class="form-control custom-select">
				<div
					class="custom-select--wrap-selected"
					v-clickaway={this.clickaway}
					vOn:click={() => {
						this.isShow = !this.isShow
					}}>
					<div class="custom-select--selected-items">{$selected}</div>
					{$dropdown}
					<div class="custom-select--icon-dropdown">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g opacity="0.6">
								<path d="M7 9.5L12 14.5L17 9.5H7Z" fill="black"></path>
							</g>
						</svg>
					</div>
				</div>
				{/* {$dropdown} */}
			</div>
		)
	},

	methods: {
		clickaway () {
			this.isShow = false
		},

		add (field) {
			this.$emit('onAddItem', field)
		},

		remove (index) {
			this.$emit('onRemoveItem', index)
		},
	},
}
