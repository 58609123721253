import store from '@sb/store'
import sb from '@sb/util'
import {getAttributeName} from '../common.js'

const PREFIX_ATTRIBUTES = ['fullname', 'emails', 'phones']

export default {
	name: 'add-lead-modal',
	props: ['open', 'user', 'segmentId'],

	data() {
		return {
			userData: {},
			saving: false,
			saveToSegment: '',
			isShrink: true,
		}
	},

	watch: {
		open() {
			if (this.open) this.resetData()
		},
	},

	methods: {
		resetData() {
			this.userData = lo.cloneDeep(this.user || {})
			this.saving = false
			this.isShrink = true
			let segment = store.matchSegment()[this.segmentId] || {}
			this.saveToSegment = segment.id
			setTimeout(() => this.$refs.fullname_input.focus(), 100)
		},

		getAllFields() {
			let priorityAttrs = lo.map(PREFIX_ATTRIBUTES, (key) => store.matchUserAttribute()[key] || {})
			let remains = lo.filter(
				store.matchUserAttribute(),
				(attr) =>
					!attr.archived && !attr.is_system && attr.kind !== 'system' && !lo.includes(PREFIX_ATTRIBUTES, attr.key),
			)

			return [...priorityAttrs, ...remains]
		},

		async closeModal() {
			this.$emit('close')
		},

		getFilteredSegments() {
			let segments = lo.filter(
				store.matchSegment(),
				(segment) => segment.type === 'manual' && lo.includes(segment.managers, store.me().id),
			)
			return segments
		},

		renderField(attr) {
			if (!attr) return
			let attrname = getAttributeName(attr) || lo.get(store.matchUserAttribute(), `${attr.key}.label`)
			attrname = this.$t(attrname)
			return (
				<div class='lead_add_user_modal_field'>
					<div class='text__semibold mb-2'>
						{attrname} {attr.key === 'fullname' && <span class='text__danger'>*</span>}
					</div>
					{this.renderTextInput(attr)}
					{this.renderNumberInput(attr)}
					{this.renderBooleanInput(attr)}
					{this.renderDateTimeInput(attr)}
				</div>
			)
		},

		renderSecondaryFields() {
			let attrs = lo.filter(
				store.matchUserAttribute(),
				(attr) =>
					!attr.archived && !attr.is_system && attr.kind !== 'system' && !lo.includes(PREFIX_ATTRIBUTES, attr.key),
			)

			attrs.unshift(lo.find(store.matchUserAttribute(), (attr) => attr.key === 'lifecycle_stage'))

			if (this.isShrink) {
				return (
					<div
						class='link link__secondary align-items-center'
						style='display: inline-flex'
						vOn:click={() => (this.isShrink = false)}
					>
						{this.$t('show_more_fields')} <Icon name='chevron-down' class='ml-2' size='16' stroke-width='2' />
					</div>
				)
			}

			return lo.map(attrs, this.renderField)
		},

		renderPrimaryFields() {
			let attrs = lo.map(PREFIX_ATTRIBUTES, (key) => store.matchUserAttribute()[key])
			let segments = lo.filter(
				store.matchSegment(),
				(segment) =>
					lo.includes(segment.managers, store.me().id) && !(segment.id || '').startsWith('sgdefault_source_campaign'),
			)
			let items = lo.map(segments, (segment) => ({
				id: segment.id,
				label: segment.name,
			}))
			items.unshift({
				id: '',
				label: this.$t('dont_save'),
			})

			return (
				<Fragment>
					{lo.map(attrs, this.renderField)}
					{this.renderLeadOwnersField()}
				</Fragment>
			)
		},

		renderLeadOwnersField() {
			let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent' && agent.state === 'active')
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				label: sb.getAgentDisplayName(agent),
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
			}))

			let owner = sb.getUserTextAttr(this.userData, 'owner')
			return (
				<div class='lead_add_user_modal_field'>
					<div class='text__semibold mb-2'>{this.$t('assign_to')}</div>
					<Dropdown
						items={items}
						mode='input'
						selected={owner}
						placeholder={this.$t('choose_an_agent_to_assign')}
						vOn:select={(item) => this.onChangeAttribute({key: 'owner', type: 'text'}, item.id)}
					/>
				</div>
			)
		},

		onChangeAttribute(attr, value) {
			let newattr = lo.cloneDeep(attr)
			let user = lo.cloneDeep(this.userData)
			newattr[attr.type] = value

			let attributes = user.attributes || []

			let idx = lo.findIndex(user.attributes, (eattr) => eattr.key === newattr.key)
			if (idx > -1) {
				lo.set(user, `attributes.${idx}.${attr.type}`, value)
			} else {
				attributes.push({key: attr.key, [attr.type]: value})
				user.attributes = attributes
			}

			this.userData = user
		},

		onClickFileInput(attr) {
			if (this.uploading) return
			this.$refs[`${attr.key}_file_input`].click()
		},

		async onUploadImage(e, attr) {
			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (this.uploading) return
			this.uploading = true
			let res = await store.uploadLocalFile(file)
			this.uploading = false
			if (res.error) {
				this.$showError(res.error)
			}
			this.onChangeAttribute(attr, res.url)
		},

		onToggleCheckbox(attr, value) {
			let userattr = lo.find(this.editAttributes, (uattr) => uattr.key === attr.key) || {}
			let values = userattr.text ? lo.split(userattr.text, ',') : []
			if (values.includes(value)) {
				values = lo.filter(values, (v) => v !== value)
			} else {
				values.push(value)
			}

			this.onChangeAttribute(attr, values.join(','))
		},

		renderTextInput(attr) {
			if (attr.type !== 'text' && attr.type !== 'list') return null
			let $input = null
			let userattr =
				lo.find(this.userData.attributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}

			if (attr.is_image) {
				return (
					<div class='d-flex align-items-center'>
						<img2 src={userattr.text} style='width: 32px; height: 32px' clickable />
						<div class='link ml-3 mr-2' vOn:click={(_) => this.onClickFileInput(attr)}>
							{this.$t('change')}
						</div>
						{this.uploading && <Spinner size='14' mode='blue' />}
						<input
							type='file'
							ref={`${attr.key}_file_input`}
							style='display: none'
							vOn:click={(e) => (e.target.value = '')}
							vOn:change={(e) => this.onUploadImage(e, attr)}
						/>
					</div>
				)
			}

			if (attr.select === 'radio') {
				return lo.map(attr.items, (item) => {
					return (
						<div class='form-check'>
							<input
								type='radio'
								class='form-check-input form-check-input--light'
								name={`${attr.key}_radio`}
								id={`${attr.key}_radio_${item.value}`}
								checked={item.value === userattr.text}
								vOn:change={(e) => this.onChangeAttribute(attr, item.value)}
							/>
							<label class='form-check-label' for={`${attr.key}_radio_${item.value}`}>
								{item.label}
							</label>
						</div>
					)
				})
			}

			if (attr.select === 'checkbox') {
				let values = userattr.text ? lo.split(userattr.text, ',') : []
				return lo.map(attr.items, (item) => {
					return (
						<div class='form-check'>
							<input
								type='checkbox'
								id={`${attr.key}_checkbox_${item.value}`}
								class='form-check-input form-check-input--light'
								name={`${attr.key}_checkbox`}
								checked={lo.includes(values, item.value)}
								vOn:change={(e) => this.onToggleCheckbox(attr, item.value)}
							/>
							<label class='form-check-label' for={`${attr.key}_checkbox_${item.value}`}>
								{item.label}
							</label>
						</div>
					)
				})
			}

			if (attr.select === 'dropdown') {
				let items = lo.map(attr.items, (item) => ({id: item.value, label: item.label}))
				if (userattr.text) items.unshift({id: '', label: this.$t('unselect')})
				return (
					<Dropdown2
						items={items}
						mode='input'
						extra_cls=' form-control'
						selected={userattr.text}
						vOn:select={(item) => this.onChangeAttribute(attr, item.id)}
					/>
				)
			}

			if (attr.multiple_line) {
				return (
					<textarea
						ref={`${attr.key}_input`}
						class='form-control'
						value={userattr.text}
						vOn:change={(e) => this.onChangeAttribute(attr, e.target.value)}
					/>
				)
			}

			return (
				<input
					class='form-control'
					ref={`${attr.key}_input`}
					value={userattr.text}
					vOn:change={(e) => this.onChangeAttribute(attr, e.target.value)}
				/>
			)
		},

		renderNumberInput(attr) {
			if (attr.type !== 'number') return null
			let userattr =
				lo.find(this.userData.attributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}
			return (
				<input
					type='number'
					class='form-control'
					ref={`${attr.key}_input`}
					value={userattr.number}
					vOn:change={(e) => this.onChangeAttribute(attr, +e.target.value)}
				/>
			)
		},

		renderBooleanInput(attr) {
			if (attr.type !== 'boolean') return null
			let userattr =
				lo.find(this.userData.attributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}
			return <Sw green checked={userattr.boolean} vOn:change={(checked) => this.onChangeAttribute(attr, checked)} />
		},

		renderDateTimeInput(attr) {
			if (attr.type !== 'datetime') return null
			let userattr =
				lo.find(this.userData.attributes, (uattr) => uattr.key.toLowerCase() === attr.key.toLowerCase()) || {}
			let isDateOnly = attr.is_date_only
			let fm = isDateOnly ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss"

			let date = userattr.datetime ? format(new Date(userattr.datetime), fm) : ''
			return (
				<input
					class='form-control'
					style='width: 100%'
					ref={`${attr.key}_input`}
					type={isDateOnly ? 'date' : 'datetime-local'}
					value={date}
					vOn:change={(e) => {
						let date = e.target.value ? new Date(e.target.value) : ''
						this.onChangeAttribute(attr, date ? date.toISOString() : '')
					}}
				/>
			)
		},

		async submit() {
			if (this.saving) return
			this.saving = true
			let user = lo.cloneDeep(this.userData)
			user.channel = 'import'
			user.channel_source = 'import'
			let owner = sb.getUserTextAttr(user, 'owner')
			if (owner) user.lead_owners = [owner]

			//let res = await this.$createUser(user)
			//console.log('VVVVVVVVVVVVVV', res)
			//return
			let body = await store.createUser(user)
			if (this.saveToSegment) {
				await store.addUserToSegment([body.id], this.saveToSegment)
			}
			this.saving = false
			if (body.error) return this.$showError(body.error)
			this.$showSuccess(this.$t('add_lead_successfully'))
			this.$emit('close')
			this.$emit('success', body)
		},
	},

	render() {
		let segments = this.getFilteredSegments()
		let items = lo.map(segments, (segment) => ({
			id: segment.id,
			label: segment.name,
		}))
		let $content = null

		return (
			<Modal show={this.open} vOn:bg={this.closeModal}>
				<div class='modal__background' style='overflow: hidden;'>
					<div class='modal_content' style='width: 500px;'>
						<div class='modal_content__header d-flex align-items-center'>
							<div>{this.$t('add_lead')}</div>
							<Icon name='x' class='btn__close ml-auto' vOn:click={this.closeModal} />
						</div>
						<div class='modal_content__main' style='overflow: auto; max-height: 480px;'>
							{this.renderPrimaryFields()}
							{this.renderSecondaryFields()}
						</div>
						<div class='modal_content__footer'>
							<div class='d-flex align-items-center'>
								<div class='btn btn__light ml-auto link link__secondary' vOn:click={this.closeModal}>
									{this.$t('cancel')}
								</div>
								<div style='flex: 1;'></div>
								<button
									type='button'
									style='width: 120px; display: inline-flex'
									class='ml-4 btn btn__success align-items-center justify-content-center'
									vOn:click={this.submit}
									disabled={this.saving}
								>
									{this.saving && <Spinner size='16' class='mr-2' />}
									{this.$t('add')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	},
}
