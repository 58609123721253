export default {
	props: ['show', 'zindex', 'width', 'height'], // open: true, false; position: 'right', 'left'
	data() {
		return {
			extraCls: ' ',
			hasDom: false,
		}
	},

	methods: {
		doShow() {
			let actionIndex = this.actionIndex || 0
			actionIndex++
			this.actionIndex = actionIndex

			let waittime = 0
			if (!this.hasDom) this.hasDom = true
			this.$nextTick(() => {
				setTimeout(() => {
					if (this.actionIndex != actionIndex) return // outdated
					this.extraCls = ' newmodal__show'
				}, 10)
			})
		},

		doHide() {
			if (!this.hasDom) return
			let actionIndex = this.actionIndex || 0
			actionIndex++
			this.actionIndex = actionIndex
			this.extraCls = ' newmodal__hide'
			// rerender
			setTimeout(() => {
				if (this.actionIndex != actionIndex) return // outdated
				this.hasDom = false // cleanup
			}, 150) // aninmation
		},
	},

	mounted() {
		if (this.show) this.doShow()
		else this.doHide()
	},

	watch: {
		show(show) {
			if (show) this.doShow()
			else this.doHide()
		},
	},

	render() {
		if (!this.hasDom) return null //
		let zindexStyle = undefined
		if (this.zindex) zindexStyle = 'z-index:' + this.zindex
		let containerStyle = ''
		if (this.width) containerStyle += ';width:' + this.width
		if (this.height) containerStyle += ';height:' + this.height
		return (
			<div style={this.style}>
				<div class={'newmodal__overlay ' + this.extraCls} style={zindexStyle}></div>
				<div class={'newmodal ' + this.extraCls} vOn:click={() => this.$emit('bg')} style={zindexStyle}>
					<div style='position: relative' data-comment='this div is required to render absolute child, do not delete'>
						<div class='modal__container' vOn:click_stop={() => false} style={containerStyle}>
							{this.$slots.default}
						</div>
					</div>
				</div>
			</div>
		)
	},
}
