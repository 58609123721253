import sb from '@sb/util'
import AppendToBody from '../commons/append_to_body.js'

const DROPDOWN_WIDTH = 200
const DROPDOWN_HEIGHT = 250
const EDGE_DISTANCE = 50 // minumun distance from dropdown to window edge

export default {
	name: 'lexical-mentions-dropdown',
	props: ['agents', 'open', 'range'],

	data() {
		return {
			softSelected: '',
		}
	},

	watch: {
		open() {
			this.resetSoftSelect()
		},

		agents() {
			this.resetSoftSelect()
		},
	},

	mounted() {
		document.body.addEventListener('keyup', this.onKeyup)
		document.body.addEventListener('keydown', this.onKeydown)
	},

	destroyed() {
		document.body.removeEventListener('keyup', this.onKeyup)
		document.body.removeEventListener('keydown', this.onKeydown)
	},

	methods: {
		resetSoftSelect() {
			if (!lo.size(this.agents)) return
			if (!this.open) return
			this.softSelected = lo.get(lo.first(this.agents), 'id')
		},
		onMouseOver(item) {
			this.softSelected = item.id
		},

		renderItem(agent, idx) {
			let cls = 'dropdown_item lexical_mention_dropdown_item'
			if (this.softSelected === agent.id) cls += ' active'
			return (
				<div
					class={cls}
					data-agent-id={agent.id}
					vOn:click_stop={(_) => this.onSelectItem(agent)}
					vOn:mouseover={(_) => this.onMouseOver(agent)}
				>
					<div class='d-flex align-items-center'>
						<Avatar agent={agent} size='xs' />
						<div class='ml-2'>{sb.getAgentDisplayName(agent)}</div>
					</div>
				</div>
			)
		},

		onKeydown(e) {
			if (!this.open) return
			if (!lo.size(this.agents)) return
			switch (e.keyCode) {
				case 38: {
					// UP
					let items = this.agents
					let idx = lo.findIndex(items, (item) => item.id === this.softSelected)
					idx--
					if (idx < 0) idx = lo.size(items) - 1
					this.softSelected = items[idx].id
					// this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()

					// check to scroll
					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-agent-id="${this.softSelected}"]`)
					$element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
				case 40: {
					// DOWN
					let items = this.agents
					let idx = lo.findIndex(items, (item) => item.id === this.softSelected)
					idx++
					if (idx >= lo.size(items)) idx = 0
					this.softSelected = items[idx].id

					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-agent-id="${this.softSelected}"]`)
					$element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
			}
		},

		onKeyup(e) {
			if (!this.open) return
			if (!lo.size(this.agents)) return
			switch (e.keyCode) {
				case 27: // ESC
					this.closeDropdown()
					e.preventDefault()
					e.stopPropagation()
					break
				case 13: // ENTER
					var item = lo.find(this.agents, (item) => item.id === this.softSelected)
					this.onSelectItem(item)
					break
			}
		},

		closeDropdown() {
			this.$emit('close')
		},

		onSelectItem(agent) {
			console.log('onSelectItemsssssss 111111111')
			this.$emit('select', agent)
			this.closeDropdown()
		},
	},

	render() {
		let rect = this.range ? this.range.getBoundingClientRect() : {}
		let {top = 0, left = 0, height = 0, right = 0} = rect
		let isBottom = document.body.offsetHeight - (top + height + EDGE_DISTANCE) < DROPDOWN_HEIGHT
		let topStyle = `top: ${top + height}px`
		let bottomStyle = `bottom: ${document.body.offsetHeight - top}px; top: unset`

		let style = {
			position: 'absolute',
			zIndex: 999,
			left: left + 'px',
			top: !isBottom ? top + height + 'px' : 'unset',
			bottom: isBottom ? document.body.offsetHeight - top + 'px' : 'unset',
			width: DROPDOWN_WIDTH + 'px',
			maxHeight: DROPDOWN_HEIGHT + 'px',
		}
		if (!this.open) style.display = 'none'

		return (
			<AppendToBody>
				<div class='dropdown' ref='dropdown' style={style}>
					{lo.map(this.agents, this.renderItem)}
				</div>
			</AppendToBody>
		)
	},
}
