import store from '@sb/store'
import c from '../product/common.js'

const loadHelpers = () => import(/*webpackChunkName: 'category_helpers'*/ '../product/category_helpers.js')

export default {
	name: 'product-image',
	props: ['product', 'clickable', 'has_tooltip', 'extra_cls'],
	data() {
		return {
			exactProduct: {},

			// cateogry helpers,
			helpers: {
				getCategoryShortName: function () {},
			},
		}
	},

	async mounted() {
		this.loadProduct()
		if (this.has_tooltip) this.helpers = await loadHelpers()
	},

	watch: {
		product(newProduct, oldProduct) {
			this.loadProduct()
		},
	},

	methods: {
		async loadProduct() {
			let src = this.product.image || lo.get(this.product, 'images.0')
			if (src) {
				this.exactProduct = this.product
				return
			}

			await store.fetchProducts([this.product.id])
			this.exactProduct = store.matchProduct(this.product.id) || {}
		},

		renderImage() {
			let cls = 'no-shrink'
			if (this.extra_cls) cls += ` ${this.extra_cls}`
			let src = lo.get(this.exactProduct, 'image') || lo.get(this.exactProduct, 'image.0')
			return <img2 src={src} class={cls} clickable={this.clickable} />
		},

		renderTooltipImage() {
			let helpers = this.helpers
			let product = this.exactProduct
			let $category = ''
			let categoryPaths = lo.split(product.category, '.')

			if (!product.category) {
				$category = null
			} else if (!helpers.getCategoryShortName(product.category)) {
				$category = null
			} else if (lo.size(categoryPaths) === 1) {
				$category = <div class='mb-3'>[{helpers.getCategoryShortName(product.category)}]</div>
			} else {
				let lastCategory = helpers.getCategoryShortName(product.category)

				let parentCatId = lo.take(categoryPaths, lo.size(categoryPaths) - 1).join('.')
				$category = (
					<div class='d-flex align-items-center mb-3' style='line-height: 1'>
						{helpers.getCategoryShortName(parentCatId)}
						<span class='dot dot--sm dot--secondary ml-2 mr-2' style='margin-top: 0' />
						<span class='text__muted'>{lastCategory}</span>
					</div>
				)
			}
			let options = product.options || []
			let props = product.props || []
			let $detail = lo.map(props, (prop) => {
				let option = lo.find(options, (option) => option.key === prop.key)

				return (
					<div class='d-flex mb-1'>
						<div class='text__muted mr-5' style='min-width: 100px'>
							{lo.get(option, 'name')}
						</div>
						<div class='text__right flex__1 text__truncate' title={prop.value}>
							{prop.value}
						</div>
					</div>
				)
			})

			let $dropdown = (
				<div class='product_preview_card'>
					{$category}
					<div class='product_preview_card_image'>
						<img2
							src={product.image || lo.get(product, 'images.0')}
							style='object-fit: cover; width: 100%; height: 100%'
						/>
					</div>
					<div class='product_preview_card_title'>{c.getProductDisplayName(product)}</div>
					<div class='product_preview_card_description'>{c.getProductDisplayDescription(product)}</div>
					{lo.size($detail) > 0 && (
						<Fragment>
							<div class='mt-4 mb-1' style='font-weight: 500; margin-top: 15px'>
								{this.$t('product_properties_short')}
							</div>
							{$detail}
						</Fragment>
					)}
				</div>
			)
			return (
				<HoverDropdown direction='horizontal' dropdown_content={$dropdown}>
					{this.renderImage()}
				</HoverDropdown>
			)
		},
	},

	render() {
		if (this.has_tooltip) return this.renderTooltipImage()
		return this.renderImage()
	},
}
