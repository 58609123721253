var UpdateContactAttribute = {}
var flow = require('@subiz/flow')

// true if update contact attribute progress is successs
// false if progress has error
// we use this variable to make sure there is only one
// confirm popup is displayed at a time
let g_waitingRs

function responseFn(param) {
	if (!g_waitingRs) return
	g_waitingRs(param)
	g_waitingRs = undefined
}

// true if we have already listen for event _confirm_response
// we use this variable to prevent multiple event registration
// on root level
let g_register = false

UpdateContactAttribute.install = (Vue) => {
	Vue.prototype.$updateContactAttribute = function (param) {
		// listen for _confirm_response events if we haven't listen yet
		if (!g_register) {
			g_register = true
			this.$root.$on('_update_contact_response', responseFn)
		}

		// busy waiting for other confirm popup to be closed
		return flow
			.loop(() => new Promise((rs) => (g_waitingRs ? setTimeout(rs, 100, true) : rs(false))))
			.then(() => {
				// show the confirm popup
				return new Promise((rs) => {
					g_waitingRs = rs
					this.$root.$emit('_update_contact_request', param)
				})
			})
	}
}

export default UpdateContactAttribute
