import FormAttachment from './formAttachment.js'

export default {
	props: ['ev'],

	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType === 'user') return // don't support form from use yet
		byType = 'agent'

		let forms = []
		let attachments = lo.get(this.ev, 'data.message.attachments') || []
		lo.each(attachments, (att) => {
			if (!att) return
			if (att.type !== 'form' && att.type !== 'ask_info_form') return
			att.form._type = att.type
			forms.push(att.form)
		})

		let $forms = lo.map(forms, (form, index) => {
			return <FormAttachment key={index} form={form} ev={this.ev} />
		})

		return <div class={`message_container message_container__${byType}`}>{$forms}</div>
	},
}
