import store from '@sb/store'
var sb = require('@sb/util')
import accounting from 'accounting'
import {Time} from '@sb/com'

export default {
	name: 'make-payment-modal',
	props: ['show', 'cid', 'uid', 'order_id'],
	data() {
		return {
			method: 'cash',
			description: '',
			amount: 0,
			picture_url: '',
			paidAt: '',
			uploading: false,
			saving: false,
		}
	},

	watch: {
		show(show) {
			this.method = 'cod'
			this.description = ''
			this.amount = 0
			var d = new Date()
			this.paidAt = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0, -1)
			if (show) sb.focus(this, 'moneyinput')
			this.picture_url = ''
		},
	},

	render() {
		let fullname = sb.getAgentDisplayName(store.me())
		// <Modal onBgClck></Modal>
		return (
			<div>
				<Modal show={this.show} vOn:bg={(_) => this.$emit('close')}>
					<div
						class='modal__background'
						style='width:400px; position: realtive; min-height: 300px; max-height: 600px; overflow: hidden; padding: 0; display: flex; flex-direction: column'
					>
						<div class='assign_agent__header' style='padding: 10px 20px'>
							<div>{this.$t('payment')}</div>
							<Icon
								name='x'
								v-tooltip={this.$t('close')}
								class='btn__close'
								vOn:click_stop={(_) => this.$emit('close')}
							/>
						</div>
						{this.renderBody()}
						<div class='order_modal__footer'>
							<div>
								<Avatar agent={store.me()} size='xs' />
								&nbsp;{fullname}
							</div>
							<div style='flex: 1' />
							<button
								disabled={this.saving || this.amount == 0}
								class='btn btn__success btn__sm btn__lg'
								vOn:click={this.save}
							>
								{this.$t('make_payment')}
							</button>
						</div>
					</div>
				</Modal>
			</div>
		)
	},

	methods: {
		renderBody() {
			let methods = ['cod', 'cash', 'bank_transfer']
			let $methods = lo.map(methods, (med) => {
				return (
					<div class='form-check mt-1'>
						<input
							name='payment_method'
							type='radio'
							checked={med == this.method}
							value={med}
							id={'payment_method_' + med}
							class='form-check-input'
							vOn:change={(e) => this.onChangePM(e, med)}
						/>
						<label for={'payment_method_' + med} class='form-check-label'>
							{this.$t('payment_method_' + med)}
						</label>
					</div>
				)
			})
			let cur = 'VND'
			let acc = store.me().account

			let $pic = (
				<div class='link link__primary' vOn:click={(_) => this.$refs.picture_input.click()}>
					<Icon name='photo' size='1x' style='margin-top: -3px' /> {this.$t('upload_image')}
				</div>
			)

			if (this.picture_url)
				$pic = (
					<fragment>
						<div style='position: relative'>
							<div
								class='agent-avatar__upload'
								vOn:click={(_) => this.$refs.picture_input.click()}
								v-tooltip={this.$t('upload_image')}
							>
								<Icon name='photo' size='1x' />
							</div>
							<img2 src={this.picture_url} width='100' height='100' style='object-fit: contain' />
						</div>
						<span
							class='link link__danger'
							vOn:click={(_) => {
								this.picture_url = ''
							}}
						>
							<Icon name='trash' size='1x' style='margin-top: -3px' /> {this.$t('delete')}
						</span>
					</fragment>
				)
			return (
				<div style='flex: 1; overflow: auto; padding: 20px; background: whitesmoke'>
					<h6 class='mt-4'>
						{this.$t('amount')}
						&nbsp;({cur})
					</h6>
					<MoneyInput
						ref='moneyinput'
						style='font-size: 40px'
						class='form-control'
						value={this.amount}
						vOn:change={this.onChangeAmount}
					/>
					<h6 class='mt-4'>{this.$t('cust_paid_at')}</h6>
					<input type='datetime-local' vModel={this.paidAt} class='form-control' />
					<h6 class='mt-4'>{this.$t('note')}</h6>
					<textarea class='form-control' rows='2' value={this.description} vOn:input={this.onChangeDescription} />

					<h6 class='mt-4'>{this.$t('payment_method')}</h6>
					{$methods}

					<h6 class='mt-4'>{this.$t('payment_picture')}</h6>
					<div class='mt-1 text__muted'>{this.$t('payment_picture_desc')}</div>
					<div class='agent-avatar__upload-container' style='width: 150px;'>
						<input
							type='file'
							accept='image/*'
							style='display: none;'
							ref='picture_input'
							vOn:change={this.onChangePicture}
						/>
						{$pic}
						{this.uploading && (
							<div class='loading-wrapper'>
								<Icon name='loader' size='18' />
							</div>
						)}
					</div>
				</div>
			)
		},

		onChangeDescription(e) {
			this.description = e.target.value
		},

		onChangeAmount(e) {
			this.amount = e
		},

		onChangePM(e) {
			this.method = e.target.value
		},

		async save() {
			if (this.saving) return
			this.saving = true

			// wait for uploading success
			while (this.uploading) await sb.sleep(100)

			let out = await store.recordPayment({
				order_id: this.order_id,
				conversation_id: this.cid,
				user_id: this.uid,
				method: this.method,
				desc: this.description,
				amount: this.amount,
				pic: this.picture_url,
				paid_at: this.paidAt ? new Date(this.paidAt).getTime() : Date.now(),
			})

			this.saving = false
			if (out.error) return this.$showError(out.error)
			this.$showSuccess(this.$t('payment_success'))
			this.$emit('close', true) // true = should refetch
		},

		async onChangePicture(e) {
			let MAXIMUM_SIZE = 5 * 1024 * 1024
			let currentAvatar = this.picture_url

			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (file.size > MAXIMUM_SIZE) {
				return this.$showError(this.$t('error_file_is_too_large'))
			}
			if (this.uploading) return

			this.uploading = true
			this.picture_url = await sb.getBlobUrlFromFile(file)

			let res = await store.uploadLocalFile(file)
			this.uploading = false
			if (res.error) {
				this.picture_url = currentAvatar
				return this.$showError(res.error)
			}
			this.picture_url = res.url
		},
	},
}
