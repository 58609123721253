export const checkName = function (name) {
	if (typeof name !== 'string') return false
	var reg = new RegExp('^.{2,30}$')
	return reg.test(name)
}

export const checkEmail = function (email) {
	if (typeof email !== 'string') return false
	var reg = new RegExp(
		"^(([^<>()[\\]\\\\.,;:\\s@\\']+(\\.[^<>()[\\]\\\\.,;:\\s@\\']+)*)|(\\'.+\\'))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
	)
	return reg.test(email)
}

export const checkPassword = function (password) {
	if (typeof password !== 'string') return false
	var reg = new RegExp('^.{8,200}$')
	return reg.test(password)
}

export const validateUrl = (s) => {
	return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
		s,
	)
}

export const validateDomain = (s) => {
	return /^[a-zA-Z0-9][a-zA-Z0-9-]*(?:\.[a-zA-Z]{2,})+$/.test(s)
}

export const checkIp = (ip = '', options = {subnet: false}) => {
	if (options.subnet) {
		if (!ip) return false
		if (typeof ip !== 'string') return false
		let trances = ip.split('.')
		if (trances.length !== 4) return false
		let [sub1, sub2, sub3, sub4] = trances
		if (sub1.length > 3 || sub2.length > 3 || sub3.length > 3) return false
		const DIGIT_REGEX = /^\d+$/
		return (
			DIGIT_REGEX.test(sub1) &&
			DIGIT_REGEX.test(sub2) &&
			(DIGIT_REGEX.test(sub3) || (sub3 === '*' && sub4 === '*')) &&
			(DIGIT_REGEX.test(sub4) || sub4 === '*' || checkIsSubnet(sub4))
		)
	}
	return /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(
		ip,
	)
}

function checkIsSubnet(lastIp = '') {
	if (typeof lastIp !== 'string') return false
  if (!lastIp) return false

	let [sub1, sub2] = lastIp.split('/')
  console.log("sub1", sub1, sub2)
	if (sub1.length > 3 || sub2.length > 2) return false
	const DIGIT_REGEX = /^\d+$/
	return DIGIT_REGEX.test(sub1) && +sub1 <= 255 && DIGIT_REGEX.test(sub2) && +sub2 <= 32
}

export const checkDomain = (domain) =>
	/^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/.test(
		domain,
	)
export const checkPhoneNumber = (phone) => /[0-9]{6,}/.test(phone)

export const checkVietnamesePhoneNumber = (phone = '') => {
	if (!phone) return false
	if (phone.startsWith('+84')) phone = phone.replace('+84', '0')

	if (!/[0-9]/.test(phone)) return false
	if (phone.length !== 10 && phone.length !== 11) return false
	// https://www.thegioididong.com/hoi-dap/danh-sach-cac-dau-so-moi-cua-cac-nha-mang-vitettel-1263877
	const VIETTEL_PREFIXES = [
		'086',
		'096',
		'097',
		'098',
		'0169',
		'0168',
		'0167',
		'0166',
		'0165',
		'0164',
		'0163',
		'0162',
		'039',
		'038',
		'037',
		'036',
		'035',
		'034',
		'033',
		'032',
	]
	const VINAPHONE_PREFIXES = [
		'091',
		'094',
		'0128',
		'0123',
		'0124',
		'0125',
		'0127',
		'0129',
		'091',
		'094',
		'088',
		'083',
		'084',
		'085',
		'081',
		'082',
	]
	const MOBIPHONE_PREFIES = [
		'0120',
		'070',
		'0121',
		'079',
		'0122',
		'077',
		'0126',
		'076',
		'0128',
		'078',
		'089',
		'089',
		'090',
		'090',
		'093',
		'093',
	]
	const VIETNAMOBILE_PREFIXES = ['092', '092', '0182', '052', '0186', '056', '0188', '058']
	const GMOBILE_PREFIXES = ['099', '0199', '059']
	const ITELECOM_PREFIXES = ['087']
	let prefixes = [
		...VIETTEL_PREFIXES,
		...VINAPHONE_PREFIXES,
		...MOBIPHONE_PREFIES,
		...VIETNAMOBILE_PREFIXES,
		...ITELECOM_PREFIXES,
		...GMOBILE_PREFIXES,
		'02', // ma vung cac tinh thành viet nam
	]
	if (prefixes.every((prefix) => !phone.startsWith(prefix))) return false
	return true
}

export default {
	checkName,
	checkEmail,
	checkPassword,
	checkVietnamesePhoneNumber,
	validateUrl,
}
