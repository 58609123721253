var sb = require('@sb/util')
import store from '@sb/store'

export default {
	name: 'invite-message',
	props: ['ev', 'mode', 'followers'],

	render() {
		let ev = this.ev
		let by = lo.get(ev, 'by', {})
		if (by.type === 'user') return null
		if (this.mode !== 'lead') return null

		let members = lo.filter(lo.get(ev, 'data.conversation.members'), (m) => m.type !== 'user' && m.type !== 'bot')
		let inviter = store.matchAgent()[lo.get(ev, 'by.id', '')] || {}
		let invitername = sb.getAgentDisplayName(inviter)
		let hasme
		let agent_ids = []
		let fos = lo.split(this.followers, ',')

		if (lo.get(ev, 'data.conversation.members.0.type') == 'agent') {
			fos.unshift(lo.get(ev, 'data.conversation.members.0.id'))
		}

		lo.map(fos, (id) => {
			if (!id) return
			if (id === inviter.id) return
			if (lo.get(store.matchAgent()[id], 'type') === 'bot') return

			if (id === store.me().id) {
				// move me on top
				agent_ids.unshift(id)
				return
			}
			agent_ids.push(id)
		})
		if (agent_ids.length === 0) return null
		let befores = []
		let afters = []
		for (var i = 0; i < agent_ids.length; i++) {
			if (i === 0 || (i < 3 && i !== agent_ids.length - 1)) befores.push(agent_ids[i])
			else afters.push(agent_ids[i])
		}

		// befores - and - afters
		let $beforeAgents = lo.map(befores, (id) => {
			let ag = store.matchAgent()[id] || {}
			return <Avatar class='ml-2' agent={ag} size='xs' />
		})

		let $afterAgents = []
		if (afters.length === 1) {
			$afterAgents.push(<span class='mr-2'>{this.$t('and')}</span>)
			let ag = store.matchAgent()[afters[0]] || {}
			$afterAgents.push(<Avatar class='mr-2' agent={ag} size='xs' />)
		} else if (afters.length > 1) {
			// tooptip
			let afteragentnames = lo.map(afters, (id) => {
				let ag = store.matchAgent()[id] || {}
				return sb.getAgentDisplayName(ag)
			})
			let tooltip = afteragentnames.join(', ')
			$afterAgents.push(
				<span title={tooltip}>{this.$t('and') + afters.length + ' ' + this.$t('other_members')}&nbsp;</span>,
			)
		}

		let createdtime = new Date(sb.unixSec(ev.created) * 1000)
		return (
			<div
				title={createdtime.toLocaleDateString() + ' ' + createdtime.toLocaleTimeString()}
				data-key={ev.id}
				class='d-flex justify-content-center mt-5 ml-4 mr-4 text__muted'
				style='flex-wrap: wrap'
			>
				<span>{invitername}</span>&nbsp;
				<span>{this.$t('convo_invite')}</span>
				{$beforeAgents}&nbsp;{$afterAgents}
				{this.$t('to_the_conversation')}
			</div>
		)
	},
}
