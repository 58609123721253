import store from '@sb/store'
import sb from '@sb/util'

export default {
	name: 'email-message-attachment',
	props: ['attachment'],
	data() {
		return {
			loading: false,
			url: '',
		}
	},

	mounted() {
		this.setThumbailUrl()
	},

	watch: {
		attachment() {
			this.setThumbailUrl()
		},
	},

	methods: {
		async setThumbailUrl() {
			let att = this.attachment
			let currentUrl = lo.get(att, 'url', '')
			let isImage = (att.mimetype || '').startsWith('image')

			if (!isImage) {
				this.url = currentUrl
				return
			}
			let thumbnailUrl = lo.get(att, 'thumbnail_url')
			if (thumbnailUrl) {
				this.url = thumbnailUrl
				return
			}
			let fileid = getFileIdFromUrl(currentUrl)
			if (!fileid) {
				this.url = currentUrl
				return
			}

			this.loading = true
			let res = await store.getFileDetail(fileid)
			this.loading = false
			console.log('email_message_card_attachment', res)
			if (res.error) {
				this.url = currentUrl
				return
			}

			this.url = lo.get(res, 'body.thumbnail_url', '') || currentUrl
		},
	},

	render() {
		let att = this.attachment
		let isImage = (att.mimetype || '').startsWith('image')
		let extension = (att.name || '').split('.').pop()

		if (!isImage) {
			return (
				<a class='email_message_card_attachment' href={att.url} target='_blank'>
					<div class='email_message_card_attachment_icon'>
						<Icon name='file' size='18' class='text__muted' />
					</div>
					<div class='ml-3' style='overflow: hidden; font-size: 14px;'>
						<div class='text__semibold text__truncate' title={att.name}>
							{att.name}
						</div>
						<div class='text__muted'>
							{extension.toUpperCase()} {'/'} {sb.humanFileSize(att.size)}
						</div>
					</div>
				</a>
			)
		}

		return (
			<a class='email_message_card_attachment' href={att.url} target='_blank'>
				<div class='email_message_card_attachment_icon'>
					{this.loading ? <Spinner size='16' mode='dark' /> : <img2 src={this.url} style='width: 100%; height: 100%' />}
				</div>
				<div class='ml-3' style='overflow: hidden; font-size: 14px;'>
					<div class='text__semibold text__truncate' title={att.name}>
						{att.name}
					</div>
					<div class='text__muted'>
						{extension.toUpperCase()} {'/'} {sb.humanFileSize(att.size)}
					</div>
				</div>
			</a>
		)
	},
}

function getFileIdFromUrl(url) {
	if (!url) return ''

	let splits = lo.split(url, '/')
	let fileid = lo.last(splits)
	fileid = fileid.split('.')
	fileid = lo.first(fileid)

	return fileid
}
