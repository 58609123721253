export default {
	name: 'toaster',
	props: {
		type: String,
		title: String,
		description: String,
		timeout: Number,
		action: Object,
	},
	data: function () {
		return {
			isShow: true,
			clearTimeout: null,
		}
	},
	render() {
		if (!this.type) return null
		this.clearTimeout = setTimeout(() => {
			this.close()
		}, this.timeout || 3000)
		let root = `toaster toaster__border__${this.type || ''}`
		let iconStatus = `toaster__icon_status toaster__icon_status__${this.type || ''}`
		let $action = ''
		if (this.action)
			$action = (
				<button class='btn btn__light btn__sm ml-2' vOn:click={this.clickAction}>
					{this.action.title || 'Action'}
				</button>
			)
		return (
			<div class={root}>
				<div class={iconStatus}></div>
				<div class='toaster__content'>
					<div class='toaster__title'>{this.$t(this.title)}</div>
					<div class='toaster__descrption'>
						{this.description}
						{$action}
					</div>
				</div>
				<Icon
					name='x'
					v-tooltip={this.$t('close')}
					stroke-width='2'
					size='18'
					vOn:click={(_) => this.close()}
					class='toaster__iconclose'
				/>
			</div>
		)
	},
	methods: {
		close() {
			clearTimeout(this.clearTimeout)
			this.$emit('onClose', this.type)
		},

		clickAction() {
			if (this.action.callback) {
				this.action.callback()
			}
			this.$emit('onClickAction')
		},
	},
}
