import sb from '@sb/util'

const MARGIN_PX = 15 // distance between dropdown and trigger
const EDGE_PX = 20 // distance dropdown with trigger edge
const DEFAULT_DELAY_MS = 500

import AppendToBody from './append_to_body.js'

export default {
	name: 'hover-dropdown',
	// direction: 'vertical', 'horizontal', default is vertical
	// trigger: 'hover', 'manual' default is hover
	props: ['extra_dropdown_cls', 'dropdown_width', 'dropdown_content', 'delay', 'direction', 'trigger', 'isOpened'], // dropdown_content is node
	data() {
		return {
			open: false,
			dom: false,
			// trigger Cond
			x: 0,
			y: 0,
			width: 0,
			height: 0,

			// dropdown width and height
			dropdownWidth: 0,
			dropdownHeight: 0,
		}
	},

	mounted() {
		this.calculateDropdownSize()
		this.toggleDropdownManually()
	},

	watch: {
		isOpened() {
			this.toggleDropdownManually()
		},
	},

	methods: {
		toggleDropdownManually() {
			if (this.trigger === 'manual') {
				this.dom = this.isOpened
				this.$nextTick(() =>
					setTimeout(() => {
						if (this.dom) {
							this.open = true
							this.calculateDropdownSize()
							this.setDropdownCond()
						} else {
							this.open = false
						}
					}, 10),
				)
			}
		},

		calculateDropdownSize() {
			let $dropdown = this.$refs.dropdown
			this.dropdownWidth = $dropdown ? $dropdown.offsetWidth : 0
			this.dropdownHeight = $dropdown ? $dropdown.offsetHeight : 0
		},

		renderDropdown() {
			if (!this.dom) return null
			let style = this.calculateDropdownStyle()
			let cls = `hover_dropdown ${this.getDropdownPosition()}`
			if (this.extra_dropdown_cls) cls += ` ${this.extra_dropdown_cls}`
			if (this.open) cls += ' active'
			if (this.getArrowPosition()) {
				style += `--edge: ${this.getArrowPosition()}px;`
			}
			return (
				<AppendToBody>
					<div
						class={cls}
						style={style}
						ref='dropdown'
						vOn:mouseleave={this.onMouseLeave}
						vOn:mouseenter={this.onMouseEnter}
					>
						{this.dropdown_content}
					</div>
				</AppendToBody>
			)
		},

		// returnn bottom-left, bottom-right, bottom-center, top-left, top-right, top-center, right-top, right-bottom, left-top, left-bottom
		getDropdownPosition() {
			let direction = this.direction || 'vertical'
			if (direction === 'vertical') {
				let isTop = window.innerHeight - (this.y + this.height + this.dropdownHeight + MARGIN_PX) < 50 // distance with bottom of page
				let isCenter = window.innerWidth - (this.x + this.width + this.dropdownWidth / 2 - this.width / 2) >= 20 // distance with left, right edge of page

				// prefer display center
				if (isCenter) {
					return `${isTop ? 'top' : 'bottom'}-center`
				}
				let isRight = window.innerWidth - (this.x + this.dropdownWidth - EDGE_PX) < 20
				return `${isTop ? 'top' : 'bottom'}-${isRight ? 'right' : 'left'}`
			} else if (direction === 'horizontal') {
				// TODO
				let isTop = window.innerHeight - (this.y + this.dropdownHeight + EDGE_PX) < 50
				let isRight = window.innerWidth - (this.x + this.width + this.dropdownWidth + MARGIN_PX) >= 20
				let isCenter = window.innerHeight - (this.y + this.height + this.dropdownHeight / 2 - this.height / 2) >= 20 // distance with left, right edge of page
				if (isCenter) {
					return `${isRight ? 'right' : 'left'}-center`
				}
				return `${isRight ? 'right' : 'left'}-${isTop ? 'top' : 'bottom'}`
			}
			return 'bottom-center'
		},

		getArrowPosition() {
			let position = this.getDropdownPosition()
			const ARROW_WIDTH = 16
			if (position === 'bottom-left' || position === 'top-left') {
				return EDGE_PX + this.width / 2 - ARROW_WIDTH / 2
			}
			if (position === 'bottom-right' || position === 'top-right') {
				return EDGE_PX + (this.width - ARROW_WIDTH) / 2
			}
			if (position === 'left-bottom' || position === 'right-bottom') {
				return EDGE_PX + this.height / 2 - ARROW_WIDTH / 2
			}
			if (position === 'left-top' || position === 'right-top') {
				return EDGE_PX + this.height / 2 - ARROW_WIDTH / 2
			}
		},

		calculateDropdownStyle() {
			let style = `position: fixed;`
			let position = this.getDropdownPosition()
			if (position === 'bottom-left') {
				style += `left: ${this.x - EDGE_PX}px; top: ${this.y + this.height + MARGIN_PX}px;`
			} else if (position === 'bottom-right') {
				style += `right: ${window.innerWidth - EDGE_PX - (this.x + this.width)}px; top: ${
					this.y + this.height + MARGIN_PX
				}px;`
			} else if (position === 'bottom-center') {
				style += `left: ${this.x - (this.dropdownWidth - this.width) / 2}px; top: ${
					this.y + this.height + MARGIN_PX
				}px;`
			} else if (position === 'top-left') {
				style += `left: ${this.x - EDGE_PX}px; bottom: ${window.innerHeight - this.y + MARGIN_PX}px;`
			} else if (position === 'top-right') {
				style += `right: ${window.innerWidth - EDGE_PX - (this.x + this.width)}px; bottom: ${
					window.innerHeight - this.y + MARGIN_PX
				}px;`
			} else if (position === 'top-center') {
				style += `left: ${this.x - (this.dropdownWidth - this.width) / 2}px; bottom: ${
					window.innerHeight - this.y + MARGIN_PX
				}px;`
			} else if (position === 'right-bottom') {
				style += `left: ${this.x + this.width + MARGIN_PX}px; top: ${this.y - EDGE_PX}px;`
			} else if (position === 'right-center') {
				style += `left: ${this.x + this.width + MARGIN_PX}px; top: ${
					this.y - (this.dropdownHeight - this.height) / 2
				}px;`
			} else if (position === 'right-top') {
				style += `left: ${this.x + this.width + MARGIN_PX}px; top: ${
					window.innerHeight - (this.y + this.height + EDGE_PX)
				}px;`
			} else if (position === 'left-bottom') {
				style += `right: ${window.innerWidth - this.x + MARGIN_PX}px; top: ${this.y - EDGE_PX}px;`
			} else if (position === 'left-center') {
				style += `right: ${window.innerWidth - this.x + MARGIN_PX}px; top: ${
					window.innerHeight - (this.y + this.height + EDGE_PX)
				}px;`
			} else if (position === 'left-top') {
				style += `right: ${window.innerWidth - this.x + MARGIN_PX}px; bottom: ${
					window.innerHeight - (this.y + this.height + EDGE_PX)
				}px;`
			}

			return style
		},

		setDropdownCond() {
			let $trigger = this.$refs.trigger
			let rect = $trigger ? $trigger.getBoundingClientRect() : {}
			let {x = 0, y = 0, width = 0, height = 0} = rect
			this.x = x
			this.y = y
			this.width = width
			this.height = height
		},

		onMouseEnter() {
			if (this.trigger === 'manual') return
			clearTimeout(this.hideDropdownTimeout)
			this.setDropdownCond()
			this.dom = true // render dom firlst
			this.showDropdownTimeout = setTimeout(() => {
				this.calculateDropdownSize()
				this.open = true
			}, this.delay || DEFAULT_DELAY_MS)
		},

		onMouseLeave() {
			if (this.trigger === 'manual') return
			clearTimeout(this.showDropdownTimeout)
			this.hideDropdownTimeout = setTimeout(() => {
				this.open = false
				this.dom = false
			}, this.delay || DEFAULT_DELAY_MS)
		},

		HideDropdown() {
			this.open = false
			clearTimeout(this.showDropdownTimeout)
			this.dom = false
		},
	},

	render() {
		return (
			<div style='position: relative' vOn:mouseleave={this.onMouseLeave} vOn:mouseenter={this.onMouseEnter}>
				<div class='dropdown-trigger' ref='trigger'>
					{this.$slots.default}
				</div>
				{this.renderDropdown()}
			</div>
		)
	},
}
