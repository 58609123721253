// This file exports all subiz utils from ./utils/*. For example unixSec, ...
//
// I created this file to help users (other developers) use utils package easier.
// They (you) don't have to remember or understand the structure of the utils package
// and yet, still able to use it.
//
// For example: without this file, to call unixSec, a developer must import it, also he
// must know that unixSec is written in ./utils/time.js (or he must grep for it), he write:
//   var { unixSec } = require('./utils/time.js')
// but by using this file, he can relax and just simply write:
//   var { unixSec } = require('./sb.js')
// Its not about how many characters shorter, its about how much information his brain don't
// have to carry.

var sb = {}

var common = require('./common.js')
sb = Object.assign(sb, common)

var time = require('./time.js')
sb = Object.assign(sb, time)

sb.Pubsub = require('./pubsub.js')

//var emoji = require('./emoji.js')
//sb = Object.assign(sb, emoji)

var extractor = require('./extract.js')
sb = Object.assign(sb, extractor)

var validate = require('./validate.js')
sb = Object.assign(sb, validate)

var clipboard = require('./clipboard.js')
sb = Object.assign(sb, clipboard)

module.exports = sb
