import sb from '@sb/util'

export default {
	name: 'message-referral-event',
	props: ['ev'],

	methods: {
		renderUserAvatar() {
			let ev = this.ev
			let byid = lo.get(ev, 'by.id')
			let user = store.matchUser(byid) || {id: byid}
			let senderName = sb.getUserDisplayName(user)
			let created = sb.getMsgField(ev, 'source_created') || ev.created

			let $name = (
				<div class='text_message_sender__name'>
					<span class='text__truncate' title={senderName}>
						{senderName}
					</span>
				</div>
			)

			return (
				<div class='text_message_sender text_message_sender__user'>
					<div class='text_message_sender__avatar'>
						<Avatar user={user} size='sm' />
					</div>
					{$name}
					<span class='text__muted'>&nbsp;·&nbsp;</span>
					<Time time={created} style='font-size: 14px; position: relative; top: -1px' class='text__muted' />
				</div>
			)
		},

		renderLinkRef() {
			let ev = lo.cloneDeep(this.ev)
			let uid = lo.get(ev, 'data.message.referral.ref_user_id')
			//uid = 'usrtiiwydnaiudcwagzxs'
			let pageid = lo.get(ev, 'data.message.referral.ref_page_id')
			//pageid = '106149078924499'

			let $user = <div class='mr-2'>{this.$t('customer')}</div>
			let $page = null
			if (uid) {
				let user = store.matchUser(uid, true)
				$user = (
					<Fragment>
						<Avatar size='18' user={user} class='mr-2' />
						<div class='mr-2'>{sb.getUserDisplayName(user)}</div>
					</Fragment>
				)
			}
			if (pageid) {
				let page = store.matchIntegration()[`${store.me().account_id}.${pageid}.fabikon`]
				if (page) {
					$page = (
						<Fragment>
							<a href={`https://facebook.com/${pageid}`} target='_blank' class='link link__secondary ml-2'>
								{page.name}
							</a>
						</Fragment>
					)
				}
			}
			return (
				<div class='message__info'>
					<div class='convo_event__time'>
						<Time time={this.ev.created} />
					</div>
					<div class='mt-1 d-flex align-items-center justify-content-center'>
						{$user} {this.$t('was_connected_by_fanpage').toLowerCase()} {$page}
					</div>
				</div>
			)
		},
	},

	render() {
		let ev = this.ev

		let source = lo.get(ev, 'data.message.referral.source')
		if (source === 'SHORTLINK') return this.renderLinkRef()
		if (source !== 'ADS') return null

		let img = lo.get(ev, 'data.message.referral.ads_context_data.photo_url')
		if (!img) img = lo.get(ev, 'data.message.referral.ads_context_data.video_url')
		let postId = lo.get(ev, 'data.message.referral.ads_context_data.post_id')
		let title = lo.get(ev, 'data.message.referral.ads_context_data.ad_title')
		let link = postId ? `https://facebook.com/${postId}` : 'javascript:;'

		let $sender = <div class={'message_container message_container__user'}>{this.renderUserAvatar(ev)}</div>

		return (
			<div class='message_container_wrapper'>
				{$sender}
				<div class='' style='margin-left: 50px'>
					<div class='text__secondary'>
						{this.$t('user_from_fb_advertise')}{' '}
						<a href={link} target={postId ? '_blank' : undefined} class='link link__secondary text__sm'>
							<strong>{title}</strong>{' '}
							<Icon name='external-link' style='position: relative; top: -2px' size={16} stroke-width='2' />
						</a>
					</div>
					<div class='d-flex'>
						<img2 clickable src={img} style='height: 54px; border-radius: 4px; overflow: hidden;' class='mr-3' />
					</div>
				</div>
			</div>
		)
	},
}
