const sb = require('@sb/util')
import accounting from 'accounting'
import pc from '../../product/common.js'

export default {
	name: 'order-card-content',
	props: ['order'],

	render() {
		let order = this.order
		let $note = null
		pc.calcOrderTotal(order)
		if (order.note) {
			$note = (
				<div>
					<div class='message_editor__attachment_order_label mt-2'>{this.$t('note')}</div>
					<div class='message_editor__attachment_order_note' title={order.note}>{order.note}</div>
				</div>
			)
		}

		let $products = null
		if (lo.size(order.items) > 0) {
			$products = (
				<div>
					<div class='message_editor__attachment_order_label mt-2'>{this.$t('product')}</div>
					{lo.map(order.items, (item) => {
						let product = item.product || {}

						let price = product.price
						return (
							<div class='d-flex align-items-center mb-2'>
								<img2
									class='message_editor__attachment_order_product_img'
									src={lo.get(product, 'image', require('../../assets/img/default.jpg'))}
								/>
								<div style='flex: 1; overflow: hidden'>
									<div
										class='text__truncate'
										style='font-size: 14px; font-weight: 500'
										title={pc.getProductDisplayName(product)}
									>
										{pc.getProductDisplayName(product)}
									</div>
									{lo.size(product.props) > 0 && (
										<div class='text__truncate text__muted' style='font-size: 13px; line-height: 1'>
											{pc.getProductPropName(product, ' / ')}
										</div>
									)}
									<div class='text__truncate text__muted' style='font-size: 13px;'>
										{this.$t('qty')} {item.quantity}&nbsp;·&nbsp;{accounting.formatNumber(price)}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			)
		}
		let $subtotal = (
			<div class='d-flex align-items-center justify-content-between'>
				<div class='message_editor__attachment_order_label'>{this.$t('subtotal')}</div>{' '}
				<div style='font-size: 14px'>{accounting.formatNumber(order.subtotal)}</div>
			</div>
		)
		let $beforeTax = null
		if (order._discount_before_tax) {
			$beforeTax = (
				<div style='padding-bottom: 10px; border-bottom: 1px solid #ccc; margin-bottom: 10px'>
					<div class='d-flex align-items-center justify-content-between'>
						<div class='message_editor__attachment_order_label'>{this.$t('subtotal_before_tax')}</div>{' '}
						<div style='font-size: 14px'>{accounting.formatNumber(order._order_value)}</div>
					</div>
					<div class='d-flex align-items-center justify-content-between'>
						<div class='message_editor__attachment_order_label'>{this.$t('discount_before_tax')}</div>{' '}
						<div style='font-size: 14px'>-{accounting.formatNumber(order._discount_before_tax)}</div>
					</div>
				</div>
			)
		}
		let $tax = null
		if (order.total_tax) {
			let taxname = ''
			if (lo.size(order._taxM) === 1) {
				let tax = lo.get(order._taxM, '0.tax', {})
				if (tax.name && tax.percentage) taxname = `(${tax.name} ${Math.round(tax.percentage || 0) / 100}%)`
			}
			$tax = (
				<div class='d-flex align-items-center justify-content-between'>
					<div class='message_editor__attachment_order_label'>
						{this.$t('tax')} {taxname}
					</div>{' '}
					<div style='font-size: 14px'>{accounting.formatNumber(order.total_tax)}</div>
				</div>
			)
		}
		let $discount = null
		if (order.discount_type === 'percentage' && order.discount_percentage) {
			$discount = (
				<div class='d-flex align-items-center justify-content-between'>
					<div class='message_editor__attachment_order_label'>
						{this.$t('discount_after_tax')} ({Math.round(order.discount_percentage / 100)}%)
					</div>{' '}
					<div style='font-size: 14px'>-{accounting.formatNumber(order._computed_discount)}</div>
				</div>
			)
		}
		if (order.discount_type === 'amount' && order.discount_amount) {
			$discount = (
				<div class='d-flex align-items-center justify-content-between'>
					<div class='message_editor__attachment_order_label'>{this.$t('discount_after_tax')}</div>{' '}
					<div style='font-size: 14px'>-{accounting.formatNumber(order._computed_discount)}</div>
				</div>
			)
		}
		let $shipping = null
		if (lo.get(order, 'shipping.nominal_fee')) {
			$shipping = (
				<div class='d-flex align-items-center justify-content-between'>
					<div class='message_editor__attachment_order_label'>{this.$t('shipping_fee')}</div>{' '}
					<div style='font-size: 14px'>{accounting.formatNumber(lo.get(order, 'shipping.nominal_fee', 0))}</div>
				</div>
			)
		}
		let $adjustment = null
		if (order.adjustment) {
			$adjustment = (
				<div class='d-flex align-items-center justify-content-between'>
					<div class='message_editor__attachment_order_label'>{order.adjustment_description}</div>{' '}
					<div style='font-size: 14px'>{accounting.formatNumber(order.adjustment)}</div>
				</div>
			)
		}

		$subtotal = (
			<div style='padding-bottom: 10px; border-bottom: 1px solid #ccc; margin-bottom: 10px'>
				{$subtotal}
				{$tax}
				{$shipping}
				{$discount}
				{$adjustment}
			</div>
		)
		if (!$shipping && !$adjustment && !$discount && !$tax) {
			$subtotal = null
		}

		let $total = (
			<div class='d-flex align-items-center justify-content-between'>
				<div class=''>{this.$t('total')}</div>{' '}
				<div>
					<b>{accounting.formatNumber(order.total)}</b>
				</div>
			</div>
		)

		let phoneattr = lo.find(lo.get(order, 'user.attributes', []), (attr) => attr.key === 'phones') || {
			key: 'phones',
			text: '',
		}
		let phone = lo.trim(lo.get(order, 'shipping.address.phone'))
		phone = phone || lo.trim(phoneattr.text)

		let $shipto = null
		if (phone || pc.displayOrderShippingAddress(order)) {
			$shipto = (
				<div>
					<div class='message_editor__attachment_order_label mt-3'>{this.$t('ship_to')}</div>
					<div style='font-size: 14px'>{pc.displayOrderShippingAddress(order) || this.$t('not_available')}</div>
					<div style='font-size: 14px'>
						{this.$t('phn')}: {phone || <em class='text__muted'>{this.$t('not_available')}</em>}
					</div>
				</div>
			)
		}

		return (
			<div>
				<div style='padding-bottom: 5px; border-bottom: 1px solid #ccc; margin-bottom: 5px; display: flex; align-items: center; justify-content: space-between'>
					{this.$t('order')}
					<span class='text__muted ml__auto'>
						<small>{pc.getOrderDisplayName(order)}</small>
					</span>
				</div>
				{$products}
				{$shipto}
				{$note}
				<div style='margin-top: 10px; padding-top: 10px; 0; border-top: 1px solid #ccc'>
					{$beforeTax}
					{$subtotal}
					{$total}
				</div>
			</div>
		)
	},
}
