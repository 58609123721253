const sb = require('@sb/util')
import store from '@sb/store'

// channel_sources: ['a', 'b']
export default {
	name: 'dropdown',
	props: ['sources', 'channels', 'placeholder', 'dropdown_text', 'all'],

	data () {
		return {
			isShow: false,
			filter: '',
			softSelected: '',
			showFilter: false,

			loading: false,
		}
	},

	async mounted () {
		document.body.addEventListener('keyup', this.onKeyup)
		document.body.addEventListener('keydown', this.onKeydown)
	},

	destroyed () {
		document.body.removeEventListener('keyup', this.onKeyup)
		document.body.removeEventListener('keydown', this.onKeydown)
	},

	render () {
		let placeholder = this.placeholder || this.$t('select_an_item')
		let $dropdown = null
		if (this.isShow) {
			$dropdown = (
				<div
					class="dropdown dropdown__full dropdown__right"
					style="display: flex; flex-direction: column; width: 280px">
					<div class="pt-3 pb-3 pl-3 pr-3">
						<input
							ref="input"
							class="form-control dropdown__input"
							placeholder={placeholder}
							type="text"
							value={this.filter}
							vOn:change={this.onFilterChange}
							vOn:keyup={this.onFilterChange}
						/>
					</div>
					<div style="display: flex; flex-direction: column; flex: 1; min-height: 210px; overflow: auto;">
						{this.renderItems()}
					</div>
				</div>
			)
		}

		let $dropdown_text = (
			<a href="#" class="link" vOn:click={_ => this.toogleDropdown(true)}>
				{this.$t('add_more')}
			</a>
		)
		if (this.dropdown_text) {
			$dropdown_text = (
				<div vOn:click={_ => this.toogleDropdown(true)}>
					{this.dropdown_text}
				</div>
			)
		}
		return (
			<div class="dropdown__input_container" v-clickaway={_ => this.toogleDropdown(false)}>
				{$dropdown_text}

				{$dropdown}
			</div>
		)
	},

	methods: {
		onFilterChange (e) {
			this.filter = this.$refs.input.value
		},

		onKeydown (e) {
			if (!this.isShow) return
			switch (e.keyCode) {
			case 38: // UP
				var items = lo.filter(this.items, item => (item.name + '').toLowerCase().indexOf(this.filter) !== -1)
				var idx = lo.findIndex(items, item => item.id === this.softSelected)
				idx--
				if (idx < 0) idx = lo.size(items) - 1
				this.softSelected = items[idx].id
				// this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
				e.preventDefault()
				break
			case 40: // DOWN
				var items = lo.filter(this.items, item => (item.label + '').toLowerCase().indexOf(this.filter) !== -1)
				var idx = lo.findIndex(items, item => item.id === this.softSelected)
				idx++
				if (idx >= lo.size(items)) idx = 0
				this.softSelected = items[idx].id

				//this.currentSelectedIndex++
				//if (this.currentSelectedIndex >= lo.size(this.filteredTemplates)) this.currentSelectedIndex = 0
				//this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
				e.preventDefault()
				break
			}
		},

		onKeyup (e) {
			if (!this.isShow) return
			switch (e.keyCode) {
			case 27: // ESC
				this.toogleDropdown(false)
				break
			case 13: // ENTER
				var item = lo.find(this.items, item => item.id === this.softSelected)
				this.onSelectItem(item)
				break
			}
		},

		onMouseOver (item) {
			this.softSelected = item.id
		},

		toogleDropdown (v) {
			if (v === false || v === true) this.isShow = v
			else this.isShow = !this.isShow

			if (this.isShow) {
				this.focusInput()
				this.filter = ''
			}
		},

		focusInput () {
			if (this.isShow) this.$refs.input && this.$refs.input.focus()
			setTimeout(() => {
				this.$refs.input && this.$refs.input.focus()
			}, 200)
		},

		renderItems () {
			if (this.loading) {
				return <Spinner static center mode="blue" size="120" />
			}

			let intes = lo.map(store.matchIntegration())
			intes = lo.filter(
				intes,
				inte => (inte.name + inte.id + inte.connector_type).toLowerCase().indexOf(this.filter) !== -1,
			)

			intes = lo.filter(intes, inte => inte.connector_type !== 'subiz' && inte.connector_type !== 'email')
			intes = lo.filter(intes, inte => lo.includes(this.channels, inte.connector_type))
			intes = lo.filter(intes, inte => !lo.includes(this.sources, inte.id)) // already picked item
			// sort by group

			// must be fb_comment, messenger or zalo
			intes = lo.filter(intes, inte => inte.state === 'activated')

			let groupM = {}
			lo.each(intes, inte => {
				let group = inte.connector_type
				groupM[group] = groupM[group] || []
				groupM[group].push(inte)
			})

			let $intes = []
			lo.map(groupM, (intes, group) => {
				let groupText = group
				if (group === 'facebook') groupText = this.$t('Messenger')
				if (group === 'facebook_comment') groupText = this.$t('facebook_comment')
				if (group) $intes.push(<div class="dropdown__item dropdown__item_group">{groupText}</div>)
				if (this.all) $intes.push(this.renderSelectItemAll(group))
				lo.map(intes, inte => {
					let cls = 'dropdown__item dropdown__item__no_hover '
					if (this.softSelected === inte.id) cls += 'dropdown__item__selected'
					$intes.push(
						<div
							class={cls}
							style="padding: 5px 10px"
							vOn:click={_ => this.onSelectItem(inte)}
							vOn:mouseover={_ => this.onMouseOver(inte)}>
							<img
								src={sb.replaceFileUrl(lo.get(inte, 'logo_url')) || getDefChannelImg(group)}
								style="width: 20px; border-radius: 4px"
								class="mr-3"
							/>{' '}
							<div class="text__truncate">{inte.name}</div>
						</div>,
					)
				})
			})

			if (lo.size($intes) === 0) return this.renderEmpty()
			return $intes
		},

		renderSelectItemAll (group) {
			if (!this.all) return null

			let cls = 'dropdown__item dropdown__item__no_hover '
			if (this.softSelected === `${group}_all`) cls += 'dropdown__item__selected'

			let text = ''
			if (group === 'facebook') {
				text = this.$t('all_fanpage')
			}
			if (group === 'zalo') {
				text = this.$t('all_zalo')
			}
			if (group === 'instagram') {
				text = this.$t('all_instagram')
			}

			return (
				<div
					class={cls}
					style="padding: 5px 10px"
					vOn:click={_ => this.onSelectItemAll(group)}
					vOn:mouseover={_ => this.onMouseOver({ id: `${group}_all` })}>
					<div class="text__truncate">{text}</div>
				</div>

			)
		},

		onSelectItemAll (group) {
			let sources = lo.clone(this.sources)
			sources = lo.filter(sources, inteId => {
				let inte = lo.get(store.matchIntegration(), inteId, {})
				return inte.connector_type !== group
			})
			this.isShow = false
			this.$emit('change', sources)
		},

		onSelectItem (item) {
			let sources = lo.clone(this.sources)
			sources.push(item.id)
			this.$emit('change', sources)
		},

		renderEmpty () {
			return (
				<div class="combobox_empty">
					<img class="location_picker__empty_img" src={require('../assets/img/not-found.png')} alt="" />
					<p class="text__muted mt-4">{this.$t('no_channel_source_found')}</p>
				</div>
			)
		},
	},
}

function getDefChannelImg (channel) {
	if (channel === 'subiz') return require('../assets/img/subiz_channel.svg')
	if (channel === 'email') return require('../assets/img/email_channel.svg')
	if (channel === 'facebook_comment') return require('../assets/img/facebook_channel.svg')
	if (channel === 'facebook') return require('../assets/img/messenger_channel.svg')
	if (channel === 'zalo') return require('../assets/img/zalo_channel.svg')
	// subiz or anything else
	return require('../assets/img/subiz_channel.svg')
}
