var QuestionMark = require('../assets/img/tabler-icons/grain.svgv')

// preload
require('../assets/img/tabler-icons/user-circle.svgv')
require('../assets/img/tabler-icons/message-dots.svgv')
require('../assets/img/tabler-icons/circle-check.svgv')
require('../assets/img/tabler-icons/receipt-2.svgv')
require('../assets/img/tabler-icons/chart-pie.svgv')
require('../assets/img/tabler-icons/filter.svgv')
require('../assets/img/tabler-icons/brand-chrome.svgv')
require('../assets/img/tabler-icons/x.svgv')
require('../assets/img/tabler-icons/search.svgv')
require('../assets/img/tabler-icons/grid-dots.svgv')
require('../assets/img/tabler-icons/settings.svgv')
require('../assets/img/tabler-icons/bell.svgv')
require('../assets/img/tabler-icons/bell-ringing-2.svgv')
require('../assets/img/tabler-icons/help.svgv')
require('../assets/img/tabler-icons/phone.svgv')
require('../assets/img/tabler-icons/phone-off.svgv')
require('../assets/img/tabler-icons/upload.svgv')
require('../assets/img/tabler-icons/plus.svgv')
require('../assets/img/tabler-icons/check.svgv')
require('../assets/img/tabler-icons/edit.svgv')
require('../assets/img/tabler-icons/dots.svgv')
require('../assets/img/tabler-icons/trash.svgv')
require('../assets/img/tabler-icons/chevron-right.svgv')
require('../assets/img/tabler-icons/chevron-down.svgv')
require('../assets/img/tabler-icons/bold.svgv')
require('../assets/img/tabler-icons/italic.svgv')
require('../assets/img/tabler-icons/underline.svgv')
require('../assets/img/tabler-icons/align-left.svgv')
require('../assets/img/tabler-icons/list.svgv')
require('../assets/img/tabler-icons/arrows-shuffle.svgv')
require('../assets/img/tabler-icons/shopping-cart.svgv')
require('../assets/img/tabler-icons/message-bolt.svgv')
require('../assets/img/tabler-icons/thumb-up-filled.svgv')
require('../assets/img/tabler-icons/map-pin.svgv')
require('../assets/img/tabler-icons/list-numbers.svgv')
require('../assets/img/tabler-icons/link.svgv')
require('../assets/img/tabler-icons/photo.svgv')
require('../assets/img/tabler-icons/paperclip.svgv')
require('../assets/img/tabler-icons/mood-smile.svgv')
require('../assets/img/tabler-icons/external-link.svgv')
require('../assets/img/tabler-icons/circle-filled.svgv')
require('../assets/img/tabler-icons/antenna-bars-1.svgv')
require('../assets/img/tabler-icons/chevrons-left.svgv')
require('../assets/img/tabler-icons/chevrons-right.svgv')
require('../assets/img/tabler-icons/calendar.svgv')
require('../assets/img/tabler-icons/chevron-left.svgv')
require('../assets/img/tabler-icons/shield.svgv')
require('../assets/img/tabler-icons/file-plus.svgv')
require('../assets/img/tabler-icons/mail-filled.svgv')
require('../assets/img/tabler-icons/cast.svgv')
require('../assets/img/tabler-icons/alert-triangle.svgv')
require('../assets/img/tabler-icons/history.svgv')
require('../assets/img/tabler-icons/arrows-sort.svgv')
require('../assets/img/tabler-icons/minus.svgv')
require('../assets/img/tabler-icons/send.svgv')
require('../assets/img/tabler-icons/tag.svgv')
require('../assets/img/tabler-icons/mail.svgv')
require('../assets/img/tabler-icons/corner-up-left.svgv')
require('../assets/img/tabler-icons/messages.svgv')
require('../assets/img/tabler-icons/checklist.svgv')
require('../assets/img/tabler-icons/circle-key.svgv')
require('../assets/img/tabler-icons/package.svgv')
require('../assets/img/tabler-icons/browser.svgv')
require('../assets/img/tabler-icons/cpu.svgv')
require('../assets/img/tabler-icons/ad.svgv')
require('../assets/img/tabler-icons/device-desktop-analytics.svgv')
require('../assets/img/tabler-icons/timeline.svgv')
require('../assets/img/tabler-icons/alert-triangle-filled.svgv')
require('../assets/img/tabler-icons/arrow-narrow-right.svgv')
require('../assets/img/tabler-icons/hourglass-low.svgv')
require('../assets/img/tabler-icons/copy.svgv')
require('../assets/img/tabler-icons/star.svgv')
require('../assets/img/tabler-icons/star-filled.svgv')

export default {
	props: ['name', 'color', 'stroke-width', 'class', 'size', 'title'],

	created() {
		this.loadIcon()
	},

	watch: {
		name() {
			this.loadIcon()
		},
	},

	methods: {
		loadIcon() {
			import('../assets/img/tabler-icons/' + this.name + '.svgv').then((module) => {
				this.module = module.default
				this.$forceUpdate()
			})
		},
	},

	render() {
		let size = 24
		if (this.size) size = parseInt(this.size) || size
		if (this.size === '1x') size = 16
		if (this.size === '1.0x') size = 16
		if (this.size === '1.5x') size = 24
		if (this.size === '2x') size = 32
		if (this.size === '3x') size = 48
		if (this.size === '4x') size = 48
		let viewBox = '0 0 24 24'
		var com = this.module
		if (!com)
			return (
				<QuestionMark
					style={{flexShrink: 0}}
					viewBox={viewBox}
					width={size}
					v-tooltip={this.title}
					height={size}
					class={this.class}
					color='#00000015'
					stroke-width={this.strokeWidth || 2}
				/>
			)
		return (
			<com
				class={this.class}
				v-tooltip={this.title}
				style={{flexShrink: 0}}
				tabindex='-1'
				vOn:click={(e) => this.$emit('click', e)}
				viewBox={viewBox}
				width={size}
				height={size}
				color={this.color}
				stroke-width={this.strokeWidth || 2}
			/>
		)
	},
}
