import sb from '@sb/util'
import {endOfDay, lastDayOfWeek, format, subDays} from 'date-fns'
import {getCurrentLanguage, getDateFnsLocale} from '../languages.js'

import AppendToBody from '../commons/append_to_body.js'

const WIDTH = 300
const HEIGHT = 260
const EDGE = 50

const DATE_PICKER_WIDTH = 250
const DATE_PICKER_HEIGHT = 370

export default {
	name: 'due-date-picker',
	props: ['value'],
	data() {
		return {
			isDropdownOpen: false,
			keyword: '',
			softSelect: '',

			isShowDatepicker: false,
		}
	},

	mounted() {},

	watch: {
		isDropdownOpen() {
			if (this.isDropdownOpen) {
				let items = this.getFilteredItems()
				this.softSelect = lo.get(items, '0.id')

				document.body.addEventListener('keyup', this.onKeyup)
				document.body.addEventListener('keydown', this.onKeydown)
			} else {
				document.body.removeEventListener('keyup', this.onKeyup)
				document.body.removeEventListener('keydown', this.onKeydown)
			}
		},

		keyword() {
			let items = this.getFilteredItems()
			this.softSelect = lo.get(items, '0.id')
		},
	},

	methods: {
		onKeyup(e) {
			switch (e.keyCode) {
				case 27: // ESC
					this.closeDropdown()
					break
				case 13: // ENTER
					let item = lo.find(this.getFilteredItems(), (item) => item.id === this.softSelect)
					this.onSelect(item)
					break
			}
		},
		onKeydown(e) {
			switch (e.keyCode) {
				case 38: {
					// UP
					let items = this.getFilteredItems()
					let idx = lo.findIndex(items, (item) => item.id === this.softSelect)
					idx--
					if (idx < 0) idx = lo.size(items) - 1
					this.softSelect = items[idx].id
					// this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()

					// check to scroll
					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-id="${this.softSelect}"]`)
					if ($element) $element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
				case 40: {
					// DOWN
					let items = this.getFilteredItems()
					let idx = lo.findIndex(items, (item) => item.id === this.softSelect)
					idx++
					if (idx >= lo.size(items)) idx = 0
					this.softSelect = items[idx].id

					let $dropdown = this.$refs.dropdown
					let $element = $dropdown.querySelector(`[data-id="${this.softSelect}"]`)
					if ($element) $element.scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				}
			}
		},

		getFilteredItems() {
			const DEFAULT_ITEMS = [
				{
					id: 'end_of_today',
					label: this.$t('today'),
					value: getEndTimeFromBusinessHours(new Date()),
					keywords: ['today', 'hom nay'],
				},
				{
					id: 'tomorrow',
					label: this.$t('tomorrow'),
					value: getEndTimeFromBusinessHours(new Date(Date.now() + 24 * 3600_000)),
					keywords: ['ngay mai', 'tomorrow'],
				},
				{
					id: 'end_this_week',
					label: this.$t('end_this_week'),
					value: getEndThisWeekValue(),
					keywords: ['cuoi tuan nay', 'end of this week'],
				},
				{
					id: 'in_one_week',
					label: this.$t('in_one_week'),
					value: getEndTimeFromBusinessHours(new Date(Date.now() + 7 * 24 * 3600_000)),
					keywords: ['in one week', 'tuan toi'],
				},
			]
			const CLEAR_ITEM = {
				id: 'clear_due_date',
				label: this.$t('clear_due_date'),
				value: 0,
				item_cls: 'text__danger',
			}
			const CUSTOM_ITEM = {
				id: 'custom',
				label: this.$t('custom'),
				keywords: [this.$t('custom')],
			}

			if (!lo.trim(this.keyword)) {
				let items = [...DEFAULT_ITEMS, CUSTOM_ITEM]
				if (this.value) items.unshift(CLEAR_ITEM)
				return items
			}

			// if keyword is digit, show in xxx mins, days, hours, weeks
			if (/^\d+$/.test(this.keyword)) {
				return [
					{
						id: 'in_n_mins',
						label: this.$t('in_n_mins', [pad(this.keyword)]),
						value: Date.now() + +this.keyword * 60_000,
					},
					{
						id: 'in_n_hours',
						value: Date.now() + +this.keyword * 3600_000,
						label: this.$t('in_n_hours', [pad(this.keyword)]),
					},
					{
						id: 'in_n_days',
						value: Date.now() + +this.keyword * 24 * 3600_000,
						label: this.$t('in_n_days', [pad(this.keyword)]),
					},
					{
						id: 'in_n_weeks',
						value: Date.now() + +this.keyword * 7 * 24 * 3600_000,
						label: this.$t('in_n_weeks', [pad(this.keyword)]),
					},
				]
			}

			let dateStr = detectSimilarDate(this.keyword)
			let fm = 'yyyy-MM-dd'
			if (getCurrentLanguage() === 'vi') fm = 'dd/MM/yyyy'
			if (dateStr) {
				return [
					{
						id: 'specific_date',
						label: format(new Date(dateStr), `EEEE, ${fm}`, {locale: getDateFnsLocale()}),
						value: getEndTimeFromBusinessHours(dateStr),
					},
				]
			}

			let SUGGESTED_ITEMS = [
				{
					id: 'suggested_monday',
					label: this.$t('monday'),
					value:
						new Date().getDay() > 0
							? getEndTimeFromBusinessHours(Date.now() + (8 - new Date().getDay()) * 24 * 3600_000)
							: getEndTimeFromBusinessHours(Date.now() + (1 - new Date().getDay()) * 24 * 3600_000),
					keywords: ['thu hai', 'monday'],
				},
				{
					id: 'suggested_tuesday',
					label: this.$t('tuesday'),
					value:
						new Date().getDay() > 1
							? getEndTimeFromBusinessHours(Date.now() + (9 - new Date().getDay()) * 24 * 3600_000)
							: getEndTimeFromBusinessHours(Date.now() + (2 - new Date().getDay()) * 24 * 3600_000),
					keywords: ['thu ba', 'tuesday'],
				},
				{
					id: 'suggested_wednesday',
					label: this.$t('wednesday'),
					value:
						new Date().getDay() > 2
							? getEndTimeFromBusinessHours(Date.now() + (10 - new Date().getDay()) * 24 * 3600_000)
							: getEndTimeFromBusinessHours(Date.now() + (3 - new Date().getDay()) * 24 * 3600_000),
					keywords: ['thu tu', 'wednesday'],
				},
				{
					id: 'suggested_thursday',
					label: this.$t('thursday'),
					value:
						new Date().getDay() > 3
							? getEndTimeFromBusinessHours(Date.now() + (11 - new Date().getDay()) * 24 * 3600_000)
							: getEndTimeFromBusinessHours(Date.now() + (4 - new Date().getDay()) * 24 * 3600_000),
					keywords: ['thu nam', 'thursday'],
				},
				{
					id: 'suggested_friday',
					label: this.$t('friday'),
					value:
						new Date().getDay() > 4
							? getEndTimeFromBusinessHours(Date.now() + (12 - new Date().getDay()) * 24 * 3600_000)
							: getEndTimeFromBusinessHours(Date.now() + (5 - new Date().getDay()) * 24 * 3600_000),
					keywords: ['thu sau', 'friday'],
				},
				{
					id: 'suggested_saturday',
					label: this.$t('saturday'),
					value:
						new Date().getDay() > 5
							? getEndTimeFromBusinessHours(Date.now() + (13 - new Date().getDay()) * 24 * 3600_000)
							: getEndTimeFromBusinessHours(Date.now() + (6 - new Date().getDay()) * 24 * 3600_000),
					keywords: ['thu bay', 'saturday'],
				},
			]
			SUGGESTED_ITEMS = lo.orderBy(SUGGESTED_ITEMS, ['value'], ['asc'])

			let allItems = [...DEFAULT_ITEMS, ...SUGGESTED_ITEMS, CUSTOM_ITEM]
			if (this.value) allItems.unshift(CLEAR_ITEM)

			return lo.filter(allItems, (item) => {
				let keyword = sb.unicodeToAscii(this.keyword).toLowerCase()
				let label = sb.unicodeToAscii(item.label).toLowerCase()

				if (label.indexOf(keyword) > -1) return true
				let match = lo.find(item.keywords, (kw) => kw.indexOf(keyword) > -1)

				if (match) return true
				return false
			})
		},

		renderDropdown() {
			if (!this.isDropdownOpen) return null

			let style = {}
			if (this.style) style = lo.cloneDeep(this.style)

			return (
				<AppendToBody>
					<div ref='dropdown' style={style} vOn:click_stop={() => false} class='dropdown d-flex flex-column'>
						<div class='dropdown_filter_container'>
							<div class='dropdown_filter_input_container'>
								<input
									class='form-control dropdown_filter_input'
									style='padding-left: 5px; padding-right: 5px;'
									placeholder={this.$t('change_due_date')}
									vModel={this.keyword}
									ref='input'
								/>
							</div>
						</div>
						<div class='dropdown_item_cotainer'>{this.renderItems()}</div>
					</div>
				</AppendToBody>
			)
		},

		renderDatepickerDropdown() {
			let style = {}
			if (this.datepickerStyle) style = lo.cloneDeep(this.datepickerStyle)
			if (!this.isShowDatepicker) style.display = 'none'

			return (
				<AppendToBody>
					<div style={style} vOn:click_stop={() => false}>
						<DatePicker
							has_time
							has_back_btn
							vOn:change={this.onChangeCustomDate}
							vOn:back={this.backFromCustom}
							current={Date.now()}
						/>
					</div>
				</AppendToBody>
			)
		},

		backFromCustom() {
			this.isDropdownOpen = true
			this.isShowDatepicker = false
		},

		onChangeCustomDate(e) {
			this.$emit('change', e)
			this.isShowDatepicker = false
		},

		renderItems() {
			let items = this.getFilteredItems()
			return lo.map(items, (item) => {
				return (
					<div
						class={{
							dropdown_item: true,
							active: this.softSelect === item.id,
							text__danger: item.id === 'clear_due_date',
						}}
						data-id={item.id}
						style='padding: 6px 10px'
						vOn:mouseenter={() => (this.softSelect = item.id)}
						vOn:mouseleave={() => (this.softSelect = '')}
						vOn:click={() => this.onSelect(item)}
					>
						<div class='d-flex align-items-center'>
							{item.label}
							{item.value > 0 && (
								<div class='ml-auto text__sm text__muted'>{format(new Date(item.value), 'dd-MM-yyyy, HH:mm')}</div>
							)}
						</div>
					</div>
				)
			})
		},

		onSelect(item) {
			console.log('changeeee', item.value)
			if (item.id === 'custom') {
				this.openDatepickerDropdown()
				return
			}
			this.$emit('change', item.value)
			this.closeDropdown()
		},

		openDatepickerDropdown() {
			this.isShowDatepicker = true

			let $wrapper = this.$refs.container
			let rect = $wrapper ? $wrapper.getBoundingClientRect() : {}
			let {top = 0, left = 0, width = 0, height = 0, right = 0} = rect

			let isBottom = document.body.offsetHeight - top < DATE_PICKER_HEIGHT + EDGE
			let isRight = document.body.offsetWidth - left < DATE_PICKER_WIDTH + EDGE

			let style = {
				zIndex: '99999',
				width: DATE_PICKER_WIDTH + 'px',
				height: DATE_PICKER_HEIGHT + 'px',
				maxWidth: 'unset',
				maxHeight: 'unset',
				minHeight: 'unset',
				minWidth: 'unset',
				position: 'fixed',
			}
			if (isRight) {
				style.right = document.body.offsetWidth - (width + left) + 'px'
			} else {
				style.left = left + 'px'
				style.right = 'unset'
			}
			if (isBottom) {
				style.bottom = document.body.offsetHeight - top + 'px'
				style.top = 'unset'
			} else {
				style.top = top + height + 'px'
			}

			this.datepickerStyle = style
			this.isDropdownOpen = false
		},

		calculateCord() {
			let $wrapper = this.$refs.container
			let rect = $wrapper ? $wrapper.getBoundingClientRect() : {}
			let {top = 0, left = 0, width = 0, height = 0, right = 0} = rect

			let isBottom = document.body.offsetHeight - top < HEIGHT + EDGE
			let isRight = document.body.offsetWidth - left < WIDTH + EDGE

			let style = {
				zIndex: '99999',
				width: WIDTH + 'px',
				height: HEIGHT + 'px',
				maxWidth: 'unset',
				maxHeight: 'unset',
				minHeight: 'unset',
				minWidth: 'unset',
				position: 'fixed',
			}
			if (isRight) {
				style.right = document.body.offsetWidth - (width + left) + 'px'
			} else {
				style.left = left + 'px'
				style.right = 'unset'
			}
			if (isBottom) {
				style.bottom = document.body.offsetHeight - top + 'px'
				style.top = 'unset'
			} else {
				style.top = top + height + 'px'
			}

			this.style = style
		},

		async toggleDropdown() {
			this.isDropdownOpen = !this.isDropdownOpen
			if (this.isDropdownOpen) {
				this.calculateCord()
				await this.$nextTick()
				this.$refs.input && this.$refs.input.focus()
			}
		},

		ToogleDropdown() {
			return this.toggleDropdown()
		},

		closeDropdown() {
			this.isDropdownOpen = false
			this.isShowDatepicker = false
		},

		renderInput() {
			return (
				<div
					class='btn btn__sm btn__white'
					vOn:click={this.toggleDropdown}
					title={this.value && new Date(this.value).toLocaleString()}
				>
					{displayOverdueText({due_at: this.value})}
				</div>
			)
		},
	},

	render() {
		return (
			<div ref='container' v-clickaway={this.closeDropdown}>
				{this.renderDropdown()}
				{this.renderDatepickerDropdown()}
				{this.mode === 'button' ? this.renderInput() : <div vOn:click={this.toggleDropdown}>{this.$slots.default}</div>}
			</div>
		)
	},
}

// try to set end_time of any date in business_hours settings, if not set to endOf day
function getEndTimeFromBusinessHours(time, test) {
	let date
	if (time instanceof Date) date = time
	else date = new Date(time)

	let workingDays = lo.get(store.me(), 'account.business_hours.working_days', [])
	workingDays = lo.filter(workingDays, (wd) => wd.weekday === format(date, 'EEEE'))

	let hhmm = '23:59'
	if (lo.size(workingDays)) {
		let trueEndWorkingDay = lo.maxBy(workingDays, (wd) => {
			if (!wd.end_time) return 0

			let [hh, mm] = lo.split(wd.end_time, ':')
			return +hh * 60 + +mm
		})
		hhmm = trueEndWorkingDay.end_time
	}

	let [hh, mm] = lo.split(hhmm, ':')
	date.setHours(hh)
	date.setMinutes(mm)

	return date.getTime()
}

function getEndThisWeekValue() {
	let saturday = lastDayOfWeek(new Date())
	let workingDays = lo.get(store.me(), 'account.business_hours.working_days', [])
	if (!lo.find(workingDays, (wd) => wd.weekday === 'Saturday')) {
		// last day of week is Sunday if doesn set Saturday is working day
		saturday = subDays(saturday, 1)
	}

	return getEndTimeFromBusinessHours(saturday, true)
}

// 01 => 1, 004 => 4
function pad(str = '') {
	if (str === '0') return '0'
	if (!str.startsWith('0')) return str

	let zeroCount = 0
	for (let i = 0; i < str.length; i++) {
		if (str[i] === '0') {
			zeroCount += 1
		} else {
			break
		}
	}
	str = str.slice(zeroCount)
	return str
}

// 29-4 => 29-4-2024 (if today is not exceed 29/4/2024)
// 29-4 => 29-4-2025 (if today is exceed 29/4/2024, )
// 31-2 => '' (Invalid date)

function detectSimilarDate(str = '') {
	let seperator = ''
	if (str.indexOf('-') > -1) seperator = '-'
	if (str.indexOf('/') > -1) seperator = '/'

	if (!seperator) return
	let [dd, mm, yyyy] = lo.split(str, seperator)

	// invalid dd mm yyyy value
	if (!/^\d+$/.test(dd)) return
	if (!/^\d+$/.test(mm)) return
	if (yyyy && !/^\d+$/.test(yyyy)) return

	if (dd.length < 1 || dd.length > 2) return
	if (mm.length < 1 || mm.length > 2) return
	if (yyyy && yyyy.length !== 4) return

	if (getCurrentLanguage() !== 'vi') {
		// US language prefer to use mm dd yyyy
		let tempdd = mm
		mm = dd
		dd = tempdd
	}

	if (!yyyy) {
		let today = new Date()

		// check today exceed date that user input => yyyy = next year
		if (today.getMonth() + 1 < +mm || (today.getDate() < +dd && today.getMonth() + 1 === +mm)) {
			yyyy = today.getFullYear()
		} else {
			yyyy = today.getFullYear() + 1
		}
	}
	let dateString = `${yyyy}-${mm}-${dd}`

	// check valid date
	if (isNaN(new Date(dateString).getTime())) return ''
	return dateString
}
