import store from '@sb/store'
import sb from '@sb/util'

export default {
	name: 'user-display-name',
	props: ['user'],
	data() {
		return {
			loading: false,
		}
	},

	mounted() {
		store.onUser(this, (userM) => {
			if (userM[lo.get(this.user, 'id')]) {
				this.$forceUpdate()
			}
		})
		this.loadData()
	},

	methods: {
		getDisplayUser() {
			let user = this.user
			let userid = ''
			if (user && user.id) {
				let realuser = store.matchUser(user.id, true) || user
				userid = realuser.primary_id || realuser.id
				user = store.matchUser(userid) || user // should display name of primaryuser when channel !== call and  !== email
				let isProfile = lo.get(realuser, 'channel') !== 'call' && lo.get(realuser, 'channel') !== 'email'
				if (isProfile) {
					userid = realuser.id
					user = realuser
				}
			}
			return user
		},

		async loadData() {
			let user = this.getDisplayUser()
			let trueName = sb.getUserTextAttr(user, 'fullname') || sb.getUserTextAttr(user, 'display_name')
			if (lo.trim(trueName)) return
			this.loading = true
			await store.fetchUser(user.id, true)
			this.loading = false
			this.$forceUpdate()
		},
	},

	render() {
		let user = this.getDisplayUser()
		let trueName = sb.getUserTextAttr(user, 'fullname') || sb.getUserTextAttr(user, 'display_name')

		if (!lo.trim(trueName) && this.loading) {
			return <em class='text__muted'>{this.$t('loading')}...</em>
		}
		return <span title={sb.getUserDisplayName(user)}>{sb.getUserDisplayName(user)}</span>
	},
}
