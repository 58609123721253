import store from '@sb/store'
var sb = require('@sb/util')
import {Time} from '@sb/com'
import Avatar from '@sb/com/avatar'
import Fragment from '@sb/com/fragment'

const LIMIT = 5

export default {
	name: 'user-related-profiles',
	props: ['user', 'refresh'],
	data() {
		return {
			loading: false,
			limit: LIMIT,
			showFull: false,
			markingDone: {},

			profiles: [],
			mergeUser: {},
			merging: false,
		}
	},

	mounted() {
		if (this.user) this.loadRelatedProfiles(this.user.id)
		this.loadRelatedProfilesInterval = setInterval(() => {
			this.loadRelatedProfiles(this.user.id, {showLoading: false})
		}, 30_000)
	},

	watch: {
		user(user, olduser) {
			if (user && user.id !== lo.get(olduser, 'id')) {
				this.profiles = []
				this.loadRelatedProfiles(user.id)
			}
		},
	},

	destroyed() {
		if (this.loadRelatedProfilesInterval) clearInterval(this.loadRelatedProfilesInterval)
	},

	methods: {
		async loadRelatedProfiles(uid, options = {showLoading: true}) {
			let iid = this._loadid || 0
			iid++
			this._loadid = iid

			if (!uid) return
			if (options.showLoading) this.loading = true
			let res = await store.listUserRelatedProfiles(uid)
			if (this._loadid != iid) {
				if (options.showLoading) this.loading = false
				return // outdated
			}
			let profiles = lo.get(res, 'body.related_users', [])
			let userids = lo.map(profiles, (profile) => profile.with_user_id)
			await store.fetchUsers(userids)
			profiles = lo.map(profiles, (profile) => {
				if (!profile.with_user_id) return
				let user = store.matchUser(profile.with_user_id, true)
				if (!user) return
				if (user.primary_id) user = store.matchUser(user.primary_id)
				return {
					...user,
					with_user_id: profile.with_user_id,
					suggested_by_type: profile.suggested_by_type,
					suggested_reason: profile.suggested_reason,
				}
			})
			profiles = lo.filter(profiles, Boolean)
			if (options.showLoading) this.loading = false
			this.profiles = lo.orderBy(profiles, ['id'], ['asc'])
		},

		renderRelatedProfiles(uid) {
			let profiles = lo.cloneDeep(this.profiles)
			// trim the list
			if (!this.showFull && profiles.length > this.limit) profiles = lo.take(profiles, this.limit)
			//if (tasks) tasks = tasks.slice(0, LIMIT)
			return lo.map(profiles, (profile) => this.renderProfile(profile))
		},

		renderHandle(profiles) {
			let more = profiles.length - this.limit
			if (more <= 0) return null

			return (
				<div class='profile_section__more' vOn:click={this.toogleShowFull}>
					{this.showFull ? this.$t('collapse') : this.$t('show_more', [more])}
				</div>
			)
		},

		toogleShowFull() {
			this.showFull = !this.showFull
		},

		renderProfile(profile) {
			let reasonField = this.$t('no_reason')
			let reasonValue = ''
			let reason = profile.suggested_reason || ''
			if (reason.startsWith('same_email')) {
				reasonField = this.$t('same_email')
				reasonValue = reason.slice(11)
			} else if (reason.startsWith('same_phone')) {
				reasonField = this.$t('same_phone')
				reasonValue = reason.slice(11)
			} else if (reason.startsWith('same_record_id')) {
				reasonField = this.$t('same_record_id')
				reasonValue = reason.slice(15)
			}

			let reasonText = `${reasonField} ${reasonValue}`

			return (
				<div class='user_task_row'>
					<div
						class='d-flex flex__1 mr-4 clickable'
						style='overflow: hidden'
						vOn:click={() => this.showQuickviewOtherTab(profile.id)}
					>
						<Avatar size='28' user={profile} is_profile />
						<div class='ml-3' style='overflow: hidden'>
							<div class='flex__1 text__truncate' title={sb.getUserDisplayName(profile)}>
								{sb.getUserDisplayName(profile)}
							</div>
							<div class='text__muted text__sm text__truncate' title={reasonText}>
								{reasonText}
							</div>
						</div>
					</div>
					<div class='link text__sm' vOn:click={() => (this.mergeUser = profile)}>
						{this.$t('merge')}
					</div>
					<div class='dot dot--sm dot__secondary mr-2 ml-2' style='margin-top: 0' />
					<Icon
						name='trash'
						size='16'
						class='link link__danger'
						vOn:click={() => this.onDeleteRelated(profile.with_user_id)}
					/>
				</div>
			)
		},

		async onDeleteRelated(profileId) {
			let cf = await this.$confirm({
				title: this.$t('delete_related_user'),
				description: this.$t('delete_related_user_noti_desc'),
				style: 'danger',
			})

			if (!cf) return
			if (this.isDeleting) return
			this.isDeleting = true
			let {error} = await store.deleteUserRelatedProfile(this.user.id, profileId)
			this.isDeleting = false
			if (error) return this.$showError(error)
			this.loadRelatedProfiles(this.user.id, {showLoading: false})
		},

		showQuickviewOtherTab(userid) {
			let quickview = lo.get(this.$route, 'query.quickview')
			if (quickview) {
				window.open(`/lead?quickview=${userid}`, '_blank')
			} else {
				this.$root.$emit('showQuickview', {user_id: userid})
			}
		},

		async submitMerge() {
			if (this.merging) return
			this.merging = true
			let res = await store.mergeUser([
				{
					id: lo.get(this.mergeUser, 'id', ''),
				},
				{
					id: this.user.id,
				},
			])
			this.merging = false
			this.mergeUser = {}
			this.loadRelatedProfiles(this.user.id, {showLoading: false})
			this.$emit('merge')
		},

		renderMergeModal() {
			let cls = 'modal'
			if (!this.mergeUser.id) cls += ' modal__hide'

			let username = sb.getUserDisplayName(this.mergeUser)
			let currentUserName = sb.getUserDisplayName(this.user)

			return (
				<div class={cls}>
					<div class='modal__overlay' vOn:click={() => (this.mergeUser = {})} />
					<div class='modal__container'>
						<div class='modal__background' style='overflow: hidden;'>
							<div class='modal_content' style='padding: 12px 20px; width: 480px'>
								<div class='d-flex align-items-center'>
									<div class='text__lg text__semibold'>{this.$t('merge_related_users')}</div>
									<Icon name='x' size='20' class='btn__close ml-auto' vOn:click={() => (this.mergeUser = {})} />
								</div>
								<div
									class='mt-4 mb-2'
									domPropsInnerHTML={this.$t('merge_related_users_noti', [username, currentUserName])}
								></div>
								<div class='mt-4 d-flex align-items-center justify-content-end'>
									<div class='link link__secondary mr-4' vOn:click={() => (this.mergeUser = {})}>
										{this.$t('cancel')}
									</div>
									<button
										type='button'
										class='btn btn__sm btn__primary justify-content-center'
										style='width: 100px; display: inline-block;'
										disabled={this.merging}
										vOn:click={this.submitMerge}
									>
										{this.$t('confirm')}
										{this.merging && <Spinner class='ml-2' size='16' />}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},
	},

	render() {
		if (this.refresh) lo.noop()
		if (!this.user) return null
		let uid = this.user.id

		let $loading = null
		if (lo.size(this.profiles) > 0)
			$loading = (
				<div class='text__muted ml-3' style='color: #adb5bd;'>
					{lo.size(this.profiles)}
				</div>
			)
		if (this.loading) $loading = <Spinner static class='ml-2 mb-1' mode='dark' />

		return (
			<div class='user_tasks'>
				<div class='user_profile__head'>
					<div class='profile_section_title' style='display: flex; flex-direction: row;'>
						{this.$t('related_users')} {$loading}
					</div>
					<div style='flex: 1'></div>
				</div>
				{lo.size(this.profiles) > 0 ? (
					<div class='user_tasks__body mt-3'>
						{this.renderRelatedProfiles(this.user.id)}
						{this.renderHandle(this.profiles)}
					</div>
				) : (
					<div class='user_tasks__body mt-3'>
						<div class='text__muted'>{this.loading ? this.$t('loading') + '...' : this.$t('no_related_users')}</div>
					</div>
				)}
				{this.renderMergeModal()}
			</div>
		)
	},
}
