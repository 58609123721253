var sb = require('@sb/util')
const flow = require('@subiz/flow')
import store from '@sb/store'
const NOTI_OVERDUE_TASK_HIDDEN_TIME = 900000 // 15 minutes

export default {
	name: 'instant_noti',
	data() {
		return {}
	},

	async mounted() {
		store.onInstantNoti(this, (newnoti) => this.doNew(newnoti))
		this.computeShouldShowOverdueNoti()

		// 5min for loop
		this.interval = setInterval(this.computeShouldShowOverdueNoti, 5 * 60_000)
	},

	beforeDestroy() {
		if (this.interval) clearInterval(this.interval)
	},

	methods: {
		async fetchRemindedTaskCount() {
			let tasks = await store.listRemindedTasks()
			await store.fetchTasks(lo.map(tasks, (task) => task.id))
			let remindedTasks = lo.filter(tasks, this.isTaskShouldReminded)

			this.remindedTasksCount = lo.size(remindedTasks)
		},

		async fetchOverdueTasksCount() {
			let overdueTasks = await store.listOverdueTasks()
			this.overdueTasksCount = lo.size(overdueTasks)
		},

		async fetchRemindedTicketsCount() {
			let query = {
				is_count_only: true,
				condition: {
					all: [
						{
							key: 'expired',
							type: 'boolean',
							boolean: {
								op: 'false',
							},
						},
						{
							key: 'due_at',
							type: 'number',
							number: {
								op: 'gt',
								gt: Date.now(),
							},
						},
						{
							key: 'due_at',
							type: 'number',
							number: {
								op: 'lt',
								lt: Date.now() + 3_600_000,
							},
						},
						{
							key: 'state',
							type: 'text',
							text: {
								op: 'eq',
								eq: ['open', 'unassigned', 'hold'],
							},
						},
						{
							key: 'assignee',
							type: 'text',
							text: {
								op: 'eq',
								eq: [store.me().id],
							},
						},
					],
				},
			}
			let res = await store.listTickets(query)
			this.remindedTicketsCount = lo.get(res, 'body.total') || 0
		},

		async fetchOverdueTicketsCount() {
			let query = {
				is_count_only: true,
				condition: {
					all: [
						{
							key: 'expired',
							type: 'boolean',
							boolean: {
								op: 'true',
							},
						},
						{
							key: 'state',
							type: 'text',
							text: {
								op: 'eq',
								eq: ['open', 'unassigned', 'hold'],
							},
						},
						{
							key: 'assignee',
							type: 'text',
							text: {
								op: 'eq',
								eq: [store.me().id],
							},
						},
					],
				},
			}
			let res = await store.listTickets(query)
			this.overdueTicketsCount = lo.get(res, 'body.total') || 0
		},

		async computeShouldShowOverdueNoti(options = {task_only: false}) {
			let promises = [this.fetchOverdueTasksCount(), this.fetchRemindedTaskCount()]
			if (!options.task_only) {
				promises.push(this.fetchRemindedTicketsCount())
				promises.push(this.fetchOverdueTicketsCount())
			}
			await Promise.all(promises)

			let shouldShow =
				Date.now() - lo.get(store.me(), 'dashboard_setting.last_close_task_notification', 0) >
				NOTI_OVERDUE_TASK_HIDDEN_TIME
			if (!shouldShow) return

			if (this.overdueTicketsCount || this.overdueTasksCount) {
				this.$root.$emit('push_noti_message', {
					id: 'overdue_reminded_tasks', // id so we dont want to split overdue_tasks noti
					type: 'overdue_tasks',
					data: {
						task_count: this.overdueTasksCount,
						ticket_count: this.overdueTicketsCount,
						reminded_task_count: this.remindedTasksCount,
						reminded_ticket_count: this.remindedTicketsCount,
					},
					priority: 'high',
				})
			} else if (this.remindedTasksCount || this.remindedTicketsCount) {
				this.$root.$emit('push_noti_message', {
					id: 'overdue_reminded_tasks',
					type: 'task_reminded',
					data: {task_count: this.remindedTasksCount, ticket_count: this.remindedTicketsCount},
					priority: 'high',
				})
			}
		},

		isTaskShouldReminded(task) {
			let dueAt = task.due_at || 0
			let reminder = task.reminder || 0
			let member = lo.find(task.members, (mem) => mem.reminder_closed && mem.agent_id === store.me().id)
			let isClosed = member && member.reminder_closed > dueAt - reminder

			return dueAt && reminder && dueAt > Date.now() && reminder + Date.now() > dueAt && !isClosed
		},

		async doNew(newnoti) {
			if (!newnoti) return this.$forceUpdate()
			if (!newnoti.data) return null
			let data = JSON.parse(newnoti.data) || {}
			let orderid
			if (newnoti.type == 'order_assigned' || newnoti.type === 'order_commented') {
				// dont show instant noti if agent is already viewing the order
				let viewing = store.getViewingOrder()
				if (viewing == data.order_id) return

				orderid = data.order_id
			}

			let ticketid
			if (
				newnoti.type == 'ticket_created' ||
				newnoti.type == 'ticket_comment_added' ||
				newnoti.type == 'ticket_state_updated' ||
				newnoti.type == 'ticket_reassigned' ||
				newnoti.type == 'ticket_assigned'
			) {
				ticketid = data.ticket_id
			}

			let uid
			if (newnoti.type == 'task_reminded' || newnoti.type === 'task_expired') {
				this.computeShouldShowOverdueNoti({task_only: true})
				return
			}
			if (newnoti.type === 'lead_created' || newnoti.type === 'user_created' || newnoti.type === 'user_returned')
				uid = lo.get(data, 'user.id')
			if (newnoti.type === 'campaign_user_converted' || newnoti.type === 'user_opened_email') {
				uid = lo.get(data, 'user_id')
			}

			let user = null
			// try to fetch user to reduce ui update
			if (uid) {
				user = store.matchUser(uid)
				let start = sb.now()
				while (!user && sb.now() - start < 2000) {
					user = store.matchUser(uid)
					await flow.sleep(200)
				}
			}

			if (orderid) {
				let order = store.matchOrder(orderid)
				let start = sb.now()
				while (!order && sb.now() - start < 10000) {
					order = store.matchOrder(orderid)
					await flow.sleep(200)
				}
				if (!order) return
			}

			if (ticketid) {
				let ticket = store.matchTicket(ticketid)
				let start = sb.now()
				while (!ticket && sb.now() - start < 10000) {
					ticket = store.matchOrder(ticketid)
					await flow.sleep(200)
				}
				if (!ticket) return
			}

			if (!newnoti.id) newnoti.id = newnoti.checkpoint + '-' + newnoti.topic
			this.$root.$emit('push_noti_message', {type: 'instant_noti', data: newnoti, duration: 6_000})
		},
	},

	render() {
		return null
	},
}
