import store from '@sb/store'
var sb = require('@sb/util')
import {Time} from '@sb/com'
import {convertUserLabelToLabelDefination} from '../common.js'
const LIMIT = 20 // LIMIT label to display

export default {
	name: 'user-labels',
	props: ['uid', 'refresh'],
	data() {
		return {
			loading: true,
			showDropdown: false,
			adding: {},
			removing: {},

			// dorpdown position
			condX: 0,
			condY: 0,
			condWidth: 0,
			condHeight: 0,

			keyword: '',
			isShrink: true,
		}
	},

	watch: {
		async uid() {
			this.loading = true
			await store.fetchUserLabels()
			this.loading = false
		},
	},
	async mounted() {
		this.loading = true
		await store.fetchUserLabels()
		this.loading = false
	},

	render() {
		if (this.refresh) lo.noop()
		let $loading = null
		if (this.loading) {
			return (
				<div class='user_notes'>
					<div class='user_profile__head' style='justify-content: flex-start'>
						<div class='profile_section_title attribute_label'>{this.$t('user_lbl')}</div>
						<div class='text__muted'>{this.$t('loading')}...</div>
					</div>
				</div>
			)
		}

		if (!this.uid) return null
		let user = store.matchUser(this.uid) || {}

		let $labels = lo
			.map(user.labels, (ulabel) => {
				let label = convertUserLabelToLabelDefination(ulabel)
				if (label.deleted) return
				if (lo.get(this.adding, [this.uid, ulabel.label])) return null

				return (
					<UserLabel
						label={label}
						deletable={!lo.get(this.removing, [this.uid, label.id])}
						vOn:delete={this.removeLabel}
						loading={lo.get(this.removing, [this.uid, label.id])}
					/>
				)
			})
			.filter((e) => !!e)

		lo.map(this.adding[this.uid], (ok, labelkey) => {
			if (!ok) return
			let label = store.matchUserLabel()[labelkey]
			$labels.push(<UserLabel label={label} loading />)
		})

		if (lo.size($labels) === 0) {
		}

		return (
			<div class='user_notes'>
				<div class='user_profile__head' style='justify-content: flex-start'>
					<div class='profile_section_title attribute_label'>{this.$t('user_lbl')}</div>
					<div class='d-flex flex-wrap'>
						<div style='position: relative;' v-clickaway={(_) => (this.showDropdown = false)}>
							<span ref='dropdown_trigger' class='convo_tag convo_tag__add_btn' vOn:click={this.showLabelDropdown}>
								+
							</span>
							{this.renderDropdown()}
						</div>
						{$labels}
					</div>
				</div>
			</div>
		)
	},

	methods: {
		showLabelDropdown() {
			this.setDropdownPosition()
			this.showDropdown = !this.showDropdown
		},

		setDropdownPosition() {
			let $trigger = this.$refs.dropdown_trigger
			let rect = $trigger ? $trigger.getBoundingClientRect() : {}

			let {x = 0, y = 0, width = 0, height = 0} = rect

			this.condX = x
			this.condY = y
			this.condWidth = width
			this.condHeight = height
		},

		isDropdowmLeft() {
			const SIDEBAR_WIDTH = 80
			const DRODOWN_WIDTH = 150
			return window.innerWidth - this.condX > SIDEBAR_WIDTH + DRODOWN_WIDTH
		},

		renderDropdown() {
			if (!this.showDropdown) return null
			let user = store.matchUser(this.uid) || {}
			let labels = lo.filter(user.labels, (ulabel) => {
				let label = store.matchUserLabel()[ulabel.label]
				return label && label.id && !label.deleted
			})

			let untaggeds = lo.filter(store.matchUserLabel(), (label) => !lo.find(labels, (ul) => ul.label === label.id))
			untaggeds = untaggeds.filter((label) => !label.deleted)
			untaggeds = lo.filter(untaggeds, (label) => !lo.get(this.adding, [this.uid, label.id]))

			if (user.channel != 'facebook') {
				untaggeds = lo.filter(untaggeds, (label) => label.source_channel != 'facebook')
			}
			// filter out correct label of page
			if (user.channel == 'facebook') {
				untaggeds = lo.filter(untaggeds, (label) => {
					if (!label.source_channel) return true
					if (label.source_channel == 'facebook' && label.source_channel_id != user.channel_source) return false
					return true
				})
			}

			untaggeds = lo.orderBy(untaggeds, ['source_channel', 'created'], ['desc', 'desc'])
			untaggeds = lo.filter(untaggeds, (label) => {
				let keyword = sb.unicodeToAscii(this.keyword).toLowerCase()
				let title = sb.unicodeToAscii(label.title || '').toLowerCase()
				return title.indexOf(keyword) > -1
			})

			let $more = null
			if (this.isShrink && !this.keyword) {
				//untaggeds = lo.take(untaggeds, LIMIT)
				//if (lo.size(untaggeds) > LIMIT) {
				//$more = (
				//<div class='mb-2'>
				//<a href='javascript:;' class='link link__secondary text__sm' vOn:click={() => (this.isShrink = false)}>
				//{this.$t('show_more')} <Icon name='chevron-down' size='14' />
				//</a>
				//</div>
				//)
				//}
			}

			let $untags = lo.map(untaggeds, (label) => {
				return (
					<div class='dropdown__item dropdown__item__thin' vOn:click={(_) => this.addUserLabel(label)}>
						<UserLabel label={label} />
					</div>
				)
			})

			let style = `position: fixed; top: ${
				this.condY + this.condHeight + 5
			}px; min-height: unset; max-height: unset; width: 200px;`
			if (this.isDropdowmLeft()) {
				style += `left: ${this.condX}px; right: unset`
			} else {
				style += `left: unset; right: ${window.innerWidth - this.condX - this.condWidth}px`
			}

			let $dropdownTags = (
				<div class='dropdown convo_header__tag_dropdown__right' style={style}>
					<div style='padding: 8px 10px; background: whitesmoke;'>
						<div class='input_search'>
							<Icon name='search' class='input_search__icon_search' size={16} stroke-width='2' />
							<input
								vModel={this.keyword}
								class='form-control input_search__input form-control-sm'
								placeholder={this.$t('search')}
							/>
							{this.keyword && (
								<Icon
									name='x'
									class='input_search__icon_close'
									size='16'
									stroke-width='2'
									vOn:click={() => (this.keyword = '')}
								/>
							)}
						</div>
					</div>
					<div style='max-height: 240px; overflow: auto'>
						{$untags}
						{$more}
						<div
							class='dropdown__item dropdown__item__thin'
							vOn:click={(_) => this.$router.push({name: 'settings.label'})}
						>
							<Icon name='tool' size='1x' class='mr-2' /> {this.$t('user_label_setting')}
						</div>
					</div>
				</div>
			)
			return $dropdownTags
		},

		async removeLabel(label) {
			if (lo.get(this.adding, [this.uid, label.id])) return
			if (lo.get(this.removing, [this.uid, label.id])) return

			lo.set(this.removing, [this.uid, label.id], true)
			this.$forceUpdate()

			let {error} = await store.unlabelUser(this.uid, label.id)
			if (error) {
				this.removeing[label.id] = false
				this.$forceUpdate()
				return this.$showError(error)
			}
			await store.fetchUser(this.uid, true)
			lo.unset(this.removing, [this.uid, label.id])
			this.$forceUpdate()

			await this.$nextTick()
			this.setDropdownPosition()
		},

		async addUserLabel(label) {
			if (lo.get(this.adding, [this.uid, label.id])) return
			lo.set(this.adding, [this.uid, label.id], true)
			this.$forceUpdate()

			let {error} = await store.addUserLabel(this.uid, label.id)
			if (error) {
				lo.unset(this.adding, [this.uid, label.id])
				this.$forceUpdate()
				return this.$showError(error)
			}
			await store.fetchUser(this.uid, true)
			lo.unset(this.adding, [this.uid, label.id])
			this.$forceUpdate()

			await this.$nextTick()
			this.setDropdownPosition()
		},
	},
}
