
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icon-tabler-star-filled",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","stroke-width":"2","stroke":"currentColor","fill":"none","stroke-linecap":"round","stroke-linejoin":"round"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","stroke":"none"}}),_c('path',{attrs:{"d":"M8.243 7.34l-6.38.925-.113.023a1 1 0 00-.44 1.684l4.622 4.499-1.09 6.355-.013.11a1 1 0 001.464.944l5.706-3 5.693 3 .1.046a1 1 0 001.352-1.1l-1.091-6.355 4.624-4.5.078-.085a1 1 0 00-.633-1.62l-6.38-.926-2.852-5.78a1 1 0 00-1.794 0L8.243 7.34z","fill":"currentColor","stroke":"none"}})])
          )
        }
      }
    