import sb from '@sb/util'
import store from '@sb/store'
import LexicalEditor from '../commons/lexical-editor.js'
const unassignedAvatar = require('../assets/img/unassigned.png')
import {getTicketTypeName, getTicketStateInfo, getAvailableTicketTypes} from './com.js'
import {computeErrorMessage} from '../commons/compute_error_message.js'
import {i18n} from '../languages.js'
import UserDisplayName from '../commons/user_display_name.js'
import {format} from 'date-fns'
import DueDateDropdown from './due_date_dropdown.js'
import AddTicketUserModal from './search_customer_modal.js'

export default {
	name: 'edit-ticket-modal',
	props: ['open', 'ticket'],
	data() {
		return {
			loading: false,
			ticketData: {},
			initNote: {
				format: 'html',
				text: '',
			},

			isContinueCreate: false,
			selectedTemplateId: '',
			saving: false,

			moreOptions: [], // edit more value like due_date, reporter
			isAddTicketCustomerModalOpen: false,
		}
	},

	watch: {
		open() {
			if (this.open) {
				this.resetTicketData()
			}
		},
	},

	computed: {
		isEdit() {
			return lo.get(this.ticket, 'id') && lo.get(this.ticket, 'id') !== 'new'
		},
	},

	mounted() {
		//this.getTicketTemplates()
		//this.setTitleInputHeight()
		this.moreOptionItems = [
			{
				id: 'reporter',
				label: i18n.t('set_reporter'),
			},
			{
				id: 'due_date',
				label: i18n.t('set_due_date'),
			},
			{
				id: 'sla',
				label: i18n.t('apply_sla'),
			},
		]
	},

	methods: {
		async resetTicketData() {
			this.isApplyTemplateFirstTime = false
			let ticketData = {}
			if (this.isEdit) {
				let id = this.ticket.id
				this.loading = true
				await store.fetchTickets([id], true)
				this.loading = false
				let ticket = store.matchTicket(id) || {id}
				ticketData = {...ticket, ...this.ticket}
			} else {
				ticketData = lo.cloneDeep(this.ticket)
				if (!ticketData.state) ticketData.state = 'unassigned'
			}
			console.log('resetTicketData', this.ticket)

			if (!ticketData.type_id) {
				let types = getAvailableTicketTypes()
				ticketData.type_id = lo.get(types, '0.id')
			}
			if (!ticketData.reporter_type) ticketData.reporter_type = 'agent'

			this.selectedTemplateId = ''
			this.ticketData = ticketData
			this.setTicketDataAttrs()
			let templates = this.getTicketTemplates()
			if (lo.size(templates)) {
				let type = store.matchTicketType()[ticketData.type_id] || {id: ticketData.type_id}
				this.selectedTemplateId = type.template_id || lo.get(templates, '0.id')
				// lexical editor sometimes emit change a modified html when run setInitialContent() in the first time. So we maintain this variable to avoid dialog when close modal imidiately
				this.isApplyTemplateFirstTime = true
				this.applyTemplate()
			} else {
				this.initNote = lo.get(ticketData, 'description') || {format: 'html', text: ''}
				await this.$nextTick()
				this.$refs.note_input && this.$refs.note_input.setInitialContent()
			}

			this.compareNote = lo.cloneDeep(this.initNote)
			this.compareTicket = lo.cloneDeep(this.ticketData)
			this.tryFocus()
		},

		setTicketDataAttrs() {
			let type = store.matchTicketType()[this.ticketData.type_id]
			let defs = lo.get(type, 'defs') || []

			let attrs = lo.map(defs, (def) => {
				return {
					key: def.key,
					type: def.type || 'text',
				}
			})
			this.onChange({attrs})
		},

		async resetDataWhenContinueCreate() {
			let templates = this.getTicketTemplates()
			// try to create new with selected template, if we switch back to type doesnt have any template so dont try to apply
			if (lo.size(templates) && store.matchTicketTemplate()[this.selectedTemplateId]) {
				this.applyTemplate()
			} else {
				this.initNote = {
					format: 'html',
					text: '',
				}
				let $note_input = this.$refs.note_input
				await this.$nextTick()
				this.$refs.note_input && this.$refs.note_input.setInitialContent()
			}
			this.ticketData = {...this.ticketData, title: ''}
			this.tryFocus()
		},

		async tryFocus() {
			await this.$nextTick()
			this.$refs.title_input && this.$refs.title_input.focus()
		},

		async closeModal() {
			let isChanged = !lo.isEqual(this.ticketData, this.compareTicket) || !lo.isEqual(this.initNote, this.compareNote)
			if (isChanged) {
				let cf = await this.$confirm({
					title: this.$t('unsaved'),
					description: this.$t('unsave_changed'),
				})

				if (!cf) return
			}
			this.$emit('close')
		},

		setTitleInputHeight(force) {
			let $input = this.$refs.title_input
			if ($input) {
				$input.style.height = 'auto' // must set auto first because scrollHeight doesnt reset if 2line => 1line
				$input.style.height = $input.scrollHeight + 'px'
			}
		},

		onChangeTitle(e) {
			//e.target.style.height = e.target.scrollHeight + 'px'
			let ticket = lo.cloneDeep(this.ticketData)
			ticket.title = e.target.value
			this.ticketData = ticket
			this.setTitleInputHeight()
		},

		onTitleInputPress(e) {
			if (e.keyCode === 13) {
				e.target.blur()
				if (this.$refs.note_input) this.$refs.note_input.focus()
				e.stopPropagation()
			}
		},

		renderTitle() {
			let cls = 'edit_ticket_modal_form_title_input'

			return (
				<textarea
					value={this.ticketData.title}
					vOn:input={this.onChangeTitle}
					ref='title_input'
					class={cls}
					placeholder={this.$t('add_title')}
					vOn:keypress={this.onTitleInputPress}
					spellcheck='false'
					rows='1'
				></textarea>
			)
		},

		renderNote() {
			return (
				<LexicalEditor
					class='edit_ticket_modal_form_desc_editor'
					placeholder={this.$t('add_description')}
					useMention
					useAutoLink
					html
					no_preview_mode
					initMessage={this.initNote}
					ref='note_input'
					vOn:change={(msg) => this.onChangeNoteDesc(msg)}
				/>
			)
		},

		onChangeNoteDesc(msg) {
			this.initNote = msg
			if (this.isApplyTemplateFirstTime) {
				this.compareNote = lo.cloneDeep(this.initNote)
				this.isApplyTemplateFirstTime = false
			}
		},

		async submit() {
			//return this.testCreate()
			//if (!this.loading) return
			if (this.saving) return

			this.saving = true
			let res
			let ticket = {
				...this.ticketData,
				description: this.initNote,
			}
			if (this.isEdit) {
				res = await store.updateTicket(ticket)
			} else {
				res = await store.createTicket(ticket)
			}
			this.saving = false
			if (res.error) {
				this.$showError(res.error)
				return
			}
			this.$showSuccess(this.$t('success'))
			if (this.isContinueCreate) {
				this.resetDataWhenContinueCreate()
			} else {
				this.$emit('close')
				this.$emit('success', res)
			}
		},

		onChange(obj) {
			this.ticketData = {...this.ticketData, ...obj}
		},

		renderPriority() {
			let items = [
				{
					id: '',
					icon: <Icon name='antenna-bars-1' size='16' class='text__secondary' style='position: relative; top: -6px' />,
					label: this.$t('low'),
				},
				{
					id: 'high',
					icon: <Icon name='antenna-bars-5' stroke-width='2' size='16' class='text__secondary' />,
					label: this.$t('high'),
				},
				{
					id: 'urgent',
					icon: (
						<Icon name='alert-square-filled' class='text__danger' size='16' style='position: relative; top: -2px' />
					),
					label: this.$t('urgent'),
				},
			]
			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label mb-1'>{this.$t('priority')}</div>
					<Dropdown
						mode='input'
						extra_cls='dropdown_input__small dropdown_input__light'
						items={items}
						vOn:select={(item) => this.onChange({priority: item.id})}
						selected={this.ticketData.priority || ''}
						dropdown_width={200}
						style='overflow: hidden'
					></Dropdown>
				</div>
			)
		},

		renderReporter() {
			let reporter = lo.get(this.ticketData, 'reporter') || store.me().id

			let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent' && agent.state === 'active')
			agents = lo.sortBy(agents, [
				(agent) => {
					let name = lo.get(agent, 'fullname') || ''
					name = sb.unicodeToAscii(name).toLowerCase()
					return name
				},
			])
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: sb.getAgentDisplayName(agent),
			}))

			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label mb-1'>{this.$t('reporter')}</div>
					<Dropdown
						mode='input'
						items={items}
						extra_cls={'dropdown_input__light dropdown_input__small'}
						vOn:select={(item) => this.onChange({reporter: item.id})}
						selected={reporter}
					></Dropdown>
				</div>
			)
		},

		renderSLA() {
			let slas = lo.filter(store.matchSLA(), (sla) => {
				if (sla.archived) return false
				let ticketTypeId = this.ticketData.type_id || ''
				// sla with no type_id  is used for default
				if (!sla.type_id) {
					return ticketTypeId.startsWith('ttdefault')
				}
				return ticketTypeId === sla.type_id
			})
			slas = lo.orderBy(slas, ['number_of', 'created'], ['asc', 'asc'])
			let items = lo.map(slas, (sla) => {
				return {
					id: sla.id,
					label: sla.name,
				}
			})

			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label mb-1'>{this.$t('sla_policy')}</div>
					<Dropdown
						mode='input'
						ref='sla_dropdown'
						placeholder={this.$t('apply_sla')}
						extra_cls={'dropdown_input__small dropdown_input__light'}
						items={items}
						selected={this.ticketData.sla_policy}
						vOn:select={(item) => this.onChange({sla_policy: item.id})}
					></Dropdown>
				</div>
			)
		},

		renderAssignee() {
			let agents = lo.filter(store.matchAgent(), (agent) => agent.type === 'agent' && agent.state === 'active')
			agents = lo.sortBy(agents, [
				(agent) => {
					let name = lo.get(agent, 'fullname') || ''
					name = sb.unicodeToAscii(name).toLowerCase()
					return name
				},
			])
			let items = lo.map(agents, (agent) => ({
				id: agent.id,
				img: agent.avatar_url || sb.getAgentDefaultAvatarUrl(agent),
				label: sb.getAgentDisplayName(agent),
			}))

			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label mb-1'>{this.$t('assignee')}</div>
					<Dropdown
						mode='input'
						extra_cls='dropdown_input__small dropdown_input__light'
						items={items}
						vOn:select={(item) => this.onSelectAssignee(item.id)}
						selected={this.ticketData.assignee}
					></Dropdown>
				</div>
			)
		},

		onSelectAssignee(agentid) {
			let obj = {
				assignee: agentid,
			}
			let currentState = lo.get(this.ticketData, 'state')
			if (!currentState || currentState === 'unassigned') obj.state = 'open'
			this.onChange(obj)
		},

		renderStatus() {
			let ticket = this.ticketData
			let {type, text} = getTicketStateInfo(ticket)
			let $content = (
				<div class='d-flex align-items-center' key='status_new'>
					<Icon name='circle-filled' class={`mr-2 text__${type}`} size='16' />
					{text}
				</div>
			)

			let items = lo.map(['open', 'hold', 'closed'], (state) => {
				let {type, text} = getTicketStateInfo({state})
				return {
					id: state,
					icon: <Icon name='circle-filled' size='16' class={`text__${type}`} />,
					label: text,
				}
			})
			return (
				<Dropdown
					mode='custom'
					class='mr-3 mt-3'
					items={items}
					vOn:select={(item) => this.onChange({state: item.id})}
					dropdown_width={150}
					style='overflow: hidden'
				>
					<div class='btn btn__sm btn__white align-items-center' style='display: inline-flex'>
						{$content}
						<Icon name='chevron-down' stroke-width='2' size='16' class='text__muted ml-3' />
					</div>
				</Dropdown>
			)
		},

		toggleContinueCreate() {
			this.isContinueCreate = !this.isContinueCreate
		},

		clearFileInput(e) {
			e.target.value = ''
		},

		async addLocalFiles(e, type) {
			let $editor = this.$refs.note_input
			if ($editor && $editor.addLocalFiles && typeof $editor.addLocalFiles === 'function') {
				$editor.addLocalFiles(e, type)
			}
		},

		removeDueDate() {
			this.removeMoreOptions('due_date')
			this.onChange({due_at: 0})
		},

		removeMoreOptions(key) {
			this.moreOptions = lo.filter(this.moreOptions, (option) => option !== key)
		},

		renderDueDate() {
			let $content = <div class='text__muted'>{this.$t('set_due_date')}</div>

			if (this.ticketData.due_at) {
				let fm = lo.get(store.me(), 'account.date_format') || 'dd/MM/yyyy'
				fm = fm.replace(/DD/g, 'dd')
				fm = fm.replace(/Y/g, 'y')

				$content = format(this.ticketData.due_at, `${fm} HH:mm`)
			}

			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label mb-1'>{this.$t('due_date')}</div>
					<DueDateDropdown
						ref='due_date_dropdown'
						value={this.ticketData.due_at}
						vOn:change={(v) => this.onChange({due_at: v})}
					>
						<div class='dropdown_input dropdown_input__light dropdown_input__small d-flex align-items-center'>
							<Icon name='calendar' class='mr-2 text__secondary' size='16' title={this.$t('due_date')} />
							{$content}
						</div>
					</DueDateDropdown>
				</div>
			)
		},

		getTicketTemplates() {
			let templates = lo.filter(store.matchTicketTemplate(), (tmpl) => {
				if (!tmpl) return false
				if (!tmpl.type_id) return true
				return tmpl.type_id === lo.get(this.ticketData, 'type_id')
			})
			templates = lo.orderBy(templates, ['created'], ['desc'])
			return templates
		},

		async applyTemplate() {
			let selectedTmpl = store.matchTicketTemplate()[this.selectedTemplateId]
			if (!selectedTmpl) return

			let desc = selectedTmpl.message || {format: 'html', text: ''}
			this.initNote = lo.cloneDeep(desc)
			await this.$nextTick()
			this.$refs.note_input && this.$refs.note_input.setInitialContent()
		},

		onChangeTemplateId(v) {
			this.selectedTemplateId = v
			this.applyTemplate()
		},

		onChangeType(v) {
			let currentSelectedTemplateId = this.selectedTemplateId
			this.onChange({type_id: v})
			console.log('onChangeType', currentSelectedTemplateId)
			this.setTicketDataAttrs()
			let templates = this.getTicketTemplates()
			if (!currentSelectedTemplateId && lo.size(templates)) {
				let type = store.matchTicketType()[v] || {id: v}
				this.selectedTemplateId = type.template_id || lo.get(templates, '0.id')
				this.applyTemplate()
			}
		},

		onChangeCustomAttr(idx, obj) {
			let attrs = lo.get(this.ticketData, 'attrs') || []
			lo.each(obj, (val, key) => {
				lo.set(attrs, [idx, key], val)
			})
			this.onChange({attrs})
		},

		onRemoveCustomAttrOtherValue(idx, oidx) {
			let attrs = lo.get(this.ticketData, 'attrs') || []
			let attr = attrs[idx]
			if (!attr) return
			let otherValues = attr.other_values || []
			otherValues.splice(oidx, 1)
			attr.other_values = otherValues
			this.onChange({attrs})
		},

		async addCustomAttrOtherValue(idx) {
			let attrs = lo.get(this.ticketData, 'attrs') || []
			let attr = attrs[idx]
			if (!attr) return
			let otherValues = attr.other_values || []
			otherValues.push('')
			attr.other_values = otherValues
			this.onChange({attrs})
			await this.$nextTick()
			let $input = this.$refs[`custom_attr_label_input_${idx}_other_value_${lo.size(otherValues) - 1}`]
			if ($input) $input.focus()
		},

		onChangeCustomAttrOtherValue(idx, oidx, v) {
			let attrs = lo.get(this.ticketData, 'attrs') || []
			lo.set(attrs, [idx, 'other_values', oidx], v)
			this.onChange({attrs})
		},

		toggleCustomAttrCheckboxValue(idx, value) {
			let attrs = lo.get(this.ticketData, 'attrs') || []
			let attr = lo.get(attrs, idx) || {}

			let values = []
			if (lo.trim(attr.text)) values.push(attr.text)
			lo.each(attr.other_values, (v) => {
				if (lo.trim(v)) values.push(v)
			})
			if (values.includes(value)) {
				values = lo.filter(values, (v) => v !== value)
			} else {
				values.push(value)
			}

			let obj = {text: '', other_values: []}
			if (lo.size(values)) {
				let [text, ...otherValues] = values
				obj.text = text
				obj.other_values = otherValues
			}
			this.onChangeCustomAttr(idx, obj)
		},

		renderCustomAttrTextInputs(attr, idx) {
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							class='form-control form-control__light form-control-sm'
							value={attr.text}
							vOn:input={(e) => this.onChangeCustomAttr(idx, {text: e.target.value})}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									class='form-control form-control__light form-control-sm'
									value={v}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) => this.onChangeCustomAttrOtherValue(idx, oidx, e.target.value)}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrTextAreas(attr, idx) {
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex'>
						<textarea
							rows='2'
							style='resize: none'
							class='form-control form-control__light form-control-sm'
							value={attr.text}
							vOn:input={(e) => this.onChangeCustomAttr(idx, {text: e.target.value})}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon mt-2'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						return (
							<div class='d-flex mt-2'>
								<textarea
									rows='2'
									style='resize: none'
									class='form-control form-control__light form-control-sm'
									value={v}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) => this.onChangeCustomAttrOtherValue(idx, oidx, e.target.value)}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon mt-2'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrCheckboxes(attr, idx, attrdef) {
			let items = lo.get(attrdef, 'items') || []
			let values = []
			if (lo.trim(attr.text)) values.push(attr.text)
			lo.each(attr.other_values, (v) => {
				if (lo.trim(v)) values.push(v)
			})
			return (
				<div class='d-flex flex-wrap flex__1'>
					{lo.map(items, (item, iidx) => {
						return (
							<div class='form-check mr-3 mb-2' style='width: calc(50% - 10px); overflow: hidden'>
								<input
									name={`custom_attr_${attr.key}_cb`}
									id={`custom_attr_${attr.key}_cb_${item.value}`}
									type='checkbox'
									class='form-check-input form-check-input--bold'
									checked={values.includes(item.value)}
									vOn:change={() => this.toggleCustomAttrCheckboxValue(idx, item.value)}
								/>
								<label
									for={`custom_attr_${attr.key}_cb_${item.value}`}
									class='form-check-label text__sm'
									style='padding-left: 5px'
									title={item.label}
								>
									{item.label}
								</label>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrRadios(attr, idx, attrdef) {
			let items = lo.get(attrdef, 'items') || []
			return (
				<div class='d-flex flex-wrap flex__1'>
					{lo.map(items, (item, iidx) => {
						return (
							<div class='form-check mr-3 mb-2' style='width: calc(50% - 10px); overflow: hidden'>
								<input
									name={`custom_attr_${attr.key}_cb`}
									id={`custom_attr_${attr.key}_cb_${item.value}`}
									type='radio'
									class='form-check-input form-check-input--bold'
									checked={attr.text === item.value}
									vOn:change={() => this.onChangeCustomAttr(idx, {text: item.value})}
								/>
								<label
									for={`custom_attr_${attr.key}_cb_${item.value}`}
									class='form-check-label text__sm'
									style='padding-left: 5px'
									title={item.label}
								>
									{item.label}
								</label>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrDropdown(attr, idx, attrdef) {
			let items = lo.get(attrdef, 'items') || []
			items = lo.map(items, (item) => ({id: item.value, label: item.label}))

			if (attr.text) {
				items.unshift({id: '', html: <em>{this.$t('unselect')}</em>})
			}

			return (
				<div class='flex__1'>
					<Dropdown2
						items={items}
						mode='input'
						extra_cls={'form-control form-control__light dropdown_input__small'}
						selected={attr.text}
						placeholder={this.$t('select')}
						vOn:select={(item) => this.onChangeCustomAttr(idx, {text: item.id})}
					/>
				</div>
			)
		},

		renderCustomAttrDateInputs(attr, idx) {
			let date = attr.datetime ? format(new Date(attr.datetime), 'yyyy-MM-dd') : ''
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							type='date'
							class='form-control form-control__light form-control-sm'
							value={date}
							vOn:input={(e) =>
								this.onChangeCustomAttr(idx, {datetime: e.target.value ? new Date(e.target.value).toISOString() : ''})
							}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						let date = v ? format(new Date(v), 'yyyy-MM-dd') : ''
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									type='date'
									class='form-control form-control__light form-control-sm'
									value={date}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) =>
										this.onChangeCustomAttrOtherValue(
											idx,
											oidx,
											e.target.value ? new Date(e.target.value).toISOString() : '',
										)
									}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrDateTimeInputs(attr, idx) {
			let date = attr.datetime ? format(new Date(attr.datetime), 'yyyy-MM-dd HH:mm') : ''
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							type='datetime-local'
							class='form-control form-control__light form-control-sm'
							value={date}
							vOn:input={(e) =>
								this.onChangeCustomAttr(idx, {datetime: e.target.value ? new Date(e.target.value).toISOString() : ''})
							}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						let date = v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : ''
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									type='datetime-local'
									class='form-control form-control__light form-control-sm'
									value={date}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) =>
										this.onChangeCustomAttrOtherValue(
											idx,
											oidx,
											e.target.value ? new Date(e.target.value).toISOString() : '',
										)
									}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrBoolean(attr, idx) {
			let checked = lo.get(attr, 'boolean')
			return (
				<div class='flex__1 d-flex align-items-center'>
					<Sw checked={checked} vOn:change={(checked) => this.onChangeCustomAttr(idx, {boolean: checked})} />
				</div>
			)
		},

		renderCustomAttrNumberInputs(attr, idx) {
			return (
				<div class='d-flex flex-column flex__1'>
					<div class='d-flex align-items-center'>
						<input
							type='number'
							class='form-control form-control__light form-control-sm'
							value={attr.number}
							vOn:input={(e) => this.onChangeCustomAttr(idx, {number: +e.target.value})}
						/>
						<Icon
							name='plus'
							title={this.$t('add_value')}
							size='16'
							class='ml-2 x-icon'
							vOn:click={() => this.addCustomAttrOtherValue(idx)}
						/>
					</div>
					{lo.map(attr.other_values, (v, oidx) => {
						return (
							<div class='d-flex align-items-center mt-2'>
								<input
									class='form-control form-control__light form-control-sm'
									type='number'
									value={v}
									ref={`custom_attr_value_input_${idx}_other_value_${oidx}`}
									vOn:input={(e) => this.onChangeCustomAttrOtherValue(idx, oidx, e.target.value)}
								/>
								<Icon
									name='circle-minus'
									title={this.$t('remove')}
									size='16'
									class='ml-2 x-icon'
									vOn:click={() => this.onRemoveCustomAttrOtherValue(idx, oidx)}
								/>
							</div>
						)
					})}
				</div>
			)
		},

		renderCustomAttrRow(attr, idx) {
			let ticket = this.ticketData
			let type = store.matchTicketType()[ticket.type_id] || {id: ticket.type_id}
			let defs = lo.get(type, 'defs') || []
			let attrdef = lo.find(defs, (def) => def.key === attr.key) || {type: 'text'}

			let $content = this.renderCustomAttrTextInputs(attr, idx)
			if (attrdef.type === 'text') {
				if (attrdef.multiple_line) $content = this.renderCustomAttrTextAreas(attr, idx)
				if (attrdef.select === 'dropdown') $content = this.renderCustomAttrDropdown(attr, idx, attrdef)
				if (attrdef.select === 'radio') $content = this.renderCustomAttrRadios(attr, idx, attrdef)
				if (attrdef.select === 'checkbox') $content = this.renderCustomAttrCheckboxes(attr, idx, attrdef)
			}
			if (attrdef.type === 'number') $content = this.renderCustomAttrNumberInputs(attr, idx)
			if (attrdef.type === 'boolean') $content = this.renderCustomAttrBoolean(attr, idx)
			if (attrdef.type === 'datetime') {
				if (attrdef.is_date_only) $content = this.renderCustomAttrDateInputs(attr, idx)
				else $content = this.renderCustomAttrDateTimeInputs(attr, idx)
			}

			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label'>{attrdef.label || attr.key}</div>
					{$content}
				</div>
			)
		},

		renderCustomAttributes() {
			if (!lo.size(this.ticketData.attrs)) return
			return (
				<Fragment>
					<hr class='mt-4 mb-4' />
					<div class='text__semibold mb-2'>{this.$t('custom_attributes')}</div>
					<div>{lo.map(this.ticketData.attrs, this.renderCustomAttrRow)}</div>
				</Fragment>
			)
		},

		renderSidebar() {
			return (
				<div class='edit_ticket_modal_form_sidebar'>
					{this.renderType()}
					{this.renderUsers()}
					{this.renderAssignee()}
					{this.renderPriority()}
					{this.renderDueDate()}
					{this.renderSLA()}
					{this.renderReporter()}
					{this.renderCustomAttributes()}
				</div>
			)
		},

		renderType() {
			let items = getAvailableTicketTypes()
			items = lo.map(items, (item) => ({
				id: item.id,
				label: getTicketTypeName(item),
			}))
			if (!lo.size(items)) return
			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label mb-1'>{this.$t('ticket_type')}</div>
					<Dropdown
						mode='input'
						extra_cls='dropdown_input__small dropdown_input__light'
						disabled={this.isEdit}
						items={items}
						selected={this.ticketData.type_id}
						vOn:select={(item) => this.onChangeType(item.id)}
					></Dropdown>
				</div>
			)
		},

		renderUsers() {
			let users = lo.get(this.ticketData, 'users') || []
			let $content = (
				<div>
					{lo.map(users, (user, idx) => {
						return (
							<div class={{'d-flex': true, 'align-items-center': true, 'mb-2': true}}>
								<div
									class='flex__1 d-flex align-items-center mr-4 clickable text__sm clickable'
									style='overflow: hidden'
									vOn:click={() => this.$root.$emit('showQuickview', {user_id: user.id})}
								>
									<Avatar user={user} size='24' />
									<div class='text__truncate ml-3 text__semibold'>
										<UserDisplayName user={user} />
									</div>
								</div>
								<Icon
									name='circle-minus'
									size='18'
									class='ml-3 x-icon'
									vOn:click={() => this.removeTicketUser(user.id)}
								/>
							</div>
						)
					})}
				</div>
			)
			return (
				<div class='edit_ticket_modal_form_sidebar_field'>
					<div class='edit_ticket_modal_form_sidebar_field_label mb-1'>{this.$t('customer')}</div>
					{$content}
					<div>
						<span class='text__sm link link__secondary' vOn:click={() => (this.isAddTicketCustomerModalOpen = true)}>
							+ {this.$t('add')}
						</span>
					</div>
				</div>
			)
		},

		removeTicketUser(uid) {
			let users = lo.get(this.ticketData, 'users') || []
			users = lo.filter(users, (user) => user.id !== uid)
			this.onChange({users})
		},
	},

	render() {
		let style = 'width: 750px; position: relative; height: 540px;'
		let selectedType = store.matchTicketType()[this.ticketData.type_id] || {}
		let templates = this.getTicketTemplates()
		let tmplItems = lo.map(templates, (tmpl) => ({
			id: tmpl.id,
			label: tmpl.title,
		}))
		let selectedTmpl = store.matchTicketTemplate()[this.selectedTemplateId] || {id: this.selectedTemplateId}

		return (
			<Modal show={this.open} zindex={200} vOn:bg={this.closeModal} style='overflow: unset'>
				<div class='modal__background'>
					<div class='edit_ticket_modal_header'>
						<div class='d-flex align-items-center'>
							<div>
								<span class='text__semibold text__lg'>
									{this.isEdit ? this.$t('edit_ticket') : this.$t('create_ticket')}
								</span>
								{lo.size(tmplItems) > 0 && (
									<Dropdown
										mode='link'
										placeholder={this.$t('select_template')}
										disabled={this.isEdit}
										style='display: inline-block'
										class='link link__secondary text__sm ml-4'
										items={tmplItems}
										dropdown_width={200}
										vOn:select={(item) => this.onChangeTemplateId(item.id)}
									></Dropdown>
								)}
							</div>
							<Icon name='x' size='18' class='btn__close ml-auto' vOn:click={this.closeModal} />
						</div>
					</div>
					<div class='modal_content' style={style}>
						{this.loading ? (
							<div style='position: absolute; inset: 0' class='d-flex align-items-center justify-content-center'>
								<Spinner mode='blue' size='32' />
							</div>
						) : (
							<div class='edit_ticket_modal_form'>
								<input type='file' ref='file_input' multiple style='display: none;' />
								<div class='edit_ticket_modal_form_main'>
									{this.renderTitle()}
									{this.renderNote()}
								</div>
								{this.renderSidebar()}
							</div>
						)}
					</div>
					<div class='edit_ticket_modal_footer'>
						<div class='d-flex align-items-center'>
							<div class='flex__1 mr-4'>
								<input
									type='file'
									ref='file_input'
									multiple
									style='display: none;'
									vOn:click={this.clearFileInput}
									vOn:change={this.addLocalFiles}
								/>
								<div
									class='link text__sm link__secondary d-inline-flex align-items-center'
									vOn:click={() => this.$refs.file_input.click()}
								>
									<Icon name='paperclip' size='16' class='mr-2' />
									{this.$t('add_attachments')}
								</div>
							</div>
							{!this.isEdit && (
								<button
									type='button'
									disabled={!this.ticketData || this.loading || this.saving}
									class='btn btn__outline_primary mr-3 no-shrink align-items-center justify-content-center'
									style='width: 150px; display: inline-flex'
									vOn:click={() => {
										this.isContinueCreate = true
										this.submit()
									}}
								>
									{this.$t('save_and_continue')}
									{this.saving && this.isContinueCreate && <Icon name='loader-2' class='ml-2 rotate' size='16' />}
								</button>
							)}
							<button
								type='button'
								disabled={!this.ticketData || this.loading || this.saving}
								class='btn btn__primary no-shrink align-items-center justify-content-center'
								style='width: 140px; display: inline-flex'
								vOn:click={() => {
									this.isContinueCreate = false
									this.submit()
								}}
							>
								{this.$t('save')}
								{this.saving && !this.isContinueCreate && <Icon name='loader-2' class='rotate ml-2' size='16' />}
							</button>
						</div>
					</div>
				</div>
				<AddTicketUserModal
					open={this.isAddTicketCustomerModalOpen}
					current={lo.get(this.ticketData, 'users') || []}
					vOn:close={() => (this.isAddTicketCustomerModalOpen = false)}
					vOn:success={(users) => this.onChange({users})}
				/>
			</Modal>
		)
	},
}
