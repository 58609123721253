const sb = require('@sb/util')
import store from '@sb/store'
import Tag from './tag.js'
import AppendToBody from './append_to_body.js'

if (!Element.prototype.scrollIntoViewIfNeeded) {
	Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
		centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded

		var parent = this.parentNode
		var parentComputedStyle = window.getComputedStyle(parent, null)
		var parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'))
		var parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'))
		var overTop = this.offsetTop - parent.offsetTop < parent.scrollTop
		var overBottom =
			this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth >
			parent.scrollTop + parent.clientHeight
		var overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft
		var overRight =
			this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth >
			parent.scrollLeft + parent.clientWidth
		var alignWithTop = overTop && !overBottom

		if ((overTop || overBottom) && centerIfNeeded) {
			parent.scrollTop =
				this.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + this.clientHeight / 2
		}

		if ((overLeft || overRight) && centerIfNeeded) {
			parent.scrollLeft =
				this.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + this.clientWidth / 2
		}

		if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
			this.scrollIntoView(alignWithTop)
		}
	}
}

export default {
	name: 'suggestion',
	props: ['query'],
	data() {
		return {
			currentSelectedIndex: 0,
			isShowing: false,
			forceShow: false, // when agent click on message template icon
			filteredActions: [],
			start: '/',

			// trigger position to calculate message template fixed position
			customStyle: {},
		}
	},

	async mounted() {
		// invite agent
		// tag
		// end convo
		this.updateFuse()
		this.onQueryChange(this.query)
		store.onAccount(this, () => {
			this.updateFuse()
			this.$forceUpdate()
		})

		document.body.addEventListener('keyup', this.onKeyup)
		document.body.addEventListener('keydown', this.onKeydown)
	},

	destroyed() {
		document.body.removeEventListener('keyup', this.onKeyup)
		document.body.removeEventListener('keydown', this.onKeydown)
	},

	watch: {
		query(query) {
			this.onQueryChange(query)
		},

		isShowing() {
			this.calculateStyle()
		},
	},

	methods: {
		updateFuse() {
			let actions = []
			let agents = store.matchAgent()
			lo.map(agents, (ag) => {
				if (ag.state !== 'active') return
				if (ag.type !== 'agent') return
				actions.push({text: 'Invite agent ' + sb.getAgentDisplayName(ag), action: 'invite', agent_id: ag.id})
			})
			let tags = store.matchTag()
			tags = lo.filter(tags, (tag) => !tag.type)
			lo.map(tags, (tag) => {
				actions.push({text: 'Tag ' + tag.title, action: 'tag', tag_id: tag.id})
			})
			actions.push({text: 'End conversation', action: 'done', confirm: true})
			this.allActions = actions
		},
		onQueryChange(query) {
			query = lo.trim(query)
			if (query.length < this.start.length) this.start = '@'

			if (!query.startsWith(this.start)) {
				this.isShowing = false
				this.filteredActions = []
				return
			}

			// clean this.start if user has deleted it
			if (this.forceShow) this.isShowing = true
			this.forceShow = false

			query = query.substr(this.start.length, query.length - this.start.length)

			// smart trigger
			if (query === '') this.isShowing = true
			if (!this.isShowing) return
			this.filteredActions = lo.filter(this.allActions, (action) => {
				let text = sb.unicodeToAscii(action.text || '').toLowerCase()
				let helpText = sb.unicodeToAscii(action.help_text || '').toLowerCase()
				let search = sb.unicodeToAscii(query).toLowerCase()
				return text.indexOf(search) > -1 || helpText.indexOf(search) > -1
			})

			this.currentSelectedIndex = 0
		},

		IsShow() {
			return this.isShowing && lo.size(this.filteredActions) > 0
		},

		calculateStyle() {
			if (!this.IsShow()) return
			const MAX_HEIGHT = 500
			const VERTICAL_EDGE = 100
			const MIN_HEIGHT = 200

			let customStyle = {}
			let rect = this.$refs.trigger ? this.$refs.trigger.getBoundingClientRect() : {}
			let {top = 0, left = 0, width = 0} = rect
			customStyle.bottom = window.innerHeight - top + 'px'
			customStyle.left = left + 'px'
			customStyle.width = width + 'px'
			customStyle.zIndex = 99999

			let isOverHeight = top < MAX_HEIGHT
			if (isOverHeight) {
				let maxHeight = top - VERTICAL_EDGE
				if (maxHeight < MIN_HEIGHT) {
					customStyle.bottom = window.innerHeight - top - (MIN_HEIGHT - maxHeight) + 'px'
					maxHeight = MIN_HEIGHT
				}
				customStyle.maxHeight = maxHeight + 'px'
			}
			this.customStyle = customStyle
		},

		Show(show, start) {
			if (!show) return this.hideWindow()
			start = lo.trim(start)
			if (!start) start = '@'
			this.start = start
			this.onQueryChange(start)

			this.forceShow = true
		},

		onKeydown(e) {
			if (!this.isShowing) return
			switch (e.keyCode) {
				case 38: // UP
					this.currentSelectedIndex--
					if (this.currentSelectedIndex < 0) this.currentSelectedIndex = lo.size(this.filteredActions) - 1
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
				case 40: // DOWN
					this.currentSelectedIndex++
					if (this.currentSelectedIndex >= lo.size(this.filteredActions)) this.currentSelectedIndex = 0
					this.$refs[`item${this.currentSelectedIndex}`].scrollIntoViewIfNeeded()
					e.preventDefault()
					break
			}
		},

		onKeyup(e) {
			if (!this.isShowing) return
			switch (e.keyCode) {
				case 27: // ESC
					this.hideWindow()
					break
				case 13: // ENTER
					if (!this.isShowing) return
					var action = this.filteredActions[this.currentSelectedIndex]
					this.chooseAction(action)
			}
		},

		hideWindow() {
			if (!this.isShowing) return
			this.start = '/'
			this.isShowing = false
		},

		chooseAction(action) {
			this.$emit('choose', action)
			this.hideWindow()
		},
	},

	render() {
		let $help = (
			<div class='suggestion__item__help'>
				<Icon name='corner-down-left' size='16' /> to apply
			</div>
		)
		let $actions = lo.map(this.filteredActions, (action, i) => {
			let active = i === this.currentSelectedIndex
			let cls = 'suggestion__item'
			if (active) cls += ' suggestion__item__active'

			let $content = null
			if (action.action === 'invite') {
				let agent = store.matchAgent()[action.agent_id]
				$content = (
					<div class='suggestion__item__text'>
						<Icon name="user-plus" style='margin-top:-5px' class='mr-4' size='18' /> Invite
						<Avatar online agent={agent} class='ml-2' size='sm' />
						<span class='ml-2'>{sb.getAgentDisplayName(agent)}</span>
					</div>
				)
			}
			if (action.action === 'tag') {
				let tag = store.matchTag()[action.tag_id]
				$content = (
					<div class='suggestion__item__text'>
						<Icon name="tag" size='18' class='mr-4' /> <div class='mr-2'>Add tag </div>
						<Tag tag={tag} />
					</div>
				)
			}

			if (action.action === 'done') {
				$content = (
					<div class='suggestion__item__text'>
						<Icon name='circle-check' size='18' class='mr-4' style='margin-top:-5px' /> Complete conversation
					</div>
				)
			}

			return (
				<div class={cls} ref={`item${i}`} vOn:click={(_) => this.chooseAction(action)}>
					{$content} {$help}
				</div>
			)
		})

		let cls = 'suggestion'

		if (!this.isShowing || this.filteredActions.length === 0) cls += ' d-none'
		return (
			<div style='position: absolute; inset: 0; width: 100%;height: 100%; z-index: -9999'>
				<div
					ref='trigger'
					style='position: absolute; inset: 0; width: 100%; height: 100%; opacity: 0; z-index: -9999'
				/>
				<AppendToBody>
					<div class={cls} v-dblclickaway={this.hideWindow} v-clickaway={this.hideWindow} style={this.customStyle}>
						<div>{$actions}</div>
					</div>
				</AppendToBody>
			</div>
		)
	},
}
