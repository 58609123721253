import {format, formatDistanceToNow, startOfDay} from 'date-fns'
import vi from 'date-fns/locale/vi'

const Day = {
	name: 'day',
	props: ['currentDate', 'date', 'selected'],

	render() {
		let className = ['calendar--day']

		if (sameDay(Date.now(), this.date)) className.push('active')
		if (this.selected) className.push('selected')
		// if (!sameMonth(this.date, this.currentDate)) className.push('muted')
		// if (isBeforeDay(this.date, new Date())) className.push('muted') // only if we want hide a day in the pass

		return (
			<span vOn:click={() => this.$emit('click', this.date)} class={className.join(' ')}>
				{new Date(this.date).getDate()}
			</span>
		)
	},
}

var Days = {
	name: 'days',
	props: ['date', 'current'],

	render() {
		let thisDate = this.date

		const daysInMonth = numDaysInMonth(this.date.getFullYear(), this.date.getMonth() + 1)

		const firstDayDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
		let previousMonth = new Date(this.date)
		previousMonth.setDate(2)
		previousMonth.setMonth(this.date.getMonth() - 1)

		const previousMonthDays = numDaysInMonth(previousMonth.getFullYear(), previousMonth.getMonth() + 1)
		let nextsMonth = new Date(this.date)
		nextsMonth.setDate(2)
		nextsMonth.setMonth(this.date.getMonth() + 1)
		let days = []
		let labels = []

		labels.push(
			<span class='text__muted' style={{fontSize: '13px', cursor: 'default'}} key='t2'>
				T2
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px', cursor: 'default'}} key='t3'>
				T3
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px', cursor: 'default'}} key='t4'>
				T4
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px', cursor: 'default'}} key='t5'>
				T5
			</span>,
		)
		labels.push(
			<span class='text__muted' style={{fontSize: '13px', cursor: 'default'}} key='t6'>
				T6
			</span>,
		)
		labels.push(
			<span class='text__danger' style={{fontSize: '13px', cursor: 'default'}} key='t7'>
				T7
			</span>,
		)
		labels.push(
			<span class='text__danger' style={{fontSize: '13px', cursor: 'default'}} key='cn'>
				CN
			</span>,
		)

		let firstday = firstDayDate.getDay()
		if (firstday === 0) firstday = 7
		for (let i = firstday; i > 1; i--) {
			previousMonth = new Date(previousMonth)
			previousMonth.setDate(previousMonthDays - i + 2)
			days.push(
				<Day
					selected={sameDay(previousMonth, this.current)}
					current={this.current}
					key={format(previousMonth, 'dd MM yyyy', {locale: vi})}
					vOn:click={(date) => this.$emit('click', date)}
					currentDate={this.date}
					date={previousMonth}
				/>,
			)
		}

		for (let i = 1; i <= daysInMonth; i++) {
			thisDate = new Date(thisDate)
			thisDate.setDate(i)

			days.push(
				<Day
					selected={sameDay(thisDate, this.current)}
					key={format(thisDate, 'dd MM yyyy', {locale: vi})}
					vOn:click={(date) => this.$emit('click', date)}
					currentDate={this.date}
					date={thisDate}
				/>,
			)
		}

		const daysCount = days.length
		for (let i = 1; i <= 42 - daysCount; i++) {
			nextsMonth = new Date(nextsMonth)
			nextsMonth.setDate(i)
			days.push(
				<Day
					selected={sameDay(nextsMonth, this.current)}
					key={format(nextsMonth, 'dd MM yyyy', {locale: vi})}
					vOn:click={(date) => this.$emit('click', date)}
					currentDate={this.date}
					date={nextsMonth}
				/>,
			)
		}

		return (
			<nav class='calendar--days'>
				{labels.concat()}
				{days.concat()}
			</nav>
		)
	},
}

export default {
	name: 'date-picker',
	props: ['style', 'shortcuts', 'current', 'has_time', 'has_back_btn'],
	data() {
		return {
			date: startOfDay(new Date()),

			// use for date time picker
			dateSelect: new Date(),
			hours: 0, // 0 -> 23
			minutes: 0, // 0 -> 59
		}
	},

	watch: {
		current() {},
	},

	mounted() {
		if (this.has_time) {
			try {
				this.hours = this.current.getHours()
				this.minutes = this.current.getMinutes()
				this.dateSelect = this.current
			} catch (err) {}
		}
	},

	methods: {
		resetDate() {
			this.date = startOfDay(new Date())
		},

		changeMonth(month) {
			this.date.setMonth(month)
			this.$forceUpdate()
		},

		changeYear(year) {
			this.date.setFullYear(year)
			this.$forceUpdate()
		},

		onChangeDate(date) {
			if (!this.has_time) {
				return this.$emit('change', date)
			}

			this.dateSelect = date
		},

		submitDatetime() {
			let time = startOfDay(this.dateSelect).getTime() + this.hours * 3600 * 1000 + this.minutes * 60 * 1000
			this.$emit('change', time)
		},

		renderTimeSelects() {
			if (!this.has_time) return null

			let hours = []
			let minutes = []
			lo.times(60, (i) => {
				let text = i < 10 ? `0${i}` : `${i}`
				if (i <= 23) hours.push({value: i, text})
				minutes.push({value: i, text})
			})

			return (
				<div class='calendar--times'>
					<div class='d-flex align-items-center mt-3'>
						<div style='width: 85px;' class='text__muted'>
							{this.$t('rule_time')}
						</div>
						<div class='d-flex align-items-center flex__1'>
							<select class='form-control' value={this.hours} vOn:change={(e) => (this.hours = +e.target.value)}>
								{hours.map((obj) => (
									<option value={obj.value}>{obj.text}</option>
								))}
							</select>
							<div class='ml-2 mr-2'>{':'}</div>
							<select class='form-control' value={this.minutes} vOn:change={(e) => (this.minutes = +e.target.value)}>
								{minutes.map((obj) => (
									<option value={obj.value}>{obj.text}</option>
								))}
							</select>
						</div>
					</div>
					<div class='mt-4 d-flex align-items-center'>
						<div style='flex: 1'></div>
						{this.has_back_btn ? (
							<div class='btn btn__sm btn__white mr-3' vOn:click={() => this.$emit('back')}>
								{this.$t('back')}
							</div>
						) : (
							<div class='btn btn__sm btn__white mr-3' vOn:click={() => this.$emit('change', 0)}>
								{this.$t('clear')}
							</div>
						)}
						<div class='btn btn__primary btn__sm' vOn:click={this.submitDatetime}>
							{this.$t('confirm')}
						</div>
					</div>
				</div>
			)
		},

		renderCalendar() {
			let current = this.current
			if (this.has_time) current = this.dateSelect
			return (
				<div class='calendar' style={this.style}>
					<nav class='calendar--nav'>
						<a style='width: fit-content;' vOn:click={() => this.changeYear(this.date.getFullYear() - 1)}>
							<Icon name='chevrons-left' stroke-width='1' stroke='black' />
						</a>
						<a style='width: fit-content;' vOn:click={() => this.changeMonth(this.date.getMonth() - 1)}>
							<Icon name='chevron-left' stroke-width='1' stroke='black' size='22' />
						</a>
						<div class='d-flex justify-content-center' style='flex: 1;' vOn:click={() => this.resetDate()}>
							<div class='form-label mb-0'>
								{format(this.date, 'MMMM', {locale: vi})} {format(this.date, 'yyyy', {locale: vi})}
							</div>
						</div>
						<a style='width: fit-content;' vOn:click={() => this.changeMonth(this.date.getMonth() + 1)}>
							<Icon name='chevron-right' stroke-width='1' stroke='black' size='22' />
						</a>
						<a style='width: fit-content;' vOn:click={() => this.changeYear(this.date.getFullYear() + 1)}>
							<Icon name='chevrons-right' stroke-width='1' stroke='black' />
						</a>
					</nav>
					<Days vOn:click={this.onChangeDate} date={new Date(this.date)} current={current} />
					{this.renderTimeSelects()}
				</div>
			)
		},

		renderShortcuts() {
			if (lo.size(this.shortcuts) <= 0) return null
			let $shortcuts = lo.map(this.shortcuts, (shortcut) => {
				return (
					<div
						class='calendar_shortcut__item'
						vOn:click={(_) => this.$emit('change', shortcut.onClick(), shortcut.text)}
					>
						{shortcut.text}
					</div>
				)
			})
			return <div class='calendar_shortcut'>{$shortcuts}</div>
		},
	},

	render() {
		return (
			<div style='position: relative; width: 280px;'>
				<div class='calendar_container'>
					{this.renderShortcuts()}
					{this.renderCalendar()}
				</div>
			</div>
		)
	},
}

function sameDay(d1, d2) {
	d1 = new Date(d1)
	d2 = new Date(d2)
	return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
}

function isBeforeDay(d1, d2) {
	d1 = new Date(d1)
	d2 = new Date(d2)
	if (sameDay(d1, d2)) return false
	return d1.getTime() < d2.getTime()
}

function sameMonth(d1, d2) {
	d1 = new Date(d1)
	d2 = new Date(d2)
	return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
}

function numDaysInMonth(year, month) {
	return new Date(year, month, 0).getDate()
}
