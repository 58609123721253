import {mergeRegister} from '@lexical/utils'
import {TextNode} from 'lexical'

import {$createEmojiNode, EmojiNode} from './lexical-emoji-node.js'
const emojisMap = {
	':D': ['grinning', '😀'],
	':d': ['grinning', '😀'],
	':(': ['pensive', '😔'],
	';)': ['wink', '😉'],
	'<3': ['heart_eyes', '😍'],
	':|': ['neutral', '😐'],
	':)': ['smile', '😄'],
	':P': ['tongue_out', '😛'],
	':o': ['surprise', '😮'],
	':/': ['confused', '😕'],
}

function findAndTransformEmoji(node) {
	const text = node.getTextContent()

	for (let i = 0; i < text.length; i++) {
		const emojiObj = emojisMap[text[i]] || emojisMap[text.slice(i, i + 2)]
		if (!emojiObj) continue
		let [emojiClass, emojiText] = emojiObj
		let targetNode

		if (i === 0) {
			;[targetNode] = node.splitText(i + 2)
		} else {
      if (text[i - 1] !== ' ') continue
			;[, targetNode] = node.splitText(i, i + 2)
		}
		const emojiNode = $createEmojiNode(emojiClass + ' lexical-emoji', emojiText)
		targetNode.replace(emojiNode)
		return emojiNode
	}

	return null
}

function textNodeTransform(node) {
	let targetNode = node

	while (targetNode !== null) {
		if (!targetNode.isSimpleText()) {
			return
		}

		targetNode = findAndTransformEmoji(targetNode)
	}
}

export function registerEmojiTransformPlugin(editor) {
	if (!editor.hasNodes([EmojiNode])) {
		throw new Error('EmojiTransformPlugin: EmojiNode not registered on editor')
	}

	return mergeRegister(editor.registerNodeTransform(TextNode, textNodeTransform))
}
