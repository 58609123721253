import sb from '@sb/util'
import store from '@sb/store'
import {getAttributeName, UNUSED_USER_ATTRIBUTES} from '../common.js'

export default {
	name: 'update-contact-attribute',

	data() {
		return {
			uid: '',
			attr: {},

			// used for create_user_request
			creatingUser: {},

			isMergeModalOpened: false,
			mergeUser: {},
			merging: false,
			duplicatedValue: '',
		}
	},

	created() {
		this.$root.$on('_update_contact_request', this.updateContactInfo)
		this.$root.$on('_create_user_request', this.createUser)
	},

	destroyed() {
		this.$root.$off('_update_contact_request', this.updateContactInfo)
		this.$root.$off('_create_user_request', this.createUser)
	},

	methods: {
		async createUser(user) {
			this.uid = 'new'
			this.creatingUser = user

			if (this.saving) return
			this.saving = true
			let res = await store.checkExistedUser(user)
			console.log('createUser 0000000000000', user, res)
			if (res.error) {
				this.saving = false
				this.$root.$emit('_create_user_response', {error: res.error})
				return
			}
			return
			let code = lo.get(res, 'body.code')
			if (code === 'duplicated') {
				this.isMergeModalOpened = true
				console.log('createUser 1111111111111', res)
				this.duplicatedValue = lo.get(res, 'body.duplicated_values.0', '')
				this.mergeUser = lo.get(res, 'body.users.0', {})
				this.attr = {
					key: 'emails',
				}
				this.saving = false
				return
			}

			res = await store.createUser(user)
			this.saving = false
			if (res.error) {
				this.$root.$emit('_update_contact_response', {error: res.error})
				return
			}

			this.$root.$emit('_update_contact_response', res)
		},

		async updateContactInfo({uid, attr}) {
			this.uid = uid
			this.attr = attr

			if (this.saving) return
			this.saving = true
			let res = await store.checkExistedUser({id: this.uid, attributes: [attr]})
			if (res.error) {
				this.saving = false
				this.$root.$emit('_update_contact_response', false)
				return this.$showError(res.error)
			}
			let code = lo.get(res, 'body.code')
			if (code === 'duplicated') {
				this.isMergeModalOpened = true
				this.duplicatedValue = lo.get(res, 'body.duplicated_values.0', '')
				this.mergeUser = lo.get(res, 'body.users.0', {})
				this.saving = false
				return
			}

			res = await store.updateUser({id: this.uid, attributes: [attr]})
			this.saving = false
			if (res.error) {
				this.$root.$emit('_update_contact_response', false)
				return this.$showError(res.error)
			}

			this.$root.$emit('_update_contact_response', true)
		},

		skipMerge() {
			this.isMergeModalOpened = false
			this.mergeUser = {}
			this.$root.$emit('_update_contact_response', false)
		},

		async submitMerge() {
			if (this.merging) return
			this.merging = true
			let res = await store.mergeUser([
				{
					id: lo.get(this.mergeUser, 'id', ''),
				},
				{
					id: this.uid,
				},
			])
			this.merging = false
			this.isMergeModalOpened = false
			if (res.error) {
				this.$root.$emit('_update_contact_response', false)
			} else {
				this.$root.$emit('_update_contact_response', true)
			}
		},

		renderMergeModal() {
			let attrname = this.getAttrName()
			let username = sb.getUserDisplayName(this.mergeUser)

			let currentUser = store.matchUser(this.uid, true)
			let currentUserName = sb.getUserDisplayName(currentUser)

			return (
				<Modal show={this.isMergeModalOpened} zindex={999}>
					<div class='modal__background' style='overflow: hidden;'>
						<div class='modal_content' style='padding: 12px 20px; width: 480px'>
							<div class='d-flex align-items-center'>
								<div class='text__lg text__semibold'>{this.$t('primary_attribute_dynamic_was_used', [attrname])}</div>
							</div>
							<div
								class='mt-4 mb-2'
								domPropsInnerHTML={this.$t('primary_attribute_dynamic_merge_noti', [
									attrname || this.$t('value'),
									this.duplicatedValue,
									username,
									currentUserName,
								])}
							></div>
							<div class='card d-flex align-items-center' style='flex-direction: row; padding: 12px 20px'>
								<Avatar user={this.mergeUser} size='40' />
								<div class='ml-3'>
									<div class='text__semibold'>{username}</div>
									<div class='text__muted'>
										{attrname}: {sb.getUserTextAttr(this.mergeUser, this.attr.key)}
									</div>
								</div>
							</div>
							<div class='mt-4 d-flex align-items-center justify-content-end'>
								<div class='link link__secondary mr-4' vOn:click={this.skipMerge}>
									{this.$t('back')}
								</div>
								<button
									type='button'
									class='btn btn__sm btn__primary justify-content-center'
									style='width: 100px; display: inline-block;'
									disabled={this.merging}
									vOn:click={this.submitMerge}
								>
									{this.$t('i_understood')}
									{this.merging && <Spinner class='ml-2' size='16' />}
								</button>
							</div>
						</div>
					</div>
				</Modal>
			)
		},

		getAttrName() {
			let attrdef = lo.find(store.matchUserAttribute(), (a) => {
				if (!a) return null
				if (!a.key) return null
				if (!this.attr.key) return null
				return a.key.toLowerCase() === this.attr.key.toLowerCase()
			}) || {key: ''}
			let attrname = getAttributeName(attrdef) || attrdef.label
			attrname = this.$t(attrname)
			if (this.attr.key === 'emails') attrname = this.$t('Email')
			if (this.attr.key === 'phones') attrname = this.$t('phn')

			return attrname
		},
	},

	render() {
		return <div>{this.renderMergeModal()}</div>
	},
}
