import {OnelineMsg, AvatarGroup} from '@sb/com'
var sb = require('@sb/util')

import store from '@sb/store'

let DEFTICKETAVATAR = require('./assets/img/ticket.svg')
export default {
	name: 'search',
	data() {
		return {
			searching: false,
			show: false,
			keyword: '',

			clicked: null,
			suggestions: [],

			tab: 'all', // all, convo, user, order
			results: [], // [users] or [convos]
			convo_results: [],
			user_results: [],
			ticket_results: [],

			notfound: false,

			user_anchor: '',
			convo_anchor: '',
			ticket_anchor: '',

			user_count: 0,
			convo_count: 0,
			ticket_count: 0,

			out_of_user: false,
			out_of_convo: false,
			out_of_ticket: false,

			error: false,

			lastScrollTop: 0,
		}
	},

	mounted() {
		store.onUser(this, () => this.$forceUpdate())

		document.addEventListener('keydown', this.onKeydown)
		this.$once('hook:beforeDestroy', () => document.removeEventListener('keydown', this.onKeydown))
	},

	methods: {
		async doShow() {
			if(this.show) {
				this.show = false
				return
			}
			
			this.show = true
			await this.$nextTick()
			setTimeout(() => this.$refs.input && this.$refs.input.focus(), 100)
			return
		},
		clear() {
			if (this.keyword) {
				this.keyword = ''
				this.$refs.input && this.$refs.input.focus()
				return
			}

			this.show = false
		},

		onKeydown(e) {
			if (e.keyCode == 27) {
				this.show = false
				return
			}
		},

		recordSearch: lo.debounce(function () {
			let normquery = sb.unicodeToAscii(lo.trim(this.keyword)).toLowerCase()
			store.recordSearch(normquery)
		}, 2000),

		onScroll: lo.throttle(
			function (e) {
				let list = e.target
				var st = list.scrollTop
				if (st > this.lastScrollTop) {
					// downscroll code
					let distToBottom = list.scrollHeight - list.scrollTop - list.clientHeight
					if (distToBottom < 110) {
						if (!this.out_of_user || !this.out_of_convo) this.doSearch()
					}
				} else {
					// upscroll code
				}
				this.lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
			},
			200,
			{trailing: true},
		),

		doSearch: lo.throttle(
			async function (reset) {
				if (reset) {
					this.out_of_user = false
					this.out_of_convo = false
					this.convo_anchor = ''
					this.user_anchor = ''
					this.ticket_anchor = ''
				}

				if (
					this.user_anchor &&
					this.out_of_user &&
					this.convo_anchor &&
					this.out_of_convo &&
					this.ticket_anchor &&
					this.out_of_ticket
				)
					return

				this.searching = true
				let _searchIndex = this._searchIndex || 0
				_searchIndex++
				this._searchIndex = _searchIndex

				let keyword = this.keyword
				let out = await Promise.all([
					store.searchUser({query: keyword, anchor: this.user_anchor, limit: 12}),
					store.search('convo', keyword, this.convo_anchor),
					store.listTickets({query: keyword, next_cursor: this.ticket_anchor}, true),
				])
				if (this._searchIndex !== _searchIndex) return // outdated

				let userbody = out[0].body || {}
				let convobody = out[1].body || {}
				let ticketbody = out[2].body || {}
				if (!this.user_anchor) {
					this.user_count = 0
					this.user_results = []
					this.user_count = 0
				}

				if (!this.convo_anchor) {
					this.convo_count = 0
					this.convo_results = []
					this.convo_count = 0
				}

				if (!this.ticket_anchor) {
					this.ticket_count = 0
					this.ticket_results = []
					this.ticket_count = 0
				}

				if (!this.convo_anchor && !this.user_anchor && !this.ticket_anchor) {
					this.results = []
				}
				this.user_count += lo.size(userbody.hits)
				this.convo_count += lo.size(convobody.hits)
				this.ticket_count += lo.size(ticketbody.tickets)
				if (this.user_anchor == userbody.anchor || !userbody.anchor) this.out_of_user = true
				if (this.convo_anchor == convobody.anchor || !convobody.anchor) this.out_of_convo = true
				if (this.ticket_anchor == ticketbody.anchor || !ticketbody.anchor) this.out_of_ticket = true
				this.user_anchor = userbody.anchor ? userbody.anchor : ''
				this.convo_anchor = convobody.anchor ? convobody.anchor : ''
				this.ticket_anchor = ticketbody.anchor ? ticketbody.anchor : ''

				let user_results = this.user_results.concat(lo.map(userbody.hits, (hit) => Object.assign(hit, {type: 'user'})))
				let convo_results = this.convo_results.concat(
					lo.map(convobody.hits, (hit) => Object.assign(hit, {type: 'convo'})),
				)
				let ticket_results = this.ticket_results.concat(
					lo.map(ticketbody.tickets, (ticket) => Object.assign(ticket, {type: 'ticket'})),
				)

				let results = this.results
					.concat(lo.map(ticketbody.tickets, (ticket) => Object.assign(ticket, {type: 'ticket'})))
					.concat(lo.map(userbody.hits, (hit) => Object.assign(hit, {type: 'user'})))
					.concat(lo.map(convobody.hits, (hit) => Object.assign(hit, {type: 'convo'})))

				let arrUserId = results.map((res) => (res.type === 'user' ? res.document_id : res.user_id))
				await store.fetchUsers(arrUserId)
				if (this._searchIndex !== _searchIndex) return // outdated

				this.user_results = user_results
				this.convo_results = convo_results
				this.ticket_results = ticket_results

				this.results = results
				this.searching = false
			},
			100,
			{trailing: true},
		),

		async onResClick(res) {
			this.clicked = res
			if (res.type === 'user') this.$root.$emit('showQuickview', {user_id: res.document_id})
			if (res.type === 'convo') {
				if (!res.user_id) {
					await store.fetchConvos([res.document_id])
					let convo = store.matchConvo(res.document_id) || {}
					let user = lo.find(convo.members, (mem) => mem.type == 'user') || {}
					res.user_id = user.id
				}
				this.$root.$emit('showQuickview', {convo_id: res.document_id, user_id: res.user_id})
			}
		},

		renderResults() {
			let results = this.results
			if (this.tab == 'user') results = this.user_results
			if (this.tab == 'convo') results = this.convo_results

			if (lo.size(this.keyword) == 0 && lo.size(results) === 0 && !this.searching) {
				return <div>{this.renderSuggestions()}</div>
			}

			if (lo.size(results) == 0 && !this.searching) {
				return (
					<div>
						<div class='text__center text__muted mr-3 ml-3 mt-2 mb-2'>{this.$t('cant_find_anyone')} ¯\_(ツ)_/¯</div>
						{this.renderSuggestions()}
					</div>
				)
			}

			let normquery = sb.unicodeToAscii(lo.trim(this.keyword)).toLowerCase()

			return lo.map(results, (res) => {
				res = Object.assign({}, res)
				let $subimg = null
				let avatar_url = res.avatar_url
				let user = null

				let $info = null

				let user_id = res.type == 'user' ? res.document_id : res.user_id
				if (res.type == 'ticket') {
					let ev = {
						type: 'message_sent',
						by: {type: res.reporter_type, id: res.reporter},
						data: {message: res.description},
					}
					$info = <OnelineMsg class='text-truncate' ev={ev} />
					res.description = ''
					res.document_id = res.id
					res.updated = lo.get(res, 'last_event.created', res.updated || res.created)
				}
				if (res.type == 'user') {
					//user = store.matchUser(res.document_id, true)
					//if (user && user.primary_id) user = store.matchUser(user.primary_id)
					user = {
						id: res.document_id,
						attributes: [
							{
								key: 'fullname',
								type: 'text',
								text: res.name,
							},
							{
								key: 'phones',
								type: 'text',
								text: res.phone,
							},
							{
								key: 'emails',
								type: 'text',
								text: res.email,
							},
							{
								key: 'avatar_url',
								type: 'text',
								text: avatar_url,
							},
						],
						type: res.type,
					}

					if ((res.name || '').indexOf(normquery) >= 0) res.description = res.name
					if ((res.phone || '').indexOf(normquery) >= 0) res.description = res.phone
					if ((res.email || '').indexOf(normquery) >= 0) res.description = res.email
				}

				if (res.type == 'convo') {
					user = store.matchUser(user_id)
					let convo = store.matchConvo(res.document_id) || {}
					let channel = lo.get(convo, 'touchpoint.channel')

					$subimg = (
						<span class='search__convo_channel'>
							{(() => {
								if (channel === 'facebook_comment') {
									return <img class='search__convos_channel_img' src={require('./assets/img/facebook_1.svg')} alt='' />
								} else if (channel === 'facebook') {
									return <img class='search__convos_channel_img' src={require('./assets/img/messenger_1.svg')} alt='' />
								}

								if (channel === 'zalo') {
									return <img class='search__convos_channel_img' src={require('./assets/img/zalo_1.svg')} alt='' />
								}
								if (channel === 'instagram' || channel === 'instagram_comment') {
									return <img class='search__convos_channel_img' src={require('./assets/img/instagram.svg')} alt='' />
								}
								if (channel === 'call') {
									return <img class='search__convos_channel_img' src={require('./assets/img/call.svg')} alt='' />
								}
								if (channel === 'form') {
									return <img class='search__convos_channel_img' src={require('./assets/img/form.svg')} alt='' />
								}
								if (channel === 'email') {
									return <img class='search__convos_channel_img' src={require('./assets/img/email_1.svg')} alt='' />
								}

								return <img class='search__convos_channel_img' src={require('./assets/img/subiz_xsm.svg')} alt='' />
							})()}
						</span>
					)
				}

				let $ticketstate = null
				let name = ''
				if (res.type == 'ticket') {
					let {type, text} = getTicketStateInfo(res)
					$ticketstate = (
						<div
							title={text}
							class={'mr-2 square square__' + type}
							style={{width: 12, display: 'inline-block', height: 12, borderRadius: 2}}
						></div>
					)
					name = res.number + ' ' + res.title
					avatar_url = DEFTICKETAVATAR
				} else {
					name = sb.getUserDisplayName(store.matchUser(user_id))
					name = lo.trim(name)
				}

				let size = 40
				let $avatar = (
					<div class='search__convo_avatar'>
						<div class='convos__avatar_avt'>
							<img2
								style={`width: ${size}px; height: ${size}px;border-radius: 50%;object-fit: cover;`}
								src={avatar_url}
							/>
							{$subimg}
						</div>
					</div>
				)

				if (!avatar_url)
					$avatar = (
						<div class='search__convo_avatar'>
							<div class='convos__avatar_avt'>
								<Avatar style='display: block' class='user_info__avatar' user={user} size={size} notooltip nodot />
								{$subimg}
							</div>
						</div>
					)

				let info = (res.description || '').substr(0, 200)
				if (res.part == 'contact.fullname' || !info)
					info = (res.email || res.phone || res.description || '').substr(0, 200)

				let i = 0
				if (!$info) {
					// default info
					$info = []
					i = sb.unicodeToAscii(info).toLowerCase().indexOf(normquery)
					if (i >= 0) {
						$info.push(<span>{info.substr(0, i)}</span>)
						$info.push(<span class='search__highlight'>{info.substr(i, normquery.length)}</span>)
						$info.push(<span>{info.substr(i + normquery.length)}</span>)
					} else {
						$info.push(info)
					}
				}

				let $name = []
				i = sb.unicodeToAscii(name).toLowerCase().indexOf(normquery)
				if (i >= 0) {
					$name.push(<span>{name.substr(0, i)}</span>)
					$name.push(<span class='search__highlight'>{name.substr(i, normquery.length)}</span>)
					$name.push(<span>{name.substr(i + normquery.length)}</span>)
				} else {
					$name.push(name)
				}

				// if (!name) $name = [<span class='text__muted'>{this.$t('unnamed')}</span>]

				if (res.type == 'convo') {
					$info.unshift(
						<Icon name='message-dots' style='margin-top: -4px' class='mr-1 text__muted' size='15' stroke-width='2' />,
					)
				}

				if (res.type == 'user' && (res.part || '').startsWith('note.')) {
					$info.unshift(
						<Icon name='note' style='margin-top: -4px' class='mr-1 text__muted' size='15' stroke-width='2' />,
					)
				}

				let cls = 'search__item'
				if (
					lo.get(this.clicked, 'type') == res.type &&
					lo.get(this.clicked, 'document_id') == res.document_id &&
					lo.get(this.clicked, 'part') == res.part
				) {
					cls += ' search__item__active'
				}

				return (
					<div class={cls} vOn:click={(_) => this.onResClick(res)} key={res.id || res.document_id}>
						{$avatar}
						<div class='ml-3' style='overflow: hidden; flex: 1'>
							<div class='search__user_username'>
								<span class='text__truncate' style='flex:1; margin-right: 10px'>
									{$ticketstate}
									<b>{$name}</b>
								</span>{' '}
								<Time class='convos__time' time={new Date(res.updated)} ago />{' '}
							</div>
							<div class='search__user_info'>{$info}</div>
						</div>
						<div></div>
					</div>
				)
			})
		},

		onChangeFilter(filter) {
			this.tab = filter
			this.$emit('filterChange', filter)
		},

		renderSuggestions() {
			let normquery = sb.unicodeToAscii(lo.trim(this.keyword)).toLowerCase()

			let suggs = lo.take(
				[]
					.concat(this.suggestions)
					.concat(store.matchSearchQuery().filter((v) => !!v))
					.concat(['báo giá', 'iphone 12', 'ha noi', 'ma nhung', 'ho chi minh'])
					.filter((v) => v != normquery),
				5,
			)

			let $suggestions = lo.map(suggs, (sug) => {
				if (!sug) return null

				let splitindex = 0
				for (splitindex = 0; splitindex < sug.length; splitindex++) {
					if (sug[splitindex] == normquery[splitindex]) continue
					break
				}

				return (
					<div class='search__item search__item__suggestion' vOn:click={(_) => this.useSuggestion(sug)}>
						<div class='suggestion_icon'>
							<Icon name='search' size='1x' />
						</div>
						<span class='suggestion_prefix'>{sug.substr(0, splitindex)}</span>
						<span class='suggestion_suffix'>{sug.substr(splitindex)}</span>
					</div>
				)
			})
			return <div>{$suggestions}</div>
		},

		renderCount(n) {
			if (n > 10) return <div class='search_count'>10+</div>
			return <div class='search_count'>{n}</div>
		},

		renderTabs() {
			let convocls = 'tab__item tab__item__secondary'
			let usercls = 'tab__item tab__item__secondary'
			let allcls = 'tab__item tab__item__secondary'
			let ordercls = 'tab__item tab__item__secondary'

			if (this.tab === 'convo') convocls += ' tab__item__active'
			if (this.tab === 'user') usercls += ' tab__item__active'
			if (this.tab === 'order') ordercls += ' tab__item__active'
			if (this.tab === 'all') allcls += ' tab__item__active'
			return (
				<div class='tab' style='margin-bottom: 0; padding: 0 10px;'>
					<div class={allcls} vOn:click={(_) => this.onChangeFilter('all')}>
						{this.$t('all')}
						{this.renderCount(this.convo_count + this.user_count)}
					</div>

					<div class={usercls} vOn:click={(_) => this.onChangeFilter('user')}>
						<Icon name='user-circle' viewBox='0 0 28 28' stroke-width='1.5' />
						&nbsp;{this.$t('cust')}
						{this.renderCount(this.user_count)}
					</div>
					<div class={convocls} vOn:click={(_) => this.onChangeFilter('convo')}>
						<Icon name='messages' viewBox='0 0 28 28' stroke-width='1.5' />
						&nbsp;{this.$t('messages')}
						{this.renderCount(this.convo_count)}
					</div>
				</div>
			)
		},

		onChangeKeyword(e) {
			this.keyword = e.target.value
			if (this.keyword) this.show = true
			this.doSearch(true)
		},

		useSuggestion(sug) {
			this.keyword = sug
			this.doSearch(true)
		},
	},

	render() {
		let $loading = null
		if (this.searching) {
			// show loading overlay
			$loading = (
				<div class='search__loading'>
					<Spinner size='40' mode='blue' />
				</div>
			)
		}

		if (this.searching && (this.user_anchor || this.convo_anchor)) {
			// show load more
			$loading = (
				<div class='d-flex align-items-center justify-content-center mt-2 mb-2'>
					<Spinner size='20' mode='blue' />
				</div>
			)
		}

		if (this.tab == 'all') {
			if (lo.size(this.results) > 0 && this.out_of_user && this.out_of_convo) {
				$loading = (
					<div class='text__muted d-flex align-items-center justify-content-center mt-3 mb-2'>
						{this.$t('no_result_searching')} ¯\_(ツ)_/¯
					</div>
				)
			}
		}

		if (this.tab == 'user') {
			if (lo.size(this.user_results) > 0 && this.out_of_user) {
				$loading = (
					<div class='text__muted d-flex align-items-center justify-content-center mt-3 mb-2'>
						{this.$t('no_result_searching')} ¯\_(ツ)_/¯
					</div>
				)
			}
		}

		if (this.tab == 'convo') {
			if (lo.size(this.convo_results) > 0 && this.out_of_convo) {
				$loading = (
					<div class='text__muted d-flex align-items-center justify-content-center mt-3 mb-2'>
						{this.$t('no_result_searching')} ¯\_(ツ)_/¯
					</div>
				)
			}
		}

		let $body = null
		if (this.error) {
			$body = (
				<div>
					<div
						class='mr-3 ml-3 mt-4'
						style='display: flex; flex-direction: column; align-items: center; text-align: center;'
					>
						<img src={require('./assets/img/bad_feedback.svg')} style='width: 40px' />
						<div class='mt-4'>
							Rất xin lỗi, đã có sự cố xảy ra, bạn vui lòng thử lại sau.{' '}
							<a class='link' href='#' vOn:click={(_) => this.applySearch(this.keyword)}>
								{this.$t('retry')}
							</a>
						</div>
					</div>
					{this.renderSuggestions()}
				</div>
			)
		} else $body = this.renderResults()

		let searchCls = 'header_bar_menu_link'
		if (this.show) searchCls += ' active'
		return (
			<div
				class={''}
				v-clickaway={(_) => {
					this.show = false
				}}
				style='position: relative;'
			>
				<div class={searchCls} vOn:click={() => this.doShow()}>
					<Icon name='search' size='20' class='' stroke-width='2' />
				</div>

				<div class={'search_modal ' + (this.show ? 'search_modal__show' : '')}>
					<div
						class={'header_bar_search_input_container ' + (this.show ? 'header_bar_search_input_container__long' : '')}
					>
						<Icon name='search' size='18' class='search_modal_search_icon' stroke-width='2' />
						<input
							class='form-control header_bar_search_input'
							ref='input'
							placeholder={this.$t('search')}
							value={this.keyword}
							vOn:input={this.onChangeKeyword}
						/>
						<div class='search_modal_clear_btn' vOn:click_stop={this.clear}>
							<Icon size='18' name='x' stroke-width='2' />
						</div>
					</div>
					{this.renderTabs()}
					<div class='search__body' vOn:scroll={this.onScroll}>
						{$body}
						{$loading}
					</div>
				</div>
			</div>
		)
	},
}

export const getTicketStateInfo = (ticket) => {
	if (ticket.state === 'open') {
		return {
			type: 'success',
			text: i18n.t('ticket_status_open'),
		}
	}
	if (ticket.state === 'hold') {
		return {
			type: 'primary',
			text: i18n.t('ticket_status_hold'),
		}
	}
	if (ticket.state === 'closed') {
		return {
			type: 'secondary',
			text: i18n.t('ticket_status_closed'),
		}
	}

	return {
		type: 'warning',
		text: i18n.t('ticket_status_unassigned'),
	}
}
