import Buttons from './buttons.js'
const sb = require('@sb/util')
import accounting from 'accounting'
import pc from '../../product/common.js'
import lightGallery from 'lightgallery'

export default {
	name: 'product-attachments',

	props: ['ev'],

	mounted() {
		lightGallery(this.$refs.image_attachment)
	},

	methods: {
		async addProductToOrder(product) {
			let uid = lo.get(this.$route, 'query.uid')
			let user = lo.cloneDeep(store.matchUser(uid))
			delete user.session
			delete user.account_id

			let defpos = lo.get(store.me(), 'dashboard_setting.default_pos')
			if (!defpos) {
				let setting = store.matchShopSetting()
				defpos = lo.get(setting, 'poses.0.id', '')
			}

			let convo = store.matchConvo(this.cid)
			let channel = lo.get(convo, 'touchpoint.channel', 'subiz')
			let channel_touchpoint = lo.get(convo, 'touchpoint.source', '')
			if (channel == 'subiz') {
				channel_touchpoint = lo.get(convo, 'source', '')
				try {
					const {hostname} = new URL(channel_touchpoint)
					channel_touchpoint = hostname
				} catch (e) {}
			}

			let defpipeline = pc.getDefaultPipeline()
			let stageid = lo.get(defpipeline, 'stages.0.id')

			let orders = await store.fetchUserOrders(uid)
			let draft = lo.find(orders, (order) => order.is_draft)

			let neworder = {
				id: 'new',
				payment_made: 0,
				payment_status: 'unpaid',
				related_conversations: [this.cid],
				is_draft: true,
				items: [{product, quantity: 1}],
				created: Date.now(),
				due_date: Date.now(),
				issue_date: Date.now(),
				created_by: store.me().id,
				updated: Date.now(),
				updated_by: store.me().id,
				pos_id: defpos,
				status: 'open',
				user: user,
				channel: channel,
				channel_touchpoint: channel_touchpoint,
				salesperson: store.me().id,
				pipeline: defpipeline.id,
				pipeline_stage: stageid,
				assigned: Date.now(),
			}
			if (draft) {
				const NEW_ORDER_TIMEOUT = 5 * 3600 * 1000 // 5min
				if (Date.now() - (draft.created || 0) > NEW_ORDER_TIMEOUT) {
					this.$showOrder(neworder, {no_change_user: true})
					return
				}
				let existed = lo.find(draft.items, (item) => lo.get(item, 'product.id') === product.id)
				if (existed) {
					this.$showOrder(draft, {no_change_user: true})
					return
				}

				lo.set(draft, 'items', [...(draft.items || []), {product, quantity: 1}])
				this.$showOrder(draft, {no_change_user: true})
				return
			}

			this.$showOrder(neworder, {no_change_user: true})
		},
	},

	render() {
		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		// filter out all generic
		let attachments = lo.get(this.ev, 'data.message.attachments', [])
		let products = lo.filter(
			attachments,
			(att) => lo.get(att, 'type') === 'product' || lo.get(att, 'type') === 'product_preview',
		)

		products = products.filter((product) => product.product)

		if (products.length === 0) return null
		let $products = lo.map(products, (att, index) => {
			let product = att.product

			let $detailbtn = null
			let url = product.url || product.mobile_url
			if (url)
				$detailbtn = (
					<a class='product_attachment__btn' href={url} target='_blank'>
						{this.$t('detail')} <Icon name="external-link" size='1x' class='ml-2' style='margin-top: -2px' />
					</a>
				)

			let $title = (
				<a href={url} class='product_attachment__title' target='_blank'>
					{pc.getProductDisplayName(product)}
				</a>
			)
			let $subtitle = null
			if (product.description) $subtitle = <div class='product_attachment__subtitle'>{product.description}</div>

			let $price = null
			if (product.price) {
				$price = <div class='product_attachment__price'>{sb.displayMoney(product.price)}</div>
			}

			let cid = lo.get(this.ev, 'data.message.conversation_id')
			let convo = store.matchConvo(cid)

			let cls = `product_attachment product_attachment__${byType}`
			if (convo.state === 'ended') cls += ' convo_ended'

			let img = lo.get(product, 'image')
			if (!img) img = sb.replaceFileUrl(lo.get(product, 'images.0', require('../../assets/img/default.jpg')))
			return (
				<div class={cls}>
					<div ref='image_attachment'>
						<a href={img} class='product_attachment__img_container'>
							<img2 class='product_attachment__img' src={img} />
						</a>
					</div>
					{$title}
					{$subtitle}
					{$price}
					<div class='product_attachment__btns'>{$detailbtn}</div>
					<div class='btn product_attachment__create_order_btn' vOn:click={(_) => this.addProductToOrder(product)}>
						<div class='product_attachment__create_order_btn_icon'>
							<Icon name='plus' size='12' stroke-width='3' />
						</div>
						{this.$t('create_order_with_this_product')}
					</div>
				</div>
			)
		})

		return $products
	},
}
