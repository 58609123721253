import store from '@sb/store'
import sb from '@sb/util'
import {i18n, getDateFnsLocale} from '../languages.js'
import {formatDistanceStrict} from 'date-fns'

export function getTicketViewName(view, options = {fallback: true, shorten: false}) {
	let id = lo.get(view, 'id') || ''
	if (id.startsWith('tvdefault_type_')) {
		let typeid = getTypeIdFromViewId(id)
		let prefix = getTicketTypeName(store.matchTicketType()[typeid]) + ' - '
		if (options.shorten) prefix = ''
		if (id.endsWith('myview')) return prefix + i18n.t('assigns_to_me')
		if (id.endsWith('allview')) return prefix + i18n.t('all')
		if (id.endsWith('unassign')) return prefix + i18n.t('unassigned_short')
		if (id.endsWith('follow')) return prefix + i18n.t('mine')
	}
	if (id.startsWith('tvdefault')) {
		if (id === `tvdefault_${store.me().id}_myview`) return i18n.t('assigns_to_me')
		if (id === `tvdefault_${store.me().id}_unassign`) return i18n.t('unassigned_short')
		if (id === `tvdefault_${store.me().id}_follow`) return i18n.t('mine')
		if (id === `tvdefault_${store.me().id}_inbox`) return i18n.t('inbox2')
		if (id === `tvdefault_${store.me().id}_recycleBin`) return i18n.t('trash')
		if (id === `tvdefault_${store.me().id}_all`) return i18n.t('all')
	}

	if (options.fallback) {
		if (options.fallback === true) return view.name || i18n.t('untitled')
		return view.name || fallback
	}
	return view.name || ''
}

export function getTypeIdFromViewId(viewid = '') {
	if (!viewid.startsWith('tvdefault_type_')) return ''
	let typeids = lo.split(viewid, '_')
	let typeid = lo.get(typeids, '2')
	if (typeid === 'ttdefault') typeid = typeid + '_' + lo.get(typeids, '3')
	return typeid
}

export function getTicketTypeName(type = {}, def) {
	let id = type.id || ''
	if (id.startsWith('ttdefault') && !lo.trim(type.name)) {
		if (def === undefined) return i18n.t('default')
		return def
	}
	return type.name
}

export const extractLexicalHtml = lo.memoize(function (text, options) {
	let plaintext = sb.lexicalToPlainText(text, options)
	return sb.extractText(plaintext)
})

export const getTicketStateInfo = (ticket) => {
	if (ticket.state === 'open') {
		return {
			type: 'success',
			text: i18n.t('ticket_status_open'),
		}
	}
	if (ticket.state === 'hold') {
		return {
			type: 'primary',
			text: i18n.t('ticket_status_hold'),
		}
	}
	if (ticket.state === 'closed') {
		return {
			type: 'secondary',
			text: i18n.t('ticket_status_closed'),
		}
	}

	return {
		type: 'warning',
		text: i18n.t('ticket_status_unassigned'),
	}
}

export const ORDERED_GENERAL_VIEW_SUFFIXES = ['allview', 'inbox', 'myview', 'all', 'follow', 'unassign']

export const canViewTicket = (ticket = {}) => {
	return true
}

export const isTicketUnread = (ticket = {}) => {
	if (typeof ticket !== 'object') return false
	let lastEv = ticket.last_event
	if (!lastEv) return false

	let byid = lo.get(lastEv, 'by.id')
	if (byid === store.me().id) return false

	let members = ticket.read_receipts || []
	let meag = lo.find(members, (mem) => mem.id === store.me().id)

	if (lo.get(meag, 'is_subscribed') === 'off') return false
	if (meag && typeof meag === 'object' && lastEv.created) {
		if ((meag.read_at || 0) < lastEv.created) return true
	}

	return false
}

export const isTicketImportantUnread = (ticket = {}) => {
	if (typeof ticket !== 'object') return false
	let members = ticket.read_receipts || []
	let meag = lo.find(members, (mem) => mem.id === store.me().id)
	if (meag && typeof meag === 'object') {
		if ((meag.read_at || 0) < (meag.last_mentioned || 0)) return true
	}

	return false
}

export const displayOverdueText = (dueAt, options = {shorten: true}) => {
	if (!dueAt) return i18n.t('no_due_date')
	let distance = Math.abs(dueAt - Date.now())
	let n = Math.round(distance / (24 * 3600_000))

	if (dueAt < Date.now()) {
		if (options.shorten) {
			return i18n.t('overdue_distances_short', [formatDistanceStrict(distance, 0, {locale: getDateFnsLocale()})])
		} else {
			return i18n.t('overdue_distances', [formatDistanceStrict(distance, 0, {locale: getDateFnsLocale()})])
		}
	}

	let due = new Date(dueAt)
	let today = new Date()
	let isToday =
		due.getDate() === today.getDate() && due.getMonth() === today.getMonth() && due.getFullYear() == today.getFullYear()

	if (isToday) {
		if (options.shorten) {
			return i18n.t('today')
		} else {
			return i18n.t('due_today')
		}
	}

	if (options.shorten) {
		return i18n.t('due_in_distances_short', [formatDistanceStrict(distance, 0, {locale: getDateFnsLocale()})])
	} else {
		return i18n.t('due_in_distances', [formatDistanceStrict(distance, 0, {locale: getDateFnsLocale()})])
	}
}

export const getAvailableTicketTypes = () => {
	let types = lo.filter(store.matchTicketType(), (type) => {
		if (type.archived) return false
		return lo.find(type.permissions, (mem) => mem.member_id === store.me().id)
	})
	// defaylt type have created nanoseconds so must use getMS
	types = lo.orderBy(types, [(type) => sb.getMs(type.created)], ['asc'])
	return types
}
