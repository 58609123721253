/**
 * @fileoverview dragscroll - scroll area by dragging
 * @version 0.0.8
 *
 * @license MIT, see http://github.com/asvd/dragscroll
 * @copyright 2015 asvd <heliosframework@gmail.com>
 */


function bind (el) {
	el.removeEventListener('mousedown', el.md, 0)
	window.removeEventListener('mouseup', el.mu, 0)
	window.removeEventListener('mousemove', el.mm, 0)

	var lastClientX, pushed
	el.md = function (e) {
		if (
			!el.hasAttribute('nochilddrag') ||
				!document.elementFromPoint(e.pageX, e.pageY)
		) {
			pushed = 1
			lastClientX = e.clientX
			e.preventDefault()
		}
	}

	el.mu = () => {
		pushed = 0
	}

	el.mm = function (e) {
		if (!pushed) return

		el.scrollLeft -= -lastClientX + e.clientX
		lastClientX = e.clientX
	}

	el.addEventListener('mousedown', el.md, 0)
	window.addEventListener('mouseup', el.mu, 0)
	window.addEventListener('mousemove', el.mm, 0)
}

module.exports = bind
