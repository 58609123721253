import store from '@sb/store'
import sb from '@sb/util'
import {OnelineMsg} from '@sb/com'
let defaultAvatar = require('../assets/img/default_avatar.png')

export default {
	name: 'noti_item',
	props: ['noti', 'unread', 'extra_class'],

	data() {
		return {
			data: {},
		}
	},

	async created() {
		let noti = this.noti
		if (!noti.data) return null
		let data = sb.parseJSON(this.noti.data) || {}
		this.data = data
		let uid = ''
		if (noti.type === 'user_created') uid = lo.get(data, 'user.id')
		if (noti.type === 'lead_created') uid = lo.get(data, 'user.id')
		if (noti.type === 'user_returned') uid = lo.get(data, 'user.id')
		if (noti.type === 'campaign_user_converted') uid = lo.get(data, 'user_id')
		if (noti.type === 'user_opened_email') uid = lo.get(data, 'user_id')
		if (uid && !store.matchUser(uid)) {
			await store.fetchUser(uid)
			this.$forceUpdate()
		}
		let oid = ''
		if (noti.type == 'order_assigned' || noti.type === 'order_commented') {
			let orderid = data.order_id

			if (orderid && !store.matchOrder(orderid)) {
				await store.fetchOrders([orderid])
				this.$forceUpdate()
			}
		}

		if (
			noti.type == 'ticket_assigned' ||
			noti.type === 'ticket_state_updated' ||
			noti.type == 'ticket_comment_added' ||
			noti.type == 'ticket_created'
		) {
			let ticketid = data.ticket_id
			if (ticketid && !store.matchTicket(ticketid)) {
				await store.fetchTickets([ticketid])
				this.$forceUpdate()
			}
		}
	},

	methods: {
		renderComment(msg) {
			msg = msg || {}
			var nAtts = lo.size(msg.attachments)

			if (!msg.text && nAtts === 0) return null
			// if has text and 0 attachment => first line of text
			if (msg.text && nAtts === 0) {
				if (msg.format === 'html') {
					return html2Text(msg.text)
				}

				var text = lo.trim(msg.text)
				let block = msg.block
				if (!block && msg.format === 'delta') {
					let text = lo.get(this.ev, 'data.message.quill_delta', '')
					if (!text) text = lo.get(this.ev, 'data.message.text')
					block = sb.deltaToBlock(text)
				}
				if (block) text = sb.blockToText(block)

				text = text.split('\n').join('. ')

				var $tags = sb.extractText(text).map((tag) => {
					if (tag.type === 'emoji') return <Emoji emojiCode={tag.text} />
					return tag.text
				})

				return <fragment>{$tags}</fragment>
			}
			// if has multiple attachment || has text and ? attachment
			if (nAtts > 2 || (msg.text && nAtts > 0)) {
				return <div>{`sent a message with ${nAtts} attachments`}</div>
			}

			// no text, just one attachment
			var mimetype = lo.get(msg, 'attachments.0.mimetype')
			if (lo.includes(mimetype, 'audio')) {
				return <div>{'sent an audio'}</div>
			}
			if (lo.includes(mimetype, 'video')) {
				return <div>{'sent a video'}</div>
			}
			if (lo.includes(mimetype, 'image')) {
				return <div>{this.$t('sent_a_picture')}</div>
			}
			return <span>sent an attachment</span>
		},

		renderNotiIcon(noti) {
			let data = this.data || {}
			if (!data) return null

			if (noti.type === 'zalo_user_reply_consent') {
				return (
					<div class='instant_noti__icon '>
						<img
							class='mr-4'
							src={require('../assets/img/zalo_noti.png')}
							width='40px'
							height='40px'
							style='border-radius: 50%'
						/>
						<Icon name='chart-bar' size='12' class='instant_noti__icon_type instant_noti__icon_type__primary' />
					</div>
				)
			}

			if (noti.type === 'daily_manager_report' || noti.type === 'daily_agent_report') {
				return (
					<div class='instant_noti__icon '>
						<img
							class='mr-4'
							src={require('../assets/img/report.png')}
							width='40px'
							height='40px'
							style='border-radius: 50%'
						/>
						<Icon name='chart-bar' size='12' class='instant_noti__icon_type instant_noti__icon_type__red' />
					</div>
				)
			}

			if (noti.type === 'order_commented') {
				let ags = lo
					.map(data.authors, (id) => {
						let ag = store.matchAgent(id)
						if (!ag) return null
						return ag
					})
					.filter((n) => n)

				let $avatar = <Avatar agent={{}} size='md' notooltip rounded />
				if (ags.length > 0) $avatar = <Avatar agent={ags[ags.length - 1]} size='md' notooltip rounded />

				let uid = lo.get(data, 'user.id')
				let user = store.matchUser(uid) || {id: uid}
				return (
					<div class='instant_noti__icon' style='margin-right: 30px'>
						{$avatar}
						<div class='order_noti__icon instant_noti__icon_type instant_noti__icon_type__blue' style='color: white'>
							<Icon name='messages' size='18' stroke-width='2' />
						</div>
					</div>
				)
			}

			if (noti.type === 'task_commented') {
				let ag = store.matchAgent(lo.get(data, 'authors.0'))
				let $avatar = <Avatar agent={ag} size='md' notooltip rounded />
				return (
					<div class='instant_noti__icon' style='margin-right: 30px'>
						{$avatar}
						<div class='order_noti__icon instant_noti__icon_type instant_noti__icon_type__green' style='color: white'>
							<Icon name='messages' size='18' stroke-width='2' />
						</div>
					</div>
				)
			}

			if (noti.type === 'task_expired') {
				let ag = store.matchAgent(lo.get(data, 'assignee'))
				let $avatar = <Avatar agent={ag} size='md' notooltip rounded />
				return (
					<div class='instant_noti__icon' style='margin-right: 30px'>
						{$avatar}
						<div class='order_noti__icon instant_noti__icon_type' style='background: #ea3d2f; color: #fff'>
							<Icon name='calendar' size='18' stroke-width='2' />
						</div>
					</div>
				)
			}

			if (
				noti.type === 'task_status_updated' ||
				noti.type === 'task_title_updated' ||
				noti.type === 'task_note_updated' ||
				noti.type === 'task_due_date_updated' ||
				noti.type == 'task_assigned' ||
				noti.type == 'task_reminded' ||
				noti.type == 'task_expired' ||
				noti.type == 'task_deleted'
			) {
				let ag = store.matchAgent(lo.get(data, 'by'))
				let $avatar = <Avatar agent={ag} size='md' notooltip rounded />
				return (
					<div class='instant_noti__icon' style='margin-right: 30px'>
						{$avatar}
						<div class='order_noti__icon instant_noti__icon_type instant_noti__icon_type__green' style='color: white'>
							<Icon name='circle-check' size='18' stroke-width='2' />
						</div>
					</div>
				)
			}

			if (
				noti.type === 'ticket_created' ||
				noti.type === 'ticket_comment_added' ||
				noti.type === 'ticket_state_updated' ||
				noti.type === 'ticket_assigned'
			) {
				let by = lo.get(data, 'by', '')
				let $avatar = <img2 src={require('../assets/img/ticket.svg')} width='40' />
				if (by.startsWith('ag')) {
					let ag = store.matchAgent(lo.get(data, 'by'))
					$avatar = <Avatar agent={ag} size='md' notooltip rounded />
				}
				return (
					<div class='instant_noti__icon' style='margin-right: 30px'>
						{$avatar}
						<div class='order_noti__icon instant_noti__icon_type instant_noti__icon_type__red' style='color: white'>
							<Icon name='bookmark' size='18' stroke-width='2' />
						</div>
					</div>
				)
			}

			if (noti.type === 'task_mentioned') {
				let ag = store.matchAgent(lo.get(data, 'authors.0'))
				let $avatar = <Avatar agent={ag} size='md' notooltip rounded />
				return (
					<div class='instant_noti__icon' style='margin-right: 30px'>
						{$avatar}
						<div class='order_noti__icon instant_noti__icon_type instant_noti__icon_type__red' style='color: white'>
							<Icon name='at' size='18' stroke-width='2' />
						</div>
					</div>
				)
			}

			if (noti.type === 'order_assigned') {
				let order = store.matchOrder(data.order_id)
				if (!order) {
					return (
						<div class='instant_noti__icon' style='margin-right: 20px'>
							<img src={defaultAvatar} width={40} height={40} style='border-radius: 50%' />
							<div
								class='order_noti__icon instant_noti__icon_type instant_noti__icon_type__yellow'
								style='color: white'
							>
								<Icon name='file' size='18' stroke-width='2' />
							</div>
						</div>
					)
				}
				let $avatar = <Avatar user={order.user} size='md' notooltip rounded />
				return (
					<div class='instant_noti__icon' style='margin-right: 20px'>
						{$avatar}
						<div class='order_noti__icon instant_noti__icon_type instant_noti__icon_type__yellow' style='color: white'>
							<Icon name='file' size='18' stroke-width='2' />
						</div>
					</div>
				)
			}

			if (noti.type === 'user_created') {
				let uid = lo.get(data, 'user.id')
				let user = store.matchUser(uid) || {id: uid}
				return (
					<div class='instant_noti__icon'>
						<Avatar class='mr-4' user={user} size='md' notooltip />
						<Icon name='eye' size='12' class='instant_noti__icon_type instant_noti__icon_type__yellow' />
					</div>
				)
			}

			if (noti.type === 'lead_created') {
				let uid = lo.get(data, 'user.id')
				let user = store.matchUser(uid) || {id: uid}
				return (
					<div class='instant_noti__icon'>
						<Avatar class='mr-4' user={user} size='md' notooltip />
						<Icon name='award' size='12' class='instant_noti__icon_type instant_noti__icon_type__yellow' />
					</div>
				)
			}

			if (noti.type === 'user_returned') {
				let uid = lo.get(data, 'user.id')
				let user = store.matchUser(uid) || {id: uid}

				return (
					<div class='instant_noti__icon'>
						<Avatar class='mr-4' user={user} size='md' notooltip />
						<Icon name='eye' size='12' class='instant_noti__icon_type instant_noti__icon_type__blue' />
					</div>
				)
			}

			if (noti.type === 'campaign_user_converted') {
				let uid = lo.get(data, 'user_id')
				let user = store.matchUser(uid) || {id: uid}
				return (
					<div class='instant_noti__icon'>
						<Avatar class='mr-4' user={user} size='md' notooltip />
						<Icon name='sun' size='12' class='instant_noti__icon_type instant_noti__icon_type__green' />
					</div>
				)
			}

			if (noti.type === 'user_opened_email') {
				let uid = lo.get(data, 'user_id')
				let user = store.matchUser(uid) || {id: uid}
				return (
					<div class='instant_noti__icon'>
						<Avatar class='mr-4' user={user} size='md' notooltip />
						<Icon name='mail' size='12' class='instant_noti__icon_type instant_noti__icon_type__orange' />
					</div>
				)
			}

			return (
				<div class='instant_noti__icon' style='margin-right: 20px; border-radius: 50%'>
					<img2 src={defaultAvatar} width={40} height={40} />
				</div>
			)
		},

		renderNotiContent(noti) {
			let data = this.data
			let uid = lo.get(data, 'user.id')
			let user = store.matchUser(uid) || {id: uid}

			if (noti.type === 'daily_manager_report') {
				let usercount = data.user_sent_count || 0
				let convocount = data.conversation_count || 0
				return <div>Ngày hôm qua bạn có {convocount} cuộc hội thoại</div>
			}

			if (noti.type === 'zalo_user_reply_consent') {
				if (data.code == 'ALLOW') {
					return <div>{this.$t('zalo_user_accept_consent', {0: data.phone, 1: data.oa_name})}</div>
				}
				return <div>{this.$t('zalo_user_deny_consent', {0: data.phone, 1: data.oa_name})}</div>
			}

			if (noti.type === 'user_created') {
				if (data.total > 1) {
					return (
						<div>
							{data.total} {this.$t('visitor')} {this.$t('first_time_view_visitor')}
						</div>
					)
				}
				return (
					<div>
						<b>{sb.getUserDisplayName(user)}</b> {this.$t('first_time_view_visitor')}
					</div>
				)
			}

			if (noti.type === 'lead_created') {
				if (data.total > 1) {
					return (
						<div>
							{data.total} {this.$t('visitor')} {this.$t('converted_to_lead')}
						</div>
					)
				}
				return (
					<div>
						<b>{sb.getUserDisplayName(user)}</b> {this.$t('converted_to_lead')}
					</div>
				)
			}

			if (noti.type === 'order_assigned') {
				let order = store.matchOrder(data.order_id)
				if (!order) {
					return (
						<div
							class='order_noti_content'
							style='height: 17px; width: 170px; background: #eee; border-radius: 5px'
						></div>
					)
				}
				let username = sb.getUserDisplayName(order.user)

				let $stage = null

				return (
					<div class='order_noti_content'>
						<span class='text__muted'>{this.$t('assigned_to_you_an_order')}</span> {username}. <div>{$stage}</div>
					</div>
				)
			}

			if (noti.type == 'task_commented') {
				let agentnames = lo
					.map(data.authors, (id) => {
						if (!id) return ''
						let ag = store.matchAgent(id)
						return sb.getAgentDisplayName(ag)
					})
					.filter((n) => n)

				if (agentnames.length == 0) agentnames = [this.$t('someone')]

				let $agentname = null
				if (agentnames.length == 1) {
					$agentname = <b>{agentnames[0]}</b>
				}

				if (agentnames.length == 2) {
					$agentname = (
						<fragment>
							<b>{agentnames[0]}</b>&nbsp;{this.$t('and')}&nbsp;<b>{agentnames[1]}</b>
						</fragment>
					)
				}

				if (agentnames.length > 2) {
					$agentname = (
						<fragment>
							<b>{agentnames[0]}</b>&nbsp;{this.$t('and')}&nbsp;<b>{agentnams.length - 2}</b>
							{this.$t('other_agent')}
						</fragment>
					)
				}

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'
				return (
					<div class='order_noti_content'>
						{$agentname} {this.$t('commented_on_task')} <strong>{title}</strong>: {this.renderComment(data.comment)}
					</div>
				)
			}

			if (noti.type == 'task_status_updated') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'
				let text =
					data.status == 'done'
						? this.$t('completed').toLowerCase()
						: this.$t('update_task_state_to_not_done').toLowerCase()
				return (
					<div class='order_noti_content'>
						{$agentname} {text} <strong>{title}</strong>
					</div>
				)
			}

			if (noti.type == 'task_note_updated') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'

				return (
					<div class='order_noti_content'>
						{$agentname} {this.$t('change_note_of_task')} <strong>{title}</strong>
						{':'} {data.note}
					</div>
				)
			}

			if (noti.type == 'task_title_updated') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'

				return (
					<div class='order_noti_content'>
						{$agentname} {this.$t('change_title_of_task_to')} <strong>{title}</strong>
					</div>
				)
			}

			if (noti.type == 'task_due_date_updated') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'

				return (
					<div class='order_noti_content'>
						{$agentname} {this.$t('changed_due_date_of_task_to')} <strong>{title}</strong> {this.$t('to2')}{' '}
						<Time time={data.due_at} />
					</div>
				)
			}

			if (noti.type == 'task_assigned') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'

				return (
					<div class='order_noti_content'>
						{$agentname} {this.$t('assigned_to_you_task')} <b>{title}</b>
					</div>
				)
			}

			if (noti.type == 'task_deleted') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'

				return (
					<div class='order_noti_content'>
						{$agentname} {this.$t('deleted_task')} <strong>{title}</strong>
					</div>
				)
			}

			if (noti.type == 'task_reminded') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'

				return (
					<div class='order_noti_content'>
						<strong>{title}</strong> {this.$t('will_be_due_on')} <Time time={data.due_at} />
					</div>
				)
			}
			if (noti.type == 'task_expired') {
				let ag = store.matchAgent(data.by)
				let $agentname = <b>{sb.getAgentDisplayName(ag)}</b>

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'

				return (
					<div class='order_noti_content'>
						<strong>{title}</strong> {this.$t('is_expired')}
					</div>
				)
			}

			if (noti.type == 'task_mentioned') {
				let agentnames = lo
					.map(data.authors, (id) => {
						if (!id) return ''
						let ag = store.matchAgent(id)
						return sb.getAgentDisplayName(ag)
					})
					.filter((n) => n)

				if (agentnames.length == 0) agentnames = [this.$t('someone')]

				let $agentname = null
				if (agentnames.length == 1) {
					$agentname = <b>{agentnames[0]}</b>
				}

				if (agentnames.length == 2) {
					$agentname = (
						<fragment>
							<b>{agentnames[0]}</b>&nbsp;{this.$t('and')}&nbsp;<b>{agentnames[1]}</b>
						</fragment>
					)
				}

				if (agentnames.length > 2) {
					$agentname = (
						<fragment>
							<b>{agentnames[0]}</b>&nbsp;{this.$t('and')}&nbsp;<b>{agentnams.length - 2}</b>
							{this.$t('other_agent')}
						</fragment>
					)
				}

				let title = data.title || ''
				if (data.title > 20) title = title.substr(0, 18) + '...'
				return (
					<div class='order_noti_content'>
						{$agentname} {this.$t('mentioned_you_on_task')} <strong>{title}</strong>: {this.renderComment(data.comment)}
					</div>
				)
			}

			if (noti.type === 'order_commented') {
				let order = store.matchOrder(data.order_id)
				if (!order) {
					return (
						<div
							class='order_noti_content'
							style='height: 17px; width: 170px; background: #eee; border-radius: 5px'
						></div>
					)
				}
				let username = sb.getUserDisplayName(order.user)

				let agentnames = lo
					.map(data.authors, (id) => {
						let ag = store.matchAgent(id)
						if (!ag) return ''
						return sb.getAgentDisplayName(ag)
					})
					.filter((n) => n)

				if (agentnames.length == 0) agentnames = [this.$t('someone')]

				let $agentname = null
				if (agentnames.length == 1) {
					$agentname = <b>{agentnames[0]}</b>
				}

				if (agentnames.length == 2) {
					$agentname = (
						<fragment>
							<b>{agentnames[0]}</b>&nbsp;{this.$t('and')}&nbsp;<b>{agentnames[1]}</b>
						</fragment>
					)
				}

				if (agentnames.length > 2) {
					$agentname = (
						<fragment>
							<b>{agentnames[0]}</b>&nbsp;{this.$t('and')}&nbsp;<b>{agentnams.length - 2}</b>
							{this.$t('other_agent')}
						</fragment>
					)
				}

				return (
					<div class='order_noti_content'>
						{$agentname} <span class='text__muted'>{this.$t('commented_on_order_of')}</span> {username}:{' '}
						{this.renderComment(data.comment)}
					</div>
				)
			}

			if (noti.type === 'user_returned') {
				if (data.total > 1) {
					return (
						<div>
							{data.total} {this.$t('visitor')} {this.$t('return_visitor')}
						</div>
					)
				}
				return (
					<div>
						<b>{sb.getUserDisplayName(user)}</b> {this.$t('return_visitor')}
					</div>
				)
			}

			if (noti.type === 'campaign_user_converted') {
				if (data.total > 1) {
					return (
						<div>
							{data.total} {this.$t('new_conversion')}
						</div>
					)
				}

				return <div>{this.$t('new_conversion')}</div>
			}

			if (noti.type === 'user_opened_email') {
				let uid = lo.get(data, 'user_id')
				let user = store.matchUser(uid) || {id: uid}

				return (
					<div>
						<b>{sb.getUserDisplayName(user)}</b> {this.$t('user_opened_email_noti')}
					</div>
				)
			}

			let ticket
			let name
			if (data.ticket_id) {
				ticket = store.matchTicket(data.ticket_id) || {}
				let by = data.by || ''
				if (by.startsWith('ag')) name = sb.getAgentDisplayName(store.matchAgent(by))
				else if (by.startsWith('us')) name = this.$t('guest')
				else name = this.$t('system')
			}

			if (noti.type === 'ticket_created') {
				return (
					<div style='overflow:hidden'>
						<span class='text__semibold'>{name}</span> {this.$t('create_ticket').toLowerCase()}{' '}
						<span class='text__muted'>{'#' + ticket.number}</span>
						<div style='overflow: hidden; display: flex;'>
							<div class='text__truncate'>{ticket.title}</div>
						</div>
					</div>
				)
			}

			if (noti.type === 'ticket_comment_added') {
				let ev = Object.assign({}, data.comment)
				ev.type = 'message_sent'
				return (
					<div style='overflow:hidden'>
						<span>{name}</span> {this.$t('comment_on_ticket').toLowerCase()}{' '}
						<span class='text__muted'>{'#' + ticket.number}</span>
						<div style='overflow: hidden; display: flex;'>
							<div class='text__truncate'>{ticket.title}</div>
						</div>
						<div class='text__truncate text__semibold'>
							<OnelineMsg class='text-truncate' ev={ev} />
						</div>
					</div>
				)
			}

			if (noti.type === 'ticket_state_updated') {
				let statename = data.state
				let state = data.state
				if (state === 'open') statename = this.$t('ticket_status_open')
				if (state === 'hold') statename = this.$t('ticket_status_hold')
				if (state === 'closed') statename = this.$t('ticket_status_closed')

				return (
					<div style='overflow:hidden'>
						<span class='text__semibold'>{name}</span> {this.$t('change_ticket_state_to').toLowerCase()}{' '}
						<span class='text__semibold'>{statename}</span>
						<div class='text__truncate'>
							<span class='text__muted mr-1'>{'#' + ticket.number}</span>
							<span>{ticket.title}</span>
						</div>
					</div>
				)
			}

			if (noti.type === 'ticket_assigned') {
				return (
					<div style='overflow:hidden'>
						<span class='text__semibold'>{this.$t('you_have_new_assigned_ticket')}</span>
						<div class='text__truncate'>
							<span class='text__muted mr-1'>{'#' + ticket.number}</span>
							<span>{ticket.title}</span>
						</div>
					</div>
				)
			}

			return null
		},
	},

	render() {
		let noti = this.noti
		if (!noti) return
		if (!noti.data) return
		let created = sb.getMs(noti.created)
		let style = 'overflow: hidden; flex: 1; '
		let $time = (
			<b>
				<Time class='instant_noti__time' ago time={created || Date.now()} notooltip />
			</b>
		)

		// 2mins
		if (Date.now() - created < 120000) {
			$time = (
				<b>
					<Time class='instant_noti__time text__primary' ago time={created || Date.now()} notooltip />
				</b>
			)
		}
		if (noti.is_read) {
			$time = <Time class='instant_noti__time' ago time={created || Date.now()} notooltip />
			style += ' opacity: 0.6;'
		}

		let data = sb.parseJSON(noti.data) || {}
		return (
			<div class={'instant_noti__noti ' + (this.extra_class || '')} vOn:click={(_) => this.$emit('click', noti)}>
				{this.renderNotiIcon(noti)}
				<div class='instant_noti__noti_body'>
					<div style={style} class='instant_noti__noti_content'>
						{this.renderNotiContent(noti)}
					</div>
					<div class='d-flex align-items-center mt-2'>
						{$time}
						{(noti.type === 'user_returned' || noti.type === 'user_created' || noti.type === 'lead_created') && (
							<div
								class='btn btn__xs btn__primary ml-4'
								style='border-radius: 5px'
								vOn:click_stop={() => this.$emit('click', noti, {is_send_message: true})}
							>
								{this.$t('send_message')}
							</div>
						)}
					</div>
				</div>
			</div>
		)
	},
}

function html2Text(html) {
	let doc = new DOMParser().parseFromString(html, 'text/html')
	return doc.body.textContent || ''
}
