import store from '@sb/store'
const sb = require('@sb/util')
import {format} from 'date-fns'

export default {
	name: 'user-profile-button',
	props: ['profile', 'subiz', 'true_user_id'],

	data() {
		return {
			loading: false,
		}
	},

	mounted() {},

	methods: {
		async loadProfile() {
			let user = store.matchUser(this.profile.id)
			if (!user) {
				this.loading = true
				await store.fetchUsers([this.profile.id], true)
				this.loading = false
				this.$forceUpdate()
			} else {
				this.$forceUpdate()
			}
		},

		onSendMessageToUser(user) {
			this.$emit('sendMessage', user)
		},

		renderLoadingOverlay() {
			let loadingstyle = 'position: absolute; inset: 0; background-color: #fff; z-index: 99;'
			if (!this.loading) loadingstyle += 'display: none;'

			return (
				<div style={loadingstyle} class='d-flex align-items-center justify-content-center'>
					<Spinner size='32' mode='blue' />
				</div>
			)
		},

		renderMergeInfo(user) {
			if (user.id === this.true_user_id) {
				// dont show merged info with true user
				user = lo.cloneDeep(user)
				user.merged = 0
			} else {
				// show merged info for primary user
				if (!user.merged) {
					let trueUser = store.matchUser(this.true_user_id, true) || {}
					user = lo.cloneDeep(user)
					user.merged_by = trueUser.merged_by
					user.merged = trueUser.merged
					user.merged_reason = trueUser.merged_reason
				}
			}
			if (!user.merged) return null
			let by = this.$t('system').toLowerCase()
			let mergeBy = user.merged_by || ''
			if (mergeBy.startsWith('ag')) {
				let ag = store.matchAgent()[mergeBy]
				by = sb.getAgentDisplayName(ag)
			}

			let $reason = null
			let reason = user.merged_reason || ''
			if (reason && reason !== 'swap') {
				let reasonText = ''
				let sameValue = ''
				if (reason.startsWith('same_email.')) {
					reasonText = this.$t('same_email')
					sameValue = reason.replace('same_email.', '')
				}
				if (reason.startsWith('same_phone.')) {
					reasonText = this.$t('same_phone')
					sameValue = reason.replace('same_phone.', '')
				}
				if (reason.startsWith('same_record_id.')) {
					reasonText = this.$t('same_record_id')
					sameValue = reason.replace('same_record_id.', '')
				}
				$reason = (
					<div class='text__muted'>
						{this.$t('reason')}: {reasonText} {sameValue}
					</div>
				)
			}
			return (
				<Fragment>
					<div class='mt-3 d-flex align-items-center mb-2'>
						<div class='text__semibold'>{this.$t('contact_link_short')}</div>
						<div class='link link__danger ml-auto' vOn:click={() => this.$emit('unlink', user)}>
							{this.$t('cancel')}
						</div>
					</div>
					<div class='text__muted'>
						<Time time={user.merged} /> {this.$t('by')} {by}
					</div>
					{$reason}
				</Fragment>
			)
		},

		renderZaloProfile() {
			let user = store.matchUser(this.profile.id, true) || {id: this.profile.id}

			let img = require('../assets/img/zalo_channel.svg')
			let connectorTypeImg = require('../assets/img/zalo_1.svg')

			let created = sb.getUserDateAttr(user, 'created')
			created = created ? new Date(created).getTime() : 0
			let lastMessageSent = sb.getUserDateAttr(user, 'last_message_sent')
			lastMessageSent = lastMessageSent ? new Date(lastMessageSent).getTime() : 0

			let inteid = `${store.me().account_id}.${user.channel_source}.zalokon`
			let inte = store.matchIntegration()[inteid]

			let isFollowed = sb.getUserBooleanAttr(user, 'is_followed')
			let $zns = null
			let $zalo_call = null
			if (lo.find(store.matchIntegration(), (inte) => inte.connector_type === 'zalo' && inte.state === 'activated')) {
				$zns = (
					<button type='button' class='btn btn__sm btn__outline_primary' vOn:click={() => this.$emit('zns')}>
						{this.$t('send_zns')}
					</button>
				)
				$zalo_call = (
					<button
						type='button'
						class='btn btn__sm btn__outline_primary mr-3'
						vOn:click={() =>
							this.$root.$emit('show_call_modal', {
								from_number: user.channel_source,
								call_to: user.profile_id,
								call_immediately: true,
							})
						}
					>
						{this.$t('call_short')} {this.$t('zalo')}
					</button>
				)
			}
			if (store.me().account_id !== 'acpxkgumifuoofoosble' && store.me().account_id != 'acrhcpsplyhtxseavsqw') {
				//$zns = null
				$zalo_call = null
			}

			let $content = (
				<div class='contact_profile_dropdown'>
					{this.renderLoadingOverlay()}
					<div class='contact_profile_dropdown_header'>
						<div class='contact_profile_dropdown_avatar_wrapper'>
							<Avatar user={user} size={48} />
							<div class='contact_avatar_channel_wrapper'>
								<img src={connectorTypeImg} class='contact_avatar_channel' />
							</div>
						</div>
						<div class='flex__1 ml-3 mr-4' style='overflow: hidden'>
							<div class='text__semibold text__truncate'>{sb.getUserDisplayName(user)}</div>
							{lastMessageSent ? (
								<Time time={lastMessageSent} ago suffix class='text__muted text__truncate text__sm' />
							) : (
								<div class='text__sm text__muted'>{this.$t('Facebook')}</div>
							)}
						</div>
						<div class='no-shrink d-flex'>
							{$zalo_call}
							{$zns}
							<button
								type='button'
								class='btn btn__sm btn__primary ml-3'
								vOn:click={() => this.onSendMessageToUser(user)}
							>
								{this.$t('inbox')}
							</button>
						</div>
					</div>
					<div class='contact_profile_dropdown_body'>
						<div class='text__semibold mb-2'>{this.$t('information')}</div>
						<div class='d-flex'>
							<div class='contact_profile_info_label'>{this.$t('created_date')}</div>
							{created ? <Time time={created} /> : <em>{this.$t('no_information')}</em>}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('OA')}</div>
							{inte ? (
								<a target='_blank' href={`https://zalo.me/${user.channel_source}`}>
									{lo.get(inte, 'name')}
								</a>
							) : (
								<em>{this.$t('no_information')}</em>
							)}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('zalo_follow')}</div>
							{isFollowed ? <div>{this.$t('zalo_followed')}</div> : <div>{this.$t('zalo_unfollowed')}</div>}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('last_message')}</div>
							{lastMessageSent ? <Time time={lastMessageSent} /> : <em>{this.$t('no_information')}</em>}
						</div>
						{this.renderMergeInfo(user)}
					</div>
				</div>
			)
			return (
				<div class='contact_profile_btn_wrapper' vOn:mouseover={this.loadProfile}>
					<HoverDropdown dropdown_content={$content} ref={`user_contact_dropdown_${user.id}`}>
						<img src={img} class='contact_profile_btn' />
					</HoverDropdown>
				</div>
			)
		},

		renderSubizProfile() {
			let user = store.matchUser(this.profile.id, true) || {id: this.profile.id}

			let img = require('../assets/img/subiz_channel.svg')
			let connectorTypeImg = require('../assets/img/subiz_xsm.svg')
			let created = sb.getUserDateAttr(user, 'created')
			created = created ? new Date(created).getTime() : 0

			let lastMessageSent = sb.getUserDateAttr(user, 'last_message_sent')
			lastMessageSent = lastMessageSent ? new Date(lastMessageSent).getTime() : 0

			let pageUrl = lo.get(user, 'start_content_view.by.device.page_url', '')
			let domain = sb.getDomainFromUrl(pageUrl)

			let prefix = pageUrl.split('://')
			prefix = prefix[0] || ''

			let $content = (
				<div class='contact_profile_dropdown'>
					{this.renderLoadingOverlay()}
					<div class='contact_profile_dropdown_header'>
						<div class='contact_profile_dropdown_avatar_wrapper'>
							<Avatar user={user} size={48} is_profile />
							<div class='contact_avatar_channel_wrapper'>
								<img src={connectorTypeImg} class='contact_avatar_channel' />
							</div>
						</div>
						<div class='flex__1 ml-3 mr-4' style='overflow: hidden'>
							<div class='text__semibold text__truncate'>{sb.getUserDisplayName(user)}</div>
							{lastMessageSent ? (
								<Time time={lastMessageSent} ago suffix class='text__muted text__truncate text__sm' />
							) : (
								<div class='text__sm text__muted'>{'Website'}</div>
							)}
						</div>
						<div class='no-shrink d-flex'>
							<button
								type='button'
								class='btn btn__sm btn__primary ml-3'
								vOn:click={() => this.onSendMessageToUser(user)}
							>
								{this.$t('inbox')}
							</button>
						</div>
					</div>
					<div class='contact_profile_dropdown_body'>
						<div class='text__semibold mb-2'>{this.$t('information')}</div>
						<div class='d-flex'>
							<div class='contact_profile_info_label'>{this.$t('created_date')}</div>
							{created ? <Time time={created} /> : <em>{this.$t('no_information')}</em>}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('Website')}</div>
							{domain ? (
								<a target='_blank' href={prefix + '://' + domain}>
									{domain}
								</a>
							) : (
								<em>{this.$t('no_information')}</em>
							)}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('last_message')}</div>
							{lastMessageSent ? <Time time={lastMessageSent} /> : <em>{this.$t('no_information')}</em>}
						</div>
						{this.renderMergeInfo(user)}
					</div>
				</div>
			)
			return (
				<div class='contact_profile_btn_wrapper' vOn:mouseover={this.loadProfile}>
					<HoverDropdown dropdown_content={$content} ref={`user_contact_dropdown_${user.id}`}>
						<img src={img} class='contact_profile_btn' />
					</HoverDropdown>
				</div>
			)
		},

		renderFacebookProfile() {
			let user = store.matchUser(this.profile.id, true) || {id: this.profile.id}

			let img = require('../assets/img/facebook_channel.svg')
			let connectorTypeImg = require('../assets/img/facebook_1.svg')

			let created = sb.getUserDateAttr(user, 'created')
			created = created ? new Date(created).getTime() : 0
			let lastMessageSent = sb.getUserDateAttr(user, 'last_message_sent')
			lastMessageSent = lastMessageSent ? new Date(lastMessageSent).getTime() : 0

			let inteid = `${store.me().account_id}.${user.channel_source}.fabikon`
			let inte = store.matchIntegration()[inteid]

			let $content = (
				<div class='contact_profile_dropdown' style='position: relative'>
					{this.renderLoadingOverlay()}

					<div class='contact_profile_dropdown_header'>
						<div class='contact_profile_dropdown_avatar_wrapper'>
							<Avatar user={user} size={48} />
							<div class='contact_avatar_channel_wrapper'>
								<img src={connectorTypeImg} class='contact_avatar_channel' />
							</div>
						</div>
						<div class='flex__1 ml-3 mr-4' style='overflow: hidden'>
							<div class='text__semibold text__truncate'>{sb.getUserDisplayName(user)}</div>
							{lastMessageSent ? (
								<Time time={lastMessageSent} ago suffix class='text__muted text__truncate text__sm' />
							) : (
								<div class='text__sm text__muted'>{this.$t('Facebook')}</div>
							)}
						</div>
						<div class='no-shrink d-flex'>
							<button
								type='button'
								class='btn btn__sm btn__primary ml-3'
								vOn:click={() => this.onSendMessageToUser(user)}
							>
								{this.$t('inbox')}
							</button>
						</div>
					</div>
					<div class='contact_profile_dropdown_body'>
						<div class='text__semibold mb-2'>{this.$t('information')}</div>
						<div class='d-flex'>
							<div class='contact_profile_info_label'>{this.$t('created_date')}</div>
							{created ? <Time time={created} /> : <em>{this.$t('no_information')}</em>}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('Fanpage')}</div>
							{inte ? (
								<a target='_blank' href={`https://facebook.com/${user.channel_source}`}>
									{lo.get(inte, 'name')}
								</a>
							) : (
								<em>{this.$t('no_information')}</em>
							)}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('last_message')}</div>
							{lastMessageSent ? <Time time={lastMessageSent} /> : <em>{this.$t('no_information')}</em>}
						</div>
						{this.renderMergeInfo(user)}
					</div>
				</div>
			)
			return (
				<div class='contact_profile_btn_wrapper' vOn:mouseover={this.loadProfile}>
					<HoverDropdown dropdown_content={$content} ref={`user_contact_dropdown_${user.id}`}>
						<img src={img} class='contact_profile_btn' />
					</HoverDropdown>
				</div>
			)
		},

		renderInstagramProfile() {
			let user = store.matchUser(this.profile.id, true) || {id: this.profile.id}
			let img = require('../assets/img/instagram.svg')
			let connectorTypeImg = require('../assets/img/instagram_circle.svg')

			let created = sb.getUserDateAttr(user, 'created')
			created = created ? new Date(created).getTime() : 0
			let lastMessageSent = sb.getUserDateAttr(user, 'last_message_sent')
			lastMessageSent = lastMessageSent ? new Date(lastMessageSent).getTime() : 0

			let inteid = `${store.me().account_id}.instagram_${user.channel_source}.fabikon`
			let inte = store.matchIntegration()[inteid]

			let $content = (
				<div class='contact_profile_dropdown'>
					{this.renderLoadingOverlay()}
					<div class='contact_profile_dropdown_header'>
						<div class='contact_profile_dropdown_avatar_wrapper'>
							<Avatar user={user} size={48} />
							<div class='contact_avatar_channel_wrapper'>
								<img src={connectorTypeImg} class='contact_avatar_channel' />
							</div>
						</div>
						<div class='flex__1 ml-3 mr-4' style='overflow: hidden'>
							<div class='text__semibold text__truncate'>{sb.getUserDisplayName(user)}</div>
							{lastMessageSent ? (
								<Time time={lastMessageSent} ago suffix class='text__muted text__truncate text__sm' />
							) : (
								<div class='text__sm text__muted'>{this.$t('Facebook')}</div>
							)}
						</div>
						<div class='no-shrink d-flex'>
							<button
								type='button'
								class='btn btn__sm btn__primary ml-3'
								vOn:click={() => this.onSendMessageToUser(user)}
							>
								{this.$t('inbox')}
							</button>
						</div>
					</div>
					<div class='contact_profile_dropdown_body'>
						<div class='text__semibold mb-2'>{this.$t('information')}</div>
						<div class='d-flex'>
							<div class='contact_profile_info_label'>{this.$t('created_date')}</div>
							{created ? <Time time={created} /> : <em>{this.$t('no_information')}</em>}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('Instagram')}</div>
							{inte ? (
								<a target='_blank' href={`https://instagram.com/${inte.username}`}>
									{lo.get(inte, 'name')}
								</a>
							) : (
								<em>{this.$t('no_information')}</em>
							)}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('last_message')}</div>
							{lastMessageSent ? <Time time={lastMessageSent} /> : <em>{this.$t('no_information')}</em>}
						</div>
						{this.renderMergeInfo(user)}
					</div>
				</div>
			)
			return (
				<div class='contact_profile_btn_wrapper' vOn:mouseover={this.loadProfile}>
					<HoverDropdown dropdown_content={$content} ref={`user_contact_dropdown_${user.id}`}>
						<img src={img} class='contact_profile_btn' />
					</HoverDropdown>
				</div>
			)
		},
	},

	render() {
		if (this.subiz) return this.renderSubizProfile()
		if (this.profile.channel === 'facebook') return this.renderFacebookProfile()
		if (this.profile.channel === 'zalo') return this.renderZaloProfile()
		if (this.profile.channel === 'instagram') return this.renderInstagramProfile()
	},
}
