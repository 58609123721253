import store from '@sb/store'
var sb = require('@sb/util')
import UserNote from './user_note'

export default {
	name: 'user-notes',
	props: ['user', 'refresh'],
	data() {
		return {
			notes: [],
			loading: false,
		}
	},

	mounted() {
		this.$root.$on('shortcut_create_note', this.onCreateNoteShortcut)
		if (this.user) this.loadUserNotes(this.user.id)
	},

	beforeDestroy() {
		this.$root.$off('shortcut_create_note', this.onCreateNoteShortcut)
	},

	watch: {
		user(user, oldUser) {
			let newid = lo.get(user, 'id')
			let oldid = lo.get(oldUser, 'id')
			if (newid && oldid !== newid) this.loadUserNotes(user.id)
		},
	},

	methods: {
		onCreateNoteShortcut() {
			this.showEditNote({})
		},

		renderNotes(notes, uid) {
			notes = lo.orderBy(notes, 'id', 'desc')
			return lo.map(notes, (note) => (
				<UserNote
					ref={`note_component_${note.id}`}
					note={note}
					userId={uid}
					vOn:update={this.onUpdateNote}
					vOn:delete={this.onDeleteNote}
					vOn:create={this.onCreateNote}
				/>
			))
		},

		onUpdateNote(note) {
			let index = this.notes.findIndex((n) => n.id === note.id)
			if (index === -1) return
			this.notes[index] = note
			this.$forceUpdate()
		},

		onDeleteNote(note) {
			this.notes = lo.filter(this.notes, (cnote) => cnote.id !== note.id)
		},

		onCreateNote(note) {
			let hasNewNote = !lo.get(this.notes, '0.id')
			if (!hasNewNote) return

			this.notes[0] = note
			this.$forceUpdate()
		},

		async loadUserNotes(uid) {
			if (!uid) return

			let notes = store.matchUserNotes(uid)
			if (!lo.size(notes)) {
				this.loading = true
				await store.fetchUserNotes(uid)
				this.loading = false
				notes = store.matchUserNotes(uid)
			}
			this.notes = lo.cloneDeep(notes)
		},

		async onClickAddNote() {
			let hasNewNote = !lo.get(this.notes, '0.id')
			if (hasNewNote && lo.size(this.notes)) return

			let notes = lo.cloneDeep(this.notes)
			let newNote = {
				created: Date.now(),
				creator_id: store.me().id,
			}
			notes.unshift(newNote)
			this.notes = notes

			await this.$nextTick()
			let $noteComponent = this.$refs[`note_component_${newNote.id}`]
			console.log('VVVVVVVVVV', this.notes, $noteComponent)
			if ($noteComponent) $noteComponent.showEditNote()
		},
	},

	render() {
		let notes = this.notes
		if (this.refresh) lo.noop()
		if (!this.user) return null

		let $loading = null
		if (this.loading) $loading = <Spinner static class='ml-2 mb-1' mode='dark' />
		notes = lo.orderBy(notes, ['created'], ['desc'])

		return (
			<div class='user_notes'>
				<div class='user_profile__head'>
					<div class='profile_section_title'>
						{this.$t('note')} {$loading}
					</div>
					<div style='flex: 1'></div>
					<div class='link link__primary d-flex align-items-center' vOn:click={(_) => this.onClickAddNote()}>
						{this.$t('add_note')}&nbsp;
						<Icon name='file-plus' size='18' stroke-width='1.5' />
					</div>
				</div>

				{lo.size(notes) > 0 && <div class='user_notes__body mt-3'>{this.renderNotes(notes, this.user.id)}</div>}
			</div>
		)
	},
}
