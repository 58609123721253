var sb = require('@sb/util')
import {format} from 'date-fns'
import accounting from 'accounting'

// TODO: chi khi edit moi hien control
export default {
	props: ['value', 'loading', 'type', 'title', 'instant', 'isLight', 'centered', 'verified_suffix'],
	data() {
		return {
			isEdit: false,
			text: '',
		}
	},

	created() {
		this.text = this.value
	},

	watch: {
		key() {
			// key change => must reset all state
			Object.assign(this.$data, this.$options.data())
		},

		value(value) {
			if (this.isEdit) return
			if (this.loading) return
			this.text = value
		},

		isEdit() {
			this.$emit('toggleEdit', this.isEdit)
		},
	},

	render() {
		if (!this.type || this.type === 'text' || this.type === 'textarea') return this.renderText()
		if (this.type === 'date') return this.renderDate()
		if (this.type === 'number') return this.renderNumber()
		if (this.type === 'boolean') return this.renderBoolean()
	},

	methods: {
		onCheckboxChange(e) {
			this.text = e.target.checked
			this.$emit('change', this.text)

			if (this.instant) {
				setTimeout(() => {
					this.text = this.value
				}, 10)
			}
		},

		renderBoolean() {
			let $loading = null

			if (this.loading) {
				$loading = <Icon name='loader' vOn:click_stop={this.cancel} size='18' stroke='3' class='input2__icon__loader' />
			}

			let id = 'editable2_bool_' + sb.randomString()
			return (
				<div class='form-check form-check-inline'>
					<input
						class={`form-check-input ${this.isLight ? 'form-check-input--light' : ''}`}
						type='checkbox'
						id={id}
						checked={this.text}
						vOn:change={this.onCheckboxChange}
					/>
					<label class='form-check-label' for={id}>
						{this.title}
						{$loading}
					</label>
				</div>
			)
		},

		renderDate() {
			let value = undefined
			try {
				value = format(new Date(this.value), 'yyy-MM-dd')
			} catch (e) {}

			return (
				<div class='input2'>
					<input
						type='date'
						class='form-control'
						style='padding: 0; border: none'
						value={value}
						vOn:change={(e) => {
							if (!e.target.value) return
							let [year, month, day] = e.target.value.split('-')
							this.$emit('change', new Date(year, month - 1, day))
						}}
					/>
				</div>
			)
		},

		renderNumber() {
			if (this.isEdit) {
				let cls = 'input2 input2__edit'
				let controlstyle = ''
				if (this.centered) controlstyle = 'position: absolute; right: 0;'
				let $control = (
					<div style={controlstyle}>
						<Icon name='circle-check' size='18' vOn:click_stop={this.save} stroke='2' class='input2__icon__save' />
						<Icon name='circle-x' vOn:click_stop={this.cancel} size='18' stroke='2' class='input2__icon__close' />
					</div>
				)

				if (this.loading) {
					cls = 'input2 input2__loading'
					$control = (
						<div class='d-flex align-items-center' style={controlstyle}>
							<Icon name='loader' vOn:click_stop={this.cancel} size='18' stroke='2' class='input2__icon__loader' />
						</div>
					)
				}

				return (
					<div class={cls} v-dblclickaway={this.cancel} v-clickaway={this.onClickaway}>
						<input
							vOn:focus={(_) => this.$emit('focus')}
							readonly={this.loading}
							type='number'
							class={{
								input2__input: true,
								text__center: this.centered,
							}}
							vOn:keyup_enter={this.save}
							ref='input__control'
							vModel={this.text}
						/>
						{$control}
					</div>
				)
			}

			let $loading = null

			if (this.loading) {
				$loading = <Icon name='loader' vOn:click_stop={this.cancel} size='18' stroke='2' class='input2__icon__loader' />
			}

			let $text = this.text
			if (typeof this.text === 'number') {
				if (this.text % 1 === 0) {
					// INTEGER
					$text = accounting.formatNumber(this.text)
				} else {
					$text = accounting.formatNumber(this.text, 3, ',')
				}
			}
			if (!this.text) $text = <span class='input2__placeholder'>{this.$t('click_to_edit')}</span>

			let $verified = null
			if (this.verified_suffix) {
				$verified = (
					<img2
						src={require('../assets/img/user_verified.svg')}
						width='18'
						v-tooltip={this.$t('verified')}
						height='18'
						style='margin-bottom: 2px; margin-left: 3px'
					/>
				)
			}
			return (
				<div
					class={{
						input2: true,
						'justify-content-center': this.centered,
					}}
					vOn:click={this.showEdit}
				>
					<span class='input2__text' style={this.type === 'textarea' ? 'white-space: pre' : ''}>
						{$text} {$loading} {$verified}
					</span>
				</div>
			)
		},

		renderText() {
			if (this.isEdit) {
				let cls = 'input2 input2__edit'
				let controlstyle = ''
				if (this.centered) controlstyle = 'position: absolute; right: 0;'
				let $control = (
					<div style={controlstyle}>
						<Icon name='circle-check' size='18' vOn:click_stop={this.save} stroke='2' class='input2__icon__save' />
						<Icon name='circle-x' vOn:click_stop={this.cancel} size='18' stroke='2' class='input2__icon__close' />
					</div>
				)

				if (this.loading) {
					cls = 'input2 input2__loading'
					$control = (
						<div class='d-flex align-items-center' style={controlstyle}>
							<Icon name='loader' vOn:click_stop={this.cancel} size='18' stroke='2' class='input2__icon__loader' />
						</div>
					)
				}

				return (
					<div class={cls} v-dblclickaway={this.cancel} v-clickaway={this.onClickaway}>
						{this.type === 'textarea' ? (
							<textarea
								vOn:focus={(_) => this.$emit('focus')}
								readonly={this.loading}
								type='text'
								class='input2__input'
								vOn:keyup_enter={this.save}
								ref='input__control'
								vModel={this.text}
							/>
						) : (
							<input
								vOn:focus={(_) => this.$emit('focus')}
								readonly={this.loading}
								type='text'
								class={{
									input2__input: true,
									text__center: this.centered,
								}}
								vOn:keyup_enter={this.save}
								ref='input__control'
								vModel={this.text}
							/>
						)}
						{$control}
					</div>
				)
			}

			let $loading = null

			if (this.loading) {
				$loading = <Icon name='loader' vOn:click_stop={this.cancel} size='18' stroke='2' class='input2__icon__loader' />
			}

			let $text = this.text
			if (!this.text) $text = <span class='input2__placeholder'>{this.$t('click_to_edit')}</span>

			let $verified = null
			if (this.verified_suffix) {
				$verified = (
					<img2
						src={require('../assets/img/user_verified.svg')}
						width='18'
						v-tooltip={this.$t('verified')}
						height='18'
						style='margin-bottom: 2px; margin-left: 3px'
					/>
				)
			}
			return (
				<div
					class={{
						input2: true,
						'justify-content-center': this.centered,
					}}
					vOn:click={this.showEdit}
				>
					<span class='input2__text' style={this.type === 'textarea' ? 'white-space: pre' : ''}>
						{$text} {$loading} {$verified}
					</span>
				</div>
			)
		},

		save() {
			this.$emit('change', this.text)
			this.isEdit = false
		},

		onClickaway() {
			if (this.text === this.value) this.isEdit = false
		},
		cancel() {
			if (this.loading) return
			this.text = this.value
			this.isEdit = false
		},
		showEdit() {
			if (this.loading) return
			if (getSelection().toString()) return
			this.isEdit = true
			this.$nextTick(() => {
				this.$refs['input__control'].select()
			})
		},
		onEsc(e) {
			if (e.keyCode != 27) return
			this.cancel()
		},
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.onEsc)
	},
	mounted() {
		window.addEventListener('keydown', this.onEsc)
	},
}
