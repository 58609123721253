import {format} from 'date-fns'
import {getDateFnsLocale} from '../../languages.js'

export default {
	name: 'form-submited-event-card',
	props: ['ev'],

	mounted() {
		store.onAccount(this, (payload) => {
			if (lo.get(payload, 'object_type') === 'web_plugin') {
				console.log('onAcctttttttttt', payload)
				this.$forceUpdate()
			}
		})
	},

	methods: {
		renderFormSubmittedField(field, form) {
			let keyName = field.key
			let formId = form.form_id || ''
			let convo = store.matchConvo(this.cid)
			let locale = lo.get(convo, 'locale', 'vi-VN')
			locale = locale.replace('-', '_')

			let $input = <span>{field.value}</span>
			if (!field.value || field.value === 'undefined') $input = <em class='text__muted'>{this.$t('no_input')}</em>

			if (formId.startsWith('chatbox')) {
				let chatbox = lo.find(store.matchWebPlugin(), (wp) => wp.type === 'chatbox') || {}
				let chatboxFields = lo.get(chatbox, 'chatbox.prechat_form.fields', [])
				let questionField = lo.get(chatbox, 'chatbox.prechat_form.i18n_question_label')
				let questionTitle = lo.get(chatbox, 'chatbox.prechat_form.question_label')
				let groupField = lo.get(chatbox, 'chatbox.prechat_form.i18n_group_label') || {}
				let groupItems = lo.get(chatbox, 'chatbox.prechat_form.groups') || []
				if (formId === 'chatbox_offline') {
					chatboxFields = lo.get(chatbox, 'chatbox.offline_form.fields', [])
					questionField = lo.get(chatbox, 'chatbox.offline_form.i18n_question_label')
					questionTitle = lo.get(chatbox, 'chatbox.offline_form.question_label')
					groupField = lo.get(chatbox, 'chatbox.offline_form.i18n_group_label') || {}
					groupItems = lo.get(chatbox, 'chatbox.offline_form.groups') || []
				}
				let chatboxField = lo.find(chatboxFields, (f) => {
					return f.key === keyName || `attr:${f.key}` === keyName
				})
				if (chatboxField) {
					keyName = lo.get(chatboxField, ['i18n_label', locale])
				}
				if (field.key === 'question') {
					keyName = lo.get(questionField, locale)
					if (!keyName) keyName = questionTitle
					if (!keyName) {
						if (locale === 'vi_VN') keyName = 'Câu hỏi của bạn'
						else keyName = 'Your question'
					}
				}
				if (field.key === 'group') {
					keyName = lo.get(groupField, locale)
					let v = sb.parseJSON(field.value)
					let groupItem = lo.find(groupItems, (gr) => gr.id === v)
					$input = (
						<span>
							{'"'}
							{lo.get(groupItem, 'name') || v}
							{'"'}
						</span>
					)
				}

				if (!keyName) keyName = field.key
			}

			return (
				<div>
					{keyName}: {$input}
				</div>
			)
		},
	},

	render() {
		let ev = this.ev
		let bytype = 'user'
		let form = lo.get(ev, 'data.form_submission', {})
		let formId = form.form_id || ''

		const popup = store.matchWebPlugin()[formId]
		let action = this.$t('submitted_form').toLowerCase()
		let content = this.$t('with_content')

		if (popup && popup.type === 'notification') {
			action = this.$t('clicked') + ' ' + this.$t('order_notification').toLowerCase()
			content = ''
		}
		let fields = lo.get(form, 'values', [])
		let msgcls = `message_payment_box message_payment_box__${bytype} text__sm`
		let formName = ''

		if (formId.startsWith('chatbox')) {
			formName = 'Prechat'
			if (formId === 'chatbox_offline') formName = 'Offline'
		} else {
			if (popup) {
				formName = (
					<router-link to={{name: 'popup', params: {id: formId}}} target='_blank'>
						{popup.name}
					</router-link>
				)
				if (popup.type === 'notification') {
					formName = (
						<router-link to={{name: 'notification', params: {id: formId}}} target='_blank'>
							{popup.name}
						</router-link>
					)
				}
			}
		}

		return (
			<div title={format(new Date(ev.created), 'HH:mm:ss, d MMM, yyyy', {locale: getDateFnsLocale()})}>
				<div class={msgcls} style='word-wrap: break-word;'>
					<div class='d-flex align-items-start mb-2'>
						<img
							src={require('../../assets/img/form.svg')}
							width='20'
							class='no-shrink mr-3'
							style='position: relative; top: 2px;'
						/>
						<div style='overflow:hidden;'>
							{this.$t('cust')} {action} {formName} {this.$t('at_page_url')}{' '}
							<a href={form.page_url} target='_blank' style='word-wrap: break-word; max-width:100%;'>
								{form.page_url}
							</a>{' '}
							{content}
						</div>
					</div>
					{lo.map(fields, (field) => {
						return this.renderFormSubmittedField(field, form)
					})}
				</div>
			</div>
		)
	},
}
