import common from './common.js'
import sb from '@sb/util'
import store from '@sb/store'
import pc from '../product/common.js'
import accounting from 'accounting'

export default {
	name: 'bot_generic_template_builder',
	props: ['message', 'bot', 'node', 'noBranching', 'locale'],

	data() {
		return {
			editing: {eleindex: '', btnindex: ''},

			draggingButton: null,
			buttons: [],

			focusing: 0, // element index

			draggingElement: undefined,
			elements: [],

			uploadingIndex: 0, // element index

			// search product
			searchProductDrodownIndex: -1,
			searchValue: '',
			searching: false,
			products: [],
			p: {}, // use to avoid late render
		}
	},

	mounted() {
		document.body.addEventListener('keyup', this.onKeyup)
	},

	destroyed() {
		document.body.removeEventListener('keyup', this.onKeyup)
	},

	methods: {
		onKeyup(e) {
			let keyCode = e.keyCode
			// ESC
			if (keyCode === 27) this.closeSearchProductDropdown()
		},

		addElement() {
			let locales = lo.get(this.bot, 'locales')
			if (!locales) locales = [lo.get(store.me(), 'account.locale')]
			let i18n_title = {}
			let i18n_subtitle = {}
			let btn_i18n_title = {}
			lo.each(locales, (locale) => {
				if (locale === 'vi-VN') {
					i18n_title.vi_VN = 'Tên sản phẩm'
					i18n_subtitle.vi_VN = 'Mô tả sản phẩm'
					btn_i18n_title.vi_VN = 'Xem trên website'
				} else {
					i18n_title[locale.replace('-', '_')] = 'Product name'
					i18n_subtitle[locale.replace('-', '_')] = 'Product description'
					btn_i18n_title[locale.replace('-', '_')] = 'View in website'
				}
			})
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let elements = lo.get(gen, 'elements', [])

			elements.push({
				i18n_title,
				title: 'Tên sản phẩm',
				// image_url: 'https://didongthongminh.vn/upload_images/2018/04/iphone-6.jpg',
				i18n_subtitle,
				subtitle: 'Mô tả sản phẩm',
				buttons: [{type: 'url_button', title: 'Xem trên website', i18n_title: btn_i18n_title}],
			})
			this.$emit('change', message)
			setTimeout(() => this.focus(elements.length - 1), 300)
		},

		addElementBtn(eleindex) {
			let locales = lo.get(this.bot, 'locales')
			if (!locales) locales = [lo.get(store.me(), 'account.locale')]
			let i18n_title = {}
			lo.each(locales, (locale) => {
				if (locale === 'vi-VN') {
					i18n_title.vi_VN = 'Nút'
				} else {
					i18n_title[locale.replace('-', '_')] = 'Button'
				}
			})
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let buttons = lo.get(gen, 'elements.' + eleindex + '.buttons', [])
			buttons.push({type: 'url_button', title: 'Nút', i18n_title, url: ''})
			lo.set(gen, 'elements.' + eleindex + '.buttons', buttons)

			this.editing = {eleindex: eleindex, btnindex: buttons.length - 1}
			this.$emit('change', message)
		},

		onChangeButtonPhoneNumber(e) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let button = lo.get(gen, 'elements.' + this.editing.eleindex + '.buttons.' + this.editing.btnindex)
			button.phone_number = e.target.value
			this.$emit('change', message)
		},

		async onChangeButtonTitle(e) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let element = lo.get(gen, 'elements.' + this.editing.eleindex)
			let button = lo.get(element, 'buttons.' + this.editing.btnindex)

			let payload = lo.get(button, 'payload')
			payload = sb.parseJSON(payload)
			lo.set(payload, 'title', e.target.value)
			button.payload = JSON.stringify(payload)
			lo.set(button, `i18n_title.${this.getLocale()}`, e.target.value)
			//button.title = e.target.value
			this.$emit('change', message)
		},

		onChangeButtonWebviewSize(size) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let button = lo.get(gen, 'elements.' + this.editing.eleindex + '.buttons.' + this.editing.btnindex)
			button.webview_size = size
			this.$emit('change', message)
		},

		onChangeButtonUrl(e) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let button = lo.get(gen, 'elements.' + this.editing.eleindex + '.buttons.' + this.editing.btnindex)
			button.url = e.target.value
			this.$emit('change', message)
		},

		clearFile(e) {
			e.target.value = ''
		},

		async uploadImage(e) {
			let index = this.uploadingIndex
			let MAXIMUM_SIZE = 5 * 1024 * 1024

			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (file.size > MAXIMUM_SIZE) return this.$showError(this.$t('error_file_is_too_large'))

			let id = sb.randomString(20)
			let tempUrl = await sb.getBlobUrlFromFile(file)

			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			gen.elements[index].image_url = tempUrl
			gen.elements[index]._local_id = id
			gen.elements[index]._loading = true
			this.$emit('change', message)

			let res = await store.uploadLocalFile(file)
			if (res.error) {
				this.$emit('error', id)
				return this.$showError(res.error)
			}

			message = lo.cloneDeep(this.message)
			gen = lo.get(message, 'attachments.0')

			// make sure msg is still our
			if (lo.get(gen, 'elements.' + index + '._local_id') !== id) return

			gen.elements[index].image_url = res.url
			delete gen.elements[index]._local_id
			delete gen.elements[index]._loading
			lo.set(gen, `elements.${index}.id`, '')

			let buttons = lo.get(gen, `elements.${index}.buttons`, [])
			lo.map(buttons, (button) => {
				if (button.type !== 'postback_button') return
				let payload = sb.parseJSON(button.payload) || {}
				if (payload.type !== 'add_to_cart') return
				delete payload.product_id
				button.payload = JSON.stringify(payload)
			})

			this.$emit('change', message)
		},

		changeElementBackground(index) {
			this.uploadingIndex = index
			this.$refs.image_input.click()
		},

		focus(eleindex) {
			this.focusing = eleindex
			let $gallery = this.$refs.gallery
			$gallery && $gallery.scrollTo({left: 280 * eleindex - 30, behavior: 'smooth'})
		},

		async removeElement(index) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let elements = gen.elements || []
			elements.splice(index, 1)
			if (elements.length === 0) {
				this.$emit('remove')
				return
			}
			this.$emit('change', message)

			// somehow lost focus when last element is deleted
			if (index > 0) {
				await this.$nextTick()
				this.focus(lo.size(elements) - 1)
			}
		},

		removeElementBtn(eleindex, btnindex) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')

			gen.elements[eleindex].buttons.splice(btnindex, 1)
			this.$emit('change', message)
		},

		editBtn(eleindex, btnindex) {
			this.editing = {eleindex, btnindex}
		},

		resetEditing() {
			this.editing = {eleindex: '', btnindex: ''}
		},

		async doSearch(text = '') {
			this.searchValue = text
			//if (this.searching) return
			let p = {
				query: text,
			}
			this.p = lo.cloneDeep(p)
			this.searching = true
			let out = await store.listProducts(p)
			if (!lo.isEqual(this.p, p)) {
				this.searching = false
				return
			}
			let products = lo.get(out, 'products', [])
			this.products = lo.take(products, 10)
			this.searching = false
		},

		toggleSearchProductDropdown(e, eindex) {
			e.stopPropagation()
			if (this.searchProductDrodownIndex > -1) {
				this.closeSearchProductDropdown()
				return
			}

			this.searchProductDrodownIndex = eindex
			this.searchValue = ''
			this.doSearch('')
		},

		onClickResultProduct(product, eindex) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')

			let i18n_title = {}
			let i18n_subtitle = {}
			let locales = lo.get(this.bot, 'locales')
			if (!locales) locales = lo.get(store.me(), 'account.supported_locales', ['vi-VN'])
			lo.each(locales, (locale) => {
				locale = locale.replace('-', '_')
				i18n_title[locale] = lo.get(product, `name.${locale}`) || product.name
				i18n_subtitle[locale] = accounting.formatNumber(product.price)
			})
			lo.set(gen, `elements.${eindex}.title`, product.name)
			lo.set(gen, `elements.${eindex}.i18n_title`, i18n_title)
			lo.set(gen, `elements.${eindex}.subtitle`, accounting.formatNumber(product.price))
			lo.set(gen, `elements.${eindex}.i18n_subtitle`, i18n_subtitle)
			lo.set(gen, `elements.${eindex}.image_url`, product.image)
			lo.set(gen, `elements.${eindex}.id`, product.id)

			let buttons = lo.get(gen, `elements.${eindex}.buttons`, [])
			let btnindex = lo.findIndex(buttons, (button) => button.type === 'url_button')
			if (btnindex > -1 && product.url) lo.set(gen, `elements.${eindex}.buttons.${btnindex}.url`, product.url)

			let found = lo.find(buttons, (button) => button.type === 'postback_button')
			if (lo.size(buttons) < 3 && !found && this.node) {
				let branches = common.listBranches(this.node)
				// find the first empty node, just not the last node
				let firstempty = lo.find(this.node.action.nexts, (next, i) => {
					let found = lo.find(branches, (branch) => lo.get(next, 'action.id') == branch.action_id)
					if (!found && i != this.node.action.nexts.length - 1) return true
					return false
				})

				let payload = {
					type: 'add_to_cart',
					product_id: product.id,
					bot_id: this.bot.id,
					from_action: lo.get(this.node, 'action.id'),
					action_id: lo.get(firstempty, 'action.id'),
				}
				let locales = lo.get(this.bot, 'locales', [])
				let postback_button_i18n_title = {}
				lo.each(locales, (locale) => {
					if (locale === 'vi-VN') {
						postback_button_i18n_title.vi_VN = '🛒 Mua hàng'
					} else {
						postback_button_i18n_title[locale.replace('-', '_')] = '🛒 Purchase'
					}
				})
				let btn = {
					type: 'postback_button',
					i18n_title: postback_button_i18n_title,
					payload: JSON.stringify(payload),
				}
				lo.set(gen, `elements.${eindex}.buttons.${lo.size(buttons)}`, btn)
			}

			lo.map(buttons, (button) => {
				if (!this.node) return
				if (button.type !== 'postback_button') return
				let payload = sb.parseJSON(button.payload) || {}
				if (payload.type !== 'add_to_cart') return
				payload.product_id = product.id
				button.payload = JSON.stringify(payload)
			})

			this.$emit('change', message)
			this.searchProductDrodownIndex = -1

			// this update all postback
		},

		closeSearchProductDropdown() {
			this.searchProductDrodownIndex = -1
		},

		renderElementSearchProduct(eindex) {
			let products = this.products

			let $content = (
				<div class='d-flex justify-content-center pt-5 w_100 h_100'>
					<div class='text__center'>
						<img src={require('../assets/img/empty_1.svg')} width='80' height='80' />
						<br />
						<small class='text__muted'>{this.$t('no_search_product_result')}</small>
					</div>
				</div>
			)
			if (lo.size(products)) {
				$content = lo.map(products, (product) => (
					<div
						class='dropdown__item dropdown__item__thin dropdown__item__hover-light'
						vOn:click={(_) => this.onClickResultProduct(product, eindex)}
					>
						<div class='d-flex' style='overflow: hidden'>
							<ProductAvatar class='product_res__img' product={product} />
							<div style='flex: 1; overflow: hidden' class='text__left'>
								<div class='text__truncate'>{pc.getProductNameWithProp(product)}</div>
								<div class='product_res__price'>{accounting.formatNumber(product.price)}</div>
							</div>
						</div>
					</div>
				))
			}

			return (
				<div style='position: relative;' v-clickaway={this.closeSearchProductDropdown}>
					<div class='generic_template_img_btn' vOn:click={(e) => this.toggleSearchProductDropdown(e, eindex)}>
						<Icon name='shopping-bag' size='1x' class='mr-3' /> {this.$t('select_product')}
					</div>
					<div
						class='dropdown generic_template_search_product_dropdown'
						vOn:click={(e) => e.stopPropagation()}
						style={this.searchProductDrodownIndex !== eindex ? 'display: none' : ''}
					>
						<div style='padding: 10px'>
							<InputSearch
								value={this.searchValue}
								vOn:change={this.doSearch}
								placeholder={this.$t('search_product_by_name_id_sku')}
								loading={this.searching}
							/>
						</div>
						<div style='flex: 1; overflow: auto'>{$content}</div>
					</div>
				</div>
			)
		},

		renderElement(ele, index) {
			let $loading = null
			if (ele._loading) {
				$loading = (
					<div style='position: absolute; top: 0; left:0 ;right: 0; bottom: 0; background: black; opacity: 0.8; display: flex; align-items: center; justify-content: center'>
						<Spinner size='40' />
					</div>
				)
			}

			let cls = 'generic-attachment--element generic-attachment--element--edit'
			if (this.focusing !== index) {
				cls += ' generic-attachment--element--fade'
			}

			let $producttag = null

			if (ele.id) {
				$producttag = (
					<div class='generic_template__product_tag' style='position: absolute; bottom: 10px; left: 10px'>
						<Icon name='package' size='16' stroke-width='1.5' style='margin-top: -2px' />
						&nbsp; {this.$t('product')} {ele.id}
					</div>
				)
			}
			return (
				<div class={cls}>
					<div class='generic_template__element_img' vOn:click={(_) => this.focus(index)}>
						<img2 draggable='false' class='generic-attachment--element-img' src={sb.replaceFileUrl(ele.image_url)} />
						{$producttag}

						<div class='generic_template_img_overlay'>
							<div class='text__center d-flex align-items-center flex-column'>
								{this.renderElementSearchProduct(index)}
								<div
									class='generic_template_img_btn'
									style='margin-top: 15px'
									vOn:click={(_) => this.changeElementBackground(index)}
								>
									<Icon name='photo' size='1x' class='mr-3' /> {this.$t('change_background')}
								</div>
							</div>
							<Icon
								name='trash'
								size='15'
								class='generic_template_img_trash_icon'
								v-tooltip={this.$t('delete')}
								vOn:click_stop={(_) => this.removeElement(index)}
							/>
						</div>
						{$loading}
					</div>
					<div class='flex__1'>
						<input
							class='generic-attachment--element-title-input'
							vOn:input={(e) => this.changeElementTitle(index, e)}
							value={lo.get(ele, `i18n_title.${this.getLocale()}`)}
						/>
						<input
							class='generic-attachment--element-subtitle-input'
							vOn:input={(e) => this.changeElementSubtitle(index, e)}
							value={lo.get(ele, `i18n_subtitle.${this.getLocale()}`)}
						/>
					</div>
					{this.renderButtons(ele.buttons, index)}
				</div>
			)
		},

		getLocale() {
			return this.locale.replace('-', '_')
		},

		getFallbackLocale() {
			let locale = lo.get(store, 'account.locale', 'vi-VN')
			return locale.replace('-', '_')
		},

		async changeElementTitle(index, e) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')

			lo.set(gen, `elements.${index}.i18n_title.${this.getLocale()}`, e.target.value)
			//gen.elements[index].title = e.target.value

			this.$emit('change', message)
		},

		changeElementSubtitle(index, e) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			lo.set(gen, `elements.${index}.i18n_subtitle.${this.getLocale()}`, e.target.value)
			//gen.elements[index].subtitle = e.target.value

			this.$emit('change', message)
		},

		changeBtnType(type, payloadtype) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			let element = lo.get(gen, 'elements.' + this.editing.eleindex)
			let button = lo.get(element, 'buttons.' + this.editing.btnindex)
			button.type = type

			if (type == 'postback_button') {
				let branches = common.listBranches(this.node)
				// find the first empty node, just not the last node
				let firstempty = lo.find(this.node.action.nexts, (next, i) => {
					let found = lo.find(branches, (branch) => lo.get(next, 'action.id') == branch.action_id)
					if (!found && i != this.node.action.nexts.length - 1) return true
					return false
				})

				let payload = {
					type: payloadtype,
					bot_id: this.bot.id,
					from_action: lo.get(this.node, 'action.id'),
					action_id: lo.get(firstempty, 'action.id'),
				}
				if (payloadtype === 'add_to_cart') {
					payload.product_id = element.id
				}
				button.payload = JSON.stringify(payload)
			}
			this.$emit('change', message)
		},

		renderEditingBtn() {
			let gen = lo.get(this.message, 'attachments.0')
			let element = lo.get(gen, 'elements.' + this.editing.eleindex)
			let button = lo.get(element, 'buttons.' + this.editing.btnindex)

			let $branchingBtn = null
			let $addToCartBtn = null
			if (!this.noBranching) {
				let payload = sb.parseJSON(button.payload) || {}
				let branchingcls = `radio-button radio-button--primary ${
					button.type === 'postback_button' && payload.type == 'bot_jump' && 'radio-button--active'
				}`
				$branchingBtn = (
					<label style='flex: 1' for='btn_type_postback_bot_jump' v-tooltip={this.$t('branching')} class={branchingcls}>
						<Icon name='git-merge' class='radio-button__icon' />
						<input
							type='radio'
							id='btn_type_postback_bot_jump'
							name='button_type'
							checked={button.type === 'postback_button' && payload.type == 'bot_jump'}
							vOn:change={(_) => this.changeBtnType('postback_button', 'bot_jump')}
						/>
					</label>
				)

				let addtocartcls = `radio-button radio-button--primary ${
					button.type === 'postback_button' && payload.type == 'add_to_cart' && 'radio-button--active'
				}`
				$addToCartBtn = (
					<label
						style='flex: 1'
						v-tooltip={this.$t('button_add_to_cart')}
						for='btn_type_postback_add_to_cart'
						class={addtocartcls}
					>
						<Icon name='shopping-bag' class='radio-button__icon' />
						<input
							type='radio'
							id='btn_type_postback_add_to_cart'
							name='button_type'
							checked={button.type === 'postback_button' && payload.type == 'add_to_cart'}
							vOn:change={(_) => this.changeBtnType('postback_button', 'add_to_cart')}
						/>
					</label>
				)
			}

			let $type = (
				<fragment>
					<div class='generic_template_button_edit_label'>{this.$t('button_type')}</div>
					<div class='radio-btn-group'>
						<label
							v-tooltip={this.$t('url_button')}
							style='flex: 1'
							for='btn_type_url'
							class={`radio-button radio-button--primary ${button.type === 'url_button' && 'radio-button--active'}`}
						>
							<Icon name='link' class='radio-button__icon' />
							<input
								type='radio'
								id='btn_type_url'
								name='button_type'
								checked={button.type === 'url_button'}
								vOn:change={(_) => this.changeBtnType('url_button')}
							/>
						</label>
						{$branchingBtn}
						{$addToCartBtn}
						<label
							style='flex: 1'
							v-tooltip={this.$t('call_button')}
							for='btn_type_call'
							class={`radio-button radio-button--primary ${button.type === 'call_button' && 'radio-button--active'}`}
						>
							<Icon name='phone' class='radio-button__icon' />
							<input
								type='radio'
								id='btn_type_call'
								name='button_type'
								checked={button.type === 'call_button'}
								vOn:change={(_) => this.changeBtnType('call_button')}
							/>
						</label>
					</div>
				</fragment>
			)

			let $content = null
			let btntitle = lo.get(button, `i18n_title.${this.getLocale()}`)
			if (!btntitle) btntitle = lo.get(button, `i18n_title.${this.getFallbackLocale()}`)
			if (button.type === 'url_button') {
				$content = (
					<fragment>
						<div class='generic_template_button_edit_label'>URL</div>
						<input
							class='form-control'
							vOn:change={(e) => this.onChangeButtonUrl(e)}
							value={button.url}
							placeholder={'https://subiz.com.vn/blog/vi/'}
						/>

						<div class='generic_template_button_edit_label'>{this.$t('webview_size')}</div>
						<div class='radio-btn-group'>
							<label
								style='flex: 1'
								for='webview_size_compact'
								class={`radio-button radio-button--primary ${
									button.webview_size !== 'full' && button.webview_size !== 'tall' && 'radio-button--active'
								}`}
							>
								<span style='font-size: 12px'> {this.$t('compact')}</span>
								<input
									type='radio'
									id='webview_size_compact'
									name='webview_size'
									checked={button.webview_size !== 'full' && button.webview_size !== 'tall'}
									vOn:change={(_) => this.onChangeButtonWebviewSize('compact')}
								/>
							</label>

							<label
								style='flex: 1'
								for='webview_size_tall'
								class={`radio-button radio-button--primary ${button.webview_size === 'tall' && 'radio-button--active'}`}
							>
								<span style='font-size: 12px'> {this.$t('tall')}</span>
								<input
									type='radio'
									id='webview_size_tall'
									name='webview_size'
									checked={button.webview_size === 'tall'}
									vOn:change={(_) => this.onChangeButtonWebviewSize('tall')}
								/>
							</label>

							<label
								style='flex: 1'
								for='webview_size_full'
								class={`radio-button radio-button--primary ${button.webview_size === 'full' && 'radio-button--active'}`}
							>
								<span style='font-size: 12px'> {this.$t('full')}</span>
								<input
									type='radio'
									id='webview_size_full'
									name='webview_size'
									checked={button.webview_size === 'full'}
									vOn:change={(_) => this.onChangeButtonWebviewSize('full')}
								/>
							</label>
						</div>
					</fragment>
				)
			}

			if (button.type === 'postback_button') {
				let payload = sb.parseJSON(button.payload) || {}
				if (payload.type == 'bot_jump') {
					$content = <div class='mt-3 text__muted'>{this.$t('bot_jump_button_desc')}</div>
				} else if (payload.type == 'add_to_cart') {
					if (payload.product_id) {
						$content = <div class='mt-3 text__muted'>{this.$t('bot_add_to_cart_button_desc')}</div>
					} else $content = <div class='mt-3 text__danger'>{this.$t('select_a_product_add_to_cart_work')}</div>
				}
			}

			if (button.type === 'call_button') {
				$content = (
					<fragment>
						<div class='generic_template_button_edit_label'>
							{this.$t('example_phone_number')} <Icon name='phone' size='1x' />
						</div>
						<input
							class='form-control'
							vOn:change={(e) => this.onChangeButtonPhoneNumber(e)}
							value={button.phone_number}
							placeholder={this.$t('setting_agent_edit_phone_number_placeholder')}
						/>
					</fragment>
				)
			}

			return (
				<div
					class='generic_template_button generic_template_button__edit'
					key={this.editing.btnindex}
					draggable='false'
					v-clickaway={this.resetEditing}
				>
					<div class='d-flex align-items-center'>
						<div class='' style='flex: 1'>
							<b>{this.$t('edit_button')}</b>
						</div>
						<div class='btn__sm btn btn__success' vOn:click={this.resetEditing} v-tooltip={this.$t('done')}>
							<Icon name='check' size='15' stroke-width='2' />
						</div>
					</div>
					<div class='generic_template_button_edit_label' style='flex: 1'>
						{this.$t('title')}
					</div>
					<input class='form-control' vOn:input={(e) => this.onChangeButtonTitle(e)} value={btntitle} />
					{$type}
					{$content}
				</div>
			)
		},

		dragButtonStart(buttons, eleindex, btnindex) {
			this.draggingButton = {eleindex, btnindex}
			this.buttons = [].concat(buttons) // just copy array, not clone deep
		},

		onDragButtonEnd(eleindex) {
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			lo.set(gen, 'elements.' + eleindex + '.buttons', this.buttons)
			this.$emit('change', message)
			this.draggingButton = undefined
		},

		onDragButtonEnter(eleindex, btnindex) {
			if (!this.draggingButton) return
			if (eleindex !== this.draggingButton.eleindex) return

			var fromindex = this.draggingButton.btnindex
			var draggingbtn = this.buttons[fromindex]
			var toindex = btnindex
			if (fromindex === toindex) return this.$forceUpdate()

			if (fromindex < toindex) {
				for (let i = fromindex; i < toindex; i++) {
					this.buttons[i] = this.buttons[i + 1]
				}
				this.buttons[toindex] = draggingbtn
			} else {
				for (let i = fromindex; i > toindex; i--) {
					this.buttons[i] = this.buttons[i - 1]
				}
				this.buttons[toindex] = draggingbtn
			}

			this.draggingButton.btnindex = toindex
		},

		onMouseOverBranch(button, index) {
			if (button.type !== 'postback_button') return
			this.$emit('quickReplyOver', index)
		},

		onMouseLeaveBranch() {
			this.$emit('quickReplyOver', undefined)
		},

		renderButtons(buttons, eleindex) {
			if (lo.get(this.draggingButton, 'eleindex') === eleindex) buttons = this.buttons // use button order while dragging

			var $buttons = lo.map(buttons, (button, index) => {
				if (eleindex === this.editing.eleindex && index === this.editing.btnindex) return this.renderEditingBtn()
				return (
					<div
						class='generic_template_button'
						key={index}
						draggable='true'
						vOn:dragstart={(ev) => this.dragButtonStart(buttons, eleindex, index)}
						vOn:dragenter={(ev) => this.onDragButtonEnter(eleindex, index)}
						vOn:dragend={(_) => this.onDragButtonEnd(eleindex)}
						vOn:mouseover={(_) => this.onMouseOverBranch(button, 'ele_' + eleindex + '_' + index)}
						vOn:mouseleave={this.onMouseLeaveBranch}
					>
						<div class='generic_template_button_handler'>
							<svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<g id='icon/action/drag_indicator_24px'>
									<path
										id='icon/action/drag_indicator_24px_2'
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM7 12C7 10.9 7.9 10 9 10C10.1 10 11 10.9 11 12C11 13.1 10.1 14 9 14C7.9 14 7 13.1 7 12ZM9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20ZM17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM13 18C13 16.9 13.9 16 15 16C16.1 16 17 16.9 17 18C17 19.1 16.1 20 15 20C13.9 20 13 19.1 13 18Z'
										fill='black'
									/>
								</g>
							</svg>
						</div>
						<div class='generic_template_button_title text__truncate'>
							{lo.get(button, `i18n_title.${this.getLocale()}`)}
						</div>
						<Icon
							name='edit'
							size='18'
							stroke-width='2'
							class='generic_template_button__btn'
							vOn:click={(_) => this.editBtn(eleindex, index)}
							v-tooltip={this.$t('edit')}
						/>
						<Icon
							name='trash'
							stroke-width='2'
							size='18'
							class='generic_template_button__btn'
							vOn:click={(_) => this.removeElementBtn(eleindex, index)}
							v-tooltip={this.$t('delete')}
						/>
					</div>
				)
			})

			if ($buttons.length < 3) {
				$buttons.push(
					<div class='generic_template_button' key={-1}>
						<div
							class='generic_template_button_title generic_template_button_title__add'
							vOn:click={(_) => this.addElementBtn(eleindex)}
						>
							<Icon name='plus' size='1x' style='margin-top: -3px; margin-right: 5px;' />
							{this.$t('add_more_button')}
						</div>
					</div>,
				)
			}

			return <div class='message_button'>{$buttons}</div>
		},

		dragEleStart(elements, ele) {
			this.draggingElement = ele
			this.elements = [].concat(elements) // just copy array, not clone deep
		},

		onDragEleEnd() {
			this.draggingElement = undefined
			let message = lo.cloneDeep(this.message)
			let gen = lo.get(message, 'attachments.0')
			lo.set(gen, 'elements', this.elements)
			this.$emit('change', message)
		},

		onDragEleEnter(ele) {
			if (!this.draggingElement) return

			var fromindex = -1
			var toindex = -1
			for (let i = 0; i < this.elements.length; i++) {
				if (this.elements[i] === ele) toindex = i
				if (this.elements[i] === this.draggingElement) fromindex = i
			}
			if (fromindex === toindex) return this.$forceUpdate()

			if (fromindex < toindex) {
				for (let i = fromindex; i < toindex; i++) {
					this.elements[i] = this.elements[i + 1]
				}
				this.elements[toindex] = this.draggingElement
			} else {
				for (let i = fromindex; i > toindex; i--) {
					this.elements[i] = this.elements[i - 1]
				}
				this.elements[toindex] = this.draggingElement
			}
			this.$forceUpdate()
		},

		renderElementsSummary() {
			let elements = lo.get(this.message, 'attachments.0.elements', [])
			if (this.draggingElement) elements = this.elements // use button order while dragging

			var $thumbnails = lo.map(elements, (ele, i) => {
				let cls = 'generic_template_summary_img'
				if (this.focusing === i) cls += ' generic_template_summary_img__active'
				return (
					<div
						draggable='true'
						vOn:click={(_) => this.focus(i)}
						key={i}
						vOn:dragstart={(ev) => this.dragEleStart(elements, ele)}
						vOn:dragenter={(ev) => this.onDragEleEnter(ele)}
						vOn:dragend={(_) => this.onDragEleEnd()}
					>
						<img2 class={cls} src={sb.replaceFileUrl(ele.image_url)} />
					</div>
				)
			})

			if ($thumbnails.length < 10) {
				$thumbnails.push(
					<div vOn:click={this.addElement}>
						<div class='generic_template_summary_img generic_template_summary_img__add'>+</div>
					</div>,
				)
			}
			return <div class='generic_template_summary'>{$thumbnails}</div>
		},
	},

	render() {
		let gen = lo.get(this.message, 'attachments.0')
		if (lo.get(gen, 'type') !== 'generic') return null
		let elements = lo.get(this.message, 'attachments.0.elements', [])

		let leftNavigatorCls = 'generic-attachment-navigator generic-attachment-navigator--left'
		let rightNavigatorCls = 'generic-attachment-navigator generic-attachment-navigator--right'
		if (this.focusing === 0) leftNavigatorCls += ' generic-attachment-navigator--disabled'
		if (this.focusing === elements.length - 1) rightNavigatorCls += ' generic-attachment-navigator--disabled'

		var $eles = lo.map(gen.elements, (ele, i) => this.renderElement(ele, i))
		return (
			<div style='position: relative'>
				<input
					ref='image_input'
					type='file'
					vOn:click={this.clearFile}
					vOn:change={this.uploadImage}
					accept='image/*'
					style='display: none;'
				/>
				<div class={leftNavigatorCls} vOn:click={(_) => this.focus(this.focusing - 1)}>
					<span>&#8249;</span>
				</div>
				<div class={rightNavigatorCls} vOn:click={(_) => this.focus(this.focusing + 1)}>
					<span>&#8250;</span>
				</div>
				<div class='generic-attachment' ref='gallery'>
					{$eles}
				</div>
				{this.renderElementsSummary()}
			</div>
		)
	},
}
