const sb = require('@sb/util')
var api = require('./api.js')

import InMemKV from './inmem_kv.js'

function NewLiveStore(convoStore, realtime, pubsub) {
	let kv = new InMemKV()
	kv.init()

	var me = {}

	me.onLive = (o, cb) => pubsub.on2(o, 'live', cb)

	me.destroy = () => kv.destroy()
	let viewid = 'lv' + sb.randomString(10)

	me.fetchLiveUsers = async (filter) => {
		let {agent_id} = api.getCred()
		let account_id = api.getAccountId()
		realtime.subscribe(['live_users_updated.account.' + account_id + '.agent.' + agent_id + '.view.' + viewid]) // ignore result
		filter = Object.assign({limit: 100}, filter, {id: viewid})

		let {body, error} = await api.filterLiveUsers(filter)
		if (error) return [undefined, error]

		let userids = body.user_ids || []
		await convoStore.fetchUsers(userids)
		// load convo
		let userconvos = {}
		return Object.assign({}, body, {convos: userconvos})
	}

	realtime.onEvent(async (ev) => {
		if (!ev || ev.type != 'live_users_updated') return
		let body = lo.get(ev, 'data.live_user_view', {})

		let userids = body.user_ids || []
		await convoStore.fetchUsers(userids)
		// if (body.id != viewid) return
		pubsub.publish('live', body)
	})
	return me
}

export default NewLiveStore
