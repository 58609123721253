const sb = require('@sb/util')
var api = require('./api.js')
const flow = require('@subiz/flow')
import KV from './kv.js'
import InMemKV from './inmem_kv.js'
import NewObjectStore from './object_store.js'
const config = require('@sb/config')
import {startOfDay} from 'date-fns'

function TaskStore(realtime, pubsub) {
	let kv = new InMemKV()
	kv.init()

	let dead = false
	let {agent_id} = api.getCred()
	let account_id = api.getAccountId()

	let ls = new KV(config.db_prefix + account_id + '.tasks_sort_by')
	ls.init()

	let $audio
	if (window.Audio) $audio = new Audio(require('../src/assets/media/Ting-Notification-Sound.mp3'))
	let myOverdueFilter = {status: 'open', assignee: agent_id, is_overdue: true}
	let remindedFilter = {id: 'reminder', status: 'open', assignee: agent_id}

	let me = {}
	me.destroy = () => {
		dead = true
		ls.destroy()
	}
	let db = NewObjectStore(
		account_id,
		realtime,
		pubsub,
		'task',
		async (tasks) => {
			let ids = lo.map(tasks, 'id')
			let last_modifieds = lo.map(tasks, (task) => task.actived || 0)
			let {body, code, error} = await api.match_tasks(ids, last_modifieds)
			if (error || code !== 200) return {error}
			let out = lo.get(body, 'tasks', [])
			let ts = []
			lo.map(out, (task) => {
				let i = lo.findIndex(ids, (id) => task.id == id)
				if (i == -1) return
				if (lo.size(task) < 2) delete task.id // no updates, must delete id to tell object store
				ts[i] = task
			})

			return {data: ts}
		},
		[],
		true,
	)

	realtime.onEvent(async (ev) => {
		if (!ev || !ev.type) return
		switch (ev.type) {
			case 'task_created':
			case 'task_updated':
				var task = lo.get(ev, 'data.task')
				db.put(task.id, task)
				me.onUpdateTask(task)
				pubsub.publish('task', task)
				return
			case 'task_deleted':
				var task = lo.get(ev, 'data.task', {})
				if (!task.id) return
				if (!db.has(task.id)) {
					db.del(task.id)
				}
				me.onDeleteTask(task.id)
				pubsub.publish('task', task)
				return
			case 'task_history_updated':
				let entry = lo.get(ev, 'data.task_history_entry', {})
				let taskId = entry.task_id
				let evType = lo.get(entry, 'event.type')
				if (taskId && evType === 'task_comment_added') {
					let events = kv.match(`task_comments_${taskId}`)
					if (lo.size(events)) {
						events.push(entry)
					} else {
						events = [entry]
					}
					kv.put(`task_comments_${taskId}`, events)
				}
				pubsub.publish('task_history', ev)
				return
		}
	})

	me.onTask = (o, cb) => pubsub.on2(o, 'task', cb)
	me.onSortTask = (o, cb) => pubsub.on2(o, 'tasks_sort', cb)
	me.onTaskHistory = (o, cb) => pubsub.on2(o, 'task_history', cb)
	realtime.subscribe([
		`task_created.account.${account_id}.agent.${agent_id}`,
		`task_updated.account.${account_id}.agent.${agent_id}`,
		`task_deleted.account.${account_id}.agent.${agent_id}`,
		`task_history_updated.account.${account_id}.agent.${agent_id}`,
	])

	// use to keep draft task
	me.matchDraftTask = () => {
		return kv.match('draft_task') || {}
	}

	me.updateDraftTask = (task) => {
		kv.put('draft_task', task)
	}

	me.matchTasksSortBy = () => {
		return ls.match('task_sort_by')
	}

	me.updateTasksSortBy = (v) => {
		let currentSortBy = kv.match('task_sort_by')
		if (currentSortBy === v) return
		console.log('updateTasksSortBy', v)
		ls.put('task_sort_by', v)
		pubsub.publish('tasks_sort')
	}

	me.updateTask = async (task, fields) => {
		var {error, body} = await api.update_task(task, fields)
		if (error) return {error}
		db.put(task.id, body)
		return body
	}

	me.seenTask = async (id) => {
		if (!id || id === 'new' || id === '-') return undefined
		let task = db.match(id)
		if (task) {
			task.last_seen = Date.now()
			db.put(id, task)
		}
		var {error, body} = await api.seen_task(id)
		pubsub.publish('task')
	}

	me.closeTaskReminder = async (id) => {
		if (!id || id === 'new' || id === '-') return undefined
		let task = db.match(id)
		if (task) {
			let members = task.members
			if (!members) {
				members = [{agent_id, reminder_closed: Date.now()}]
			} else {
				members.push({agent_id, reminder_closed: Date.now()})
			}
			task.members = members
			db.put(id, task)
		}
		var {error, body} = await api.close_task_reminder(id)
		pubsub.publish('task')
	}

	me.markTaskDone = async (id, status) => {
		var {error, body} = await api.update_task({id, status: status || 'done'}, ['status'])
		if (error) return {error}
		if (!status || status === 'done') {
			if (!$audio) return
			$audio.currentTime = 0
			$audio.play()
		}
		db.put(id, body)
		return body
	}

	me.createTask = async (task) => {
		// agent auto seen when create task
		task.members = [{agent_id, last_seen: Date.now()}]
		var {error, body} = await api.create_task(task)
		if (error) return {error}
		db.put(task.id, body)
		return body
	}

	me.deleteTask = async (id) => {
		var {error, body: task} = await api.delete_task(id)
		if (error) return {error}
		db.del(id)
		return
	}

	me.matchTask = (id) => {
		if (!id || id === '-') return undefined
		return db.match(id)
	}

	me.fetchTasks = (ids, force) => db.fetch(ids, force)

	me.fetchUserTasks = async (userid) => {
		await refetch('user.' + userid, {associated_users: [userid], shorten: true, limit: 1000, order_by: 'actived'})
		return data['user.' + userid]
	}

	me.matchUserTasks = (userid) => data['user.' + userid]

	me.fetchTaskComments = async (taskid) => {
		let out = await api.list_task_comments(taskid)
		if (out.error) return {error: out.error}
		let hist = lo.get(out, 'body.entries') || []
		kv.put('task_comments_' + taskid, hist)
		return hist
	}

	me.matchTaskComments = (taskid) => {
		return kv.match('task_comments_' + taskid) || []
	}

	me.addTaskComment = api.add_task_comment

	let data = {}
	let lastFetch = {}
	let fetching = {}
	let fetchLock = {}

	let refetchid = 0
	let refetch = async (name, query, force) => {
		if (fetchLock[name]) {
			await sb.sleep(100)
			return await refetch(name, query, force)
		}

		fetchLock[name] = true
		if (data[name]) {
			fetchLock[name] = false
			return data[name]
		}

		let out = await api.list_tasks(query)
		let tasks = lo.get(out, 'body.tasks', [])
		tasks = lo.filter(tasks, (task) => statisfyFilter(task, query))
		data[name] = tasks.sort(compareTask)
		pubsub.publish('task')
		fetchLock[name] = false
	}

	me.matchFetchStatus = () => fetching['filter']

	me.listOverdueTasks = async () => {
		await refetch('my_overdue', Object.assign({}, myOverdueFilter, {shorten: true, limit: 1000, order_by: 'actived'}))
		return data['my_overdue']
	}

	me.listRemindedTasks = async () => {
		await refetch(
			'reminded_tasks',
			Object.assign({}, remindedFilter, {shorten: true, limit: 1000, order_by: 'actived'}),
		)
		return data.reminded_tasks
	}

	// only call this function on list task component
	// otherwise this could cause a loop
	me._listTasks = (filter) => {
		let force = false
		if (JSON.stringify(filter) != JSON.stringify(_filter)) {
			_filter = filter
			delete data['filter']
			force = true
		}
		refetch('filter', Object.assign({}, filter, {shorten: true, limit: 1000, order_by: 'actived'}), force)
		return data['filter']
	}

	let _filter = {}
	me.onUpdateTask = (task) => {
		// find associate user
		lo.map(task.associated_users, (userid) => {
			if (!data['user.' + userid]) return

			let foundIndex = lo.findIndex(data['user.' + userid], (t) => t.id == task.id)
			if (foundIndex > -1) {
				let t = data['user.' + userid][foundIndex]
				t.actived = task.actived
				t.title = task.title
				t.status = task.status
				t.pinned = task.pinned
				t.due_at = task.due_at
				t.reminder = task.reminder
				t.assignee = task.assignee
				t.watchers = task.watchers
				t.members = task.members
				t.created_by = task.created_by
				t.created = task.created
				t.supervisor = task.supervisor
				t.associated_users = task.associated_users
				data['user.' + userid].sort(compareUserTask)
				return
			}
			data['user.' + userid].push(task)
			data['user.' + userid].sort(compareUserTask)
		})

		if (data['my_overdue']) me.updateMyOverdue(task)

		if (!data['filter']) return
		let foundIndex = lo.findIndex(data['filter'], (t) => t.id == task.id)
		if (foundIndex > -1) {
			if (!statisfyFilter(task, _filter)) return data['filter'].splice(foundIndex, 1)
			let t = data['filter'][foundIndex]
			t.actived = task.actived
			t.title = task.title
			t.status = task.status
			t.pinned = task.pinned
			t.due_at = task.due_at
			t.reminder = task.reminder
			t.assignee = task.assignee
			t.watchers = task.watchers
			t.done_at = task.done_at
			t.members = task.members
			t.created_by = task.created_by
			t.created = task.created
			t.supervisor = task.supervisor
			t.associated_users = task.associated_users
			data['filter'].sort(compareTask)
			return
		}

		if (!statisfyFilter(task, _filter)) return
		data['filter'].push(task)
		data['filter'].sort(compareTask)
	}

	me.isTaskUnread = (taskid) => {
		let task = me.matchTask()
		if (!task) {
			// look in filter
		}
	}

	me.onDeleteTask = (taskid) => {
		lo.map(data, (dat) => {
			if (!dat) return
			let foundIndex = lo.findIndex(dat, (t) => t.id == taskid)
			if (foundIndex > -1) dat.splice(foundIndex, 1)
		})
	}

	me.updateMyOverdue = (task) => {
		let foundIndex = lo.findIndex(data['my_overdue'], (t) => t.id == task.id)
		if (foundIndex > -1) {
			if (!statisfyFilter(task, myOverdueFilter)) return data['my_overdue'].splice(foundIndex, 1)
			let t = data['my_overdue'][foundIndex]
			t.actived = task.actived
			t.title = task.title
			t.status = task.status
			t.pinned = task.pinned
			t.due_at = task.due_at
			t.reminder = task.reminder
			t.assignee = task.assignee
			t.done_at = task.done_at
			t.watchers = task.watchers
			t.members = task.members
			t.created_by = task.created_by
			t.created = task.created
			t.supervisor = task.supervisor
			t.associated_users = task.associated_users
			data['my_overdue'].sort(compareTask)
			return
		}
		if (!statisfyFilter(task, myOverdueFilter)) return
		data['my_overdue'].push(task)
		data['my_overdue'].sort(compareTask)
	}

	function compareUserTask(a, b) {
		let aactived2Min = (a.actived || 0) / 120000
		let bActived2Min = (b.actived || 0) / 120000
		if (bActived2Min === aactived2Min) return a.id > b.id ? -1 : 1
		return aactived2Min > bActived2Min ? -1 : 1

		// Below is old logic for sort overdue first
		// most overdue go first
		// dont try to fix this logic
		//let aOverdue = a.status !== 'done' && a.due_at && new Date() > startOfDay(new Date(a.due_at))
		//let bOverdue = b.status !== 'done' && b.due_at && new Date() > startOfDay(new Date(b.due_at))
		//aOverdue = aOverdue ? 0 : 1 // convert to number
		//bOverdue = bOverdue ? 0 : 1
		//const MAX_INT = 9007199254740991
		//let aDueAt = a.due_at ? a.due_at - MAX_INT : -1
		//let bDueAt = b.due_at ? b.due_at - MAX_INT : -1
		//if (aDueAt * (1 - aOverdue) > bDueAt * (1 - bOverdue)) return 1
		//if (aDueAt * (1 - aOverdue) < bDueAt * (1 - bOverdue)) return -1

		//// done status to the end
		//if (a.status === 'done' && b.status !== 'done') return 1
		//if (a.status !== 'done' && b.status === 'done') return -1

		//let aCreated = a.created || 0
		//let bCreated = b.created || 0
		//if (aCreated == bCreated) return a.id < b.id ? 1 : -1
		//return aCreated < bCreated ? 1 : -1

		//let aactived2Min = (a.actived || 0) / 120000
		//let bActived2Min = (b.actived || 0) / 120000
		//if (bActived2Min === aactived2Min) return a.id > b.id ? -1 : 1
		//return aactived2Min > bActived2Min ? -1 : 1
	}

	function compareTask(a, b) {
		let {agent_id} = api.getCred()

		let aPinned = lo.find(a.members, (mem) => mem.agent_id === agent_id)
		aPinned = lo.get(aPinned, 'pinned', 0)
		if (a.status === 'done') aPinned = 0
		let bPinned = lo.find(b.members, (mem) => mem.agent_id === agent_id)
		bPinned = lo.get(bPinned, 'pinned', 0)
		if (b.status === 'done') bPinned = 0
		if (aPinned > bPinned) return -1
		if (aPinned < bPinned) return 1

		if (a.status === 'done' && b.status !== 'done' && Date.now() - (a.done_at || 0) > 5 * 60_000) return 1
		if (a.status !== 'done' && b.status === 'done' && Date.now() - (b.done_at || 0) > 5 * 60_000) return -1

		let sortBy = ls.match('task_sort_by')
		if (sortBy === 'due_at') {
			let aOverdue = a.status !== 'done' && a.due_at && new Date() > startOfDay(new Date(a.due_at))
			let bOverdue = b.status !== 'done' && b.due_at && new Date() > startOfDay(new Date(b.due_at))
			aOverdue = aOverdue ? 0 : 1 // convert to number
			bOverdue = bOverdue ? 0 : 1
			const MAX_INT = 9007199254740991
			let aDueAt = a.due_at ? a.due_at - MAX_INT : -1
			let bDueAt = b.due_at ? b.due_at - MAX_INT : -1
			if (aDueAt * (1 - aOverdue) > bDueAt * (1 - bOverdue)) return 1
			if (aDueAt * (1 - aOverdue) < bDueAt * (1 - bOverdue)) return -1
		} else if (sortBy === 'created') {
			let aCreated = a.created || 0
			let bCreated = b.created || 0
			if (aCreated === bCreated) return a.id < b.id ? 1 : -1
			return aCreated < bCreated ? 1 : -1
		}

		return compareUserTask(a, b)
	}
	return me
}

function isWatched(task, agid) {
	if (!task) return false
	if (!agid) return true
	if (task.assignee == agid) return true
	if (task.supervisor == agid) return true
	return lo.includes(task.watchers, agid)
}

function statisfyFilter(task, filter) {
	if (filter.watcher) {
		if (!isWatched(task, filter.watcher)) return false
	}

	if (filter.is_overdue) {
		if (!task.due_at) return false
		if (task.due_at > Date.now()) return false
	}

	if (filter.status) {
		if (filter.status == 'open' || filter.status == '') {
			if (task.status != 'open' && task.status) return false
		} else if (task.status !== filter.status) return false
	}

	if (filter.assignee) {
		if (task.assignee !== filter.assignee) return false
	}
	return true
}

export default TaskStore
