const sb = require('@sb/util')
const flow = require('@subiz/flow')
const config = require('@sb/config')
import KV from './kv.js'
import InMemKV from './inmem_kv.js'

// parent {api, realtime, insync, pubsub}
// like kv store, but support match and pubsub
function NewObjectStore(accid, realtime, pubsub, name, matcher, topics, inmem) {
	let kv
	if (inmem) kv = new InMemKV()
	else kv = new KV(config.db_prefix + accid + '.' + name)
	kv.init()

	// expires time in ms
	// must re-fetch data if expires
	let expires = {}

	let isExpired = (key) => (expires[key] || 0) < Date.now()

	var me = {}
	me.put = async (key, value) => {
		let ret = kv.put(key, value)
		if (!Array.isArray(ret)) ret = [ret]
		let itemM = {}
		lo.map(ret, (item) => {
			if (item && item.id) itemM[item.id] = item
		})
		if (lo.size(itemM) === 0) return
		if (me.onchange) me.onchange(itemM)
		if (me.beforePublish) pubsub.publish(name, me.beforePublish(itemM))
		else pubsub.publish(name, itemM)
		lo.map(itemM, (item) => pubsub.publish(name + '.' + item.id, item))
	}

	me.has = (key) => {
		if (!key) return false
		return !!kv.match(key)
	}

	me.lastRead = {}
	setTimeout(async () => {
		for (;;) {
			await sb.sleep(5000)
			for (let key in me.lastRead) if (isExpired(key)) fetchQueue.push(key)
			me.lastRead = {}
		}
	})

	me.match = (key) => {
		if (!key) return {}
		me.lastRead[key] = true

		let out = kv.match(key)
		return out
	}

	me.all = () => kv.all()

	me.del = (key) => kv.del(key)

	me.fetch = async (keys, force, log) => {
		if (!keys) return {}
		if (typeof keys === 'string') keys = [keys]
		if (!Array.isArray(keys)) throw new Error('keys must be array')
		let unsyncids = lo.filter(keys, (k) => !!k)
		if (!force) {
			unsyncids = unsyncids.filter((k) => isExpired(k))
		} else {
			unsyncids.map((k) => {
				expires[k] = 0
			})
		}
		let lastid = unsyncids.pop()
		lo.map(unsyncids, (id) => fetchQueue.push(id))
		if (lastid) await fetchQueue.push(lastid)
		return lo.map(keys, (k) => kv.match(k))
	}

	let fetchQueue = new flow.batch(100, 1, async (ids) => {
		let suberr
		if (lo.size(topics) > 0) {
			let {error} = await realtime.subscribe(topics)
			suberr = error
		}
		// ids = ids.filter((key) => key.startsWith('cs'))
		ids = lo.uniq(ids)
		// filter items that must be fetched
		ids = ids.filter((id, i) => {
			if (!id) return false
			if (!isExpired(id)) return false
			return true
		})
		let items = ids.map((id) => {
			let item = kv.match(id) || {}
			item.id = id
			return item
		})

		if (items.length === 0) return []

		let {data: newitems, error} = await matcher(items)
		if (error) {
			console.log('ERR', error)
			return []
		}

		newitems = newitems || {}
		let itemM = {}
		ids.map((id, i) => {
			let newitem = newitems[i] || {}
			if (newitem.id) itemM[id] = newitem // server does return new data
		})

		// add 1 more expire days if subscribe success
		if (!suberr) {
			ids.map((id) => {
				expires[id] = Date.now() + 120000 // 2 mins
			})
		}

		kv.put(lo.map(itemM))
		ids.map((id) => {
			expires[id] = Date.now() + 30000 // should retry in 30 sec
		})
		if (me.onchange) me.onchange(itemM)
		if (me.beforePublish) pubsub.publish(name, me.beforePublish(itemM))
		pubsub.publish(name, itemM)
		lo.map(itemM, (item) => pubsub.publish(name + '.' + item.id, item))
	})

	return me
}

export default NewObjectStore
