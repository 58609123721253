import store from '@sb/store'
import sb from '@sb/util'
import {computeErrorMessage} from '../commons/compute_error_message.js'
import LexicalEditor from '../commons/lexical-editor.js'

export default {
	name: 'ticket-message-sent-event',
	props: ['ev', 'ticket_id'],
	data() {
		return {
			isEdit: false,
			saving: false,
			initMessage: {},
		}
	},

	methods: {
		onSelectMoreOption(action) {
			if (action === 'edit') return this.openEdit()
		},

		async openEdit() {
			this.initMessage = lo.get(this.ev, 'data.message') || {}
			this.isEdit = true
			await this.$nextTick()

			this.$refs.note_input && this.$refs.note_input.focus()
		},

		async submit() {
			this.saving = true
			let res = await store.editTicketComment(this.ticket_id, this.ev.id, this.initMessage)
			this.saving = false
			if (res.error) {
				this.$showError(res.error)
				return
			}
			this.isEdit = false
		},

    // this copy from activities/covnersation/textMessge.js file. Please make sure 2 function is the same.
    // Will merge 2 funciton later
		renderOneBlock(block) {
			if (lo.size(block) == 0) return null
			let ele = {}
			ele.style = ''
			if (block.style) {
				lo.map(block.style, (v, k) => {
					ele.style += k + ': ' + v + ';'
				})
			}

			ele.attrs = {}
			if (block.title != '') {
				ele.attrs['title'] = block.title
			}
			if (block.href != '') {
				ele.attrs['href'] = block.href
			}

			ele.class = block.class
			lo.map(block.attrs, (v, k) => {
				ele.attrs[k] = v
			})
			if (block.type == 'bullet_list' || block.type == 'ordered_list') {
				if (block.type == 'bullet_list') {
					ele.tag = 'ul'
				} else {
					ele.tag = 'ol'
				}
			}

			if (block.type == 'list_item') {
				ele.tag = 'li'
			}

			if (block.type == 'heading') {
				if (block.level < 1) block.level = 1
				if (block.level > 6) block.level = 6
				ele.tag = 'h' + block.level
			}

			if (block.type == 'paragraph') ele.tag = 'div'
			ele.text = block.text
			if (block.type == '' || block.type == 'text' || block.type == 'dynamic-field') {
				ele.tag = 'span'
				if (block.bold) ele.tag = 'b'
				if (block.italic) {
					if (ele.tag == 'b') {
						ele.style += 'font-style: italic;'
					} else {
						ele.tag = 'em'
					}
				}

				if (block.underline) ele.style += 'text-decoration:underline;'
				if (block.strike_through) ele.style += 'text-decoration: line-through;'
			}

			if (block.type == 'link') {
				ele.tag = 'a'
				ele.attrs['href'] = block.href
				ele.attrs['target'] = block.target
				ele.attrs['title'] = block.title
			}
			if (block.type == 'mention-agent' || block.type == 'mention') {
				ele.tag = 'span'
				ele.class = 'mention'

				let id = (block.attrs.id = '')

				if (block.attrs.id == '*' || block.attrs.id == 'all') {
					ele.text = '@' + this.$t('all')
				}

				if (id.startsWith('ag')) {
					let ag = store.matchAgent(id)
					let name = sb.getAgentDisplayName(ag, insert.mention.fullname)
					ele.text = '@' + name
				}
			}
			if (block.type == 'horizontal_rule') ele.tag = 'hr'
			if (block.type == 'emoji') {
				let code = ''
				if (lo.size(block.attrs) > 0) code = block.attrs['code']
				if (code == '') code = block.Text
				if (code != '') {
					ele.tag = 'Emoji' //
					ele.attrs['emojiCode'] = code
				}
			}

			if (block.type == 'table') {
			}

			if (block.type === 'image') {
				ele.tag = 'img'
				ele.attrs['src'] = lo.get(block, 'image.url')
				ele.attrs['alt'] = block.alt_text
			}

			let $body = null
			if (lo.size(block.content)) $body = lo.map(block.content, (block) => this.renderOneBlock(block))
			else $body = ele.text

			switch (ele.tag) {
				case 'img':
					return <img class={ele.class} style={ele.style} {...{attrs: ele.attrs}} />
				case 'div':
					return (
						<div class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</div>
					)
				case 'h1':
					return (
						<h1 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h1>
					)
				case 'h2':
					return (
						<h2 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h2>
					)
				case 'h3':
					return (
						<h3 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h3>
					)
				case 'h4':
					return (
						<h4 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h4>
					)
				case 'h5':
					return (
						<h5 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h5>
					)
				case 'h6':
					return (
						<h6 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h6>
					)
				case 'ol':
					return (
						<ol class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</ol>
					)
				case 'ul':
					return (
						<ul class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</ul>
					)
				case 'li':
					return (
						<li class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</li>
					)
				case 'span':
					return (
						<span class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</span>
					)
				case 'em':
					return (
						<em class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</em>
					)
				case 'b':
					return (
						<b class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</b>
					)

				case 'hr':
					return <hr class={ele.class} style={ele.style} {...{attrs: ele.attrs}} />
				case 'a':
					return (
						<a {...{attrs: ele.attrs}} class={ele.class} style={ele.style} target='_blank'>
							{$body}
						</a>
					)
				case 'Emoji':
					return <Emoji {...{attrs: ele.attrs}} class={ele.class} style={ele.style} />
			}

			return (
				<component class={ele.class} is={ele.tag} style={ele.style} {...ele.attrs}>
					{$body}
				</component>
			)
		},
	},

	render() {
		let ev = this.ev
		let agentid = lo.get(ev, 'by.id')
		let agent = store.matchAgent()[agentid] || {id: agentid}
		let text = lo.get(ev, 'data.message.text') || ''

		let $attachments = null
		let attachments = lo.get(ev, 'data.message.attachments') || []
		if (lo.size(attachments)) {
			$attachments = (
				<div class='d-flex flex-wrap mt-3'>
					{lo.map(attachments, (file, idx) => {
						if ((file.mimetype || '').startsWith('image')) {
							return (
								<div class='task_detail_attachment' style='width: 60px; height: 60px'>
									<img2
										clickable
										src={file.url}
										style='width: 100%; height: 100%; border-radius: 8px; overflow: hidden'
									/>
								</div>
							)
						}

						return (
							<div class={{task_detail_attachment: true}} style='width: 60px; height: 60px'>
								<div class='text__center text__sm pl-3 pr-3' style='max-width: 100%'>
									<Icon name='file' size='16' stroke-width='2' class={{text__secondary: true}} />
									<br />
									<a
										href={file.url}
										target='_blank'
										style='display: inline-block; overflow: hidden; max-height: 30px; max-width: 100%'
										class={{
											link: true,
											text__xxs: true,
											'mt-2': true,
											link__secondary: true,
										}}
										title={file.name}
									>
										{file.name}
									</a>
								</div>
							</div>
						)
					})}
				</div>
			)
		}
		let items = []
		if (agentid === store.me().id) items.push({id: 'edit', label: this.$t('edit')})
		let block = sb.lexicalHtmlToBlock(text)

		return (
			<div class={{ticket_detail_event_message_sent: true, danger: ev._error}}>
				{ev._sending && <div class='ticket_detail_event_message_sent_status text__muted'>{this.$t('sending')}...</div>}
				{ev._error && <div class='ticket_detail_event_message_sent_status text__danger'>{this.$t('send_error')}</div>}
				<div class='d-flex align-items-center text__sm'>
					<Avatar agent={agent} size='16' />
					<div class='ml-3 text__muted'>
						<span class='text__semibold'>{sb.getAgentDisplayName(agent)}</span>
					</div>
					<div class='text__muted' style='margin-left: 15px'>
						<Time time={ev.created} ago suffix />{' '}
						{ev.updated && <span style='font-size: 12px'>({this.$t('edited').toLowerCase()})</span>}
					</div>
					<div class='ml-auto d-flex align-items-center'>
						<Dropdown
							mode='custom'
							items={items}
							dropdown_width={200}
							vOn:select={(item) => this.onSelectMoreOption(item.id)}
						>
							<button type='button' class='btn btn__xs btn__transparent' style='line-height: 1'>
								<Icon name='dots' size='14' />
							</button>
						</Dropdown>
					</div>
				</div>
				{this.isEdit ? (
					<div class='mt-2 ticket_message_sent_editable' key='is-edi'>
						<LexicalEditor
							class=''
							plaintext
							useMention
							initMessage={this.initMessage}
							ref='note_input'
							vOn:change={(msg) => (this.initMessage = msg)}
						/>
						<div class='d-flex mt-3 justify-content-end'>
							<button
								disabled={this.saving}
								type='button'
								class='btn btn__sm btn__light mr-3'
								vOn:click={() => (this.isEdit = false)}
							>
								{this.$t('cancel')}
							</button>
							<button disabled={this.saving} type='button' class='btn btn__sm btn__primary' vOn:click={this.submit}>
								{this.$t('save')}
							</button>
						</div>
					</div>
				) : (
					<Fragment>
						<div class='mt-2' style='white-space: pre-wrap; word-break: break-word;'>
							{this.renderOneBlock(block)}
						</div>
						{$attachments}
					</Fragment>
				)}
			</div>
		)
	},
}
